import {
  React,
  connect,
  Link,
  reduxForm,
  Panel,
  PanelHeader,
  PanelBody,
  postDataNoTImeOut2,
  useState,
  ToastNotification,
  reset,
} from "../../../components/helpers/library";
import HeadPembagianPesanan from "./HeadPembagianPesanan";

import { useDispatch } from "react-redux";
import { setTmpBarang } from "../../../actions/databarang_action";
let PembagianPesanan = () => {
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const handleSubmit = (data) => {
    let row = {
      no_pesanan: data.no_pesanan.toUpperCase(),
      kode_tukang: data.kode_tukang,
      ongkos_tukang: parseInt(data.ongkos_tukang),
      berat_susut: parseFloat(data.berat_susut === undefined ? 0 : data.berat_susut),
    };
    setLoading(true);

    postDataNoTImeOut2("pembagian-pesanan", row, [], true)
      .then((res) => {
        ToastNotification("success", "Berhasil Disimpan");
        dispatch(setTmpBarang([]));
        dispatch(reset("HeadPembagianPesanan"));
        setLoading(false);
        if (res.data.length !== 0) {
          let notapesanan = [];
          for (let index = 0; index < 2; index++) {
            let feedback = "";
            let jml_alamat = res.data.slip.alamat.length;
            let keterangan = res.data.slip.keterangan;
            let jml_keeterangan = keterangan.deskripsi.length;
            feedback += `========================================\n`;
            feedback += `         NOTA PEMBAGIAN PESANAN         \n`;
            feedback += `========================================\n`;
            feedback += `No Pesanan       : ${res.data.slip.no_pesanan}\n`;
            feedback += `Tgl Pesan        : ${res.data.slip.tgl}\n`;
            feedback += `Tgl Selesai      : ${res.data.slip.tgl_selesai}\n`;
            feedback += `Tukang           : ${res.data.slip.tukang || "-"}\n`;
            feedback += `Kode Member      : ${res.data.slip.kode_member || "-"
              }\n`;
            feedback += `Nama Pemesan     : ${res.data.slip.nama_pemesan}\n`;
            feedback += `Alamat Customer  : ${res.data.slip.alamat.slice(
              0,
              20
            )}\n`;
            if (jml_alamat > 20) {
              feedback += `                   ${res.data.slip.alamat.slice(
                20,
                40
              )}\n`;
            }
            if (jml_alamat > 40) {
              feedback += `                   ${res.data.slip.alamat.slice(
                40,
                60
              )}\n`;
            }
            feedback += `Telepon          : ${res.data.slip.telepon}\n`;
            feedback += `========================================\n`;
            feedback += `Keterangan\n`;
            feedback += `========================================\n`;
            let jmlJenis = keterangan.jenis_pesanan.length;
            // res.data.slip.keterangan.forEach((detail, index) => {
            feedback += `Kategori           : ${keterangan.kategori}\n`;
            feedback += `Nama Barang        : ${keterangan.jenis_pesanan.slice(
              0,
              20
            )}\n`;
            if (jmlJenis === 20) {
              feedback += `                     ${keterangan.jenis_pesanan.slice(
                20,
                40
              )}\n`;
            }
            if (jmlJenis === 40) {
              feedback += `                     ${keterangan.jenis_pesanan.slice(
                40,
                60
              )}\n`;
            }
            feedback += `Atribut            : ${keterangan.atribut && keterangan.atribut.toUpperCase()
              }\n`;
            feedback += `Deskripsi          : ${keterangan.deskripsi.slice(
              0,
              20
            )}\n`;
            if (jml_keeterangan > 20) {
              feedback += `                     ${keterangan.deskripsi.slice(
                20,
                40
              )}\n`;
            }
            if (jml_keeterangan > 40) {
              feedback += `                     ${keterangan.deskripsi.slice(
                40,
                60
              )}\n`;
            }
            if (jml_keeterangan > 60) {
              feedback += `                     ${keterangan.deskripsi.slice(
                50,
                70
              )}\n`;
            }

            feedback += `Berat              : ${keterangan.berat.toFixed(3)}\n`;
            feedback += `Berat Susut        : ${keterangan.berat_susut.toFixed(
              3
            )}\n`;
            feedback += `Ongkos Tukang      : ${keterangan.ongkos_tukang.toLocaleString(
              "kr-ko"
            )}\n`;
            feedback += `Berat Atribut      : ${keterangan.berat_atribut.toFixed(
              3
            )}\n`;
            feedback += `Size               : ${keterangan.size}\n`;
            feedback += `========================================\n`;
            // })
            feedback += `



`;
            notapesanan.push(feedback);
          }
          document.getElementById("nota_pesanan").value = notapesanan.join("");
        }
      })
      .then(() => {
        const element = document.createElement("a");
        const file = new Blob([document.getElementById("nota_pesanan").value], {
          type: "text/plain;charset=utf-8",
        });
        element.href = URL.createObjectURL(file);
        element.download = "autoprint_pesanan.txt";
        document.body.appendChild(element);
        element.click();
      })
      .catch((err) => {
        console.log(err);
        ToastNotification("info", err.response?.data?.message);
        setLoading(false);
      });
  };

  return (
    <div>
      <textarea
        style={{ display: "none" }}
        id="nota_pesanan"
        rows="100"
        cols="100"
      />
      <ol className="breadcrumb float-xl-right">
        <li className="breadcrumb-item">
          <Link to="#">Pesanan</Link>
        </li>
        <li className="breadcrumb-item active">Pembagian Pesanan</li>
      </ol>
      <h1 className="page-header">Pembagian Pesanan </h1>
      <Panel>
        <PanelHeader>Pembagian Pesanan</PanelHeader>
        <br />
        {/* Pembagian Pesanan */}
        <PanelBody>
          <HeadPembagianPesanan
            isLoading={isLoading}
            onSubmit={(data) => handleSubmit(data)}
          />
        </PanelBody>

        <br />
      </Panel>
    </div>
  );
};

PembagianPesanan = reduxForm({
  form: "PembagianPesanan",
  enableReinitialize: true,
})(PembagianPesanan);
export default connect()(PembagianPesanan);
