import jsPDF from "jspdf";
import "jspdf-autotable";
import {
  getToday,
  getUserdata,
  localStoragedecryp,
} from "../../../../../components/helpers/function";
// Date Fns is used to format the dates we receive

// define a generatePDF function that accepts a tickets argument
const LaporanPesananSoNew = (data = "") => {
  // initialize jsPDF
  const doc = new jsPDF("l", "mm", [297, 210]);
  //   let data = JSON.parse(localStorage.getItem("tt_pengeluaran_barang")) || [];
  let tableRows = [];
  // let footRows = [];
  let tableColumn = [];
  let finalY = 30;

  doc.setFontSize(15);
  doc.text("LAPORAN PESANAN SO", 14, 15);
  doc.setFontSize(20);
  doc.text(getUserdata().nama_toko, 200, 15);
  doc.setFontSize(8);
  let jml_alamat = getUserdata().alamat_toko.length;
  if (jml_alamat > 20) {
    doc.text(getUserdata().alamat_toko.slice(0, 48), 200, 20);
  }
  if (jml_alamat > 50) {
    doc.text(getUserdata().alamat_toko.slice(48, 90), 200, 25);
  }
  doc.setFontSize(10);
  doc.setProperties({
    title: "LAPORAN PESANAN SO",
  });
  //row 1
  doc.text(
    `PERIODE : ${
      localStoragedecryp("tgl_laporan", [], true).length === 0
        ? null
        : localStoragedecryp("tgl_laporan", [], true).tgl_awal +
          " s/d " +
          localStoragedecryp("tgl_laporan", [], true).tgl_akhir
    }`,
    14,
    25
  );
  tableColumn = [
    [
      {
        content: `LAPORAN PESANAN SO`,
        colSpan: 10,
        styles: {
          halign: "center",
        },
      },
    ],
  ];
  const datalap = [];
  data.forEach((element) => {
    const rowHeader = [
      {
        content: "NO SO PESANAN",
        styles: {
          fontSize: 8,
          // lineWidth: 0.02,
          // lineColor: "#000",
          fillColor: "#E8E5E5",
          textColor: "#000",
          fontStyle: "bold",
        },
        colSpan: 10,
      },
    ];
    tableRows.push(rowHeader);
    const rowHead = [{ content: element.no_so_pesanan, colSpan: 10 }];
    tableRows.push(rowHead);
    const bodyHead = [
      {
        content: `NO PERSEKOT`,
        styles: {
          fontSize: 8,
          // lineWidth: 0.02,
          // lineColor: "#000",
          fillColor: "#E8E5E5",
          textColor: "#000",
        },
      },
      {
        content: `KODE TUKANG`,
        styles: {
          fontSize: 8,
          // lineWidth: 0.02,
          // lineColor: "#000",
          fillColor: "#E8E5E5",
          textColor: "#000",
        },
      },
      {
        content: `DESKRIPSI`,
        styles: {
          fontSize: 8,
          // lineWidth: 0.02,
          // lineColor: "#000",
          fillColor: "#E8E5E5",
          textColor: "#000",
        },
      },
      {
        content: `NAMA BARANG`,
        styles: {
          fontSize: 8,
          // lineWidth: 0.02,
          // lineColor: "#000",
          fillColor: "#E8E5E5",
          textColor: "#000",
        },
      },
      {
        content: `NAMA ATRIBUT`,
        styles: {
          fontSize: 8,
          // lineWidth: 0.02,
          // lineColor: "#000",
          fillColor: "#E8E5E5",
          textColor: "#000",
        },
      },
      {
        content: `BERAT ATR`,
        styles: {
          halign: "right",
          fontSize: 8,
          // lineWidth: 0.02,
          // lineColor: "#000",
          fillColor: "#E8E5E5",
          textColor: "#000",
        },
      },
      {
        content: `BERAT PAKAI`,
        styles: {
          halign: "right",
          fontSize: 8,
          // lineWidth: 0.02,
          // lineColor: "#000",
          fillColor: "#E8E5E5",
          textColor: "#000",
        },
      },
      {
        content: `SUSUT PAKAI`,
        styles: {
          halign: "right",
          fontSize: 8,
          // lineWidth: 0.02,
          // lineColor: "#000",
          fillColor: "#E8E5E5",
          textColor: "#000",
        },
      },
      {
        content: `ONGKOS`,
        styles: {
          halign: "right",
          fontSize: 8,
          // lineWidth: 0.02,
          // lineColor: "#000",
          fillColor: "#E8E5E5",
          textColor: "#000",
        },
      },
      {
        content: `ST SELESAI`,
        styles: {
          fontSize: 8,
          // lineWidth: 0.02,
          // lineColor: "#000",
          fillColor: "#E8E5E5",
          textColor: "#000",
        },
      },
      {
        content: `ST SERAH`,
        styles: {
          fontSize: 8,
          // lineWidth: 0.02,
          // lineColor: "#000",
          fillColor: "#E8E5E5",
          textColor: "#000",
        },
      },
    ];
    tableRows.push(bodyHead);
    element.pesanan.forEach((row) => {
      datalap.push(row);
      let body = [
        row.no_pesanan,
        row.kode_tukang,
        row.deskripsi,
        row.nama_barang,
        row.nama_attribut.toUpperCase(),
        {
          content: parseFloat(row.berat_atribut).toFixed(3),
          styles: {
            halign: "right",
          },
        },
        {
          content: (
            parseFloat(row.berat_pakai) - parseFloat(row.susut_pakai)
          ).toFixed(3),
          styles: {
            halign: "right",
          },
        },
        {
          content: parseFloat(row.susut_pakai).toFixed(3),
          styles: {
            halign: "right",
          },
        },
        {
          content: parseInt(row.ongkos).toLocaleString("kr-Ko"),
          styles: {
            halign: "right",
          },
        },
        row.st_selesai,
        row.st_serah,
      ];
      tableRows.push(body);
    });
  });

  //   datalap.forEach((row) => {
  //     let body = [
  //       row.no_pesanan,
  //       row.kode_tukang,
  //       row.nama_barang,
  //       row.nama_attribut.toUpperCase(),
  //       {
  //         content: parseFloat(row.berat_atribut).toFixed(3),
  //         styles: {
  //           halign: "right",
  //         },
  //       },
  //       {
  //         content: (
  //           parseFloat(row.berat_pakai) - parseFloat(row.susut_pakai)
  //         ).toFixed(3),
  //         styles: {
  //           halign: "right",
  //         },
  //       },
  //       {
  //         content: parseFloat(row.susut_pakai).toFixed(3),
  //         styles: {
  //           halign: "right",
  //         },
  //       },
  //       {
  //         content: parseInt(row.ongkos).toLocaleString("kr-Ko"),
  //         styles: {
  //           halign: "right",
  //         },
  //       },
  //       row.st_selesai,
  //       row.st_serah,
  //     ];
  //     tableRows.push(body);
  //   });
  let foot = [
    {
      content:
        "__________________________________________________________________________________________________________________________________________________________________________",
      colSpan: 10,
      styles: {
        cellPadding: {
          top: -3,
          right: 0,
          bottom: 0,
          left: 2,
        },
        halign: "left",
      },
    },
  ];
  tableRows.push(foot);
  let tot = [
    {
      content: "",
      colSpan: 5,
      styles: {
        halign: "right",
      },
    },
    {
      content: datalap
        .reduce((a, b) => parseFloat(a) + parseFloat(b.berat_atribut), 0)
        .toFixed(3),
      styles: {
        halign: "right",
      },
    },
    {
      content: datalap
        .reduce((a, b) => parseFloat(a) + parseFloat(b.berat_pakai), 0)
        .toFixed(3),
      styles: {
        halign: "right",
      },
    },
    {
      content: datalap
        .reduce((a, b) => parseFloat(a) + parseFloat(b.susut_pakai), 0)
        .toFixed(3),
      styles: {
        halign: "right",
      },
    },
    {
      content: datalap
        .reduce((a, b) => parseInt(a) + parseInt(b.ongkos), 0)
        .toLocaleString("kr-Ko"),
      styles: {
        halign: "right",
      },
    },
  ];
  tableRows.push(tot);

  let pemisah = [
    {
      content:
        "__________________________________________________________________________________________________________________________________________________________________________",
      colSpan: 10,
      styles: {
        cellPadding: {
          top: -3,
          right: 0,
          bottom: 0,
          left: 2,
        },
        halign: "left",
      },
    },
  ];
  tableRows.push(pemisah);

  // let hasil = data.map((list) => {
  //   let jml =
  //     parseFloat(list.saldo_awal) -
  //     parseFloat(list.berat_pakai) -
  //     parseFloat(list.susut_pakai);

  //   return jml.toLocaleString("id-ID");
  // });
  const saldoawal = data.reduce((a, b) => a + parseFloat(b.saldo_awal || 0), 0);
  let saldo_awal = [
    {
      content: `Printed By ${
        localStoragedecryp("userdata").user_id
      } / ${getToday()}`,
      colSpan: 6,
      styles: {
        // lineWidth: 0.1,
        fontStyle: "italic",
        textColor: "#000",
        // fillColor: "#E8E5E5"
      },
    },
    {
      content: `Saldo Awal : ${saldoawal.toFixed(3)}`,
      styles: {
        // lineWidth: 0.1,
        halign: "right",
        fontStyle: "italic",
        textColor: "#000",
        // fillColor: "#E8E5E5"
      },
    },
  ];
  tableRows.push(saldo_awal);
  const sisabahan = data.reduce(
    (a, b) => a + parseFloat(b.saldo_bahan || 0),
    0
  );
  let sisa_bahan = [
    {
      content: ``,
      colSpan: 6,
      styles: {
        // lineWidth: 0.1,
        fontStyle: "italic",
        textColor: "#000",
        // fillColor: "#E8E5E5"
      },
    },
    {
      content: `Sisa Bahan : ${sisabahan.toFixed(3)}`,
      styles: {
        // lineWidth: 0.1,
        halign: "right",
        fontStyle: "italic",
        textColor: "#000",
        // fillColor: "#E8E5E5"
      },
    },
  ];
  tableRows.push(sisa_bahan);
  const setortotal = data.reduce((a, b) => a + parseFloat(b.setor || 0), 0);
  let setor = [
    {
      content: ``,
      colSpan: 6,
      styles: {
        // lineWidth: 0.1,
        fontStyle: "italic",
        textColor: "#000",
        // fillColor: "#E8E5E5"
      },
    },
    {
      content: `Setor : ${setortotal.toFixed(3)}`,
      styles: {
        // lineWidth: 0.1,
        halign: "right",
        fontStyle: "italic",
        textColor: "#000",
        // fillColor: "#E8E5E5"
      },
    },
  ];
  tableRows.push(setor);
  let garis = [
    {
      content: ``,
      colSpan: 6,
      styles: {
        // lineWidth: 0.1,
        fontStyle: "italic",
        textColor: "#000",
        // fillColor: "#E8E5E5"
      },
    },
    {
      content: "_______________",
      styles: {
        // lineWidth: 0.1,
        cellPadding: {
          top: -3,
          right: 1,
          bottom: 0,
          left: 0,
        },
        halign: "right",
        fontStyle: "italic",
        textColor: "#000",
        // fillColor: "#000",
      },
    },
  ];
  tableRows.push(garis);
  let selisih = [
    {
      content: ``,
      colSpan: 6,
      styles: {
        // lineWidth: 0.1,
        fontStyle: "italic",
        textColor: "#000",
        // fillColor: "#E8E5E5"
      },
    },
    {
      content: `Selisih : ${(setortotal - sisabahan).toFixed(3)}`,
      styles: {
        // lineWidth: 0.1,
        cellPadding: {
          top: -4,
          right: 4,
          bottom: 0,
          left: 0,
        },
        halign: "right",
        fontStyle: "italic",
        textColor: "#000",
        // fillColor: "#E8E5E5"
      },
    },
  ];
  tableRows.push(selisih);

  doc.autoTable({
    head: tableColumn,
    body: tableRows,
    // foot: tbl_barang,
    startY: finalY,
    theme: "plain",
    // rowPageBreak: "avoid",
    // pageBreak: "avoid",
    margin: { top: 10 },
    footStyles: {
      // lineWidth: 0.02,
      // lineColor: "#000",
      fontSize: 8,
    },
    bodyStyles: {
      // lineWidth: 0.02,
      // lineColor: "#000",
      fontSize: 8,
    },
    headStyles: {
      fontSize: 8,
      // lineWidth: 0.02,
      // lineColor: "#000",
      fillColor: "#E8E5E5",
      textColor: "#000",
    },
    tableLineColor: [255, 255, 255],
    tableLineWidth: 1,
  });
  finalY = doc.autoTableEndPosY() + 3;
  tableRows = [];
  // footRows = [];
  // tbl_barang = [];
  // const date = Date().split(" ");
  // we use a date string to generate our filename.
  // const dateStr = date[2] + date[3] + date[4];
  // ticket title. and margin-top + margin-left
  // doc.text(
  //   `User                          : ${
  //     localStoragedecryp("userdata").user_id
  //   }`,
  //   14,
  //   finalY + 10
  // );
  // doc.text(`Cetak                        : ${getToday()}`, 14, finalY + 16);
  const pages = doc.internal.getNumberOfPages();
  const pageWidth = doc.internal.pageSize.width; //Optional
  const pageHeight = doc.internal.pageSize.height; //Optional
  doc.setFontSize(10); //Optional
  for (let j = 1; j < pages + 1; j++) {
    let horizontalPos = pageWidth / 2; //Can be fixed number
    let verticalPos = pageHeight - 10; //Can be fixed number
    doc.setPage(j);
    doc.text(`${j} of ${pages}`, horizontalPos, verticalPos, {
      align: "center",
    });
  }
  // doc.autoPrint();
  doc.save(`LAPORAN PESANAN SO.pdf`);
  // var string = doc.output("datauristring");
  // var embed = "<embed width='100%' height='100%' src='" + string + "'/>";
  // var x = window.open();
  // x.document.open();
  // x.document.write(
  //   "<html><head><title>LAPORAN PESANAN SO</title></head><body style='margin:0 !important'><embed width='100%' height='100%'  src=" +
  //     string +
  //     "></embed></body></html>"
  // );
  // x.document.write(embed);
  // // setInterval(() => {
  //   x.close();
  // }, 1000);
};

export default LaporanPesananSoNew;
