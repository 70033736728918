const ValidasiKirimBarangBos = (value, event) => {
    const errors = {};
    // console.log(event.charCode==13);
    if (!value.kategori) {
      errors.kategori = "Kategori Tidak Boleh Kosong";
    }
    if (!value.jenis) {
      errors.jenis = "Jenis Tidak Boleh Kosong";
    }
    if (!value.asal_barang) {
      errors.asal_barang = "Detail Jenis Tidak Boleh Kosong";
    }
    if (!value.qty) {
      errors.qty = "Qty Tidak Boleh Kosong";
    }
    if (!value.berat) {
      errors.berat = "Berat Tidak Boleh Kosong";
    }
    return errors;
  };
  
  export default ValidasiKirimBarangBos;
  