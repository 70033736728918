const HeadStockPembelian = (value) => {
    const errors = {};
    // console.log(event.charCode==13);
    if (!value.tanggal_kirim) {
      errors.tanggal_kirim = "Tanggal Kirim Harus Diisi";
    }
    if (!value.kadar) {
      errors.kadar = "Kadar Harus Diisi";
    }
    if (!value.sales_pabrik) {
      errors.sales_pabrik = "Sales Pabrik Harus Diisi";
    }
    if (!value.tujuan) {
      errors.tujuan = "Tujuan Harus Diisi";
    }
    if (!value.kategori) {
      errors.kategori = "Kategori Harus Diisi";
    }
    if (!value.jenis) {
      errors.jenis = "Jenis Harus Diisi";
    }
    if (!value.berat) {
      errors.berat = "Berat Harus Diisi";
    }
    if (!value.qty) {
      errors.qty = "Qty Harus Diisi";
    }
    if (!value.asal_barang) {
      errors.asal_barang = "Asal Barang Harus Diisi";
    }
    if (!value.detail_jenis) {
      errors.detail_jenis = "Detail Jenis Harus Diisi";
    }
  
    return errors;
  };
  
  export default HeadStockPembelian;
  