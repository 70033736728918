import React, { Component } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import {
  getUserdata,
  localStoragedecryp,
} from "../../../../../components/helpers/function";
class LaporanTerimaBarangSepuhExel extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="btn btn-success btn-block"
          table="table-to-xls"
          filename="Laporan Transaksi Penjualan Sales Detail"
          sheet="Laporan Transaksi Penjualan Sales Detail"
          buttonText="Export Exel"
        />
        <table id="table-to-xls" style={{ display: "none" }}>
          <thead>
            <tr>
              <th colSpan="11" style={{ textAlign: "center" }}>
                {" "}
                LAPORAN TRANSAKSI PENJUALAN SALES DETAIL{" "}
              </th>
            </tr>
            <tr>
              <th colSpan="11"> Periode </th>
            </tr>
            <tr>
              <th colSpan="11">
                {" "}
                {localStoragedecryp("tgl_laporan",[], true).length === 0
                  ? null
                  : localStoragedecryp("tgl_laporan",[], true).tgl_awal +
                    " s/d " +
                    localStoragedecryp("tgl_laporan",[], true).tgl_akhir}{" "}
              </th>
            </tr>
            <tr>
              <th colSpan="11"> TOKO : {getUserdata().nama_toko} </th>
            </tr>

            <tr>
              <th colSpan="11"> ALAMAT : {getUserdata().alamat_toko} </th>
            </tr>
            <tr>
              <th colSpan="11"></th>
            </tr>
            <tr>
              <td style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                NO TERIMA SEPUH
              </td>
              <td style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                TGL TERIMA
              </td>
              <td style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                QTY SEPUH
              </td>
              <td style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                BERAT
              </td>
              <td style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                QTY TERIMA
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                BERAT TERIMA
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                BERAT RUSAK
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                QTY RUSAK
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                BERAT SELISIH
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                BERAT KIRIM
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                QTY KIRIM
              </td>
            </tr>
          </thead>
          {this.props.data.map((item, index) => (
            <>
              <tbody>
                {item.detail.map((row, index) => (
                  <>
                    <tr>
                      <td
                        colSpan="11"
                        style={{ textAlign: "left", border: "3px" }}
                      >
                        No Faktur : {item._id}
                      </td>
                    </tr>
                    <tr>
                      <td>{row.no_terima_sepuh}</td>
                      <td>{row.tgl_terima}</td>
                      <td style={{ textAlign: "right" }}>{row.qty_sepuh}</td>
                      <td style={{ textAlign: "right" }}>
                        {row.berat_timbangan}
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {row.qty_terima !== undefined ? row.qty_terima : 0}
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {row.berat_terima !== undefined
                          ? row.berat_terima.toFixed(2)
                          : 0}
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {row.berat_rusak !== undefined
                          ? row.berat_rusak.toFixed(2)
                          : 0}
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {row.qty_rusak !== undefined ? row.qty_rusak : 0}
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {row.berat_selisih !== undefined
                          ? row.berat_selisih.toFixed(2)
                          : 0}
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {row.berat_kirim !== undefined
                          ? row.berat_kirim.toFixed(2)
                          : 0}
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {row.qty_kirim !== undefined ? row.qty_kirim : 0}
                      </td>
                    </tr>
                  </>
                ))}
              </tbody>
            </>
          ))}
          <tfoot>
            <tr>
              <td
                colSpan="2"
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "center",
                }}
              >
                Sub Total
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                {this.props.data.reduce(
                  (acc, item) =>
                    acc + item.detail.reduce((a, b) => a + b.qty_sepuh, 0),
                  0
                )}
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                {this.props.data
                  .reduce(
                    (acc, item) =>
                      acc +
                      item.detail.reduce((a, b) => a + b.berat_timbangan, 0),
                    0
                  )
                  .toFixed(2)}
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                {this.props.data.reduce(
                  (acc, item) =>
                    acc + item.detail.reduce((a, b) => a + b.qty_terima, 0),
                  0
                )}
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                {this.props.data
                  .reduce(
                    (acc, item) =>
                      acc + item.detail.reduce((a, b) => a + b.berat_terima, 0),
                    0
                  )
                  .toFixed(2)}
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                {this.props.data
                  .reduce(
                    (acc, item) =>
                      acc + item.detail.reduce((a, b) => a + b.berat_rusak, 0),
                    0
                  )
                  .toFixed(2)}
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                {this.props.data
                  .reduce(
                    (acc, item) =>
                      acc + item.detail.reduce((a, b) => a + b.qty_rusak, 0),
                    0
                  )
                  .toFixed(2)}
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                {this.props.data
                  .reduce(
                    (acc, item) =>
                      acc +
                      item.detail.reduce((a, b) => a + b.berat_selisih, 0),
                    0
                  )
                  .toFixed(2)}
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                {this.props.data
                  .reduce(
                    (acc, item) =>
                      acc +
                      item.detail.reduce(
                        (a, b) => a + (!b.berat_kirim ? 0 : b.berat_kirim),
                        0
                      ),
                    0
                  )
                  .toFixed(2)}
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                {this.props.data.reduce(
                  (acc, item) =>
                    acc +
                    item.detail.reduce(
                      (a, b) => a + (!b.qty_kirim ? 0 : b.qty_kirim),
                      0
                    ),
                  0
                )}
              </td>
            </tr>
          </tfoot>
        </table>
      </>
    );
  }
}
export default LaporanTerimaBarangSepuhExel;
