import React, { Component } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import {
  getUserdata,
  localStoragedecryp,
} from "../../../../../components/helpers/function";
class LaporanKirimSepuhExel extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="btn btn-success btn-block"
          table="table-to-xls"
          filename="Laporan KIRIM SEPUH"
          sheet="Laporan KIRIM SEPUH"
          buttonText="Export Exel"
        />
        <table id="table-to-xls" style={{ display: "none" }}>
          <thead>
            <tr>
              <th colSpan="10" style={{ textAlign: "center" }}>
                {" "}
                LAPORAN KIRIM SEPUH{" "}
              </th>
            </tr>
            <tr>
              <th colSpan="10"> Periode </th>
            </tr>
            <tr>
              <th colSpan="10">
                {" "}
                {localStoragedecryp("tgl_laporan",[], true).length === 0
                  ? null
                  : localStoragedecryp("tgl_laporan",[], true).tgl_awal +
                    " s/d " +
                    localStoragedecryp("tgl_laporan",[], true).tgl_akhir}{" "}
              </th>
            </tr>
            <tr>
              <th colSpan="10"> TOKO : {getUserdata().nama_toko} </th>
            </tr>

            <tr>
              <th colSpan="10"> ALAMAT : {getUserdata().alamat_toko} </th>
            </tr>
            <tr>
              <th colSpan="10"></th>
            </tr>
            <tr>
              <td style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                TANGGAL
              </td>
              <td style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                KODE BAKI
              </td>
              <td style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                JENIS
              </td>
              <td style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                KODE GROUP
              </td>

              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                QTY
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                BERAT
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                QTY RUSAK
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                QTY SEPUH
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                BERAT RUSAK
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                BERAT SEPUH
              </td>
            </tr>
          </thead>
          {this.props.data.map((row, index) => (
            <>
              <tbody>
                <tr key={index}>
                  <td colSpan="9" style={{ textAlign: "left", border: "3px" }}>
                    No Faktur : {row.no_sepuh}
                  </td>
                </tr>
                <tr>
                  <td>{row.tgl_system}</td>
                  <td>{row.kode_baki}</td>
                  <td>{row.kode_dept}</td>
                  <td>{row.kode_group}</td>
                  <td style={{ textAlign: "right" }}>{row.qty}</td>
                  <td style={{ textAlign: "right" }}>{row.berat}</td>
                  <td style={{ textAlign: "right" }}>
                    {row.qty_rusak !== undefined ? row.qty_rusak : 0}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {row.qty_espuh !== undefined ? row.qty_espuh.toFixed(2) : 0}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {row.berat_rusak !== undefined
                      ? row.berat_rusak.toFixed(2)
                      : 0}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {row.berat_sepuh !== undefined
                      ? row.berat_sepuh.toFixed(2)
                      : 0}
                  </td>
                </tr>
              </tbody>
            </>
          ))}
          <tfoot>
            <tr>
              <td
                colSpan="4"
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "center",
                }}
              >
                Sub Total
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                {this.props.data.reduce((acc, item) => acc + item.qty, 0)}
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                {this.props.data
                  .reduce((acc, item) => acc + item.berat, 0)
                  .toFixed(2)}
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                {this.props.data.reduce((acc, item) => acc + item.qty_rusak, 0)}
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                {this.props.data.reduce((acc, item) => acc + item.qty_sepuh, 0)}
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                {this.props.data
                  .reduce((acc, item) => acc + item.berat_rusak, 0)
                  .toFixed(2)}
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                {this.props.data
                  .reduce((acc, item) => acc + item.berat_sepuh, 0)
                  .toFixed(2)}
              </td>
            </tr>
          </tfoot>
        </table>
      </>
    );
  }
}
export default LaporanKirimSepuhExel;
