import React, { Component } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { getUserdata } from "../../../../../components/helpers/function";

class LaporanSaldoAktifKirimbangBosExel extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="btn btn-success btn-block"
          table="table-to-xls"
          filename="Laporan Kirim Barang Dari Bos"
          sheet="Laporan Kirim Barang Dari Bos"
          buttonText="Export Exel"
        />
        <table
          rowkey="kode_member"
          id="table-to-xls"
          style={{ display: "none" }}
        >
          <thead>
            <tr>
              <th colSpan="8" style={{ textAlign: "center" }}>
                {" "}
                LAPORAN KIRIM BARANG BOS{" "}
              </th>
            </tr>
            <tr>
              <th colSpan="8"> TOKO : {getUserdata().nama_toko} </th>
            </tr>

            <tr>
              <th colSpan="8"> ALAMAT : {getUserdata().alamat_toko} </th>
            </tr>
            <tr></tr>

            <tr>
              <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                NO KIRIM BOS
              </th>
              <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                KODE LABELING
              </th>

              <th
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                BERAT AWAL
              </th>
              <th
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                BERAT INPUT
              </th>
              <th
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                BERAT SISA
              </th>
              <th
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                QTY AWAL
              </th>
              <th
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                QTY INPUT
              </th>
              <th
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                QTY SISA
              </th>
              <th
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                STATUS
              </th>
            </tr>
          </thead>
          <tbody>
            {this.props.data.map((row, no) => (
              <tr key={no}>
                <td> {row.no_kirim_bos}</td>
                <td> {row.kode_labeling}</td>
                <td style={{ textAlign: "right" }}>
                  {row.berat_awal.toFixed(3)}
                </td>
                <td style={{ textAlign: "right" }}>
                  {row.berat_input === undefined || row.berat_input === null
                    ? 0
                    : row.berat_input.toFixed(3)}
                </td>
                <td style={{ textAlign: "right" }}>
                  {row.berat_sisa === undefined || row.berat_sisa === null
                    ? 0
                    : row.berat_sisa.toFixed(3)}
                </td>
                <td style={{ textAlign: "right" }}>{row.qty_awal}</td>
                <td style={{ textAlign: "right" }}>{row.qty_input}</td>
                <td style={{ textAlign: "right" }}>{row.qty_sisa}</td>
                <td style={{ textAlign: "right" }}>
                  {row?.status || ""}
                  {/* {row.status === undefined ? "OPEN" : "CLOSE"} */}
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <th
                colSpan="2"
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "center",
                }}
              >
                {" "}
                Total Barang : {this.props.data.length}
              </th>
              <th
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                {this.props.data
                  .reduce((a, b) => a + parseFloat(b.berat_awal), 0)
                  .toFixed(3)}
              </th>
              <th
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                {this.props.data
                  .reduce((a, b) => a + parseFloat(b.berat_input), 0)
                  .toFixed(3)}
              </th>
              <th
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                {this.props.data
                  .reduce((a, b) => a + parseFloat(b.berat_sisa), 0)
                  .toFixed(3)}
              </th>
              <th
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                {this.props.data.reduce(
                  (a, b) => a + parseFloat(b.qty_awal),
                  0
                )}
              </th>
              <th
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                {this.props.data.reduce(
                  (a, b) => a + parseFloat(b.qty_input),
                  0
                )}
              </th>
              <th
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                {this.props.data.reduce(
                  (a, b) => a + parseFloat(b.qty_sisa),
                  0
                )}
              </th>
              <th
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              ></th>
            </tr>
          </tfoot>
        </table>
      </>
    );
  }
}
export default LaporanSaldoAktifKirimbangBosExel;
