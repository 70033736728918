import HeadValidasiTerimaStock from "./HeadValidasiTerimaStock";
import {
  React,
  connect,
  Link,
  reduxForm,
  Panel,
  PanelHeader,
  PanelBody,
  BgLoading,
  formatGram,
  reset,
  postDataNoTImeOut2,
  ToastNotification,
} from "../../../components/helpers/library";
import {
  disableButtonValidasi,
  saveValidasiJual,
} from "../../../actions/hutang_action";

class ValidasiTerimaStock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isLoadingBtl: false,
      columnsJual: [
        {
          dataField: "no_kirim_bos",
          text: "No Kirim Bos",
          footer: (cell, row, num, index) => {
            return (
              <div>
                Total Barang :{" "}
                {JSON.parse(localStorage.getItem("validasi_terima")) === null
                  ? 0
                  : JSON.parse(localStorage.getItem("validasi_terima"))
                      .length || 0}{" "}
              </div>
            );
          },
        },
        {
          dataField: "tgl_system",
          text: "Tanggal",
        },
        {
          dataField: "kode_group",
          text: "Kode Group",
        },
        {
          dataField: "qty",
          text: "Qty Awal",
          formatter: (data, row) => row.qty,
        },
        {
          dataField: "qty_input",
          text: "Qty input",
          formatter: (data, row) => row.qty_input,
        },
        {
          dataField: "qty_sisa",
          text: "Qty Sisa",
          formatter: (data, row) => row.qty - row.qty_input,
        },
        {
          dataField: "berat",
          text: "Berat Awal",
          formatter: (data, row) => formatGram(row.berat_input, 3),
        },
        {
          dataField: "berat_input",
          text: "Berat input",
          formatter: (data, row) => formatGram(row.berat_input, 3),
        },
        {
          dataField: "berat_sisa",
          text: "Berat Sisa",
          formatter: (cell, row) => formatGram(row.berat - row.berat_input, 3),
        },
      ],
      columnsLabeling: [
        {
          dataField: "no_kirim_stock",
          text: "No Kirim Stock",
          footer: (cell, row, num, index) => {
            return (
              <div>
                Total Barang :{" "}
                {JSON.parse(localStorage.getItem("validasi_terima")) === null
                  ? 0
                  : JSON.parse(localStorage.getItem("validasi_terima"))
                      .length || 0}{" "}
              </div>
            );
          },
        },
        {
          dataField: "kode_lokasi_toko",
          text: "Kode Lokasi",
        },
        {
          dataField: "kode_dept",
          text: "Kode Jenis",
        },
        {
          dataField: "qty",
          text: "Qty",
          formatter: (data, row) => row.qty - row.qty_input,
        },

        {
          dataField: "berat",
          text: "Berat",
          formatter: (cell, row) => row.berat - row.berat_input,
        },
      ],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async handleSubmit(data) {
    this.setState({
      isLoading: true,
    });
    let validasi_terima_stock = JSON.parse(
      localStorage.getItem("data_validasi_terima_stock")
    );

    let no_kirim = [];
    validasi_terima_stock.forEach((el) => {
      no_kirim.push(el.no_kirim_bos);
    });

    if (data.jenis_transaksi === "KB") {
      postDataNoTImeOut2("validasi-terima-stock/validasi-bos", {
        no_kirim_bosses: no_kirim,
      })
        .then((res) => {
          localStorage.removeItem("data_validasi_terima_stock");
          localStorage.removeItem("validasi_terima");
          this.props.dispatch(reset("HeadValidasiTerimaStock"));
          this.props.dispatch(saveValidasiJual([]));
          ToastNotification("success", "Validasi Terima Stock Berhasil");
          this.props.dispatch(disableButtonValidasi(false));
          this.setState({
            isLoading: false,
          });
        })
        .catch((err) => {
          this.setState({
            isLoading: false,
          });
          ToastNotification("info", "Terjadi Kesalahaan Saat Mengirim Data");
        });
    } else {
      postDataNoTImeOut2("validasi-terima-stock/validasi-label", {
        no_kirim_stocks: no_kirim,
      })
        .then((res) => {
          localStorage.removeItem("data_validasi_terima_stock");
          localStorage.removeItem("validasi_terima");
          this.props.dispatch(reset("HeadValidasiTerimaStock"));
          this.props.dispatch(saveValidasiJual([]));
          ToastNotification("success", "Validasi Terima Stock Berhasil");
          this.props.dispatch(disableButtonValidasi(false));
          this.setState({
            isLoading: false,
          });
        })
        .catch((err) => {
          this.setState({
            isLoading: false,
          });
          console.log(err.response.data);
          ToastNotification("info", "Terjadi Kesalahaan Saat Mengirim Data");
        });
    }
  }

  handleChanger(e) {
    this.setState({
      jenis_transaksi: e,
    });
    this.props.dispatch(saveValidasiJual([]));
  }
  btnClear() {
    this.setState({
      isLoadingBtl: true,
    });
    setTimeout(() => {
      localStorage.removeItem("data_validasi_terima_stock");
      localStorage.removeItem("validasi_terima");
      this.props.dispatch(reset("HeadValidasiTerimaStock"));
      this.props.dispatch(saveValidasiJual([]));
      this.props.dispatch(disableButtonValidasi(false));
      this.setState({
        isLoadingBtl: false,
      });
    }, 1000);
  }
  render() {
    return (
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="#">Validasi</Link>
          </li>
          <li className="breadcrumb-item active">Validasi Terima Stock</li>
        </ol>
        <h1 className="page-header">Validasi Terima Stock </h1>
        <Panel>
          <PanelHeader>Validasi Terima Stock</PanelHeader>
          <br />
          {/* Validasi Terima Stock */}
          <PanelBody>
            <HeadValidasiTerimaStock
              onSubmit={(data) => this.handleSubmit(data)}
              btnClear={() => this.btnClear()}
              isLoadingBtl={this.state.isLoadingBtl}
              isLoading={this.state.isLoading}
              columnsLabeling={this.state.columnsLabeling}
              columnsJual={this.state.columnsJual}
            />
          </PanelBody>

          <br />
          {this.state.isLoading && <BgLoading />}
        </Panel>
      </div>
    );
  }
}

ValidasiTerimaStock = reduxForm({
  form: "ValidasiTerimaStock",
  enableReinitialize: true,
})(ValidasiTerimaStock);
export default connect()(ValidasiTerimaStock);
