import {
  React,
  Link,
  Panel,
  PanelHeader,
  connect,
  reduxForm,
  ToastNotification,
  PanelBody,
} from "../../../../components/helpers/library";
import HeadForm from "./HeadForm";
import { convertDate } from "../../../../components/helpers/function";
import { getDataParams, getDataParams2 } from "../../../../config/axios";

class LaporanSaldoAktifKirimBarangBos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      dataterimabarang: [],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(data) {
    this.setState({
      isLoading: true,
    });
    // console.log(data)
    // return false
    let payload = {
      no_kirim_bos:
        data.no_kirim_bos === undefined
          ? "ALL"
          : data.no_kirim_bos.toUpperCase(),
      kode_labeling: data.kode_labeling,
      tgl_awal: convertDate(data.tgl_awal),
      tgl_akhir: convertDate(data.tgl_akhir),
      status: data.status,
    };
    let payload2 = {
      no_kirim_bos:
        data.no_kirim_bos === undefined
          ? "ALL"
          : data.no_kirim_bos.toUpperCase(),
      kode_labeling: data.kode_labeling,
      tgl_from: convertDate(data.tgl_awal),
      tgl_to: convertDate(data.tgl_akhir),
      jenis: "LAPORAN",
      status: data.status,
    };

    if (data.type_stock === "KB") {
      getDataParams("krmbrgbos/report/saldo", payload)
        .then((res) => {
          if (res.data.length === 0) {
            ToastNotification("info", "Data Yang Anda Cari Tidak Ada");
            this.setState({
              export: false,
              isLoading: false,
            });
          } else {
            // console.log(res.data);
            // return false;
            let hasil = [];
            res.data.forEach((el) => {
              let row = {
                no_kirim_bos: el.no_kirim_bos,
                kode_labeling: el.kode_labeling,
                berat_awal: el.berat_awal,
                berat_input: el.berat_input,
                berat_sisa: el.berat_sisa,
                qty_awal: el.qty_awal,
                qty_input: el.qty_input,
                qty_sisa: el.qty_sisa,
                status: el.status,
              };
              hasil.push(row);
            });

            localStorage.setItem(
              "lap_saldo_barang_aktif",
              JSON.stringify(hasil)
            );
            // console.log(hasil),

            ToastNotification("success", "Laporan Saldo Aktif Tersedia");
            this.setState({
              export: true,
              isLoading: false,
              dataterimabarang: res.data,
            });
          }
        })
        .catch((err) => {
          this.setState({
            export: false,
            isLoading: false,
          });
          ToastNotification("info", "Data Yang Anda Cari Tidak Ada");
        });
    } else {
      // url = `kirim-labeling/saldo`
      getDataParams2("kirim-labeling/saldo", payload2)
        .then((res) => {
          if (res.data.length === 0) {
            ToastNotification("info", "Data Yang Anda Cari Tidak Ada");
            this.setState({
              export: false,
              isLoading: false,
            });
          } else {
            ToastNotification("success", "Laporan Saldo Aktif Tersedia");
            this.setState({
              export: true,
              isLoading: false,
              dataterimabarang: res.data,
            });
          }
        })
        .catch((err) => {
          this.setState({
            export: false,
            isLoading: false,
          });
          ToastNotification("info", "Data Yang Anda Cari Tidak Ada");
        });
    }
  }

  render() {
    return (
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="#">Laporan Stock Pembelian</Link>
          </li>
          <li className="breadcrumb-item active">Laporan Saldo Barang Aktif</li>
        </ol>
        <h1 className="page-header">Laporan Saldo Barang Aktif </h1>
        <Panel>
          <PanelHeader>Laporan Saldo Barang Aktif</PanelHeader>
          <br />
          {/* Laporan Kirim Barang Dari Bos */}
          <PanelBody>
            <HeadForm
              export={this.state.export}
              isLoading={this.state.isLoading}
              dataterimabarang={this.state.dataterimabarang}
              onSubmit={(data) => this.handleSubmit(data)}
            />
          </PanelBody>
          <br />
          {/* End Tambah Laporan Kirim Barang Dari Bos  */}
        </Panel>
      </div>
    );
  }
}

LaporanSaldoAktifKirimBarangBos = reduxForm({
  form: "LaporanSaldoAktifKirimBarangBos",
  enableReinitialize: true,
})(LaporanSaldoAktifKirimBarangBos);
export default connect()(LaporanSaldoAktifKirimBarangBos);
