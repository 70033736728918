import {
  React,
  connect,
  Link,
  reduxForm,
  Panel,
  PanelHeader,
  PanelBody,
  formatGram,
  Tabel,
  getItem,
  setItem,
  ToastNotification,
  reset,
  Swal,
  postDataNoTImeOut2,
} from "../../../components/helpers/library";

import HeadKirimSepuh from "./HeadKirimSepuh";
class KirimSepuh extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isLoadingKirim: false,
      columns: [
        {
          dataField: "kode_baki",
          text: "Baki",
          footer: "",
        },
        {
          dataField: "kode_jenis",
          text: "Jenis",
          footer: "",
        },

        {
          dataField: "jml_qty",
          text: "Qty",
          headerClasses: "text-right",
          formatter: (row) => {
            return <div className="text-right">{row}</div>;
          },
          footer: (columnData) => {
            return (
              <div className="text-right">
                {columnData.reduce((acc, item) => acc + item, 0)}
              </div>
            );
          },
        },
        {
          dataField: "jml_berat",
          text: "Berat",
          headerClasses: "text-right",
          formatter: (row) => {
            return <div className="text-right">{formatGram(row, 2)}</div>;
          },
          footer: (columnData) => {
            return (
              <div className="text-right">
                {" "}
                {formatGram(
                  columnData.reduce((acc, item) => acc + item, 0),
                  2
                )}{" "}
              </div>
            );
          },
        },

        {
          dataField: "brt_sepuh",
          text: "Brt Sepuh",
          headerClasses: "text-right",
          formatter: (row) => {
            return <div className="text-right">{formatGram(row, 2)}</div>;
          },
          footer: (columnData) => {
            return (
              <div className="text-right">
                {" "}
                {formatGram(
                  columnData.reduce((acc, item) => acc + item, 0),
                  2
                )}{" "}
              </div>
            );
          },
        },
        {
          dataField: "qty_sepuh",
          text: "Qty Sepuh",
          headerClasses: "text-right",
          formatter: (row) => {
            return <div className="text-right">{row}</div>;
          },
          footer: (columnData) => {
            return (
              <div className="text-right">
                {columnData.reduce((acc, item) => acc + item, 0)}
              </div>
            );
          },
        },
        {
          dataField: "brt_rusak",
          text: "Brt Rusak",
          headerClasses: "text-right",
          formatter: (row) => {
            return <div className="text-right">{formatGram(row, 2)}</div>;
          },
          footer: (columnData) => {
            return (
              <div className="text-right">
                {" "}
                {formatGram(
                  columnData.reduce((acc, item) => acc + item, 0),
                  2
                )}{" "}
              </div>
            );
          },
        },
        {
          dataField: "qty_rusak",
          text: "Qty Rusak",
          headerClasses: "text-right",
          formatter: (row) => {
            return <div className="text-right">{row}</div>;
          },
          footer: (columnData) => {
            return (
              <div className="text-right">
                {columnData.reduce((acc, item) => acc + item, 0)}
              </div>
            );
          },
        },
        {
          dataField: "Aksi",
          text: "Aksi",
          csvExport: false,
          headerClasses: "text-center",
          formatter: (rowcontent, row, index) => {
            return (
              <div className="row text-center">
                <div className="col-12">
                  <button
                    onClick={() => {
                      this.hapusDataBarang(index);
                    }}
                    className="btn btn-danger"
                    type="button"
                  >
                    <i className="fa fa-trash"></i>
                  </button>
                </div>
              </div>
            );
          },
        },
        // {
        //   dataField: "keterangan",
        //   text: "Keterangan",
        // },
      ],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    // this.print = this.print.bind(this);
  }
  hapusDataBarang(id) {
    Swal.fire({
      title: "Anda Yakin !!",
      text: "Ingin Menghapus Data Ini ?",
      icon: "warning",
      position: "top-center",
      cancelButtonText: "Tidak",
      showCancelButton: true,
      confirmButtonText: "OK",
      showConfirmButton: true,
    }).then((result) => {
      // console.log(id);
      if (result.isConfirmed) {
        let data = getItem("data_barang_kirim_sepuhan");
        data.splice(id, 1);
        setItem("data_barang_kirim_sepuhan", data);
        this.setState({
          status_btn: true,
        });
      }
    });
  }
  handleSubmit(data) {
    console.log(data);
  
    let cek =
      getItem("data_barang_kirim_sepuhan").length === 0
        ? []
        : getItem("data_barang_kirim_sepuhan");
    let hasil_cek = cek.findIndex(
      (hasil) => hasil.kode_jenis === data.kode_jenis
    );
    
    // console.log(data.kode_barcode);
    if (hasil_cek === -1) {
      let row = {
        kode_barcode : data.kode_barcode,
        kode_group: data.kode_group,
        kode_baki: data.kode_baki,
        kode_jenis: data.kode_jenis,
        jml_berat: parseFloat(data.jml_berat),
        jml_qty: parseInt(data.jml_qty),
        brt_sepuh: parseFloat(data.brt_sepuh),
        qty_sepuh: parseInt(data.qty_sepuh),
        brt_rusak: parseFloat(data.brt_rusak),
        qty_rusak: parseInt(data.qty_rusak),
      };
      cek.push(row);
      setItem("data_barang_kirim_sepuhan", cek);
      this.setState({
        berhasil: true,
      });
      this.props.dispatch(reset("HeadKirimSepuh"));
    } else {
      ToastNotification("info", "Barang Sudah Ada Di Tabel");
    }
  }

  kirimSepuhan() {
    let data = getItem("data_barang_kirim_sepuhan");

    let detail_barang = [];
    data.forEach((element) => {
      let row = {
        kode_barcode: element.kode_barcode,
        kode_baki: element.kode_baki,
        kode_dept: element.kode_jenis,
        kode_group: element.kode_group,
        qty_rusak: element.qty_rusak,
        berat_rusak: element.brt_rusak,
        qty_sepuh: element.qty_sepuh,
        berat_sepuh: element.brt_sepuh,
        berat: element.jml_berat,
        qty: element.jml_qty,
      };
      detail_barang.push(row);
    });
    let hasil = {
      jml_qty_sp: data.reduce((a, b) => a + b.qty_sepuh, 0),
      berat_total: data.reduce((a, b) => a + b.jml_berat, 0),
      qty_total: data.reduce((a, b) => a + b.jml_qty, 0),
      berat_sepuh_total: data.reduce((a, b) => a + b.brt_sepuh, 0),
      qty_sepuh_total: data.reduce((a, b) => a + b.qty_sepuh, 0),
      berat_rusak_total: data.reduce((a, b) => a + b.brt_rusak, 0),
      qty_rusak_total: data.reduce((a, b) => a + b.qty_rusak, 0),
      jml_qty_rusak: data.reduce((a, b) => a + b.jml_qty, 0),
      detail_barang: detail_barang,
    };
    this.setState({
      isLoadingKirim: true,
    });
    postDataNoTImeOut2("kirim-sepuh", hasil)
      .then((res) => {
        ToastNotification("success", res.data.message);
        this.props.dispatch(reset("HeadKirimSepuh"));
        setItem("data_barang_kirim_sepuhan", []);
        this.setState({
          isLoadingKirim: false,
          hapus : true
        });


let buktiSlipInternal = [];
for (let index = 0; index < 3; index++) {
let nama = "";
if(index===0){
nama= "Internal";
}else if(index===1){
nama= "Owner";
}else{
nama= "Tukang";
}
let feedbackbuktiSlipInternal = '';
feedbackbuktiSlipInternal += `========================================\n`
feedbackbuktiSlipInternal += `          Kirim Sepuh ${nama}           \n`
feedbackbuktiSlipInternal += `========================================
No Sepuh         : ${res.data.buktiSlipInternal.no_sepuh}
Tgl Sepuh        : ${res.data.buktiSlipInternal.tgl_sepuh}\n`
feedbackbuktiSlipInternal += `========================================`
res.data.buktiSlipInternal.jenis.forEach((row, index) => {
row.no_sortirs.forEach((nosortirowner, index) => {
feedbackbuktiSlipInternal += `
No Sortir        : ${nosortirowner}`
})
feedbackbuktiSlipInternal += `
Jenis            : ${row.jenis}`
feedbackbuktiSlipInternal += `
Qty Beli         : ${row.qty_beli}`
feedbackbuktiSlipInternal += `
Brt Beli         : ${row.berat_beli.toFixed(3)}`
feedbackbuktiSlipInternal += `
Qty Sepuh        : ${row.qty_sepuh}`
feedbackbuktiSlipInternal += `
Brt Sepuh        : ${row.berat_sepuh.toFixed(3)}`
feedbackbuktiSlipInternal += `
Qty Rusak        : ${row.qty_rusak}`
feedbackbuktiSlipInternal += `
Brt Rusak        : ${row.berat_rusak.toFixed(3)}\n`
})
feedbackbuktiSlipInternal += `========================================`
feedbackbuktiSlipInternal += `
Total Qty Sepuh  : ${res.data.buktiSlipInternal.total_qty_sepuh}
Total Brt Sepuh  : ${res.data.buktiSlipInternal.total_berat_sepuh.toFixed(3)}
Total Qty Rusak  : ${res.data.buktiSlipInternal.total_qty_rusak}
Total Brt Rusak  : ${res.data.buktiSlipInternal.total_berat_rusak.toFixed(3)}
Selisih Gr       : ${res.data.buktiSlipInternal.selisih_gr.toFixed(3)}

`
feedbackbuktiSlipInternal += `========================================\n`
feedbackbuktiSlipInternal += `              TERIMA KASIH              \n`
feedbackbuktiSlipInternal += `========================================`
feedbackbuktiSlipInternal += `






VA\n`;
buktiSlipInternal.push(feedbackbuktiSlipInternal);
}
document.getElementById("buktiSlipInternal").value = buktiSlipInternal.join('');
const elementbuktiSlipInternal = document.createElement("a");
const fileint = new Blob([document.getElementById("buktiSlipInternal").value], {
type: "text/plain;charset=utf-8",
});
elementbuktiSlipInternal.href = URL.createObjectURL(fileint);
elementbuktiSlipInternal.download = "autoprint_pembelian.txt";
document.body.appendChild(elementbuktiSlipInternal);
elementbuktiSlipInternal.click();
// End Bukt Slip Internal


})
      .catch((err) => {
        this.setState({
          isLoadingKirim: false,
        });
        ToastNotification("info", err.response?.data.message);
      });
  }
  render() {
    return (
      <div>
        <textarea
            style={{ display: "none" }}
            id="kirimsepuhtukang"
            rows="100"
            cols="100"
          />
        <textarea
            style={{ display: "none" }}
            id="buktiSlipOwner"
            rows="100"
            cols="100"
          />
        <textarea
            style={{ display: "none" }}
            id="buktiSlipInternal"
            rows="100"
            cols="100"
          />
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="#">Sepuhan</Link>
          </li>
          <li className="breadcrumb-item active">Kirim Sepuh</li>
        </ol>
        <h1 className="page-header">Kirim Sepuh </h1>
        <Panel>
          <PanelHeader>Kirim Sepuh</PanelHeader>
          <br />
          {/* Kirim Sepuh */}
          <PanelBody>
            <HeadKirimSepuh onSubmit={(data) => this.handleSubmit(data)} />

            <Tabel
              keyField="kode_baki"
              data={getItem("data_barang_kirim_sepuhan").length === 0 ? [] : getItem("data_barang_kirim_sepuhan")}
              columns={this.state.columns}
              empty={true}
              textEmpty="Data Barang"
            />
            <div className="row">
              <div className="col-12 text-right">
                <button
                  onClick={() => this.kirimSepuhan()}
                  disabled={
                    getItem("data_barang_kirim_sepuhan").length === 0
                      ? true
                      : false
                  }
                  className="btn btn-primary"
                  type="button"
                >
                  {this.state.isLoadingKirim ? (
                    <>
                      <i className="fas fa-spinner fa-spin"></i> &nbsp; Sedang
                      Mengirim
                    </>
                  ) : (
                    "Kirim"
                  )}
                </button>
              </div>
            </div>
          </PanelBody>

          <br />
        </Panel>
      </div>
    );
  }
}

KirimSepuh = reduxForm({
  form: "KirimSepuh",
  enableReinitialize: true,
})(KirimSepuh);
export default connect()(KirimSepuh);
