const ValidasiKirimLabeling = (value) => {
    const errors = {};
    if (value.cabang === undefined) {
        errors.cabang = "Cabang Harus Dipilih";
    }
    if (value.jml_qty === undefined) {
        errors.jml_qty = "Jumlah Qty Harus Diisi";
    }
    if (value.jml_berat === undefined) {
        errors.jml_berat = "Jumlah Berat Harus Diisi";
    }
    if (value.tipe_barang === undefined) {
        errors.tipe_barang = "Tipe Barang Harus Dipilih";
    }
   
    return errors;
}

export default ValidasiKirimLabeling;


