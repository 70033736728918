import React, { Component } from "react";

import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { getDataBaki } from "../../../../actions/datamaster_action";
import { InputDate, ReanderSelect } from "../../../../components/helpers/field";
import { cetakLaporanValidasi } from "../../../../components/helpers/function";
import { getTglSystem } from "../../../../components/helpers/library";
import Tabel from "../../../../components/helpers/tabel";
// import Tabel from "../../../../components/helpers/tabel";
import ValidasiTanggalLaporan from "../../../../Validasi/ValidasiTanggalLaporan";
import LaporanValidasiInputanpdf from "./LaporanValidasiInputanpdf.jsx";

class HeadVaidasiInputan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      databaki: [],
      columns: [
        {
          dataField: "tgl_system",
          text: "Tanggal",
          footer: "",
        },
        {
          dataField: "no_sepuh",
          text: "No Kirim",
          footer: "",
          formatter: (data, row) => {
            return <div> {row.no_kirim_stock || row.no_kirim_bos}</div>;
          },
        },
        // {
        //   dataField: "no_sortir",
        //   text: "No Sortir",
        //   footer : ''
        // },

        {
          dataField: "berat_input",
          text: "Berat Total",
          headerClasses: "text-right",
          formatter: (data) => {
            return <div className="text-right"> {data.toFixed(2)}</div>;
          },
          footer: (columnData) => (
            <div className="text-right">
              {" "}
              {columnData.reduce((acc, item) => acc + item, 0).toFixed(2)}{" "}
            </div>
          ),
        },
        {
          dataField: "qty_input",
          text: "Qty Total",
          headerClasses: "text-right",
          formatter: (data) => {
            return <div className="text-right"> {data}</div>;
          },
          footer: (columnData) => (
            <div className="text-right">
              {" "}
              {columnData.reduce((acc, item) => acc + item, 0)}{" "}
            </div>
          ),
        },
        {
          dataField: "action",
          text: "Action",
          headerClasses: "text-center",
          formatter: (cell, row) => {
            return (
              <div className="row text-center">
                <div className="col-12">
                  <button
                    type="button"
                    onClick={() => this.reprint(row)}
                    className="btn btn-primary mr-3"
                  >
                    {" "}
                    <i className="fa fa-print"></i>
                  </button>
                </div>
              </div>
            );
          },
        },
      ],
    };
    this.setStartDate = this.setStartDate.bind(this);
    this.setLastDate = this.setLastDate.bind(this);
  }

  reprint(row) {
    // console.log(row)
    let hasil = [
      {
        data_kirim: [row]
      },
    ];

    document.getElementById("myInput").value = cetakLaporanValidasi(hasil);
    setTimeout(() => {
      this.TextFile();
    }, 100);
  }
  TextFile() {
    const element = document.createElement("a");
    const file = new Blob([document.getElementById("myInput").value], {
      type: "text/plain;charset=utf-8",
    });
    element.href = URL.createObjectURL(file);
    element.download = "autoprint_pembelian.txt";
    document.body.appendChild(element);
    element.click();
  }
  exportPdf() {
    LaporanValidasiInputanpdf(this.props.databarang);
  }
  setStartDate(date) {
    this.setState({
      tgl_awal: new Date(date),
    });
  }
  setLastDate(date) {
    this.setState({
      tgl_akhir: new Date(date),
    });
  }

  componentDidMount() {
    this.props.dispatch(getDataBaki());
    this.props.change("tgl_awal", getTglSystem());
    this.props.change("tgl_akhir", getTglSystem());
    this.props.change("jenis_transaksi", "KC");
  }
  render() {
    let DataBaki = [
      {
        value: "ALL",
        name: "SEMUA",
      },
    ];
    this.props.databaki
      .filter((cek) => cek.kode_gudang === "KS")
      .forEach((list) => {
        let hasil = {
          value: list.kode_baki,
          name: list.kode_baki,
        };
        DataBaki.push(hasil);
      });

    return (
      <form onSubmit={this.props.handleSubmit} autoComplete="off">
        <div className="row">
        <textarea
            style={{ display: "none" }}
            id="myInput"
            rows="100"
            cols="100"
          />

          <div className="col-lg-3">
            <Field
              name="jenis_transaksi"
              label="Pilih Jenis Transaksi"
              placeholder="Masukan Jenis Transaksi"
              options={[
                {
                  value: "KC",
                  name: "KIRIM LABELING",
                },
                {
                  value: "KB",
                  name: "KIRIM BARANG DARI BOS",
                },
              ]}
              component={ReanderSelect}
            />
          </div>
          <div className="col-lg-3">
            <Field
              name="tgl_awal"
              component={InputDate}
              label="Tanggal Dari"
              type="text"
              selected={this.state.tgl_awal}
              onChange={(date) => this.setStartDate(date)}
              placeholder="Masukan Tanggal Dari"
            />
          </div>

          <div className="col-lg-3">
            <Field
              name="tgl_akhir"
              component={InputDate}
              type="text"
              selected={this.state.tgl_akhir}
              onChange={(date) => this.setLastDate(date)}
              label="Tanggal Akhir"
              placeholder="Masukan Tanggal Akhir"
            />
          </div>

          <div className="col-lg-3">
            <div className="text-right">
              <label>&nbsp;</label>
              <button
                type="submit"
                value="PDF"
                name="type_btn"
                className="btn btn-primary btn-block"
              >
                {this.props.isLoading ? (
                  <>
                    <i className="fas fa-spinner fa-spin"></i> &nbsp; Sedang
                    Mencari
                  </>
                ) : (
                  "Cari Laporan"
                )}
              </button>
            </div>
          </div>
          <div className="col-12">
            <Tabel
              keyField="_id"
              data={this.props.export ? this.props.databarang : []}
              columns={this.state.columns}
              empty={true}
              textEmpty="Data Laporan Kosong"
            />
          </div>
          <div className={this.props.export ? "col-lg-12" : "col-lg-12 d-none"}>
            {/* <label htmlFor="">&nbsp;</label> */}
            <div className="row">
              <div className="col-lg-12">
                <button
                  type="button"
                  onClick={() => this.exportPdf()}
                  className="btn btn-warning btn-block"
                >
                  {" "}
                  Export PDF{" "}
                </button>
              </div>
              <div className="col-lg-6"></div>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

HeadVaidasiInputan = reduxForm({
  form: "HeadVaidasiInputan",
  enableReinitialize: true,
  validate: ValidasiTanggalLaporan,
})(HeadVaidasiInputan);
// export default connect()(HeadVaidasiInputan);
export default connect((state) => {
  return {
    databaki: state.datamaster.getDataBaki,
    initialValues: {
      tgl_awal: getTglSystem(),
      tgl_akhir: getTglSystem(),
    },
  };
})(HeadVaidasiInputan);
