import { PanelBody } from "../../../../components/panel/panel";
import {
  React,
  connect,
  Link,
  reduxForm,
  Panel,
  PanelHeader,
  postDataNoTImeOut2,
  ToastNotification,
  reset,
} from "../../../../components/helpers/library";
import FormBahanTukang from "./FormBahanTukang";
import { cetakTambahBahan } from "../../../../components/helpers/function";

class TambahBahanTukang extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      settings: "",
    };
  }

  TextFile() {
    const element = document.createElement("a");
    const file = new Blob([document.getElementById("myInput").value], {
      type: "text/plain;charset=utf-8",
    });
    element.href = URL.createObjectURL(file);
    element.download = "autoprint_pembelian.txt";
    document.body.appendChild(element);
    element.click();
  }
  handleSubmit(data) {

    this.setState({
      isLoading: true,
    });
    let hasil = {
      kode_tukang: data.kode_tukang,
      jenis: data.jenis,
      kode_group: data.kategori,
      jml_bahan: parseFloat(data.tambah_bahan),
      keterangan: data.keterangan === undefined ? '' : data.keterangan.toUpperCase(),
    };
    postDataNoTImeOut2(
      "bahan/tambah-bahan",
      hasil,
      ["kode_tukang", "jml_bahan", "kode_group"],
      true
    )
      .then((res) => {
        document.getElementById("myInput").value = cetakTambahBahan(res.data, 'TAMBAH').join('');

        setTimeout(() => {
          this.TextFile();
        }, 100);
        this.setState({
          isLoading: false,
        });
        ToastNotification("success", "Tambah Bahan Tersimpan");
        this.props.dispatch(reset("FormBahanTukang"));
      })
      .catch((err) => {
        console.log(err)
        this.setState({
          isLoading: false,
        });
        ToastNotification(
          "info",
          err.response?.data?.message || "Terjadi Kesalahan Saat Mengirim Data"
        );
      });
  }
  render() {
    return (
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="#">Data Master</Link>
          </li>
          <li className="breadcrumb-item active">Tambah Bahan Tukang</li>
        </ol>
        <h1 className="page-header">Tambah Bahan Tukang </h1>
        <Panel>
          <textarea
            style={{ display: "none" }}
            id="myInput"
            rows="100"
            cols="100"
          />
          <PanelHeader>Tambah Bahan Tukang</PanelHeader>
          <PanelBody>
            <FormBahanTukang
              isLoading={this.state.isLoading}
              onSubmit={(data) => this.handleSubmit(data)}
            />
          </PanelBody>
        </Panel>
      </div>
    );
  }
}

TambahBahanTukang = reduxForm({
  form: "TambahBahanTukang",
  enableReinitialize: true,
})(TambahBahanTukang);
export default connect(null)(TambahBahanTukang);
