import {
  React,
  connect,
  Link,
  reduxForm,
  Panel,
  PanelHeader,
  PanelBody,
  getDataNoTimeOut2,
  ToastNotification,
} from "../../../../components/helpers/library";
import HeadBarangDalam from "./HeadBarangDalam";

class LaporanBarangDalam extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      export: false,
      databarang: [],
    };
  }
  componentDidMount() {
    this.handleSubmit();
  }

  handleSubmit(data){
    this.setState({
      isLoading: true,
    });
    getDataNoTimeOut2("sepuhan/report-barang-dalam").then((res)=>{
      if (res.data.length === 0) {
        ToastNotification("info", "Data Yang Anda Cari Tidak Ada");
        this.setState({
          export: false,
          isLoading: false,
          databarang : []
        });
      } else {
        ToastNotification("success", "Laporan Barang Dalam Tersedia");
        this.setState({
          databarang: [],
        });
        this.setState({
          export: true,
          isLoading: false,
          databarang : res.data,
        });
      }
    })
    .catch((err) => {
      this.setState({
        export: false,
        isLoading: false,
        dataterimabarang : [],
        databarang : []
      });
      ToastNotification("info", "Data Yang Anda Cari Tidak Ada");
    });

  }
  render() {
    return (
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="#">Laporan</Link>
          </li>
          <li className="breadcrumb-item active">Laporan Barang Dalam</li>
        </ol>
        <h1 className="page-header">Laporan Barang Dalam </h1>
        <Panel>
          <PanelHeader>Laporan Barang Dalam</PanelHeader>
          <PanelBody>
            <HeadBarangDalam
            export={this.state.export}
            isLoading={this.state.isLoading} databarang={this.state.databarang} onSubmit={(data)=>this.handleSubmit(data)} />
          </PanelBody>
        </Panel>
      </div>
    );
  }
}

LaporanBarangDalam = reduxForm({
  form: "LaporanBarangDalam",
  enableReinitialize: true,
})(LaporanBarangDalam);
export default connect(null)(LaporanBarangDalam);
