import { change, reset } from "redux-form";
import Swal from "sweetalert2";
import {
  React,
  connect,
  Link,
  reduxForm,
  Panel,
  PanelHeader,
  PanelBody,
  ToastNotification,
  Tabel,
  getItem,
  setItem,
  getDataNoTimeOut2,
  postDataNoTImeOut2,
} from "../../../components/helpers/library";
import HeadKirimLabeling from "./HeadKirimLabeling";

class BlankPages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      columns: [
        {
          dataField: "tujuan",
          text: "Tujuan",
        },
        {
          dataField: "jenis",
          text: "Jenis",
        },
        {
          dataField: "jml_qty",
          text: "Jumlah Qty",
          headerClasses: "text-right",
          formatter: (cell) => {
            return <div className="text-right"> {cell} </div>;
          },
        },
        {
          dataField: "jml_berat",
          text: "Jumlah Berat",
          headerClasses: "text-right",
          formatter: (cell) => {
            return <div className="text-right"> {cell} </div>;
          },
        },
        {
          dataField: "cabang",
          text: "Cabang",
        },
        {
          dataField: "Aksi",
          text: "Aksi",
          csvExport: false,
          headerClasses: "text-center",
          formatter: (rowcontent, row, index) => {
            return (
              <div className="row text-center">
                <div className="col-12">
                  <button
                    onClick={() => {
                      this.hapusDataBarang(index);
                    }}
                    className="btn btn-danger"
                    type="button"
                  >
                    <i className="fa fa-trash"></i>
                  </button>
                </div>
              </div>
            );
          },
        },
      ],
    };
    this.simpanbarang = this.simpanbarang.bind(this);
    // this.print = this.print.bind(this);
  }
  hapusDataBarang(id) {
    Swal.fire({
      title: "Anda Yakin !!",
      text: "Ingin Menghapus Data Ini ?",
      icon: "warning",
      position: "top-center",
      cancelButtonText: "Tidak",
      showCancelButton: true,
      confirmButtonText: "OK",
      showConfirmButton: true,
    }).then((result) => {
      // console.log(id);
      if (result.isConfirmed) {
        let data = getItem("data_kirim_labeling");
        data.splice(id, 1);
        setItem("data_kirim_labeling", data);
        this.setState({
          status_btn: true,
        });
      }
    });
  }
  simpanbarang(row) {
    // console.log(row);
    if(getItem("data_kirim_labeling").length === 1){
      ToastNotification("info", "Hanya Dapat Menginput 1 Barang")
      return false;
    }
    let hasil = {
      nokirim_sepuh_sepuh: row.nokirim_sepuh_sepuh,
      qty: row.qty,
      berat: row.berat,
      jml_qty: row.jml_qty,
      jml_berat: row.jml_berat,
      cabang: row.cabang,
      berat_atribut: row.berat_atribut || 0,
      kode_baki: row.kode_baki,
      jenis: row.jenis,
      tujuan: row.tujuan,
      kadar: row.tipe_barang || "SP",
    };
    
    let cek = JSON.parse(localStorage.getItem("data_kirim_labeling")) || [];
    let hasil_cek = cek.findIndex(
      (hasil) => hasil.jenis === row.jenis
    );
    if (hasil_cek === -1) {
      cek.push(hasil);
      localStorage.setItem("data_kirim_labeling", JSON.stringify(cek));
      this.setState({
        berasil : true
      })
    } else {
      ToastNotification("info", "Jenis Tersebut Sudah Ada Di Tabel");
    }
  }
  kirimbarang(){
    this.setState({
      isLoading : true
    })
    let detail = [];
    getItem('data_kirim_labeling').forEach(element => {
      let row = {
            kode_baki: element.kode_baki,
            kode_baki_tujuan: element.tujuan,
            kode_dept: element.jenis,
            kode_cabang: element.cabang,
            kadar: element.kadar,
            qty_awal: element.qty,
            berat_awal: element.berat,
            jumlah_qty: parseInt(element.jml_qty),
            berat_atribut: parseFloat(element.berat_atribut),
            jumlah_berat: parseFloat(element.jml_berat)
      }
      detail.push(row)
    });
    let hasil = {
      no_terima_sepuh: getItem('data_kirim_labeling')[0].nokirim_sepuh_sepuh,
      // qty: getItem('data_kirim_labeling')[0].qty,
      // berat: getItem('data_kirim_labeling')[0].berat,
      detail_kirim: detail,
      qty_kirim: getItem('data_kirim_labeling').reduce((a,b)=>parseInt(a+b.qty),0),
      berat_kirim: getItem('data_kirim_labeling').reduce((a,b)=>parseInt(a+b.berat),0),
      berat_atribut: getItem('data_kirim_labeling').reduce((a,b)=>parseFloat(a+b.berat_atribut),0),
      cabang: getItem('data_kirim_labeling')[0].cabang
    }
    
postDataNoTImeOut2('kirim-labeling',hasil).then((res)=>{
ToastNotification("success",res.data.message)
setItem("data_kirim_labeling",[])
this.setState({
  isLoading : false
})
let datasepuh = [];
let nama = "";
for (let IndexAwal = 0; IndexAwal < 3; IndexAwal++) {
if(IndexAwal===0){
  nama = "INTERNAL"
}else if(IndexAwal===1){
  nama = "LABEL"
}else{
  nama = "TUKANG"
}
let feedback = '';
feedback += `========================================\n`
feedback += `             Kirim LABELING             \n`
feedback += `                ${nama}                \n`
feedback += `========================================
No Kirim Stock   : ${res.data.buktiSlip.no_kirim_stock}
Cabang           : ${res.data.buktiSlip.cabang}
No Terimas Sepuh : ${res.data.buktiSlip.no_terima_sepuh}
Tanggal Kirim    : ${res.data.buktiSlip.tgl_kirim}
Tujuan           : ${res.data.buktiSlip.baki_tujuan}\n`
res.data.buktiSlip.baki.forEach(element => {
feedback += `
Baki             : ${element.baki}
Jenis            : ${element.jenis}
Qty Kirim        : ${element.qty_kirim}
Brt Kirim        : ${element.berat_kirim}
Brt Atribut      : ${element.berat_atribut}
`  
});
feedback += `
Tot Qty Kirim   : ${res.data.buktiSlip.baki.reduce((a,b)=>a+b.qty_kirim,0)}
Tot Brt Kirim   : ${res.data.buktiSlip.baki.reduce((a,b)=>a+b.berat_kirim,0)}
`
feedback += `========================================\n`
feedback += `              TERIMA KASIH              \n`
feedback += `========================================`
feedback += `






VA\n`;
datasepuh.push(feedback);
}
// console.log(datasepuh);
document.getElementById("myInput").value = datasepuh.join('');
}).then(() => {
const element = document.createElement("a");
const file = new Blob([document.getElementById("myInput").value], {
type: "text/plain;charset=utf-8",
});
element.href = URL.createObjectURL(file);
element.download = "autoprint_pembelian.txt";
document.body.appendChild(element);
element.click();
let datacari = getItem('data_no_terima_sepuhan');
getDataNoTimeOut2(
  "terima-sepuh?no_terima_sepuh=" +
  datacari.no_terima.toUpperCase() +
    "&kode_baki=" +
    datacari.kode_baki +
    "&kode_dept=" +  datacari.kode_dept
)
  .then((res) => {
    if (res.data.length === 0) {
      ToastNotification("info", "Data Kosong !");
      this.setState({ loadingData: false });
      localStorage.removeItem('data_no_terima_sepuhan')
      this.props.dispatch(change('HeadKirimLabeling',"berat",0))
      this.props.dispatch(change('HeadKirimLabeling',"qty",0))
      this.props.dispatch(reset('HeadKirimLabeling'));
      return false;
    }
    this.props.dispatch(change('HeadKirimLabeling',"berat",res.data[0].berat.toFixed(3)))
    this.props.dispatch(change('HeadKirimLabeling',"qty",res.data[0].qty))
    this.setState({
      loadingData: false,
    });
  }).catch((err)=>{
    ToastNotification("info", err.response?.data.message || "Terjadi Kesalahaan Saat Mengirim Data");
    this.setState({
      loadingData: false,
    });
  })
}).catch((err)=>{
  this.setState({
    isLoading : false
  })
  ToastNotification("info",err.response?.data.message)
})}
  render() {
    return (
      <div>
          <textarea
            style={{ display: "none" }}
            id="myInput"
            rows="100"
            cols="100"
          />
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="#">Sepuhan</Link>
          </li>
          <li className="breadcrumb-item active">Kirim Labeling</li>
        </ol>
        <h1 className="page-header">Kirim Labeling </h1>
        <Panel>
          <PanelHeader>Kirim Labeling</PanelHeader>
          <br />
          {/* Kirim Labeling */}
          <PanelBody>
            <HeadKirimLabeling onSubmit={(data) => this.simpanbarang(data)} />
              <Tabel
                keyField="nokirim_sepuh_sepuh"
                data={getItem('data_kirim_labeling')}
                columns={this.state.columns}
                empty={true}
                textEmpty="Data Barang Kosong"
              />
            <div className="row">
              <div className="col-12 text-right" >
                <button className="btn btn-primary" onClick={()=>this.kirimbarang()}> 
                {this.state.isLoading ? (
                <>
                  <i className="fas fa-spinner fa-spin"></i> &nbsp; Kirim
                </>
              ) : (
                "Kirim"
              )}
                 </button>
              </div>
            </div>
          </PanelBody>

          <br />
        </Panel>
      </div>
    );
  }
}

BlankPages = reduxForm({
  form: "BlankPages",
  enableReinitialize: true,
})(BlankPages);
export default connect()(BlankPages);
