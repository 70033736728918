import React, { Component } from "react";
import Camera from "react-html5-camera-photo";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { createNumberMask } from "redux-form-input-masks";
import { setCamera } from "../../../../actions/databarang_action";
import { showModal } from "../../../../actions/datamaster_action";
import {
  HiidenFiled,
  ReanderField,
} from "../../../../components/helpers/field";
import LoadingButton, {
  convertBase64,
} from "../../../../components/helpers/function";
import { NotifInfo } from "../../../../components/helpers/notification";
import { getData } from "../../../../config/axios";
import imgfound from "../../../../assets/img/temp.jpg";
import Loading from 'react-fullscreen-loading';

const maptostate = (state) => {
  return {
    initialValues: {
      foto: state.databarang.showEditBarang.foto,
      kode_barcode: state.databarang.showEditBarang.kode_barcode,
      nama_barang: state.databarang.showEditBarang.nama_barang,
      nama_atribut: state.databarang.showEditBarang.nama_atribut,
      harga_atribut: state.databarang.showEditBarang.harga_atribut,
      kode_intern: state.databarang.showEditBarang.kode_intern,
      kadar: state.databarang.showEditBarang.kadar,
      kadar_cetak: state.databarang.showEditBarang.kadar_cetak,
    },
    foto: state.databarang.showEditBarang.foto,
    setCamera: state.databarang.setCamera,
    hideModal: state.datamaster.modalDialog,
  };
};
const currencyMask = createNumberMask({
  prefix: "Rp. ",
  locale: "kr-KO",
});
class HeadEditDataBarang extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: true,
      ModalWebCam: false,
      isLoadingBaki: false,
      isLoadingJenis: false,

      tgl_awal: new Date(),
      tgl_akhir: new Date(),
      databaki: [],
      DataGroup: [],
      DataJenis: [],
      loadimage: imgfound,
    };
  }
  tgl_awalChange = (date) => {
    this.setState({
      tgl_awal: date,
    });
  };
  tgl_akhirChange = (date) => {
    this.setState({
      tgl_akhir: date,
    });
  };
  showModalWebcam() {
    this.props.dispatch(setCamera(false));
    this.props.dispatch(showModal());
    this.setState({ ModalWebCam: true });
  }
  handleTakePhoto(url) {
    this.props.dispatch(setCamera(url));
    this.props.change("foto", url);
  }

  componentDidMount() {
    getData("group/get/all")
      .then((res) => {
        this.setState({
          DataGroup: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getValue = async (event) => {
    const file = event.target.files[0];
    const base64 = await convertBase64(file);
    this.props.dispatch(setCamera(base64));
    this.props.change("foto", base64);
  };

  getJenis(params) {
    this.setState({ isLoadingJenis: true });
    getData("jenis/get/by-kode-kategori/" + params)
      .then((res) => {
        this.setState({
          DataJenis: res.data,
        });
      })
      .then((res) => {
        this.setState({
          isLoadingJenis: false,
        });
      })
      .catch((err) => {
        NotifInfo(err.data);
        console.log(err);
      });
  }
  getBaki(params) {
    this.setState({ isLoadingBaki: true });
    getData("baki/get/by-kode-baki/" + params)
      .then((res) => {
        this.setState({
          databaki: res.data === "" ? [] : [res.data],
        });
      })
      .then((res) => {
        this.setState({
          isLoadingBaki: false,
        });
      })
      .catch((err) => {
        NotifInfo(err.data);
        console.log(err);
      });
  }
  loadcamera() {
    return (
      <Camera
        onTakePhoto={(dataUri) => {
          this.handleTakePhoto(dataUri);
        }}
      />
    );
  }
  render() {
    return (
      <>
        <form onSubmit={this.props.handleSubmit} autoComplete="off">
          <div className="row">
            <div className="col-lg-8">
              <div className="row">
                <div className="col-lg-6">
                  <Field
                    readOnly={true}
                    name="foto"
                    type="hidden"
                    component={HiidenFiled}
                  />
                  <Field
                    readOnly={true}
                    name="kode_barcode"
                    component={ReanderField}
                    label="Kode Barcode"
                    placeholder="Masukan Kode Barcode"
                  />
                </div>
                <div className="col-lg-6">
                  <Field
                    name="nama_barang"
                    component={ReanderField}
                    label="Nama Barang"
                    placeholder="Masukan Nama Barang"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-8">
                  <Field
                    name="nama_atribut"
                    component={ReanderField}
                    label="Nama Atribut"
                    placeholder="Masukan Nama Atribut"
                  />
                </div>
                <div className="col-lg-4">
                  <Field
                    name="harga_atribut"
                    component={ReanderField}
                    label="Harga Atribut"
                    {...currencyMask}
                    placeholder="Masukan Harga Atribut"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <Field
                    name="kadar"
                    component={ReanderField}
                    label="Kadar"
                    placeholder="Masukan Kadar"
                  />
                </div>
                <div className="col-lg-6">
                  <Field
                    name="kadar_cetak"
                    component={ReanderField}
                    label="Kadar Cetak"
                    placeholder="Masukan Kadar Cetak"
                  />
                </div>
                <div className="col-lg-6">
                  <Field
                    name="kode_intern"
                    component={ReanderField}
                    label="Kode Intern"
                    placeholder="Masukan Kode Intern"
                  />
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label> &nbsp; </label>
                    {this.props.isLoading === true ? (
                      <LoadingButton
                        className="btn btn-block btn-primary"
                        text="Sedang Menyimpan Data"
                      />
                    ) : (
                      <>
                        <button
                          type="submit"
                          className="btn btn-block btn-primary"
                        >
                          Simpan Barang
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="row">
                <div className="col-lg-4">
                  {this.state.ModalWebCam === true ? (
                    this.props.setCamera === false ? (
                      <div className="container">{this.loadcamera()}</div>
                    ) : (
                      <>
                        <img
                          src={
                            this.props.foto === undefined
                              ? this.props.setCamera === false
                                ? imgfound
                                : this.props.setCamera
                              : this.props.setCamera
                              ? this.props.setCamera
                              : this.props.foto
                          }
                          alt="img"
                          height="245px"
                          width="355px"
                        />
                      </>
                    )
                  ) : (
                    <>
                      <img
                        src={
                          this.props.foto === undefined
                            ? this.props.setCamera === false
                              ? imgfound
                              : this.props.setCamera
                            : this.props.setCamera
                            ? this.props.setCamera
                            : this.props.foto
                        }
                        alt="img"
                        height="245px"
                        width="355px"
                      />
                    </>
                  )}
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-lg-12 mb-8">
                  <div className="row">
                    <div className="col-8">
                      <div className="input-group">
                        <div className="custom-file">
                          <input
                            type="file"
                            name="photo"
                            autoComplete="off"
                            className="custom-file-input"
                            id="exampleInputFile"
                            onChange={(e) => this.getValue(e)}
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="exampleInputFile"
                          >
                            Pilih gambar
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-4">
                      <button
                        className="btn btn-block btn-primary"
                        type="button"
                        onClick={() => this.showModalWebcam()}
                      >
                        {" "}
                        WebCam{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="row">
            <div className="col-lg-8"></div>
            <div className="col-lg-4">
              <div className="form-group">
                <label> &nbsp; </label>
                {this.props.isLoading === true ? (
                  <LoadingButton className="btn btn-block btn-primary" text="Sedang Menyimpan Data"/>
                ) : (
                  <>
                    <button type="submit" className="btn btn-block btn-primary">
                      Simpan Barang
                    </button>
                  </>
                )}
              </div>
            </div>
          </div> */}
          {this.props.isLoading ? (
        <Loading 
        loading
        background="rgba(0, 0, 0, 0.35)"
         loaderColor="rgba(94, 147, 117, 1)" />
      ) : null}
        </form>
      </>
    );
  }
}

HeadEditDataBarang = reduxForm({
  form: "HeadEditDataBarang",
  enableReinitialize: true,
})(HeadEditDataBarang);
export default connect(maptostate)(HeadEditDataBarang);
