import { SHOW_MODAL_TAMBAH_BARANG } from "../actions/KirimBarangDariBos_action";

  const initialState = {
    shoModalTambahBarang: [],
  };
  
  const KirimBarangDariBos = (state = initialState, actions) => {
      switch (actions.type) {
        
        case SHOW_MODAL_TAMBAH_BARANG:
          return {
            ...state,
            shoModalTambahBarang: actions.payload.data,
          };
       
        default:
          return state;
      }
  }
  
  export default KirimBarangDariBos;