import jsPDF from "jspdf";
import "jspdf-autotable";
import {
  getToday,
  getUserdata,
  localStoragedecryp,
} from "../../../../../components/helpers/function";
// Date Fns is used to format the dates we receive

// define a generatePDF function that accepts a tickets argument
const LaporanKirimSepuhPdf = (data = "") => {
  // initialize jsPDF
  const doc = new jsPDF("l", "mm", [297, 210]);
  //   let data = JSON.parse(localStorage.getItem("tt_pengeluaran_barang")) || [];
  let tableRows = [];
  let tableColumn = [];

  //   let footRows = [];
  let finalY = 30;
  doc.text("LAPORAN KIRIM SEPUH", 14, 15);
  doc.setFontSize(20);
  doc.text(getUserdata().nama_toko, 200, 15);
  doc.setFontSize(8);
  let jml_alamat = getUserdata().alamat_toko.length;
  if (jml_alamat > 20) {
    doc.text(getUserdata().alamat_toko.slice(0, 48), 200, 20);
  }
  if (jml_alamat > 50) {
    doc.text(getUserdata().alamat_toko.slice(48, 90), 200, 25);
  }

  doc.setFontSize(10);
  doc.setProperties({
    title: "LAPORAN KIRIM SEPUH",
  });
  doc.text(
    `PERIODE : ${
      localStoragedecryp("tgl_laporan",[], true).length === 0
        ? null
        : localStoragedecryp("tgl_laporan",[], true).tgl_awal +
          " s/d " +
          localStoragedecryp("tgl_laporan",[], true).tgl_akhir
    }`,
    14,
    25
  );

  //   tableRows.push(tableColumn)
  tableColumn = [
    [
      {
        content: `TANGGAL`,
      },
      {
        content: `KODE BAKI`,
      },
      {
        content: `JENIS`,
      },
      {
        content: `KODE GORUP`,
      },
      {
        content: `QTY`,
        styles: {
          halign: "right",
        },
      },
      {
        content: `BERAT`,
        styles: {
          halign: "right",
        },
      },
      {
        content: `QTY RUSAK`,
        styles: {
          halign: "right",
        },
      },
      {
        content: `QTY SEPUH`,
        styles: {
          halign: "right",
        },
      },
      {
        content: `BERAT RUSAK`,
        styles: {
          halign: "right",
        },
      },

      {
        content: `BERAT SEPUH`,
        styles: {
          halign: "right",
        },
      },
    ],
  ];
  data.forEach((item, no) => {
    let head = [
      {
        content: `NO FAKTUR : ${item.no_sepuh}`,
        colSpan: 12,
        styles: {
          lineWidth: 0.02,
          lineColor: "#000",
        },
      },
    ];
    tableRows.push(head);
    let row = [
      item.tgl_system,
      item.kode_baki,
      item.kode_dept,
      item.kode_group,
      {
        content: item.qty,
        styles: {
          halign: "right",
        },
      },

      {
        content: item.berat.toFixed(2),
        styles: {
          halign: "right",
        },
      },
      {
        content: item.qty_rusak,
        styles: {
          halign: "right",
        },
      },
      {
        content: item.qty_sepuh,
        styles: {
          halign: "right",
        },
      },
      {
        content: item.berat_rusak.toFixed(2),
        styles: {
          halign: "right",
        },
      },
      {
        content: item.berat_sepuh.toFixed(2),
        styles: {
          halign: "right",
        },
      },
    ];
    tableRows.push(row);
  });
  let footer = [
    {
      content: `Sub Total`,
      colSpan: 5,
      styles: {
        halign: "left",
        fillColor: "#E8E5E5",
        textColor: "#000",
      },
    },
    {
      content: data.reduce((acc, b) => acc + b.qty, 0),
      styles: {
        halign: "right",
        fillColor: "#E8E5E5",
        textColor: "#000",
      },
    },
    {
      content: data.reduce((acc, b) => acc + b.berat, 0).toFixed(2),
      styles: {
        halign: "right",
        fillColor: "#E8E5E5",
        textColor: "#000",
      },
    },
    {
      content: data.reduce((acc, b) => acc + b.qty_rusak, 0),
      styles: {
        halign: "right",
        fillColor: "#E8E5E5",
        textColor: "#000",
      },
    },
    {
      content: data.reduce((acc, b) => acc + b.qty_sepuh, 0),
      styles: {
        halign: "right",
        fillColor: "#E8E5E5",
        textColor: "#000",
      },
    },
    {
      content: data.reduce((acc, b) => acc + +b.berat_rusak, 0).toFixed(2),
      styles: {
        halign: "right",
        fillColor: "#E8E5E5",
        textColor: "#000",
      },
    },
    {
      content: data.reduce((acc, b) => acc + +b.berat_sepuh, 0).toFixed(2),
      styles: {
        halign: "right",
        fillColor: "#E8E5E5",
        textColor: "#000",
      },
    },
  ];
  tableRows.push(footer);

  let printed = [
    {
      content: `Printed By ${
        localStoragedecryp("userdata").user_id
      } / ${getToday()}`,
      colSpan: 12,
      styles: {
        // lineWidth: 0.1,
        fontStyle: "italic",
        textColor: "#000",
        // fillColor: "#E8E5E5"
      },
    },
  ];
  tableRows.push(printed);

  doc.autoTable({
    head: tableColumn,
    body: tableRows,
    // foot: footRows,
    startY: finalY,
    theme: "plain",
    // rowPageBreak: "avoid",
    pageBreak: "avoid",
    margin: { top: 10 },
    bodyStyles: {
      // lineWidth: 0.02, lineColor: "#000",
      fontSize: 8,
    },
    headStyles: {
      fontSize: 8,
      //   lineWidth: 0.02,
      //   lineColor: "#000",
      fillColor: "#E8E5E5",
      textColor: "#000",
    },
    tableLineColor: [255, 255, 255],
    tableLineWidth: 1,
  });
  tableRows = [];
  tableColumn = [];
  finalY = doc.autoTableEndPosY() + 20;

  const pages = doc.internal.getNumberOfPages();
  const pageWidth = doc.internal.pageSize.width; //Optional
  const pageHeight = doc.internal.pageSize.height; //Optional
  doc.setFontSize(10); //Optional
  for (let j = 1; j < pages + 1; j++) {
    let horizontalPos = pageWidth / 2; //Can be fixed number
    let verticalPos = pageHeight - 10; //Can be fixed number
    doc.setPage(j);
    doc.text(`${j} of ${pages}`, horizontalPos, verticalPos, {
      align: "center",
    });
  }
  // doc.autoPrint();
  // doc.save(`PenerimaanSUpplier.pdf`);
  var string = doc.output("datauristring");
  var x = window.open();
  x.document.open();
  x.document.write(
    "<html><head><title>LAPORAN KIRIM SEPUH</title></head><body style='margin:0 !important'><embed width='100%' height='100%'  src=" +
      string +
      "></embed></body></html>"
  );
  // setInterval(() => {
  //   x.close();
  // }, 1000);
};

export default LaporanKirimSepuhPdf;
