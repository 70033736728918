import React, { Component } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import {
    formatGram,
    getUserdata,
} from "../../../../../components/helpers/function";
class LaporanBarangDalamExcel extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="btn btn-success btn-block"
          table="table-to-xls"
          filename="Laporan Barang Dalam"
          sheet="Laporan Barang Dalam"
          buttonText="Export Excel"
        />
        <table id="table-to-xls" style={{ display: "none" }}>
          <thead>
            <tr>
              <th colSpan="10" style={{ textAlign: "center" }}>
                {" "}
                LAPORAN BARANG DALAM{" "}
              </th>
            </tr>
            <tr>
              <th colSpan="10"> TOKO : {getUserdata().nama_toko} </th>
            </tr>

            <tr>
              <th colSpan="10"> ALAMAT : {getUserdata().alamat_toko} </th>
            </tr>
            <tr>
              <th colSpan="10"></th>
            </tr>
            <tr>
              <td style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                DESKRIPSI
              </td>
              <td style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                KATEGORI
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                PTG
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                BERAT
              </td>
            </tr>
          </thead>
          {this.props.data.map((row, index) => (
            <>
              <tbody>
                <tr>
                  <td>{row.kode_gudang}</td>
                  <td>{row.kode_group}</td>
                  <td style={{ textAlign: "right" }}>&nbsp;{row.qty}</td>
                  <td style={{ textAlign: "right" }}>&nbsp;{formatGram(row.berat, 2)}</td>
                </tr>
              </tbody>
            </>
          ))}
          <tfoot>
            <tr>
              <td
                colSpan="2"
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "center",
                }}
              >
                Sub Total
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                {this.props.data.reduce((acc, item) => parseFloat(acc) + parseInt(item.qty), 0)}
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                {formatGram((this.props.data
                    .reduce((acc, item) => parseFloat(acc) + parseFloat(item.berat), 0)), 2)}
              </td>
            </tr>
          </tfoot>
        </table>
      </>
    );
  }
}
export default LaporanBarangDalamExcel;
