const ValidasiLaporanKeuangan = (value, event) => {
    const errors = {};
    // console.log(event.charCode==13);
    if (!value.tanggal_awal) {
      errors.tanggal_awal = "Tanggal Tidak Boleh Kosong";
    }
    if (!value.type) {
      errors.type = "Type Tidak Boleh Kosong";
    }
    if (!value.type_laporan) {
      errors.type_laporan = "Type Laporan Tidak Boleh Kosong";
    }
    return errors;
  };
  
  export default ValidasiLaporanKeuangan;
  