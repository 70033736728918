export const SHOW_MODAL_TAMBAH_BARANG = "SHOW_MODAL_TAMBAH_BARANG";
export const shoModalTambahBarang = (data) => {
    return (dispatch) => {
        dispatch({
            type: SHOW_MODAL_TAMBAH_BARANG,
            payload: {
                data: data
            }
        })   
    }
}