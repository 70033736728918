import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import {
  InputDate,
  ReanderSelect,
} from "../../../components/helpers/field";
import { getItem, getToday } from "../../../components/helpers/function";
import HeadStockPembelian from "../../../Validasi/StockPembelian/HeadStockPembelian";
const maptostate = (state) => {
  let detail = getItem('data_detail_kirim_barang_sepuh');
  return {
    initialValues: {
      tanggal_kirim: detail.tanggal_kirim ? detail.tanggal_kirim : getToday(),
      tujuan: detail.tujuan || "",
    },
  };
}
class HeadDetailKirim extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}
  setStartDate(date) {
    this.setState({
      tanggal_kirim: new Date(date),
    });
  }
  render() {
    const tujuan = [
      {
        name: "Tujuan 1",
        value: "Tujuan 1",
      },
      {
        name: "Tujuan 2",
        value: "Tujuan 2",
      },
    ];
    return (
      <form
        onSubmit={this.props.handleSubmit}
        onKeyPress={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
        autoComplete="off"
      >
        <div className="row">
          <div className="col-6">
            <Field
              name="tanggal_kirim"
              component={InputDate}
              label="Tanggal Kirim"
              type="text"
              selected={this.state.tanggal_kirim}
              onChange={(date) => this.setStartDate(date)}
              placeholder="Masukan Tanggal"
            />
          </div>
          <div className="col-6">
            <Field
              label="Tujuan"
              name="tujuan"
              options={tujuan}
              placeholder={"Silahkan Pilih Tujuan"}
              component={ReanderSelect}
            />
          </div>
          <div className="col-12 text-right">
            <label> &nbsp; </label>
            <br />
            <button
              className="btn btn-primary btn-block"
              disabled={this.props.isLoadingTambahDetailKirim}
              type="submit"
            >
              {this.props.isLoadingTambahDetailKirim ? 
                <>
                  <i className="fas fa-spinner fa-spin"></i> &nbsp; Sedang
                  Menyimpan
                </>
              : getItem('data_detail_kirim_barang_sepuh').tanggal_kirim ? 'Edit Data' : "Simpan Data"
              }
            </button>
          </div>
        </div>
      </form>
    );
  }
}

HeadDetailKirim = reduxForm({
  form: "HeadDetailKirim",
  enableReinitialize: true,
  validate : HeadStockPembelian

})(HeadDetailKirim);
export default connect(maptostate)(HeadDetailKirim);
