import {
  React,
  connect,
  Link,
  reduxForm,
  Panel,
  PanelHeader,
  PanelBody,
  getDataNoTimeOut2,
  convertDate,
  ToastNotification,
  localStorageencryp,
  Swal,
  getItem,
  doDecrypt,
  deleteData2
} from "../../../../components/helpers/library";
import HeadSortirSepuh from "./HeadSortirSepuh";

class LaporanSortirSepuh extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      export: false,
      type: "GLOBAL",
      databarang: [],
    };
  }
  btlSortir(row) {
    Swal.fire({
      html:
        "Apakah Anda Yakin Ingin " +
        "Membatal " +
        "<h1><b>No Sortir Ini <br/>" +
        row.no_sortir +
        "</b> ?</h1>",
      icon: "warning",
      position: "top-center",
      cancelButtonText: "Tidak",
      showCancelButton: true,
      confirmButtonText: "OK",
      showConfirmButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        this.setState({
          isLoading: true,
        });
        let data_laporan = getItem("tgl_laporan");
        deleteData2("sortir-sepuh/cancel?no_sortir=" + row.no_sortir).then(() => {
          let databaru = doDecrypt(data_laporan,[]);
          this.handleSubmit(databaru)
          this.setState({
            isLoading: false,
          });
         ToastNotification('success',"No Sortir Berhasil Di Batal");
        }).catch((err)=>{
          ToastNotification("info", err?.response?.data?.message || "Terjadi Kesalahan Saat Mengirim Data, Silahkan Ulangi Lagi !!")
          this.setState({
            isLoading: false,
          });
        })
      }else{
        this.setState({
          isLoading: false,
        });
      }
    }).catch((err) => {
      this.setState({
        isLoading: false,
      });
      ToastNotification("error", err?.response?.data?.message || "Terjadi Kesalahan Saat Mengirim Data, Silahkan Ulangi Lagi !!")
    })
  }
  handleSubmit(data) {
    this.setState({
      isLoading: true,
    });
    let data_post = {
      tgl_awal: convertDate(data.tgl_awal),
      tgl_akhir: convertDate(data.tgl_akhir),
      type: data.type,
      kode_baki: data.kode_baki,
    };
    getDataNoTimeOut2(
      "sortir-sepuh/reports?type=" +
        data_post.type +
        "&tgl_to=" +
        data_post.tgl_akhir +
        "&tgl_from=" +
        data_post.tgl_awal+
        "&kode_baki=" +
        data_post.kode_baki
    )
      .then((res) => {
        if (res.data.length === 0) {
          ToastNotification("info", "Data Yang Anda Cari Tidak Ada");
          this.setState({
            export: false,
            isLoading: false,
            databarang: [],
          });
        } else {
          ToastNotification("success", "Laporan Kirim Sepuh Tersedia");
          localStorageencryp("tgl_laporan", data_post, [], true);
          this.setState({
            databarang: [],
          });
          let databarang = [];
          if (this.state.type === "GLOBAL") {
            res.data.forEach((element) => {
              databarang.push(element);
            });
          } else {
            res.data.forEach((element) => {
              element.detail_sortir.forEach((el) => {
                databarang.push(el);
              });
            });
          }
          this.setState({
            export: true,
            isLoading: false,
            databarang: databarang,
          });
        }
      })
      .catch((err) => {
        this.setState({
          export: false,
          isLoading: false,
          databarang: [],
        });
        ToastNotification("info", "Data Yang Anda Cari Tidak Ada");
      });
  }
  chnageType(e) {
    this.setState({
      export: false,
      type: e,
      databarang: [],
    });
  }
  render() {
    return (
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="#">Laporan</Link>
          </li>
          <li className="breadcrumb-item active">Laporan Sortir Sepuh</li>
        </ol>
        <h1 className="page-header">Laporan Sortir Sepuh </h1>
        <Panel>
          <PanelHeader>Laporan Sortir Sepuh</PanelHeader>
          <PanelBody>
            <HeadSortirSepuh
              type={this.state.type}
              chnageType={(data) => this.chnageType(data)}
              export={this.state.export}
              isLoading={this.state.isLoading}
              databarang={this.state.databarang}
              btlSortir={(data)=>this.btlSortir(data)}
              onSubmit={(data) => this.handleSubmit(data)}
            />
          </PanelBody>
        </Panel>
      </div>
    );
  }
}

LaporanSortirSepuh = reduxForm({
  form: "LaporanSortirSepuh",
  enableReinitialize: true,
})(LaporanSortirSepuh);
export default connect(null)(LaporanSortirSepuh);
