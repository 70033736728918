const Menu = [
  {
    path: "/dashboard",
    icon: "fa fa-th",
    title: "Dashboard",
  },

  {
    path: "#",
    icon: "fa fa-hdd",
    title: "Data Master",
    children: [
      { path: "/master-toko", title: "Master Toko" },
      { path: "/master-group", title: "Master Group" },
      { path: "/master-jenis", title: "Master Jenis" },
      { path: "/master-gudang", title: "Master Gudang" },
      { path: "/master-baki", title: "Master Baki" },
      { path: "/master-bandrol", title: "Master Bandrol" },
      {
        path: "/master-tukang",
        title: "Master Tukang",
      },
      {
        path: "#",
        title: "Data bahan",
        children: [
          { path: "/tambah-bahan-tukang", title: "Tambah Bahan Tukang" },
          { path: "/ambil-bahan-tukang", title: "Ambil Bahan Tukang" },
        ],
      },
      // { path: "/master-labeling", title: "Master Labeling" },
      // { path: "/master-kadar", title: "Master Kadar" },
      { path: "/master-pabrik", title: "Master Pabrik" },
      { path: "/master-atribut", title: "Master Atribut" },
      { path: "/master-kondisi-barang", title: "Master Kondisi Barang" },
      { path: "/master-parameter-point", title: "Parameter Point" },
    ],
  },

  {
    path: "#",
    icon: "fa fa-filter",
    title: "Sepuhan",
    children: [
      { path: "/sortir-pembelian", title: "Sortir Pembelian" },
      { path: "/sortir-sepuh", title: "Sortir Sepuh" },
      { path: "/kirim-sepuh", title: "Kirim Sepuh" },
      { path: "/terima-sepuh", title: "Terima Sepuh" },
      { path: "/kirim-labeling", title: "Kirim Labeling" },
    ],
  },
  {
    path: "#",
    icon: "fa fa-boxes",
    title: "Pesanan",
    children: [
      { path: "/pembagian-pesanan", title: "Pembagian Pesanan" },
      { path: "/pesanan-selesai", title: "Pesanan Selesai" },
      { path: "/cetak-ulang-pesanan", title: "Cetak Ulang Pesanan" },
    ],
  },

  {
    path: "#",
    icon: "fa fa-archive",
    title: "Barang",
    children: [
      { path: "/terima-barang", title: "Terima Barang" },
      { path: "/data-barang", title: "Data Barang" },
      { path: "/kirim-barang", title: "Kirim Barang" },
      { path: "/hancur-data-barang", title: "Hancur Data Barang" },
      { path: "/pindah-barang", title: "Pindah Barang" },
      { path: "/pindah-barang-satu-baki", title: "Pindah Barang Satu Baki" },
      { path: "/konversi-barang", title: "Konversi Barang" },
      // { path: "/timbang-baki", title: "Timbang Baki" },
    ],
  },
  {
    path: "#",
    icon: "fa fa-shopping-cart",
    title: "Stock Pembelian",
    children: [
      { path: "/kirim-dari-barang-bos", title: "Kirim Dari Barang Bos" },
    ],
  },
  {
    path: "#",
    icon: "fas fa-users",
    title: "Manage Users",
    children: [
      { path: "/data-users", title: "Data Users" },
      { path: "/hak-akses-users", title: "Hak Akses Users" },
    ],
  },
  {
    path: "#",
    icon: "fas fa-business-time",
    title: "Stock Opname",
    children: [
      { path: "/stock-opname-pesanan", title: "Pesanan" },
      // { path: "/stock-opname-kirim-barang-dari-bos", title: "Kirim Barang Dari Bos" },
    ],
  },
  {
    path: "#",
    icon: "fas fa-desktop",
    title: "Validasi",
    children: [
      { path: "/validasi-terima-stock", title: "Validasi Terima Stock" },
      { path: "/validasi-inputan", title: "Validasi Inputan" },
      { path: "/validasi-barang-rusak", title: "Validasi Barang Rusak" },
    ],
  },
  {
    path: "#",
    icon: "fas fa-id-card",
    title: "Member",
    children: [
      { path: "/data-member", title: "Data Member" },
      { path: "/lihat-transaksi-member", title: "Lihat Transaksi Member" },
      { path: "/transaksi-poin", title: "Transaksi Poin" },
      { path: "/tukar-point", title: "Tukar Poin" },
      { path: "/database-member", title: "Database Member" },
    ],
  },
  {
    path: "#",
    icon: "fas fa-folder-open",
    title: "Laporan",
    children: [
      {
        path: "#",
        title: "Laporan Barang",
        children: [
          { path: "/laporan-tambah-barang", title: "Laporan Tambah Barang" },
          { path: "/laporan-barang-detail", title: "Laporan Barang Detail" },
          { path: "/laporan-hancur-barang", title: "Laporan Hancur Barang" },
          { path: "/laporan-pindah-barang", title: "Laporan Pindah Barang" },
          { path: "/laporan-barang-summary", title: "Laporan Barang Summary" },
          { path: "/laporan-kirim-barang", title: "Laporan Kirim Barang" },
          {
            path: "/laporan-konversi-barang",
            title: "Laporan Konversi Barang",
          },
        ],
      },
      // {
      //   path: "#", title: "Laporan Sepuhan",
      //   children: [
      //     { path: "/laporan-kirim-sepuh", title: "Laporan Kirim Sepuh" },
      //   ]
      // },
      {
        path: "#",
        title: "Laporan Member",
        children: [
          { path: "/laporan-daftar-member", title: "Laporan Daftar Member" },
        ],
      },
      { path: "/laporan-sortir-pembelian", title: "Laporan Sortir Pembelian" },
      {
        path: "#",
        title: "Laporan Stock Pembelian",
        children: [
          {
            path: "/laporan-kirim-barang-dari-bos",
            title: "Laporan Kirim Barang Dari Bos",
          },
          {
            path: "/laporan-saldo-barang-aktif",
            title: "Laporan Saldo Barang Aktif",
          },
        ],
      },
      {
        path: "#",
        title: "Laporan Sepuh",
        children: [
          { path: "/laporan-kirim-sepuh", title: "Laporan Kirim Sepuh" },
          { path: "/laporan-sortir-sepuh", title: "Laporan Sortir Sepuh" },
          { path: "/laporan-barang-dalam", title: "Laporan Barang Dalam" },
          { path: "/laporan-kirim-labeling", title: "Laporan Kirim Labeling" },
          {
            path: "/laporan-terima-barang-sepuh",
            title: "Laporan Terima Barang Sepuh",
          },
          {
            path: "/laporan-validasi-barang-rusak",
            title: "Laporan Validasi Barang Rusak",
          },
          {
            path: "/laporan-validasi-validasi-inputan",
            title: "Laporan Validasi Inputan",
          },
        ],
      },
      {
        path: "#",
        title: "Laporan Batal Sortir",
        children: [
          { path: "/laporan-batal-sortir-pembelian", title: "Pembelian" },
          { path: "/laporan-batal-sortir-sepuh", title: "Sortir Sepuh" },
        ],
      },
      {
        path: "#",
        title: "Laporan Pesanan",
        children: [
          { path: "/laporan-pesanan-tukang", title: "Laporan Pesanan Tukang" },
          { path: "/laporan-bahan-tukang", title: "Laporan Bahan Tukang" },
          {
            path: "/laporan-pesanan-so",
            title: "Laporan Pesanan So",
          },
        ],
      },
      { path: "/laporan-owner", title: "Laporan Owner" },
    ],
  },
  {
    path: "#",
    icon: "fas fa-cog",
    title: "Utility",
    children: [
      // { path: "/settingtoko", title: "Setting Toko" },
      { path: "/settingsystem", title: "Setting System" },
      { path: "/cetak-barcode", title: "Cetak Barcode" },
      // { path: '#', title: 'Hak Akses Users' },
    ],
  },
];

export default Menu;
