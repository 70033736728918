import Swal from "sweetalert2";
import {
  getMasterLabeling,
  hideModal,
  showMasterLabeling,
  showModal,
} from "../../../actions/datamaster_action";
import {
  React,
  connect,
  Link,
  reduxForm,
  Panel,
  PanelHeader,
  PanelBody,
  ModalGlobal,
  Tabel,
  reset,
  ErrorHandling,
  postData,
  isLoading,
  isEdit,
  ToastNotification,
  putData,
  deleteData,
} from "../../../components/helpers/library";
import ModalLabeling from "./ModalLabeling";

const maptostate = (state) => {
  return {
    isEdit: state.datamaster.isEdit,
    isLoading: state.datamaster.isLoading,
    DataLabeling: state.datamaster.getMasterLabeling,
  };
};
class MasterLabeling extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      settings: "",
      columns: [
        {
          dataField: "kode_labeling",
          text: "Kode Labeling",
        },
        {
          dataField: "nama_labeling",
          text: "Nama Labeling",
        },
        {
          dataField: "action",
          text: "Action",
          csvExport: false,
          headerClasses: "text-center",
          formatter: (rowcontent, row, index) => {
            let data = {
              _id: row._id,
              nama_labeling: row.nama_labeling,
              kode_labeling: row.kode_labeling,
            };
            return (
              <div className="row text-center">
                <div className="col-12">
                  <button
                    type="button"
                    h="5"
                    onClick={() => this.editModalTambahLabeling(data)}
                    className="btn btn-warning mr-3"
                  >
                    <i className="fa fa-edit"></i>
                  </button>
                  <button
                    h="5"
                    type="button"
                    onClick={() =>
                      this.hapusKodeLabeling(row.kode_labeling, row._id)
                    }
                    className="btn btn-danger mr-3"
                  >
                    <i className="fa fa-trash"></i>
                  </button>
                </div>
              </div>
            );
          },
        },
      ],
    };
    // this.print = this.print.bind(this);
  }
  hapusKodeLabeling(params, id) {
    Swal.fire({
      html:
        "Apakah Anda Yakin Ingin " +
        "Menghapus " +
        "<h1><b>Kode Labeling " +
        params +
        "</b> ?</h1>",
      icon: "warning",
      position: "top-center",
      cancelButtonText: "Tidak",
      showCancelButton: true,
      confirmButtonText: "OK",
      showConfirmButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteData("labeling/delete/by-kode-labeling/" + id).then(() => {
          ToastNotification("success", "Data Berhasil Di Hapus").then(() =>
            this.props.dispatch(getMasterLabeling())
          );
        });
      }
    });
  }
  editModalTambahLabeling(data) {
    this.props.dispatch(isEdit(true));
    this.props.dispatch(showModal());
    this.props.dispatch(showMasterLabeling(data));
  }
  componentDidMount() {
    this.props.dispatch(getMasterLabeling());
  }
  showModalTambahLabeling() {
    this.props.dispatch(isEdit(false));
    this.props.dispatch(showModal());
    this.props.dispatch(showMasterLabeling(false));
  }
  batal() {
    this.props.dispatch(reset("ModalLabeling"));
    this.props.dispatch(hideModal());
  }

  simpanLabeling(data) {
    this.props.dispatch(isLoading(true));

    let data_post = {
      kode_labeling: data.kode_labeling,
      nama_labeling: data.nama_labeling,
    };
    let data_edit = {
      nama_labeling: data.nama_labeling,
    };

    setTimeout(() => {
      this.props.isEdit
        ? putData(
            "labeling/edit/by-kode-labeling/" + data._id,
            data_edit,
            ["kode_labeling"],
            true
          )
            .then(() => {
              this.props.dispatch(hideModal());
            })
            .then(() => {
              ToastNotification("success", "Data Berhasil Diedit");
            })
            .then(() => {
              this.props.dispatch(getMasterLabeling());
            })
            .then(() => {
              // this.setState({ isLoading: false });
              this.props.dispatch(isLoading(false));
            })
            .then(() => {
              this.props.dispatch(reset("ModalLabeling"));
            })
            .catch((error) => {
              ToastNotification("info", "Terjadi Kesalahan Saat Mengirim Data");
              this.props.dispatch(isLoading(false));
            })
        : postData("labeling/add", data_post, [], true)
            .then(() => {
              this.props.dispatch(hideModal());
            })
            .then(() => {
              ToastNotification("success", "Data Berhasil Disimpan");
            })
            .then(() => {
              this.props.dispatch(getMasterLabeling());
            })
            .then(() => {
              this.props.dispatch(reset("ModalLabeling"));
            })
            .then(() => {
              this.props.dispatch(isLoading(false));
            })
            .catch((error) =>
              ErrorHandling(
                error,
                "labeling/reactive/by-kode-labeling/",
                data_post,
                this.props.dispatch,
                getMasterLabeling(),
                hideModal(),
                "ModalLabeling"
              ).then(() => {
                this.props.dispatch(isLoading(false));
              })
            );
    }, 300);
  }
  render() {
    return (
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="#">Data Master</Link>
          </li>
          <li className="breadcrumb-item active">Master Labeling</li>
        </ol>
        <h1 className="page-header">Master Labeling </h1>
        <Panel>
          <PanelHeader>Master Labeling</PanelHeader>
          <br />
          {/* Blank Page */}
          <PanelBody>
            <Tabel
              tambahData={true}
              handleClick={() => this.showModalTambahLabeling()}
              featur={true}
              keyField="kode_labeling"
              data={this.props.DataLabeling}
              columns={this.state.columns}
              empty={[]}
              textEmpty="Data Labeling Kosong"
            />
          </PanelBody>
          <br />
        </Panel>
        <ModalGlobal
          size="P"
          title={
            this.props.isEdit ? "Edit Data Labeling" : "Tambah Data Labeling"
          }
          content={
            <ModalLabeling
              onSubmit={(data) => this.simpanLabeling(data)}
              batal={() => this.batal()}
            />
          }
        />
      </div>
    );
  }
}

MasterLabeling = reduxForm({
  form: "MasterLabeling",
  enableReinitialize: true,
})(MasterLabeling);
export default connect(maptostate)(MasterLabeling);
