const ValidateMasterBarang = (value) => {
    const errors = {};
    // console.log(event.charCode==13);
    if (!value.kadar) {
      errors.kadar = "Kadar Harus Diisi";
    }
    if (!value.pabrik) {
      errors.pabrik = "Pabrik Harus Diisi";
    }
    if (!value.atribut) {
      errors.atribut = "Atribut Harus Diisi";
    }
   
  
    return errors;
  };
  
  export default ValidateMasterBarang;
  