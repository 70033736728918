import {
  React,
  connect,
  reduxForm,
  HiidenFiled,
  ReanderField,
  Field,
} from "../../../components/helpers/library";
import ValidateMasterBarang from "../../../Validasi/Barang/ValidateMasterBarang";

const maptoState = (state) => {
  if (state.datamaster.showMasterLabeling !== null) {
    return {
      isEdit: state.datamaster.isEdit,
      isLoading: state.datamaster.isLoading,
      initialValues: {
        _id: state.datamaster.showMasterLabeling._id,
        kode_labeling: state.datamaster.showMasterLabeling.kode_labeling,
        nama_labeling: state.datamaster.showMasterLabeling.nama_labeling,
      },
    };
  }
};
class ModalLabeling extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      settings: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    // this.print = this.print.bind(this);
  }

  handleSubmit() {}

  render() {
    return (
      <form
        onSubmit={this.props.handleSubmit}
        onKeyPress={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
        autoComplete="off"
      >
        <div className="row">
          <div className="col-12">
            <Field
              tabIndex="1"
              id="_id"
              name="_id"
              component={HiidenFiled}
              type="hidden"
            />
            <Field
              tabIndex="1"
              id="kode_labeling"
              name="kode_labeling"
              component={ReanderField}
              label="Kode Labeling"
              type="text"
              readOnly={this.props.isEdit}
              placeholder="Masukan Kode Labeling"
            />
            <Field
              tabIndex="1"
              id="nama_labeling"
              name="nama_labeling"
              component={ReanderField}
              label="Nama Labeling"
              type="text"
              placeholder="Masukan Nama Labeling"
            />
          </div>

          <div className="col-12 text-right">
            <label> &nbsp; </label>
            <br />
            <button
              className="btn btn-warning"
              type="button"
              onClick={() => this.props.batal()}
            >
              Batal
            </button>
            &nbsp;
            <button
              tabIndex="2"
              className="btn btn-primary"
              disabled={this.props.isLoading}
              type="submit"
            >
              {this.props.isLoading ? (
                <>
                  <i className="fas fa-spinner fa-spin"></i> &nbsp; Sedang
                  Menyimpan
                </>
              ) : (
                "Simpan Data"
              )}
            </button>
          </div>
        </div>
      </form>
    );
  }
}

ModalLabeling = reduxForm({
  form: "ModalLabeling",
  enableReinitialize: true,
  validate: ValidateMasterBarang,
})(ModalLabeling);
export default connect(maptoState)(ModalLabeling);
