import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { reduxForm, reset } from "redux-form";
import {
  disableButtonValidasi,
  saveValidasiBeli,
  saveValidasiJual,
} from "../../../actions/hutang_action.jsx";
import {
  BgLoading,
  convertDate,
  // convertDate,
  getItem,
  getTglSystem,
  // getTglSystem,
} from "../../../components/helpers/function.jsx";
import { ToastNotification } from "../../../components/helpers/notification.jsx";
import {
  Panel,
  PanelBody,
  PanelHeader,
} from "../../../components/panel/panel.jsx";
import { getDataParams2, postDataNoTImeOut2 } from "../../../config/axios.jsx";
import HeadValidasiBarangRusak from "./HeadValidasiBarangRusak.jsx";

class ValidasiBarangRusak extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isLoadingBtl: false,
      columnsJual: [
        {
          dataField: "tgl_system",
          text: "Tanggal",
          footer: (cell, row, num, index) => {
            return (
              <div>
                Total Barang :{" "}
                {JSON.parse(localStorage.getItem("validasi_rusak")) === null
                  ? 0
                  : JSON.parse(localStorage.getItem("validasi_rusak")).length ||
                    0}{" "}
              </div>
            );
          },
        },
        {
          dataField: "no_sepuh",
          text: "No Sepuh",
          footer: "",
          // formatter: (row, data) => data.no_sortir || data.no_sepuh,
        },

        {
          dataField: "qty_total",
          text: "Qty Total",
          formatter: (cell) => {
            return cell;
          },
          footer: (columnData) =>
            columnData.reduce((acc, item) => acc + item, 0),
        },

        {
          dataField: "berat_total",
          text: "Berat Total",
          formatter: (cell) => {
            return cell.toFixed(3);
          },
          footer: (columnData) =>
            columnData.reduce((acc, item) => acc + item, 0).toFixed(3),
        },
      ],
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.btnClear = this.btnClear.bind(this);
  }

  btnClear() {
    this.setState({
      isLoadingBtl: true,
    });
    setTimeout(() => {
      localStorage.removeItem("validasi_rusak");
      localStorage.removeItem("data_validasi_rusak");
      this.props.dispatch(reset("HeadValidasiJualBeliHutang"));
      this.props.dispatch(saveValidasiJual([]));
      this.props.dispatch(saveValidasiBeli([]));
      this.props.dispatch(disableButtonValidasi(false));
      this.setState({
        isLoadingBtl: false,
      });
    }, 1000);
  }
  componentWillUnmount() {
    this.props.dispatch(disableButtonValidasi(false));
    this.props.dispatch(saveValidasiJual([]));
    this.props.dispatch(saveValidasiBeli([]));
    localStorage.removeItem("validasi_rusak");
    localStorage.removeItem("data_validasi_rusak");
    this.props.dispatch(reset("HeadValidasiJualBeliHutang"));
  }

  handleSubmit(data) {
    this.setState({
      isLoading: true,
    });
    let mydata = getItem("data_validasi_rusak");

    if (mydata.length === 0) {
      this.setState({
        isLoading: false,
      });
      ToastNotification(
        "info",
        "Validasi Hancur Barang rusak Belum ada yang dipilih"
      );
    } else {
      postDataNoTImeOut2("barang-rusak/validate", mydata)
        .then((res) => {
          ToastNotification("success", "Validasi Rusak Berhasil").then(() => {
            this.getData(data);
          });
          this.setState({
            isLoading: false,
          });

          this.props.dispatch(disableButtonValidasi(true));
          localStorage.removeItem("validasi_rusak");
          this.props.dispatch(saveValidasiJual([]));
        })
        .catch((err) => {
          this.setState({
            isLoading: false,
          });
          ToastNotification(
            "info",
            err.response?.data.message ||
              "Terjadi Kesalahaan Saat Mengirim Data"
          );
        });
    }
  }
  getData(data) {
    const query = {
      tgl_from: convertDate(data.tgl_awal || getTglSystem()),
      tgl_to: convertDate(data.tgl_akhir || getTglSystem()),
      jenis_transaksi: data.jenis_transaksi,
      no_kirim_bos:
        data.no_kirim === undefined ? "ALL" : data.no_kirim.toUpperCase(),
    };
    console.log(data);
    getDataParams2("barang-rusak", query)
      .then((res) => {
        if (res.data.length === 0) {
          ToastNotification("info", "Belum Ada Data Yang Harus Di Validasi");
          this.setState({
            isLoading: false,
          });
        } else {
          let harga_total = 0;
          res.data.forEach((element) => {
            harga_total = harga_total + element.harga_total;
          });

          let hasil = [];
          res.data.forEach((el) => {
            let row = {
              berat_total: el.berat_total,
              no_sepuh: el.no_sortir || el.no_sepuh,
              qty_total: el.qty_total,
              tgl_system: el.tgl_system,
            };
            hasil.push(row);
          });
          this.props.dispatch(disableButtonValidasi(true));
          localStorage.setItem("validasi_rusak", JSON.stringify(hasil));
          this.props.dispatch(saveValidasiJual(hasil));
          this.setState({
            isLoading: false,
          });
        }
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
      });
  }

  TextFile() {
    const element = document.createElement("a");
    const file = new Blob([document.getElementById("myInput").value], {
      type: "text/plain;charset=utf-8",
    });
    element.href = URL.createObjectURL(file);
    element.download = "autoprint_pembelian.txt";
    document.body.appendChild(element);
    element.click();
  }

  render() {
    return (
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="#">Kasir</Link>
          </li>
          <li className="breadcrumb-item active">
            Validasi Hancur Barang Rusak
          </li>
        </ol>
        <h1 className="page-header">Validasi Hancur Barang Rusak </h1>
        <Panel>
          <PanelHeader>Validasi Hancur Barang Rusak</PanelHeader>
          <textarea
            style={{ display: "none" }}
            id="myInput"
            rows="100"
            cols="100"
          />

          <br />

          <PanelBody>
            <HeadValidasiBarangRusak
              onSubmit={(data) => this.handleSubmit(data)}
              btnClear={() => this.btnClear()}
              isLoadingBtl={this.state.isLoadingBtl}
              columnsBeli={this.state.columnsBeli}
              columnsJual={this.state.columnsJual}
              isLoading={this.state.isLoading}
            />
          </PanelBody>
          {this.state.isLoading && <BgLoading />}
          <br />
          {/* End Tambah Validasi Jual Beli  */}
        </Panel>
      </div>
    );
  }
}

ValidasiBarangRusak = reduxForm({
  form: "ValidasiBarangRusak",
  enableReinitialize: true,
})(ValidasiBarangRusak);
export default connect()(ValidasiBarangRusak);
