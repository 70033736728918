import {
  React,
  connect,
  Component,
  reduxForm,
  Field,
  InputDate,
  Tabel,
  Loading,
  getTglSystem,
} from "../../../components/helpers/library";
import CetakLaporanOwner from "./pdf/CetakLaporanOwner";
import LaporanOwnerExcel from "./exel/LaporanOwnerExcel";
import { formatRp } from "../../../components/helpers/function";

class HeadLaporanOwner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      columns: [
        {
          dataField: "kode_toko",
          text: "Toko",
          formatter: (data) => {
            return data === undefined ? "-" : data;
          },
          csvFormatter: (data) => {
            return data === undefined ? "-" : data;
          },
          footerAttrs: {
            colSpan: "3",
          },
          footer: "Total",
        },
        {
          dataField: "jumlah_saldo",
          text: "Jumlah Saldo",
          csvFormatter: (data) => {
            return data === undefined ? "-" : data;
          },
        },
        {
          dataField: "berat_saldo",
          text: "Berat Saldo",
          csvFormatter: (data) => {
            return data === undefined ? "-" : data;
          },
        },

        {
          dataField: "jumlah_penjualan",
          text: "Jumlah Penjualan",
          csvFormatter: (data) => {
            return data === undefined ? "-" : data;
          },
        },
        {
          dataField: "berat_penjualan",
          text: "Berat Penjualan",
          csvFormatter: (data) => {
            return data === undefined ? "-" : data;
          },
        },
        {
          dataField: "harga_penjualan",
          text: "Harga Penjualan",
          formatter: (data) => {
            return data === undefined ? "-" : formatRp(data);
          },
          csvFormatter: (data) => {
            return data === undefined ? "-" : data;
          },
        },
        {
          dataField: "jumlah_pembelian",
          text: "Jumlah Pembelian",
          csvFormatter: (data) => {
            return data === undefined ? "-" : data;
          },
        },
        {
          dataField: "berat_pembelian",
          text: "Berat Pembelian",
          csvFormatter: (data) => {
            return data === undefined ? "-" : data;
          },
        },
        {
          dataField: "harga_pembelian",
          text: "Harga Pembelian",
          formatter: (data) => {
            return data === undefined ? "-" : formatRp(data);
          },
          csvFormatter: (data) => {
            return data === undefined ? "-" : data;
          },
        },
      ],
    };
    this.setStartDate = this.setStartDate.bind(this);
    this.setLastDate = this.setLastDate.bind(this);
  }

  exportPdfRekap() {
    CetakLaporanOwner(this.props.datalaporanowner);
  }

  componentDidMount() {
    this.props.change("type", "REKAP");
    this.props.change("filter", "TRANSFER");
  }
  setStartDate(date) {
    this.setState({
      tgl_awal: new Date(date),
    });
  }
  setLastDate(date) {
    this.setState({
      tgl_akhir: new Date(date),
    });
  }

  render() {
    return (
      <form onSubmit={this.props.handleSubmit} autoComplete="off">
        <div className="row">
          <div className="col-lg-4">
            <Field
              name="tgl_awal"
              component={InputDate}
              label="Tanggal Dari"
              type="text"
              selected={this.state.tgl_awal}
              onChange={(date) => this.setStartDate(date)}
              placeholder="Masukan Tanggal Dari"
            />
          </div>
          <div className="col-lg-4">
            <div className="text-right">
              <label>&nbsp;</label>
              <button
                type="submit"
                value="PDF"
                name="type_btn"
                className="btn btn-primary btn-block"
              >
                {this.props.isLoading ? (
                  <>
                    <i className="fas fa-spinner fa-spin"></i> &nbsp; Sedang
                    Mencari
                  </>
                ) : (
                  "Cari Laporan"
                )}
              </button>
            </div>
          </div>

          <div className="col-lg-12">
            <Tabel
              keyField="kode_toko"
              data={this.props.datalaporanowner}
              columns={this.state.columns}
              empty={true}
              textEmpty="Data Laporan Owner Kosong"
            />
          </div>
          <div className={this.props.export ? "col-lg-12" : "col-lg-12 d-none"}>
            <label htmlFor="">&nbsp;</label>
            <div className="row">
              <div className="col-lg-6">
                <button
                  onClick={() => this.exportPdfRekap()}
                  className="btn btn-warning btn-block"
                >
                  Export Rekap PDF
                </button>
              </div>
              <div className="col-lg-6">
                <LaporanOwnerExcel data={this.props.datalaporanowner} />
              </div>
            </div>
          </div>
        </div>
        {this.props.isLoading ? (
          <Loading
            loading
            background="rgba(0, 0, 0, 0.35)"
            loaderColor="rgba(94, 147, 117, 1)"
          />
        ) : null}
      </form>
    );
  }
}

HeadLaporanOwner = reduxForm({
  form: "HeadLaporanOwner",
  enableReinitialize: true,
})(HeadLaporanOwner);
// export default connect()(HeadLaporanOwner);
export default connect((state) => {
  return {
    initialValues: {
      tgl_awal: getTglSystem(),
    },
  };
})(HeadLaporanOwner);
