import { getDataBaki } from "../../../../actions/datamaster_action";
import {
  Component,
  getTglSystem,
  ToastNotification,
  Tabel,
  InputDate,
  Field,
  reduxForm,
  connect,
  ReanderSelect,
  React,
} from "../../../../components/helpers/library";
import { getData, getDataParams } from "../../../../config/axios";
import ValidasiTanggalLaporan from "../../../../Validasi/ValidasiTanggalLaporan";
import LaporanKirimBarangBosExel from "./exel/LaporanKirimBarangBosExel";
import LaporanPdfKirimbarangbos from "./pdf/LaporanPdfKirimbarangbos";

class HeadForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      tujuan: [],
    };
    this.setStartDate = this.setStartDate.bind(this);
    this.setLastDate = this.setLastDate.bind(this);
  }

  exportPdf(data) {
    LaporanPdfKirimbarangbos(data);
  }
  componentDidMount() {
    getData("baki/get/by-kode-gudang/KC").then((res) => {
      this.setState({
        tujuan: res.data,
      });
    });
    this.props.change("tgl_awal", getTglSystem);
    this.props.change("tgl_akhir", getTglSystem);
    this.props.change("kode_labeling", "ALL");
    this.props.dispatch(getDataBaki());
  }
  setStartDate(date) {
    this.setState({
      tgl_awal: new Date(date),
    });
  }
  setLastDate(date) {
    this.setState({
      tgl_akhir: new Date(date),
    });
  }
  reprint(row) {
    getDataParams("krmbrgbos/reprint", { no_kirim_bos: row.no_kirim_bos })
      .then((res) => {
        console.log(res.data);
        this.print(res.data);
      })
      .catch((err) => {
        console.log(err);
        ToastNotification(
          "info",
          "Terjadi Kesalahan Saat Mengirim Data, Silahkan Ulangi Lagi !!"
        );
      });
  }
  print(data) {
    let datasepuh = [];
    let nama = "";
    for (let IndexAwal = 0; IndexAwal < 2; IndexAwal++) {
      if (IndexAwal === 0) {
        nama = "INTERNAL";
      } else {
        nama = "LABEL";
      }
      let feedback = "";
      feedback += `========================================\n`;
      feedback += `          KIRIM BARANG DARI BOS         \n`;
      feedback += `                 ${nama}                \n`;
      feedback += `========================================
No Kirim Stock   : ${data.no_kirim_bos}
Tanggal Kirim    : ${data.tgl_kirim.split("-").reverse().join("-")}`;
      feedback += `\n========================================`;
      feedback += `
Kategori         : ${data.kategori}
Asal             : ${data.asal}
Baki             : ${data.baki}
Jenis            : ${data.jenis}
Keterangan       : ${data.keterangan}
Qty Kirim        : ${data.qty_kirim}
Brt Kirim        : ${data.berat_kirim.toFixed(3)} Gr\n`;
      feedback += `========================================\n
Tot Qty Kirim   : ${data.qty_kirim}
Tot Brt Kirim   : ${data.berat_kirim.toFixed(3)} Gr\n`;
      feedback += `========================================\n`;
      feedback += `              TERIMA KASIH              \n`;
      feedback += `========================================`;
      feedback += `






`;
      datasepuh.push(feedback);
    }
    // console.log(datasepuh);
    document.getElementById("myInput").value = datasepuh.join("");
    const element = document.createElement("a");
    const file = new Blob([document.getElementById("myInput").value], {
      type: "text/plain;charset=utf-8",
    });
    element.href = URL.createObjectURL(file);
    element.download = "autoprint_pembelian.txt";
    document.body.appendChild(element);
    element.click();
  }
  render() {
    const columns = [
      {
        dataField: "no_kirim_bos",
        text: "NO KB",
        footerAttrs: {
          colSpan: "6",
        },
        footer: (cell, row, num, index) => {
          return (
            <div>Total Barang : {this.props.dataterimabarang.length} </div>
          );
        },
      },
      {
        dataField: "kode_labeling",
        text: "Kode Labeling",
      },
      {
        dataField: "kode_group",
        text: "Kode Group",
      },

      {
        dataField: "kode_dept",
        text: "Kode Jenis",
      },
      {
        dataField: "kadar",
        text: "Tipe Barang",
      },
      {
        dataField: "asal_barang",
        text: "Jenis Barang",
      },

      {
        dataField: "qty",
        text: "Qty",
        headerClasses: "text-right",
        footer: (columnData) => (
          <div className="text-right">
            {" "}
            {columnData.reduce((acc, item) => acc + item, 0)}{" "}
          </div>
        ),
        formatter: (data) => {
          return <div className="text-right"> {data}</div>;
        },
      },
      {
        dataField: "berat",
        text: "Berat",
        headerClasses: "text-right",
        formatter: (data) => {
          return <div className="text-right"> {data.toFixed(3)}</div>;
        },
        footer: (columnData) => (
          <div className="text-right">
            {" "}
            {columnData.reduce((acc, item) => acc + item, 0).toFixed(3)}{" "}
          </div>
        ),
      },
      {
        dataField: "action",
        text: "Action",
        csvExport: false,
        headerClasses: "text-center",
        formatter: (rowcontent, row) => {
          return (
            <div className="row text-center">
              <div className="col-12">
                <button
                  type="button"
                  onClick={() => this.reprint(row)}
                  className="btn btn-primary mr-3"
                >
                  {" "}
                  <i className="fa fa-print"></i>
                </button>
              </div>
            </div>
          );
        },
      },
    ];
    let datalabeling = [
      {
        name: "SEMUA",
        value: "ALL",
      },
    ];
    this.props.databaki.forEach((element) => {
      if (element.kode_gudang === "KC") {
        let row = {
          value: element.kode_baki,
          name: element.kode_baki,
        };
        datalabeling.push(row);
      }
    });

    return (
      <form onSubmit={this.props.handleSubmit} autoComplete="off">
        <div className="row">
          <textarea
            style={{ display: "none" }}
            id="myInput"
            rows="100"
            cols="100"
          />
          <div className="col-lg-3">
            <Field
              name="tgl_awal"
              component={InputDate}
              label="Tanggal Dari"
              type="text"
              selected={this.state.tgl_awal}
              onChange={(date) => this.setStartDate(date)}
              placeholder="Masukan Tanggal Dari"
              // customInput={<CustomeInputDate />}
            />
          </div>

          <div className="col-lg-3">
            <Field
              name="tgl_akhir"
              component={InputDate}
              type="text"
              selected={this.state.tgl_akhir}
              onChange={(date) => this.setLastDate(date)}
              label="Tanggal Akhir"
              placeholder="Masukan Tanggal Akhir"
            />
          </div>
          <div className="col-lg-3">
            <Field
              name="kode_labeling"
              component={ReanderSelect}
              options={this.state.tujuan
                .filter((fil) => fil !== null)
                .map((data) => {
                  let res = {
                    value: data.kode_baki,
                    name: data.kode_baki,
                  };
                  return res;
                })}
              type="text"
              label="Kode Label"
              placeholder="Pilih Kode Labeling"
            />
          </div>
          <div className="col-lg-3">
            <label>&nbsp;</label>
            <button
              type="submit"
              value="PDF"
              name="type_btn"
              className="btn btn-primary btn-block"
            >
              {this.props.isLoading ? (
                <>
                  <i className="fas fa-spinner fa-spin"></i> &nbsp; Sedang
                  Mencari
                </>
              ) : (
                "Cari Laporan"
              )}
            </button>
          </div>
          <div className="col-12">
            <Tabel
              keyField="no_kirim_bos"
              data={this.props.export ? this.props.dataterimabarang : []}
              columns={columns}
              empty={true}
              textEmpty="Data Laporan Kirim Barang Bos"
            />
          </div>
          <div className={this.props.export ? "col-lg-12" : "col-lg-12 d-none"}>
            {/* <label htmlFor="">&nbsp;</label> */}
            <div className="row">
              <div className="col-lg-6">
                <button
                  type="button"
                  onClick={() => this.exportPdf(this.props.dataterimabarang)}
                  className="btn btn-warning btn-block"
                >
                  {" "}
                  Export PDF{" "}
                </button>
              </div>
              <div className="col-lg-6">
                <LaporanKirimBarangBosExel data={this.props.dataterimabarang} />
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

HeadForm = reduxForm({
  form: "HeadForm",
  enableReinitialize: true,
  validate: ValidasiTanggalLaporan,
})(HeadForm);
// export default connect()(HeadForm);
export default connect((state) => {
  return {
    databaki: state.datamaster.getDataBaki,
    initialValues: {
      tgl_awal: getTglSystem(),
      tgl_akhir: getTglSystem(),
    },
  };
})(HeadForm);
