import {
  setCamera,
  showEditBarang,
} from "../../../actions/databarang_action.jsx";

import HeadEditDataBarang from "./EditBarang/HeadEditDataBarang.jsx";
import FormTambahBarang from "./TambahBarang/FormTambahBarang.jsx";
import {
  getDataBaki,
  GetDataGroup,
  getDataJenis,
} from "../../../actions/datamaster_action";

export {
//   ValidasiFilterTambahBarang,
  setCamera,
  getDataBaki,
  GetDataGroup,
  getDataJenis,
  showEditBarang,
  HeadEditDataBarang,
  FormTambahBarang,
  // FIlterPencarianBarang,
};
