import {
  getDataBaki,
  getDataJenis,
} from "../../../../actions/datamaster_action";
import {
  React,
  connect,
  reduxForm,
  Tabel,
  ReanderSelect,
  InputDate,
  Field,
  getTglSystem,
  BgLoading,
  getDataNoTimeOut2,
  ToastNotification,
} from "../../../../components/helpers/library";
import LaporamSortirSepuhGlobal from "./pdf/LaporamSortirSepuhGlobal";
import LaporanSortirSepuhDetail from "./pdf/LaporanSortirSepuhDetail";
// import LaporanKirimSepuhExel from "./exel/LaporanKirimSepuhExel";
// import LaporanKirimSepuhPdf from "./pdf/LaporanKirimSepuhPdf";

class HeadSortirSepuh extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      columns: [
        {
          dataField: "no_sortir",
          text: "No Sortir",
          footerAttrs: {
            colSpan: "4",
          },
          footer: (cell, row, num, index) => {
            return (
              <div>
                Total Barang : {this.props.databarang.length || 0}{" "}
              </div>
            );
          },
        },
        {
          dataField: "kondisi",
          text: "Kondisi",
        },
        {
          dataField: "kategori",
          text: "Kode Group",
        },

        {
          dataField: "kode_lokasi_toko",
          text: "Kode Baki",
        },

        {
          dataField: "qty_total",
          text: "Total Qty",
          headerClasses: "text-right",
          footer: (cell, row, num, index) => {
            return <div className="text-right"> {cell.reduce((a,b)=> a+parseInt(b),0)}</div>;
          },
          formatter:(cell) =>{
            return <div className="text-right"> {cell} </div>
          }
        },
        {
          dataField: "berat_total",
          text: "Total Berat",
          headerClasses: "text-right",
          footer: (cell, row, num, index) => {
            return <div className="text-right">{cell.reduce((a,b)=> a+parseFloat(b),0).toFixed(3)}</div> 
          },
          formatter:(cell) =>{
            return <div className="text-right"> {cell.toFixed(3)} </div>
          }
        },
        {
          dataField: "action",
          text: "Action",
          csvExport: false,
          headerClasses: "text-center",
          formatter: (rowcontent, row) => {
            return (
              <div className="row text-center">
                <div className="col-12">
                  <button
                    type="button"
                    onClick={() => this.reprint(row)}
                    className="btn btn-primary mr-3"
                  >
                    {" "}
                    <i className="fa fa-print"></i>
                  </button>
                  &nbsp;
                  <button
                    type="button"
                    onClick={() => this.props.btlSortir(row)}
                    className="btn btn-danger mr-3"
                  >
                    {" "}
                    <i className="fa fa-trash"></i>
                  </button>
                </div>
              </div>
            );
          },
        },
      ],
      columns2: [
        {
          dataField: "no_sortir",
          text: "No Sortir",
          footerAttrs: {
            colSpan: "6",
          },
          footer: (cell, row, num, index) => {
            return (
              <div>
                Total Barang : {this.props.databarang.length || 0}{" "}
              </div>
            );
          },
        },
        {
          dataField: "no_faktur_beli",
          text: "No FB",
        },

        {
          dataField: "kondisi",
          text: "Kondisi",
        },
        {
          dataField: "kode_group",
          text: "Kode Group",
        },

        {
          dataField: "kode_baki",
          text: "Kode Baki",
        },
        {
          dataField: "kode_dept",
          text: "Kode Jenis",
        },
        {
          dataField: "qty",
          text: "Qty",
          headerClasses:"text-right",
          formatter:(cell) =>{
            return <div className="text-right"> 1 </div>
          }
        },
        {
          dataField: "berat",
          text: "Berat",
        },
      ],
    };
  }
  reprint(row) {
    getDataNoTimeOut2("sortir-sepuh/reprint?no_sortir=" + row.no_sortir)
      .then((res) => {
        this.print(res.data);
      })
      .catch((err) => {
        console.log(err);
        ToastNotification(
          "info",
          "Terjadi Kesalahan Saat Mengirim Data, Silahkan Ulangi Lagi !!"
        );
      });
  }
  print(data) {
    let datasepuh = [];
// for (let index = 0; index < 2; index++) {
let feedback = "";
// let nama = "";
// if (index === 0) {
//   nama = "INTERNAL";
// } else {
//   nama = "LABEL";
// }
feedback += `========================================\n`;
feedback += `            SORTIR SEPUHAN              \n`;
feedback += `               INTERNAL                 \n`;
feedback += `========================================
No Sortir        : ${data.no_sortir}
Kode Baki        : ${data.kode_lokasi_toko}
Tgl Sortir       : ${data.tgl_sortir}
kondisi          : ${data.kondisi}
Kategori         : ${data.kategori}
\n`;
data.jenis.forEach((row, index) => {
  feedback += `Jenis             : ${Object.keys(row)[0]}\n`;
});
feedback += `
Total Qty SP     : ${data.qty_total}
Total Berat SP   : ${data.berat_total.toFixed(3)}
`;
feedback += `========================================\n`;
feedback += `              TERIMA KASIH              \n`;
feedback += `========================================`;
feedback += `






VA\n`;
datasepuh.push(feedback);
// }
    // console.log(datasepuh);
    document.getElementById("myInput").value = datasepuh.join("");
    const element = document.createElement("a");
    const file = new Blob([document.getElementById("myInput").value], {
      type: "text/plain;charset=utf-8",
    });
    element.href = URL.createObjectURL(file);
    element.download = "autoprint_pembelian.txt";
    document.body.appendChild(element);
    element.click();
  }
  componentDidMount() {
    this.props.dispatch(getDataJenis());
    this.props.dispatch(getDataBaki());
    this.props.change("type", "GLOBAL");
    this.props.change("kode_baki", "ALL");
    this.props.change("tgl_akhir", getTglSystem());
    this.props.change("tgl_awal", getTglSystem());
  }
  setStartDate(date) {
    this.setState({
      tgl_awal: new Date(date),
    });
  }
  setLastDate(date) {
    this.setState({
      tgl_akhir: new Date(date),
    });
  }

  render() {
    let DataJenis = [
      {
        value: "GLOBAL",
        name: "GLOBAL",
      },
      {
        value: "DETAIL",
        name: "DETAIL",
      },
    ];

    let databaki = [
      {
        value: "ALL",
        name: "SEMUA",
      },
    ];
    this.props.databaki.forEach((list) => {
      let row = {
        value: list.kode_baki,
        name: list.kode_baki,
      };
      databaki.push(row);
    });
    return (
      <form onSubmit={this.props.handleSubmit} autoComplete="off">
        <div className="row">
          <div className="col-lg-2">
            <textarea
              style={{ display: "none" }}
              id="myInput"
              rows="100"
              cols="100"
            />
            <Field
              name="type"
              component={ReanderSelect}
              label="Type"
              type="text"
              options={DataJenis}
              onChange={(e) => this.props.chnageType(e)}
              placeholder="Pilih Type"
              // customInput={<CustomeInputDate />}
            />
          </div>
          <div className="col-lg-3">
            <Field
              name="tgl_awal"
              component={InputDate}
              label="Tanggal Dari"
              type="text"
              selected={this.state.tgl_awal}
              onChange={(date) => this.setStartDate(date)}
              placeholder="Masukan Tanggal Dari"
            />
          </div>

          <div className="col-lg-3">
            <Field
              name="tgl_akhir"
              component={InputDate}
              type="text"
              selected={this.state.tgl_akhir}
              onChange={(date) => this.setLastDate(date)}
              label="Tanggal Akhir"
              placeholder="Masukan Tanggal Akhir"
            />
          </div>
          <div className="col-lg-2">
            <Field
              name="kode_baki"
              component={ReanderSelect}
              label="Kode Baki"
              type="text"
              options={databaki}
              placeholder="Pilih Kode Baki"
              // customInput={<CustomeInputDate />}
            />
          </div>

          <div className="col-lg-2">
            <div className="text-right">
              <label>&nbsp;</label>
              <button
                type="submit"
                value="PDF"
                name="type_btn"
                className="btn btn-primary btn-block"
              >
                {this.props.isLoading ? (
                  <>
                    <i className="fas fa-spinner fa-spin"></i> &nbsp; Sedang
                    Mencari
                  </>
                ) : (
                  "Cari Laporan"
                )}
              </button>
            </div>
          </div>
          <div className="col-12">
            <Tabel
              keyField="no_sortir"
              data={this.props.export ? this.props.databarang : []}
              columns={
                this.props.type === "GLOBAL"
                  ? this.state.columns
                  : this.state.columns2
              }
              empty={true}
              textEmpty="Data Laporan Sortir Sepuh"
            />
          </div>
          <div className={this.props.export ? "col-lg-12" : "col-lg-12 d-none"}>
            {/* <label htmlFor="">&nbsp;</label> */}
            <div className="row">
              <div className="col-lg-6"></div>
              <div className="col-lg-6">
                <button
                  type="button"
                  onClick={() =>
                    this.props.type === "GLOBAL"
                      ? LaporamSortirSepuhGlobal(this.props.databarang)
                      : LaporanSortirSepuhDetail(this.props.databarang)
                  }
                  className="btn btn-warning btn-block"
                >
                  {" "}
                  Export PDF{" "}
                </button>
              </div>
              <div className="col-lg-6">
                {/* <LaporanKirimSepuhExel data={this.props.databarang} /> */}
              </div>
            </div>
          </div>
        </div>
        {this.props.isLoading && <BgLoading />}
      </form>
    );
  }
}

HeadSortirSepuh = reduxForm({
  form: "HeadSortirSepuh",
  enableReinitialize: true,
})(HeadSortirSepuh);
export default connect((state) => {
  return {
    datajenis: state.datamaster.getDataJenis,
    databaki: state.datamaster.getDataBaki,
    initialValues: {
      tgl_awal: getTglSystem(),
      tgl_akhir: getTglSystem(),
    },
  };
})(HeadSortirSepuh);
