import {
  React,
  connect,
  Link,
  reduxForm,
  Panel,
  PanelHeader,
  PanelBody,
  getDataNoTimeOut2,
  formatDate,
  moment,
  ToastNotification,
  localStorageencryp,
  Swal,
  getItem,
  deleteData2
} from "../../../../components/helpers/library";
import HeadLaporanSortirPembelian from "./HeadLaporanSortirPembelian";

class LaporanBatalSortirPembelian extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      datasortir: [],
      type : "GLOBAL",
      export: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    // this.print = this.print.bind(this);
  }

  handleSubmit(data) {
    this.setState({
      isLoading : true
    })
    let data_post = {
      tgl_awal: formatDate(
        moment(new Date(data.tgl_awal)).format("YYYY-MM-DD")
      ),
      tgl_akhir: formatDate(
        moment(new Date(data.tgl_akhir)).format("YYYY-MM-DD")
      ),
      type: data.type,
      kode_dept: data.kode_dept,
    };
    getDataNoTimeOut2(
      "sortir-pembelian/cancel-reports?type=" +
        data_post.type +
        "&kode_dept=" +
        data.kode_dept +
        "&tgl_to=" +
        data_post.tgl_akhir +
        "&tgl_from=" +
        data_post.tgl_awal
    ).then((res) => {
      // console.log(res.data);
      if (res.data.length === 0) {
        this.setState({
          datasortir: [],
          isLoading: false,
          export: true,
          type : data.type,
        });
        ToastNotification("info","Data Laporan Tidak Tersedia")
        return false;
      }
      ToastNotification("success","Data Laporan Tersedia")
      localStorageencryp("tgl_laporan", data_post, [], true);
      this.setState({
        datasortir: res.data,
        isLoading: false,
        export: true,
        type : data.type,
      });
    }).catch((err) => {
      this.setState({
        isLoading: false,
        export: false,
        type : data.type,
      })
      ToastNotification('info',err.response?.data)
    })
  }
  changeType(e){
    this.setState({type: e, datasortir: [], export: false})
  }
  btlSortir(row) {
    Swal.fire({
      html:
        "Apakah Anda Yakin Ingin " +
        "Membatal " +
        "<h1><b>No Sortir Ini <br/>" +
        row.no_sortir +
        "</b> ?</h1>",
      icon: "warning",
      position: "top-center",
      cancelButtonText: "Tidak",
      showCancelButton: true,
      confirmButtonText: "OK",
      showConfirmButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        let data_laporan = getItem("tgl_laporan");
        this.setState({
          isLoading : true
        })
        deleteData2("sortir-pembelian/cancel?no_sortir=" + row.no_sortir).then(() => {
          this.handleSubmit(data_laporan)
          ToastNotification('success',"No Sortir Berhasil Di Batal");
         this.setState({
          isLoading : false
        })
        }).catch((err)=>{
          this.setState({
            isLoading : false
          })
          ToastNotification("info", err?.response?.data?.message || "Terjadi Kesalahan Saat Mengirim Data, Silahkan Ulangi Lagi !!")
        })
      }else{
        this.setState({
          isLoading: false,
        });
      }
    }).catch((err) => {
      this.setState({
        isLoading : false
      })
      ToastNotification("info", err?.response?.data?.message || "Terjadi Kesalahan Saat Mengirim Data, Silahkan Ulangi Lagi !!")
    })
  }
  render() {
    return (
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="#">Laporan</Link>
          </li>
          <li className="breadcrumb-item active">Laporan Batal Sortir Pembelian</li>
        </ol>
        <h1 className="page-header">Laporan Batal Sortir Pembelian</h1>
        <Panel>
          <PanelHeader>Laporan Batal Sortir Pembelian</PanelHeader>
          <br />
          {/* Blank Page */}
          <PanelBody>
            <HeadLaporanSortirPembelian
              type={this.state.type}
              export={this.state.export}
              data={this.state.datasortir}
              changeType={(e)=>this.changeType(e)}
              isLoading={this.state.isLoading}
              onSubmit={(data) => this.handleSubmit(data)}
              btlSortir={(data)=>this.btlSortir(data)}
            />
          </PanelBody>

          <br />
        </Panel>
      </div>
    );
  }
}

LaporanBatalSortirPembelian = reduxForm({
  form: "LaporanBatalSortirPembelian",
  enableReinitialize: true,
})(LaporanBatalSortirPembelian);
export default connect()(LaporanBatalSortirPembelian);
