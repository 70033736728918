import jsPDF from "jspdf";
import "jspdf-autotable";
import {
  getToday,
  localStoragedecryp,
} from "../../../../../components/helpers/function";
// Date Fns is used to format the dates we receive

// define a generatePDF function that accepts a tickets argument
const LaporanPdfKirimbarangbosAktif = (data = "") => {
  // initialize jsPDF
  console.log("AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA");
  console.log(data);

  const doc = new jsPDF("l", "mm", [297, 210]);
  //   let data = JSON.parse(localStorage.getItem("tt_pengeluaran_barang")) || [];
  let tableRows = [];
  let footRows = [];
  let finalY = 30;
  doc.setFontSize(15);
  doc.text("Laporan Saldo Aktif Kirim Barang Bos", 14, 15);
  doc.setFontSize(10);
  doc.setProperties({
    title: "Laporan Saldo Aktif Kirim Barang Bos",
  });

  let tableColumn = [
    [
      {
        content: `NO KIRIM BOS`,
      },
      {
        content: `KODE LABELING`,
      },
      // {
      //   content: `KODE GROUP`,
      // },
      // {
      //   content: `ASAL/TYPE BARANG`,
      // },
      // {
      //   content: `NAMA BARANG`,
      // },
      {
        content: `BERAT AWAL`,
        styles: {
          halign: "right",
        },
      },
      {
        content: `BERAT INPUT`,
        styles: {
          halign: "right",
        },
      },
      {
        content: `BERAT SISA`,
        styles: {
          halign: "right",
        },
      },
      {
        content: `QTY AWAL`,
        styles: {
          halign: "right",
        },
      },
      {
        content: `QTY INPUT`,
        styles: {
          halign: "right",
        },
      },
      {
        content: `QTY SISA`,
        styles: {
          halign: "right",
        },
      },
      {
        content: `STATUS`,
        styles: {
          halign: "right",
        },
      },
    ],
  ];
  data.forEach((item, no) => {
    let rows = [
      item.no_kirim_bos,
      item.kode_labeling,
      {
        content: item.berat_awal.toFixed(3),
        styles: {
          halign: "right",
        },
      },
      {
        content: item.berat_input.toFixed(3),
        styles: {
          halign: "right",
        },
      },
      {
        content: item.berat_sisa.toFixed(3),
        styles: {
          halign: "right",
        },
      },
      {
        content: item.qty_awal,
        styles: {
          halign: "right",
        },
      },
      {
        content: item.qty_input,
        styles: {
          halign: "right",
        },
      },
      {
        content: item.qty_sisa,
        styles: {
          halign: "right",
        },
      },
      {
        content: item?.status || "",
        styles: {
          halign: "right",
        },
      },
     
    ];

    tableRows.push(rows);
  });

  let footer = [
    {
      content: `Total Barang : ${data.length}`,
      colSpan: 2,
      styles: {
        halign: "left",
        fillColor: "#E8E5E5",
        textColor: "#000",
      },
    },
    {
      content: `${data
        .reduce((a, b) => a + parseFloat(b.berat_awal), 0)
        .toFixed(3)}`,
      styles: {
        halign: "right",
        fillColor: "#E8E5E5",
        textColor: "#000",
      },
    },
    {
      content: `${data
        .reduce((a, b) => a + parseFloat(b.berat_input), 0)
        .toFixed(3)}`,
      styles: {
        halign: "right",
        fillColor: "#E8E5E5",
        textColor: "#000",
      },
    },
    {
      content: `${data
        .reduce((a, b) => a + parseFloat(b.berat_sisa), 0)
        .toFixed(3)}`,
      styles: {
        halign: "right",
        fillColor: "#E8E5E5",
        textColor: "#000",
      },
    },
    {
      content: `${data
        .reduce((a, b) => a + parseFloat(b.qty_awal), 0)}`,
      styles: {
        halign: "right",
        fillColor: "#E8E5E5",
        textColor: "#000",
      },
    },
    {
      content: `${data
        .reduce((a, b) => a + parseFloat(b.qty_input), 0)}`,
      styles: {
        halign: "right",
        fillColor: "#E8E5E5",
        textColor: "#000",
      },
    },
    {
      content: `${data
        .reduce((a, b) => a + parseFloat(b.qty_sisa), 0)}`,
      styles: {
        halign: "right",
        fillColor: "#E8E5E5",
        textColor: "#000",
      },
    },
    {
      content: ``,
      styles: {
        halign: "right",
        fillColor: "#E8E5E5",
        textColor: "#000",
      },
    },
  ];

  tableRows.push(footer);
  let printed = [
    {
      colSpan: 9,
      content: `Printed By ${
        localStoragedecryp("userdata").user_id
      } / ${getToday()}`,
      styles: {
        // lineWidth: 0.1,
        fontStyle: "italic",
        textColor: "#000",
        // fillColor: "#E8E5E5"
      },
    },
  ];
  tableRows.push(printed);
  doc.autoTable({
    head: tableColumn,
    body: tableRows,
    foot: footRows,
    startY: finalY,
    theme: "plain",
    rowPageBreak: "avoid",
    // pageBreak: "avoid",
    margin: { top: 20 },
    // bodyStyles: { lineWidth: 0.02, lineColor: "#000" },
    // footStyles: { lineWidth: 0.02, lineColor: "#000" },
    headStyles: {
      // lineWidth: 0.02,
      // lineColor: "#000",
      fillColor: "#E8E5E5",
      textColor: "#000",
    },
  });
  finalY = doc.autoTableEndPosY() + 10;

  const pages = doc.internal.getNumberOfPages();
  const pageWidth = doc.internal.pageSize.width; //Optional
  const pageHeight = doc.internal.pageSize.height; //Optional
  doc.setFontSize(10); //Optional
  for (let j = 1; j < pages + 1; j++) {
    let horizontalPos = pageWidth / 2; //Can be fixed number
    let verticalPos = pageHeight - 10; //Can be fixed number
    doc.setPage(j);
    doc.text(`${j} of ${pages}`, horizontalPos, verticalPos, {
      align: "center",
    });
  }
  // doc.autoPrint();
  // doc.save(`PenerimaanSUpplier.pdf`);
  var string = doc.output("datauristring");
  var x = window.open();
  x.document.open();
  x.document.write(
    "<html><head><title>Laporan Saldo Aktif Kirim Barang Bos</title></head><body style='margin:0 !important'><embed width='100%' height='100%'  src=" +
      string +
      "></embed></body></html>"
  );
  // setInterval(() => {
  //   x.close();
  // }, 1000);
};

export default LaporanPdfKirimbarangbosAktif;
