import React, { Component } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import {
  formatGram,
  getUserdata,
  localStoragedecryp,
} from "../../../../components/helpers/function";

class ExportExcelValidasiBarangRusak extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="btn btn-success btn-block"
          table="table-to-xls"
          filename="Validasi Data Barang Rusak"
          sheet="Validasi Data Barang Rusak"
          buttonText="Export Exel"
        />

        <table rowkey="no_sepuh" id="table-to-xls" style={{ display: "none" }}>
          <thead>
            <tr>
              <th colSpan="12">
                Validasi Data Barang Rusak{" "}
                {localStoragedecryp("tgl_laporan", [], true).transaksi}
              </th>
            </tr>
            <tr>
              <th colSpan="12"> Periode </th>
            </tr>

            <tr>
              <th colSpan="12"> TOKO : {getUserdata().nama_toko} </th>
            </tr>
            <tr>
              <th colSpan="12"> ALAMAT : {getUserdata().alamat_toko} </th>
            </tr>
            <tr>
              <th colSpan="12"></th>
            </tr>
            <tr>
              <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                No Sepuh
              </th>
              <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                Berat Total
              </th>
              <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                Qty Total
              </th>
            </tr>
          </thead>

          {this.props.data?.map((item, index) => (
            <>
              <tbody>
                <>
                  <tr>
                    <td> &nbsp;{item.no_sepuh}</td>
                    <td style={{ textAlign: "right" }}>
                      &nbsp;{formatGram(item.berat_total, 3)}
                    </td>
                    <td> {item.qty_total}</td>
                  </tr>
                </>
              </tbody>
             
            </>
          ))}
           <tfoot>
                  <tr>
                      <th style={{backgroundColor: "#E8E5E5", color: "#000",textAlign : "center" }} colSpan="1">  </th>    
                      <th style={{backgroundColor: "#E8E5E5", color: "#000",textAlign : "right" }}> {formatGram(this.props.data.map(list => list.berat_total).reduce((a,b) => a+b,0),3)}   </th>                         
                      <th style={{backgroundColor: "#E8E5E5", color: "#000",textAlign : "right" }}> {this.props.data.map(list => list.qty_total).reduce((a,b) => a+b,0)}   </th>                         
                  </tr>
              </tfoot>
        </table>
      </>
    );
  }
}
export default ExportExcelValidasiBarangRusak;
