import { getItem } from "../components/helpers/function";
import { getData2 } from "../config/axios";

export const GET_DATA_TIMBANGAN = "GET_DATA_TIMBANGAN";
export const GET_PARA_SYSTEM = "GET_PARA_SYSTEM";

export const getTimbangan = (data) => {
    let timbangan = getItem('timbangan');
    // console.log(timbangan.split("g")[timbangan.split("g").length-2])
    return (dispatch) => {
        dispatch({
          type: GET_DATA_TIMBANGAN,
          payload: {
            data: timbangan.split("g")[timbangan.split("g").length-2],
          },
        });
      };
   
};

export const getParaSystem = () => {
  return (dispatch) => {
    getData2('para-system').then((res) => {
        // console.log(res);
          dispatch({
              type: GET_PARA_SYSTEM,
              payload: {
                  data: res.data
              }
          })   
      }).catch((err) => {
           console.log(err);
       })
  }
}