import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, formValueSelector, reduxForm } from "redux-form";
import {
  InputDate,
  ReanderField,
  ReanderSelect,
} from "../../../components/helpers/field";
import { getDataSales } from "../../../actions/datamaster_action";
import { getData, getDataParams2 } from "../../../config/axios";
import { ToastNotification } from "../../../components/helpers/notification";
import Tabel from "../../../components/helpers/tabel";
import {
  disableButtonValidasi,
  saveValidasiBeli,
  saveValidasiJual,
} from "../../../actions/hutang_action";
import Loading from "react-fullscreen-loading";
import { formatDate, getTglSystem } from "../../../components/helpers/function";
import moment from "moment";

const selector = formValueSelector("HeadValidasiTerimaStock");
const maptostate = (state) => {
  const { jenis_transaksi, tgl_awal, tgl_akhir, no_kirim } = selector(
    state,
    "jenis_transaksi",
    "tgl_awal",
    "tgl_akhir",
    "no_kirim"
  );
  // let hasil = parseInt(localStorage.getItem('total_harga_jual')) - parseInt(localStorage.getItem('total_harga_beli'))
  // let datausersvalidasi = localStorage.getItem('datausersvalidasi')===null ? [] : JSON.parse(localStorage.getItem('datausersvalidasi'));
  return {
    jenis_transaksi: jenis_transaksi,
    tgl_awal: tgl_awal,
    tgl_akhir: tgl_akhir,
    no_kirim: no_kirim,
    DataSales: state.datamaster.getDataSales,
    saveValidasiBeli: state.hutang.saveValidasiBeli,
    saveValidasiJual: state.hutang.saveValidasiJual,
    btnValidasi: state.hutang.disableButtonValidasi,
    initialValues: {
      tgl_awal: getTglSystem(),
      tgl_akhir: getTglSystem(),
      jenis_transaksi: "KB",
    },
  };
};
class HeadValidasiTerimaStock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      DataValidasi: [],
      btnValidasi: false,
      kode_sales: false,
      warna: false,
    };
  }

  componentDidMount() {
    this.props.dispatch(getDataSales());
    localStorage.setItem("data_validasi_terima_stock", JSON.stringify([]));
      this.props.change("tgl_awal",getTglSystem())
    this.props.change("tgl_akhir",getTglSystem())
  }
  getDataSales(e) {
    localStorage.setItem("validasi_terima", JSON.stringify([]));
    localStorage.setItem("validasi_beli", JSON.stringify([]));
    this.setState({
      kode_sales: e,
    });
    getData("sales/get/by-kode-sales/" + e)
      .then((res) => {
        // localStorage.setItem('datausersvalidasi',JSON.stringify(res.data))
        this.props.change("nama_sales", res.data.nama_sales);
        this.props.dispatch(saveValidasiJual([]));
        this.props.dispatch(saveValidasiBeli([]));
      })
      .catch((err) => {
        ToastNotification("info", "Gagal Mengambil Data Keserver");
      });
  }
  getDetaValidasi() {
    this.setState({
      isLoading: true,
    });
    const query = {
      tgl_from: formatDate(
        moment(new Date(this.props.tgl_awal || getTglSystem())).format(
          "YYYY-MM-DD"
        )
      ),
      tgl_to: formatDate(
        moment(new Date(this.props.tgl_akhir || getTglSystem())).format(
          "YYYY-MM-DD"
        )
      ),
      no_kirim_bos: this.props.no_kirim === undefined ? "ALL" : this.props.no_kirim.toUpperCase(),
    };
    if (this.props.jenis_transaksi === "KB") {
      getDataParams2("kirim-barang-bos/saldo-outstand", query)
        .then((res) => {
          if (res.data.length === 0) {
            ToastNotification("info", "Belum Ada Data Yang Harus Di Validasi");
            this.setState({
              isLoading: false,
            });
            this.props.change("grandtotal", 0);
            this.props.dispatch(saveValidasiJual([]));
          } else {
            let harga_total = 0;
            res.data.forEach((element) => {
              harga_total = harga_total + element.harga_total;
            });

            this.props.change(
              "grandtotal",
              harga_total.toLocaleString("kr-KO")
            );
            ToastNotification("success", "Data Validasi Tersedia");
            this.setState({
              isLoading: false,
            });
            this.props.dispatch(disableButtonValidasi(true));
            localStorage.setItem("validasi_terima", JSON.stringify(res.data));
            this.props.dispatch(saveValidasiJual(res.data));
          }
        })
        .catch((err) => {
          ToastNotification("info", "Terjadi Kesalahan Saat Request");
          this.setState({
            isLoading: false,
            DataValidasi: [],
          });
        });
    } else {
      getDataParams2("kirim-labeling/saldo-outstand", query)
        .then((res) => {
          if (res.data.length === 0) {
            ToastNotification("info", "Belum Ada Data Yang Harus Di Validasi");
            this.setState({
              isLoading: false,
            });
            this.props.change("grandtotal", 0);
            this.props.dispatch(saveValidasiJual([]));
          } else {
            let harga_total = 0;
            res.data.forEach((element) => {
              harga_total = harga_total + element.harga_total;
            });

            this.props.change(
              "grandtotal",
              harga_total.toLocaleString("kr-KO")
            );
            ToastNotification("success", "Data Validasi Tersedia");
            this.setState({
              isLoading: false,
            });
            this.props.dispatch(disableButtonValidasi(true));
            localStorage.setItem("validasi_terima", JSON.stringify(res.data));
            this.props.dispatch(saveValidasiJual(res.data));
          }
        })
        .catch((err) => {
          ToastNotification("info", "Terjadi Kesalahan Saat Request");
          this.setState({
            isLoading: false,
            DataValidasi: [],
          });
        });
    }
  }
  setStartDate(date) {
    this.setState({
      tgl_awal: new Date(date),
    });
  }
  setLastDate(date) {
    this.setState({
      tgl_akhir: new Date(date),
    });
  }
  handleChange(data){
    this.props.dispatch(saveValidasiJual([]));
    localStorage.removeItem("data_validasi_terima_stock");
    localStorage.removeItem("validasi_terima");
  }
  render() {
    const selectRow = {
      mode: "checkbox",
      clickToSelect: true,
      selectColumnPosition: "right",
      onSelect: (row, isSelect, rowIndex, e) => {
        // console.log(isSelect);
        if (this.props.jenis_transaksi === "KB") {
          if (isSelect === true) {
            let hasil = {
              no_kirim_bos: row.no_kirim_bos,
            };
            let cek =
              JSON.parse(localStorage.getItem("data_validasi_terima_stock")) || [];
            let hasil_cek = cek.findIndex(
              (hasil) => hasil.no_kirim_bos === row.no_kirim_bos
            );
            if (hasil_cek === -1) {
              cek.push(hasil);
              localStorage.setItem(
                "data_validasi_terima_stock",
                JSON.stringify(cek)
              );
            }
          } else {
            // console.log();
            if (localStorage.getItem("data_validasi_terima_stock") === null) {
            } else {
              let data = JSON.parse(
                localStorage.getItem("data_validasi_terima_stock")
              );
              data.splice(row.no_kirim_bos, 1);
              localStorage.setItem(
                "data_validasi_terima_stock",
                JSON.stringify(data)
              );
            }
          }
        } else {
          if (isSelect === true) {
            let hasil = {
              no_kirim_bos: row.no_kirim_stock,
            };
            let cek =
              JSON.parse(localStorage.getItem("data_validasi_terima_stock")) || [];
            let hasil_cek = cek.findIndex(
              (hasil) => hasil.no_kirim_stock === row.no_kirim_stock
            );
            if (hasil_cek === -1) {
              cek.push(hasil);
              localStorage.setItem(
                "data_validasi_terima_stock",
                JSON.stringify(cek)
              );
            }
          } else {
            // console.log();
            if (localStorage.getItem("data_validasi_terima_stock") === null) {
            } else {
              let data = JSON.parse(
                localStorage.getItem("data_validasi_terima_stock")
              );
              data.splice(row.no_kirim_stock, 1);
              localStorage.setItem(
                "data_validasi_terima_stock",
                JSON.stringify(data)
              );
            }
          }
        }
      },
      onSelectAll: (isSelect, data, e) => {
        if (this.props.jenis_transaksi === "KB") {
          data.forEach((list, index) => {
            if (isSelect === true) {
              let hasil = {
                no_kirim_bos: list.no_kirim_bos,
              };
              let cek =
                JSON.parse(localStorage.getItem("data_validasi_terima_stock")) || [];
              let hasil_cek = cek.findIndex(
                (hasil) => hasil.no_kirim_bos === list.no_kirim_bos
              );
              if (hasil_cek === -1) {
                cek.push(hasil);
                localStorage.setItem(
                  "data_validasi_terima_stock",
                  JSON.stringify(cek)
                );
              }
            } else {
              if (localStorage.getItem("data_validasi_terima_stock") === null) {
              } else {
                let data = JSON.parse(
                  localStorage.getItem("data_validasi_terima_stock")
                );
                data.splice(list.no_kirim_bos, 1);
                localStorage.setItem(
                  "data_validasi_terima_stock",
                  JSON.stringify(data)
                );
              }
            }
          });
        } else {
          data.forEach((list, index) => {
            if (isSelect === true) {
              let hasil = {
                no_kirim_bos: list.no_kirim_stock,
              };
              let cek =
                JSON.parse(localStorage.getItem("data_validasi_terima_stock")) || [];
              let hasil_cek = cek.findIndex(
                (hasil) => hasil.no_kirim_stock === list.no_kirim_stock
              );
              if (hasil_cek === -1) {
                cek.push(hasil);
                localStorage.setItem(
                  "data_validasi_terima_stock",
                  JSON.stringify(cek)
                );
              }
            } else {
              if (localStorage.getItem("data_validasi_terima_stock") === null) {
              } else {
                let data = JSON.parse(
                  localStorage.getItem("data_validasi_terima_stock")
                );
                data.splice(list.no_kirim_stock, 1);
                localStorage.setItem(
                  "data_validasi_terima_stock",
                  JSON.stringify(data)
                );
              }
            }
          });
        }
      },
    };

    return (
      <form
        onSubmit={this.props.handleSubmit}
        autoComplete="off"
        onKeyPress={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
      >
        <div className="row">
          <div className="col-3">
            <Field
              name="jenis_transaksi"
              label="Pilih Jenis Transaksi"
              placeholder="Masukan Jenis Transaksi"
              options={[
                {
                  value: "KC",
                  name: "KIRIM LABELING",
                },
                {
                  value: "KB",
                  name: "KIRIM BARANG DARI BOS",
                },
              ]}
              onChange={(date) => this.handleChange(date)}
              component={ReanderSelect}
            />
          </div>
          <div className="col-3">
            <Field
              name="no_kirim"
              component={ReanderField}
              type="text"
              label="Nomor Kirim"
              placeholder="Masukan Nomor Kirim"
            />
          </div>
          <div className="col-lg-3">
            <Field
              name="tgl_awal"
              component={InputDate}
              label="Tanggal Dari"
              type="text"
              selected={this.state.tgl_awal}
              onChange={(date) => this.setStartDate(date)}
              placeholder="Masukan Tanggal Dari"
            />
          </div>

          <div className="col-lg-3">
            <Field
              name="tgl_akhir"
              component={InputDate}
              type="text"
              selected={this.state.tgl_akhir}
              onChange={(date) => this.setLastDate(date)}
              label="Tanggal Akhir"
              placeholder="Masukan Tanggal Akhir"
            />
          </div>
          <div className="col-12 mb-3">
            <label>&nbsp;</label>
            <button
              onClick={() => this.getDetaValidasi()}
              className="btn btn-primary btn-block"
              disabled={this.state.isLoading}
              type="button"
            >
              {this.state.isLoading ? (
                <>
                  <i className="fas fa-spinner fa-spin"></i> &nbsp; Cari Data
                </>
              ) : (
                "Cari Data"
              )}
            </button>
          </div>
          <div className="col-12 mb-3">
       
          <Tabel
            selectRow={selectRow}
            keyField={ this.props.jenis_transaksi === "KB" ? "no_kirim_bos" : "no_kirim_stock"}
            columns={
              this.props.jenis_transaksi === "KB"
                ? this.props.columnsJual
                : this.props.columnsLabeling
            }
            data={this.props.saveValidasiJual || []}
            empty={true}
          />
          </div>
          <div className="col-6">
            {this.props.btnValidasi === false ? (
              <button
                className="btn btn-primary btn-block"
                disabled={true}
                type="button"
              >
                Validasi
              </button>
            ) : (
              <button
                className="btn btn-primary btn-block"
                disabled={this.props.isLoading}
                type="submit"
              >
                {this.props.isLoading ? (
                  <>
                    <i className="fas fa-spinner fa-spin"></i> &nbsp; Validasi
                  </>
                ) : (
                  "Validasi"
                )}
              </button>
            )}
          </div>
          <div className="col-6">
            <button
              className="btn btn-warning btn-block"
              disabled={this.props.isLoadingBtl}
              onClick={this.props.btnClear}
              type="button"
            >
              {this.props.isLoadingBtl ? (
                <>
                  <i className="fas fa-spinner fa-spin"></i> &nbsp; Batal
                </>
              ) : (
                "Batal"
              )}
            </button>
          </div>
        </div>
        {this.props.isLoading ? (
          <Loading
            loading
            background="rgba(0, 0, 0, 0.35)"
            loaderColor="rgba(94, 147, 117, 1)"
          />
        ) : null}
      </form>
    );
  }
}

HeadValidasiTerimaStock = reduxForm({
  form: "HeadValidasiTerimaStock",
  enableReinitialize: true,
})(HeadValidasiTerimaStock);
export default connect(maptostate,null)(HeadValidasiTerimaStock);
