import React, { Component } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import {
  getToday,
  getUserdata,
  localStoragedecryp,
} from "../../../../../components/helpers/function";
class LaporanPesananSoExelNew extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    // let hasil = this.props.data.map((list) => {
    //   let jml =
    //     parseFloat(list.saldo_awal) -
    //     parseFloat(list.berat_pakai) -
    //     parseFloat(list.susut_pakai);

    //   return jml.toLocaleString("id-ID");
    // });
    const datalap = [];
    this.props.data.forEach((element) => {
      element.pesanan.forEach((item) => {
        datalap.push(item);
      });
    });
    return (
      <>
        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="btn btn-success btn-block"
          table="table-to-xls"
          filename="Laporan Pesanan So"
          sheet="Laporan Pesanan So"
          buttonText="Export Exel"
        />
        <table id="table-to-xls" style={{ display: "none" }}>
          <thead>
            <tr>
              <th colSpan="11" style={{ textAlign: "center" }}>
                {" "}
                LAPORAN PESANAN SO{" "}
              </th>
            </tr>
            <tr>
              <th colSpan="11"> Periode </th>
            </tr>
            <tr>
              <th colSpan="11">
                {" "}
                {localStoragedecryp("tgl_laporan", [], true).length === 0
                  ? null
                  : localStoragedecryp("tgl_laporan", [], true).tgl_awal +
                    " s/d " +
                    localStoragedecryp("tgl_laporan", [], true).tgl_akhir}{" "}
              </th>
            </tr>
            <tr>
              <th colSpan="11"> TOKO : {getUserdata().nama_toko} </th>
            </tr>

            <tr>
              <th colSpan="11"> ALAMAT : {getUserdata().alamat_toko} </th>
            </tr>
            <tr></tr>
          </thead>
          <tbody>
            <>
              {this.props.data.map((item, index) => (
                <>
                  <tr>
                    <td
                      style={{
                        backgroundColor: "#E8E5E5",
                        color: "#000",
                        fontWeight: "bold",
                      }}
                      colSpan={11}
                    >
                      NO SO PESANAN
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{ backgroundColor: "#E8E5E5", color: "#000" }}
                      colSpan={11}
                    >
                      {item.no_so_pesanan}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        backgroundColor: "#E8E5E5",
                        color: "#000",
                        fontWeight: "bold",
                      }}
                    >
                      NO PRESKOT
                    </td>
                    <td
                      style={{
                        backgroundColor: "#E8E5E5",
                        color: "#000",
                        fontWeight: "bold",
                      }}
                    >
                      KODE TUKANG
                    </td>
                    <td
                      style={{
                        backgroundColor: "#E8E5E5",
                        color: "#000",
                        fontWeight: "bold",
                      }}
                    >
                      DESKRIPSI
                    </td>
                    <td
                      style={{
                        backgroundColor: "#E8E5E5",
                        color: "#000",
                        fontWeight: "bold",
                      }}
                    >
                      NAMA BARANG
                    </td>
                    <td
                      style={{
                        backgroundColor: "#E8E5E5",
                        color: "#000",
                        fontWeight: "bold",
                      }}
                    >
                      NAMA ATRIBUT
                    </td>
                    <td
                      style={{
                        backgroundColor: "#E8E5E5",
                        color: "#000",
                        fontWeight: "bold",
                        textAlign: "right",
                      }}
                    >
                      BERAT ATRIBUT
                    </td>
                    <td
                      style={{
                        backgroundColor: "#E8E5E5",
                        color: "#000",
                        fontWeight: "bold",
                        textAlign: "right",
                      }}
                    >
                      BERAT PAKAI
                    </td>
                    <td
                      style={{
                        backgroundColor: "#E8E5E5",
                        color: "#000",
                        fontWeight: "bold",
                        textAlign: "right",
                      }}
                    >
                      SUSUT PAKAI
                    </td>
                    <td
                      style={{
                        backgroundColor: "#E8E5E5",
                        color: "#000",
                        fontWeight: "bold",
                        textAlign: "right",
                      }}
                    >
                      ONGKOS
                    </td>
                    <td
                      style={{
                        backgroundColor: "#E8E5E5",
                        color: "#000",
                        fontWeight: "bold",
                        textAlign: "right",
                      }}
                    >
                      ST SELESAI
                    </td>
                    <td
                      style={{
                        backgroundColor: "#E8E5E5",
                        color: "#000",
                        fontWeight: "bold",
                        textAlign: "right",
                      }}
                    >
                      ST SERAH
                    </td>
                  </tr>
                  {item.pesanan.map((list, index2) => (
                    <>
                      <tr key={index2}>
                        <td> {list.no_pesanan} </td>
                        <td> {list.kode_tukang} </td>
                        <td> {list.deskripsi} </td>
                        <td> {list.nama_barang} </td>
                        <td> {list.nama_attribut} </td>
                        <td
                          style={{
                            color: "#000",
                            textAlign: "right",
                          }}
                        >
                          {" "}
                          {list.berat_atribut}{" "}
                        </td>
                        <td
                          style={{
                            color: "#000",
                            textAlign: "right",
                          }}
                        >
                          {" "}
                          {list.berat_pakai}{" "}
                        </td>
                        <td
                          style={{
                            color: "#000",
                            textAlign: "right",
                          }}
                        >
                          {" "}
                          {list.susut_pakai}{" "}
                        </td>
                        <td
                          style={{
                            color: "#000",
                            textAlign: "right",
                          }}
                        >
                          {" "}
                          &nbsp;
                          {list.ongkos.toLocaleString("kr-Ko")}{" "}
                        </td>
                        <td> {list.st_selesai} </td>
                        <td> {list.st_serah} </td>
                      </tr>
                    </>
                  ))}
                </>
              ))}
              {/* {datalap.map((list, index2) => (
                <>
                  <tr key={index2}>
                    <td> {list.no_pesanan} </td>
                    <td> {list.kode_tukang} </td>
                    <td> {list.nama_barang} </td>
                    <td> {list.nama_attribut} </td>
                    <td
                      style={{
                        color: "#000",
                        textAlign: "right",
                      }}
                    >
                      {" "}
                      {list.berat_atribut}{" "}
                    </td>
                    <td
                      style={{
                        color: "#000",
                        textAlign: "right",
                      }}
                    >
                      {" "}
                      {list.berat_pakai}{" "}
                    </td>
                    <td
                      style={{
                        color: "#000",
                        textAlign: "right",
                      }}
                    >
                      {" "}
                      {list.susut_pakai}{" "}
                    </td>
                    <td
                      style={{
                        color: "#000",
                        textAlign: "right",
                      }}
                    >
                      {" "}
                      &nbsp;
                      {list.ongkos.toLocaleString("kr-Ko")}{" "}
                    </td>
                    <td> {list.st_selesai} </td>
                    <td> {list.st_serah} </td>
                  </tr>
                </>
              ))} */}
              <tr>
                <td
                  colSpan="5"
                  style={{ textAlign: "left", borderTop: "1px solid black" }}
                >
                  {" "}
                </td>
                <td
                  style={{ textAlign: "right", borderTop: "1px solid black" }}
                >
                  {" "}
                  {datalap.reduce(
                    (a, b) => parseFloat(a) + parseFloat(b.berat_atribut),
                    0
                  )}{" "}
                </td>
                <td
                  style={{ textAlign: "right", borderTop: "1px solid black" }}
                >
                  {" "}
                  {datalap.reduce(
                    (a, b) => parseFloat(a) + parseFloat(b.berat_pakai),
                    0
                  )}{" "}
                </td>
                <td
                  style={{ textAlign: "right", borderTop: "1px solid black" }}
                >
                  {" "}
                  {datalap.reduce(
                    (a, b) => parseFloat(a) + parseFloat(b.susut_pakai),
                    0
                  )}{" "}
                </td>
                <td
                  style={{ textAlign: "right", borderTop: "1px solid black" }}
                >
                  {" "}
                  &nbsp;
                  {datalap
                    .reduce((a, b) => parseFloat(a) + parseFloat(b.ongkos), 0)
                    .toLocaleString("kr-Ko")}{" "}
                </td>
                <td
                  colSpan="2"
                  style={{ textAlign: "right", borderTop: "1px solid black" }}
                ></td>
              </tr>
            </>
          </tbody>

          <tr>
            <td
              colSpan="5"
              style={{ textAlign: "left", borderTop: "1px solid black" }}
            >
              Export By {getUserdata().user_id} / {getToday()}
            </td>
            <td
              colSpan="6"
              style={{ textAlign: "center", borderTop: "1px solid black" }}
            >
              Saldo Awal : &nbsp;{" "}
              {this.props.data
                .reduce((a, b) => a + parseFloat(b.saldo_awal || 0), 0)
                .toFixed(3)}
            </td>
          </tr>
          <tr>
            <td
              colSpan="5"
              style={{ textAlign: "left", borderTop: "1px solid black" }}
            ></td>
            <td
              colSpan="6"
              style={{ textAlign: "center", borderTop: "1px solid black" }}
            >
              Sisa Bahan : &nbsp;{" "}
              {this.props.data
                .reduce((a, b) => a + parseFloat(b.saldo_bahan || 0), 0)
                .toFixed(3)}
            </td>
          </tr>
          <tr>
            <td
              colSpan="5"
              style={{ textAlign: "left", borderTop: "1px solid black" }}
            ></td>
            <td
              colSpan="6"
              style={{ textAlign: "center", borderTop: "1px solid black" }}
            >
              Setor : &nbsp;{" "}
              {this.props.data
                .reduce((a, b) => a + parseFloat(b.setor || 0), 0)
                .toFixed(3)}
            </td>
          </tr>
          <tr>
            <td
              colSpan="5"
              style={{ textAlign: "left", borderTop: "1px solid black" }}
            ></td>
            <td
              colSpan="6"
              style={{ textAlign: "center", borderTop: "1px solid black" }}
            >
              Selisih : &nbsp;{" "}
              {(
                this.props.data.reduce(
                  (a, b) => a + parseFloat(b.setor || 0),
                  0
                ) -
                this.props.data.reduce(
                  (a, b) => a + parseFloat(b.saldo_bahan || 0),
                  0
                )
              ).toFixed(3)}
            </td>
          </tr>
        </table>
      </>
    );
  }
}
export default LaporanPesananSoExelNew;
