import {
  React,
  connect,
  reduxForm,
  Field,
  ReanderField,
  ReanderSelect,
  Gramasi,
  NumberOnly,
  getData,
  getDataNoTimeOut2,
  ToastNotification,
  Loading,
  HiidenFiled,
} from "../../../components/helpers/library";
// import ValidasiKirimSepuhan from "../../"
import ValidasiKirimSepuhan from "./../../../Validasi/Sepuhan/ValidasiKirimSepuhan";

class HeadKirimSepuh extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isAktif: false,
      sisaberat: 0,
      databaki: [],
      datajenis: [],
    };
  }

  componentDidMount() {
    getData("baki/get/by-kode-gudang/SS").then((res) => {
      // console.log(res.data);Baki
      let baki = [];
      res.data.forEach((element) => {
        let row = {
          value: element.kode_baki,
          name: element.kode_baki,
        };
        baki.push(row);
      });

      this.setState({
        databaki: baki,
      });
    });
  }
  getSepuhBaki(e) {
    this.setState({
      isLoading: true,
    });
    getDataNoTimeOut2("sortir-sepuh?kode_baki=" + e + "&kode_dept=ALL")
      .then((res) => {
        // console.log(res.data);
        let jenis = [];
        res.data.jenis.forEach((element) => {
          let row = {
            name: element,
            value: element,
          };
          jenis.push(row);
        });
        this.setState({
          datajenis: jenis,
          kode_baki: e,
          isLoading: false,
        });
      })
      .catch((err) => {
      ToastNotification("info", err.response?.data.message || "Terjadi Kesalhaan Saat Request Data")
        this.setState({
          isLoading: false,
        });
      });
  }
  getDataJenis(e) {
    getDataNoTimeOut2(
      "sortir-sepuh?kode_baki=" + this.state.kode_baki + "&kode_dept=" + e
    ).then((res) => {
      res.data.forEach((element) => {
        this.props.change("jml_berat", element.berat.toFixed(3));
        this.props.change("jml_qty", element.qty);
        this.props.change("kode_group", element.kode_group);
        this.props.change("kode_barcode", element.kode_barcode);
      });
    }).catch((err)=>{
      ToastNotification("info", err.response?.data.message || "Terjadi Kesalhaan Saat Request Data")
    })
    this.props.change("brt_sepuh", "");
    this.props.change("qty_sepuh", "");
    this.props.change("brt_rusak", "");
    this.props.change("qty_rusak", "");
    this.props.change("jml_berat", "");
    this.props.change("jml_qty", "");
  }
  chekcBeratRusak(e) {
    let jml_berat = document.getElementById("jml_berat").value;
    let brt_sepuh = document.getElementById("brt_sepuh").value;

    let hasil =
      parseFloat(jml_berat || 0) -
      parseFloat(brt_sepuh || 0) -
      parseFloat(e || 0);

    if (parseFloat(hasil) < 0) {
      ToastNotification(
        "info",
        "Berat Rusak Tidak Boleh Besar Dari Jumlah Berat"
      );
      this.setState({
        isAktif: true,
      });
      return false;
    } else {
      this.setState({
        isAktif: false,
      });
    }
  }
  hitungBeratSepuh(e) {
    let jml_berat = document.getElementById("jml_berat").value;
    let brt_rusak = document.getElementById("brt_rusak").value;
    let hasil =
      parseFloat(jml_berat || 0) -
      parseFloat(e || 0) -
      parseFloat(brt_rusak || 0);
    if (parseFloat(hasil) < 0) {
      ToastNotification(
        "info",
        "Berat Sepuh Tidak Boleh Besar Dari Jumlah Berat"
      );
      this.setState({
        isAktif: true,
      });
      return false;
    } else {
      this.setState({
        isAktif: false,
      });
    }
  }
  cekQtyRusak(e) {
    let jml_qty = document.getElementById("jml_qty").value;
    let qty_sepuh = document.getElementById("qty_sepuh").value;
    let hasil =
      parseFloat(jml_qty || 0) -
      parseFloat(e || 0) -
      parseFloat(qty_sepuh || 0);
    if (parseFloat(hasil) < 0) {
      ToastNotification("info", "Qty Rusak Tidak Boleh Besar Dari Jumlah");
      this.setState({
        isAktif: true,
      });
      return false;
    } else {
      this.setState({
        isAktif: false,
      });
    }
  }
  cekQtySepuh(e) {
    let jml_qty = document.getElementById("jml_qty").value;
    let qty_rusak = document.getElementById("qty_rusak").value;
    let hasil =
      parseFloat(jml_qty || 0) -
      parseFloat(e || 0) -
      parseFloat(qty_rusak || 0);
    if (parseFloat(hasil) < 0) {
      ToastNotification("info", "Qty Sepuh Tidak Boleh Besar Dari Jumlah");
      this.setState({
        isAktif: true,
      });
      return false;
    } else {
      this.setState({
        isAktif: false,
      });
    }
  }
  render() {
    return (
      <form
        onSubmit={this.props.handleSubmit}
        onKeyPress={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
        autoComplete="off"
      >
        <div className="row">
          <div className="col-3">
            <Field
              name="kode_baki"
              label="Baki"
              placeholder="Pilih Baki"
              onChange={(e) => this.getSepuhBaki(e)}
              options={this.state.databaki}
              component={ReanderSelect}
            />
          </div>

            <Field
              name="kode_group"
              type="hidden"
              component={HiidenFiled}
            />
            <Field
              name="kode_barcode"
              type="hidden"
              component={HiidenFiled}
            />
          <div className="col-3">
            <Field
              name="kode_jenis"
              label="Jenis"
              placeholder="Pilih Jenis"
              onChange={(e) => this.getDataJenis(e)}
              options={this.state.datajenis}
              component={ReanderSelect}
            />
          </div>

          <div className="col-3">
            <Field
              id="jml_berat"
              name="jml_berat"
              readOnly={true}
              component={ReanderField}
              label="Jumlah Berat"
              normalize={Gramasi}
              type="text"
              placeholder="Masukan Jumlah Berat"
            />
          </div>
          <div className="col-3">
            <Field
              id="jml_qty"
              name="jml_qty"
              readOnly={true}
              component={ReanderField}
              normalize={NumberOnly}
              label="Jumlah Qty"
              type="text"
              placeholder="Masukan Jumlah Qty"
            />
          </div>
          <div className="col-2">
            <Field
              id="brt_sepuh"
              // onChange={(e) => this.hitungBeratSepuh(e.target.value)}
              name="brt_sepuh"
              component={ReanderField}
              normalize={Gramasi}
              label="Berat Sepuh"
              type="text"
              placeholder="Masukan Berat Sepuh"
            />
          </div>
          <div className="col-2">
            <Field
              id="qty_sepuh"
              onChange={(e) => this.cekQtySepuh(e.target.value)}
              name="qty_sepuh"
              component={ReanderField}
              normalize={NumberOnly}
              label="Qty Sepuh"
              type="text"
              placeholder="Masukan Qty Sepuh"
            />
          </div>
          <div className="col-2">
            <Field
              id="brt_rusak"
              name="brt_rusak"
              // onChange={(e) => this.chekcBeratRusak(e.target.value)}
              normalize={Gramasi}
              component={ReanderField}
              label="Berat Rusak"
              type="text"
              placeholder="Masukan Berat Rusak"
            />
          </div>
          <div className="col-2">
            <Field
              id="qty_rusak"
              name="qty_rusak"
              onChange={(e) => this.cekQtyRusak(e.target.value)}
              normalize={NumberOnly}
              component={ReanderField}
              label="Qty Rusak"
              type="text"
              placeholder="Masukan Qty Rusak"
            />
          </div>
          <div className="col-4">
            <label>&nbsp;</label>
            <br />
            <button
              type="submit"
              disabled={this.state.isAktif}
              className="btn btn-block btn-primary"
            >
              <i className="fa fa-plus"></i>
            </button>
          </div>
        </div>
        {this.state.isLoading ? (
          <Loading
            loading
            background="rgba(0, 0, 0, 0.35)"
            loaderColor="rgba(94, 147, 117, 1)"
          />
        ) : null}
      </form>
    );
  }
}

HeadKirimSepuh = reduxForm({
  form: "HeadKirimSepuh",
  enableReinitialize: true,
  validate: ValidasiKirimSepuhan,
})(HeadKirimSepuh);
export default connect()(HeadKirimSepuh);
