import React, { Component } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import {
  getUserdata,
  localStoragedecryp,
} from "../../../../../components/helpers/function";

class LaporanKirimBarangBosExel extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    let qty = 0;
    let berat = 0;
    // let berat_atribut = 0;
    // let total_barang = 0;
    this.props.data.forEach((barang, index) => {
      qty = qty + barang.qty;
      berat = berat + barang.berat;
    });
    return (
      <>
        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="btn btn-success btn-block"
          table="table-to-xls"
          filename="Laporan Kirim Barang Dari Bos"
          sheet="Laporan Kirim Barang Dari Bos"
          buttonText="Export Exel"
        />
        <table
          rowkey="kode_member"
          id="table-to-xls"
          style={{ display: "none" }}
        >
          <thead>
            <tr>
              <th colSpan="8" style={{ textAlign: "center" }}>
                {" "}
                LAPORAN KIRIM BARANG BOS{" "}
              </th>
            </tr>
            <tr>
              <th colSpan="8"> Periode </th>
            </tr>
            <tr>
              <th colSpan="8">
                {" "}
                {localStoragedecryp("tgl_laporan").length === 0
                  ? null
                  : localStoragedecryp("tgl_laporan").tgl_awal +
                    " s/d " +
                    localStoragedecryp("tgl_laporan").tgl_akhir}{" "}
              </th>
            </tr>
            <tr>
              <th colSpan="8"> TOKO : {getUserdata().nama_toko} </th>
            </tr>

            <tr>
              <th colSpan="8"> ALAMAT : {getUserdata().alamat_toko} </th>
            </tr>
            <tr></tr>
            <tr>
              <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                NO KIRIM
              </th>
              <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                KODE LABELING
              </th>
              <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                KODE GROUP
              </th>
              <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                KODE DEPT
              </th>
              <th
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                TIPE BARANG
              </th>
              <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                JENIS BARANG
              </th>
              <th
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                QTY
              </th>
              <th
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                BERAT
              </th>
            </tr>
          </thead>
          <tbody>
            {this.props.data.map((row, no) => (
              <tr key={no}>
                <td> {row.no_kirim_bos}</td>
                <td> {row.kode_labeling}</td>
                <td> {row.kode_group}</td>
                <td> {row.kode_dept}</td>
                <td> {row.kadar}</td>
                <td> {row.asal_barang}</td>
                <td style={{ textAlign: "right" }}> {row.qty}</td>
                <td style={{ textAlign: "right" }}> {row.berat}</td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <th
                colSpan="6"
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "center",
                }}
              >
                {" "}
                Total Barang : {this.props.data.length}
              </th>
              <th
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                {qty}
              </th>
              <th
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                {berat}
              </th>
            </tr>
          </tfoot>
        </table>
      </>
    );
  }
}
export default LaporanKirimBarangBosExel;
