import jsPDF from "jspdf";
import "jspdf-autotable";
import {
  formatGram,
  getToday,
  getUserdata,
  localStoragedecryp,
} from "../../../../components/helpers/function";
// Date Fns is used to format the dates we receive

// define a generatePDF function that accepts a tickets argument
const ValidasiHancurBarangRusakPdf = (data = "") => {
  console.log(data);
  // return false

  const doc = new jsPDF("l", "mm", [297, 210]);

  let tableRows = [];
  let footRows = [];
  let tableColumn = [];
  let total_berat =0;
  let total_qty =0;
  let finalY = 30;

  doc.setFontSize(15);
  doc.text("Validasi Data Barang Rusak", 14, 15);
  doc.setFontSize(20);
  doc.text(getUserdata().nama_toko, 200, 15);
  doc.setFontSize(8);
  let jml_alamat = getUserdata().alamat_toko.length;
  if (jml_alamat > 20) {
    doc.text(getUserdata().alamat_toko.slice(0, 48), 200, 20);
  }
  if (jml_alamat > 50) {
    doc.text(getUserdata().alamat_toko.slice(48, 90), 200, 25);
  }
  doc.setFontSize(10);
  doc.setProperties({
    title: "VALIDASI BARANG RUSAK",
  });

  tableColumn = [
    [
      {
        content: `NO SEPUH`,
      },
      {
        content: `BERAT TOTAL`,
      },
      {
        content: `QTY TOTAL`,
      },
    ],
  ];
  data.forEach((item, index) => {
    let detail = [
      {
        content: item.no_sepuh,
      },
      {
        content: item.berat_total.toFixed(3),
      },
      // {
      //   content:
      //     data.reduce((a, b) => a + parseFloat(b.berat_total), 0).toFixed(3) ||
      //     "",
      // },
      {
        content: item.qty_total || 0,
      },
    ];
    total_berat = total_berat + parseFloat(item.berat_total);
    total_qty = total_qty + parseInt(item.qty_total);
    tableRows.push(detail);
  });
  // let footer = []
  let footer = [
    {
      content: `Total`,
      colSpan: 1,
      styles: {
        halign: "left",
        fillColor: "#E8E5E5",
        textColor: "#000",
      },
    },
    {
      content: `${formatGram(total_berat, 3)}`,
      styles: {
        fillColor: "#E8E5E5",
        textColor: "#000",
        halign: "left",
      },
    },
    {
      content: `${formatGram(total_qty, 0)}`,
      styles: {
        fillColor: "#E8E5E5",
        textColor: "#000",
        halign: "left",
      },
    },
  ];
  footRows.push(footer);
  let printed = [
    {
      colSpan: 3,
      content: `Printed By ${
        localStoragedecryp("userdata").user_id
      } / ${getToday()}`,
      styles: {
        // lineWidth: 0.1,
        fontStyle: "italic",
        textColor: "#000",
        // fillColor: "#E8E5E5"
      },
    },
  ];
  footRows.push(printed);
  doc.autoTable({
    head: tableColumn,
    body: tableRows,
    foot: footRows,
    startY: finalY,
    theme: "plain",
    rowPageBreak: "avoid",
    // pageBreak: "avoid",
    margin: { top: 10 },
    footStyles: {
      // lineWidth: 0.02,
      // lineColor: "#000",
      fontSize: 8,
    },
    bodyStyles: {
      // lineWidth: 0.02,
      // lineColor: "#000",
      fontSize: 8,
    },
    headStyles: {
      fontSize: 8,
      // lineWidth: 0.02,
      // lineColor: "#000",
      textColor: "#000",
      fillColor: "#E8E5E5",
    },
  });
  finalY = doc.autoTableEndPosY() + 3;
  tableRows = [];

  const pages = doc.internal.getNumberOfPages();
  const pageWidth = doc.internal.pageSize.width; //Optional
  const pageHeight = doc.internal.pageSize.height; //Optional
  doc.setFontSize(10); //Optional
  for (let j = 1; j < pages + 1; j++) {
    let horizontalPos = pageWidth / 2; //Can be fixed number
    let verticalPos = pageHeight - 10; //Can be fixed number
    doc.setPage(j);
    doc.text(`${j} of ${pages}`, horizontalPos, verticalPos, {
      align: "center",
    });
  }
  // doc.autoPrint();
  // doc.save(`DATABASE MEMBER.pdf`);
  var string = doc.output("datauristring");
  // var embed = "<embed width='100%' height='100%' src='" + string + "'/>";
  var x = window.open();
  x.document.open();
  x.document.write(
    "<html><head><title>VALIDASI BARANG RUSAK</title></head><body style='margin:0 !important'><embed width='100%' height='100%'  src=" +
      string +
      "></embed></body></html>"
  );
};

export default ValidasiHancurBarangRusakPdf;
