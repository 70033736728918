import { GetDataGroup } from "../TambahBarang/Module";

import {
  React,
  connect,
  Link,
  reduxForm,
  Panel,
  PanelHeader,
  PanelBody,
  ToastNotification,
  getItem,
  setItem,
  Loading,
  reset,
  getDataNoTimeOut,
  postDataNoTImeOut2,
} from "../../../components/helpers/library";
import HeadKirimBarang from "./HeadKirimBarang";
import { getDataJenis} from "../../../actions/datamaster_action.jsx";
import CetakLaporanKirimBarang from "./CetakLaporanKirimBarang";

const maptostate = (state) => {
  return {
    datagroup: state.datamaster.GetDataGroup,
    DataJenis: state.datamaster.getDataJenis,
  };
};

class KirimBarang extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      active: "none",
      isLoadingBarang: false,
      databarang: [],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit() {
    //   console.log(data.tujuan);
    let data = getItem('tujuan')
    if(data.length === 0 || data === undefined) {
      ToastNotification("info", "Tujuan Harus Diilih")
      return false;
    }
    this.setState({
      isLoading: true,
    });
   

    let groupTemp = [];
    let deptTemp = [];

    let detail_barang = [];
    getItem("kirimdatabarang").forEach((element) => {
      let row = {
        no_pesanan: element.no_pesanan || "-",
        no_kirim: element.no_kirim || "-",
        kode_barang: element.kode_barang || "-",
        kode_barcode: element.kode_barcode || "-",
        kode_group: element.kode_group || "-",
        kode_dept: element.kode_dept || "-",
        kode_gudang: element.kode_gudang || "-",
        kode_toko: element.kode_toko || "-",
        tag_id: element.tag_id || "-",
        kode_intern: element.kode_intern || "-",
        nama_barang: element.nama_barang || "-",
        nama_atribut: element.nama_atribut || "-",
        stock_on_hand: element.stock_on_hand || 0,
        berat: element.berat || 0,
        berat_atribut: element.berat_atribut || 0,
        berat_bandrol: element.berat_bandrol || 0,
        kadar: element.kadar || 0,
        kadar_cetak: element.kadar_cetak || 0,
        harga_beli: element.harga_beli || 0,
        harga_skrg: element.harga_skrg || 0,
        harga_jual: element.harga_jual || 0,
        harga_atribut: element.harga_atribut || 0,
        tgl_last_beli: element.tgl_last_beli || "-",
        tgl_last_jual: element.tgl_last_jual || "-",
        tipe_barang: element.tipe_barang || "-",
        is_markis: element.is_markis || false,
      };
      detail_barang.push(row);
      groupTemp.push({ kode_group: element.kode_group });
      deptTemp.push({ kode_dept: element.kode_dept });
    });
    var groupRemover = new Set();
    var deptRemover = new Set();

    var group = groupTemp.filter((obj) => {
      if (groupRemover.has(JSON.stringify(obj))) return false;
      groupRemover.add(JSON.stringify(obj));
      return true;
    });

    var dept = deptTemp.filter((obj) => {
      if (deptRemover.has(JSON.stringify(obj))) return false;
      deptRemover.add(JSON.stringify(obj));
      return true;
    });

    for (let index = 0; index < group.length; index++) {
        let kode = group[index].kode_group;
        let datagroup = this.props.datagroup.find((list)=>list.kode_group === kode);
        group[index] = {
          kode_group : datagroup.kode_group,
          nama_group: datagroup.nama_group,
          harga: datagroup.harga,
          persentase: datagroup.persentase,
        }
    }
    for (let index = 0; index < dept.length; index++) {
        let kode = dept[index].kode_dept;
        let datadept = this.props.DataJenis.find((list)=>list.kode_dept === kode);
        dept[index] = {
          kode_group : datadept.kode_group,
          nama_dept: datadept.nama_dept,
          kode_dept: datadept.kode_dept,
        }
    }

    if(detail_barang.length === 0){
      this.setState({
        isLoading: false,
      });
      ToastNotification("info", "Data Barag Tidak Boleh Kosong")
      return false
    }
    let hasil = {
      group,
      dept,
      detail_barang: detail_barang,
      to: data,
    };

    
    postDataNoTImeOut2("kirim-barang", hasil, { kode_cabang: data.tujuan })
    .then((res) => {
      CetakLaporanKirimBarang(getItem('kirimdatabarang'),res.data.no_kirim)
      ToastNotification("success", "Data Berhasil Dikirim");
      setItem("data_jenis", []);
      setItem("kirimdatabarang", []);
      setItem("data_group", []);
      
        localStorage.removeItem('tujuan')
        this.setState({
          isLoading: false,
          databarang: [],
          active: "none",
        });
        this.props.dispatch(reset("HeadKirimBarang"));
      })
      .catch((err) => {
        ToastNotification("info", err.response?.data.message);
        this.setState({
          isLoading: false,
        });
      });
  }
  componentWillUnmount() {
    setItem("data_jenis", []);
    setItem("kirimdatabarang", []);
    setItem("data_group", []);
  }
  getJenis(data) {
    this.setState({
      isLoadingBarang: true,
    });
    if (data.tujuan === undefined) {
      ToastNotification("info", "Tujuan Harus Dipilih");
      this.setState({
        isLoadingBarang: false,
      });
      return false;
    }
    setItem('tujuan',data.tujuan)
    getDataNoTimeOut("barang/get/query?kode_baki=" + data.kode_baki+ "&kode_group="+data.kode_group+"&kode_dept="+data.kode_jenis+"&tujuan="+data.tujuan )
      .then((res) => {
        if (res.data.length === 0) {
          ToastNotification("info", "Data Barang Tidak Ada");
          this.setState({
            active: "none",
            databarang: [],
            isLoadingBarang: false,
          });
        } else {
          setItem("kirimdatabarang", res.data);

          this.setState({
            active: "block",
            databarang: res.data,
            isLoadingBarang: false,
          });
        }
      })
      .catch((err) => {
        this.setState({
          active: "none",
          databarang: [],
          isLoadingBarang: false,
        });
        ToastNotification("info", err.response?.data.message);
      });
  }
  componentDidMount(){
    this.props.dispatch(getDataJenis());
    this.props.dispatch(GetDataGroup());
  }
  cariBarcode(e){
    this.setState({
      isLoading : true
    })
    if (e.length >= 8) {
      getDataNoTimeOut("barang/open/kode-barcode?limit=100&kode_barcode=" +(e.substr(0, 8)).toUpperCase()).then((res)=>{
        let hasil = {
          no_pesanan: res.data[0].no_pesanan || "-",
          no_kirim: res.data[0].no_kirim || "-",
          kode_barang: res.data[0].kode_barang || "-",
          kode_barcode: res.data[0].kode_barcode || "-",
          kode_group: res.data[0].kode_group || "-",
          kode_dept: res.data[0].kode_dept || "-",
          kode_gudang: res.data[0].kode_gudang || "-",
          kode_toko: res.data[0].kode_toko || "-",
          tag_id: res.data[0].tag_id || "-",
          kode_intern: res.data[0].kode_intern || "-",
          nama_barang: res.data[0].nama_barang || "-",
          nama_atribut: res.data[0].nama_atribut || "-",
          stock_on_hand: res.data[0].stock_on_hand || 0,
          berat: res.data[0].berat || 0,
          berat_atribut: res.data[0].berat_atribut || 0,
          berat_bandrol: res.data[0].berat_bandrol || 0,
          kadar: res.data[0].kadar || 0,
          kadar_cetak: res.data[0].kadar_cetak || 0,
          harga_beli: res.data[0].harga_beli || 0,
          harga_skrg: res.data[0].harga_skrg || 0,
          harga_jual: res.data[0].harga_jual || 0,
          harga_atribut: res.data[0].harga_atribut || 0,
          tgl_last_beli: res.data[0].tgl_last_beli || "-",
          tgl_last_jual: res.data[0].tgl_last_jual || "-",
          tipe_barang: res.data[0].tipe_barang || "-",
          is_markis: res.data[0].is_markis || false,
        }
        let cek = JSON.parse(localStorage.getItem("kirimdatabarang")) || [];
        let hasil_cek = cek.findIndex(
          (hasil) => hasil.kode_barcode === e
        );
        if (hasil_cek === -1) {
          cek.push(hasil);
          setItem('kirimdatabarang',cek)
          this.setState({
            active: "block",
            databarang: cek,
            isLoadingBarang: false,
          });
        }else{
          ToastNotification("info", "Kode Barcode Tersebut Sudah Ada Ditabel")
          return false;
        }
        this.setState({
          isLoading : false
        })

      }).catch((err)=>{
        ToastNotification("info","Barang Tidak Di Temukan")
        this.setState({
          isLoading : false
        })

      })
    }
  }
  render() {
    return (
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="#">Barang</Link>
          </li>
          <li className="breadcrumb-item active">Kirim Barang Kecabang</li>
        </ol>
        <h1 className="page-header">Kirim Barang Kecabang </h1>
        <Panel>
          <PanelHeader>Kirim Barang Kecabang</PanelHeader>
          <br />
          {/* Kirim Barang */}
          <PanelBody>
            <HeadKirimBarang
            cariBarcode={(data)=>this.cariBarcode(data.target.value)}
              active={this.state.active}
              isLoadingBarang={this.state.isLoadingBarang}
              databarang={this.state.databarang}
              onSubmit={(data) => this.getJenis(data)}
              isLoading={this.state.isLoading}
            />
             <div
            className="col-lg-12 mt-4 text-right"
          >
            <button
              disabled={this.state.isLoading}
              className="btn btn-primary "
              type="button"
              onClick={()=>this.handleSubmit()}
            >
              {this.state.isLoading ? (
                <>
                  <i className="fas fa-spinner fa-spin"></i> &nbsp; Kirim Barang
                </>
              ) : (
                "Kirim Barang"
              )}
            </button>
          </div>
          </PanelBody>
          {/* End Tambah Blank Page  */}
        </Panel>
        {this.state.isLoading ? (
          <Loading
            loading
            background="rgba(0, 0, 0, 0.35)"
            loaderColor="rgba(94, 147, 117, 1)"
          />
        ) : null}
      </div>
    );
  }
}

KirimBarang = reduxForm({
  form: "KirimBarang",
  enableReinitialize: true,
})(KirimBarang);
export default connect(maptostate,null)(KirimBarang);
