import {
    React,
    Component,
    HiidenFiled,
    ReanderField,
    connect,
    Field,
    reduxForm,
  } from "../../../components/helpers/library";   
  
  const maptoState = (state) => {
    if (state.datamaster.showModalDataToko !== null) {
      return {
        isEdit : state.datamaster.isEdit,
        initialValues: {
          _id: state.datamaster.showModalDataToko._id,
          kode_toko: state.datamaster.showModalDataToko.kode_toko,
          nama_toko: state.datamaster.showModalDataToko.nama_toko,
        },
      };
    }
  };
  class ModalDataToko extends Component {
    constructor(props) {
      super(props);
      this.state = {};
    }
  
    componentDidMount() {
      setTimeout(() => {
        this.props.isEdit ? document.getElementById("kode_toko").focus() :
          document.getElementById("nama_toko").focus()
      }, 100);
    }
    render() {
      return (
        <form
          onSubmit={this.props.handleSubmit}
          onKeyPress={(e) => {
            e.key === "Enter" && e.preventDefault();
          }}
          autoComplete="off"
        >
          <div className="row">
          <Field name="_id" type="hidden" component={HiidenFiled} />
            <div className="col-12">
              <Field
                tabIndex="1"
                id="kode_toko"
                name="kode_toko"
                component={ReanderField}
                readOnly={this.props.isEdit}
                label="Kode Toko"
                type="text"
                placeholder="Masukan Kode Toko"
              />
            </div>
            <div className="col-12">
              <Field
                tabIndex="1"
                id="nama_toko"
                name="nama_toko"
                component={ReanderField}
                label="Nama Toko"
                type="text"
                placeholder="Masukan Nama Toko"
              />
            </div>
            <div className="col-12 text-right">
              <label> &nbsp; </label>
              <br />
              <button
                className="btn btn-warning"
                type="button"
                onClick={() => this.props.batal()}
              >
                Batal
              </button>
              &nbsp;
              <button
                tabIndex="2"
                className="btn btn-primary"
                disabled={this.props.isLoading}
                type="submit"
              >
                {this.props.isLoading ? (
                  <>
                    <i className="fas fa-spinner fa-spin"></i> &nbsp; Sedang
                    Menyimpan
                  </>
                ) : (
                  "Simpan Data"
                )}
              </button>
            </div>
          </div>
        </form>
      );
    }
  }
  
  ModalDataToko = reduxForm({
    form: "ModalDataToko",
    enableReinitialize: true,
    // validate: ValidateMasterBarang,
  })(ModalDataToko);
  export default connect(maptoState)(ModalDataToko);
  