import {
  React,
  connect,
  Link,
  reduxForm,
  Panel,
  PanelHeader,
  PanelBody,
  ToastNotification,
  localStorageencryp,
  formatDate,
  moment,
} from "../../../../components/helpers/library";
import { getDataParams2 } from "../../../../config/axios";
import HeadKirimBarang from "./HeadKirimBarang";
import { getToday } from "../../../../components/helpers/function";

class LaporanKirimBarang extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      export: false,
      LaporanDataBarang: [],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    // this.print = this.print.bind(this);
  }

  handleSubmit(data) {
    this.setState({
      isLoading: true,
    });
    if (data.kode_toko === undefined) {
      ToastNotification("info", "Kode Toko Harus Dipilih");
      this.setState({
        isLoading: false,
      });
      return false;
    }
    let tgl = {
      tgl_awal: formatDate(
        moment(new Date(data.tgl_awal)).format("YYYY-MM-DD") || getToday()
      ),
      tgl_akhir: formatDate(
        moment(new Date(data.tgl_akhir)).format("YYYY-MM-DD") || getToday()
      ),
    };
    let data_post = {
      kode_cabang: data.kode_toko,
      no_kirim: data.no_kirim,
      tgl_from: formatDate(
        moment(new Date(data.tgl_awal)).format("YYYY-MM-DD")
      ),
      tgl_to: formatDate(moment(new Date(data.tgl_akhir)).format("YYYY-MM-DD")),
    };

    getDataParams2(`kirim-barang/report`, data_post)
      .then((res) => {
        if (res.data.length === 0) {
          ToastNotification("info", "Laporan Kirim Barang Tidak Ada");
          this.setState({
            isLoading: false,
            export: false,
            LaporanDataBarang: [],
          });
          return false;
        }
        localStorageencryp("tgl_laporan", tgl,[],true);
        this.setState({
          LaporanDataBarang: []
        })
        this.setState({
          isLoading: false,
          export: true,
          LaporanDataBarang: res.data,
        });
      })
      .catch((err) => {
        ToastNotification(
          "info",
          err?.response?.data?.message ||
            "Terjadi Kesalahan Saat Merequest Data"
        );
        this.setState({
          isLoading: false,
        });
      });
  }

  render() {
    return (
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="#">Laporan Barang</Link>
          </li>
          <li className="breadcrumb-item active">Laporan Kirim Barang</li>
        </ol>
        <h1 className="page-header">Laporan Kirim Barang </h1>
        <Panel>
          <PanelHeader>Laporan Kirim Barang</PanelHeader>
          <br />
          {/* Laporan Kirim Barang */}
          <PanelBody>
            <HeadKirimBarang
              export={this.state.export}
              LaporanDataBarang={this.state.LaporanDataBarang}
              isLoading={this.state.isLoading}
              onSubmit={(data) => this.handleSubmit(data)}
            />
          </PanelBody>
          <br />
        </Panel>
      </div>
    );
  }
}

LaporanKirimBarang = reduxForm({
  form: "LaporanKirimBarang",
  enableReinitialize: true,
})(LaporanKirimBarang);
export default connect()(LaporanKirimBarang);
