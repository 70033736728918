import Swal from "sweetalert2";
import {
  getMasterAtribut,
  hideModal,
  isEdit,
  isLoading,
  showModal,
  shwoMasterAtribut,
} from "../../../actions/datamaster_action";
import {
  React,
  connect,
  Link,
  reduxForm,
  Panel,
  PanelHeader,
  PanelBody,
  Tabel,
  ModalGlobal,
  reset,
  ToastNotification,
  putData,
  postData,
  ErrorHandling,
  deleteData,
} from "../../../components/helpers/library";
import ModalAtribut from "./ModalAtribut";

const maptostate = (state) => {
  return {
    isEdit: state.datamaster.isEdit,
    isLoading: state.datamaster.isLoading,
    DataAtibut: state.datamaster.getMasterAtribut,
  };
};
class MasterAtribut extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      settings: "",
      columns: [
        {
          dataField: "nama_atribut",
          text: "Atribut",
        },
        {
          dataField: "action",
          text: "Action",
          csvExport: false,
          headerClasses: "text-center",
          formatter: (rowcontent, row, index) => {
            let data = {
              _id: row._id,
              atribut: row.nama_atribut,
            };
            return (
              <div className="row text-center">
                <div className="col-12">
                  <button
                    type="button"
                    onClick={() => this.editDataAtribut(data, this)}
                    className="btn btn-warning mr-3"
                  >
                    <i className="fa fa-edit"></i>
                  </button>
                  <button
                    type="button"
                    onClick={() =>
                      this.hapusDataAtribut(row.nama_atribut, row._id)
                    }
                    className="btn btn-danger mr-3"
                  >
                    <i className="fa fa-trash"></i>
                  </button>
                </div>
              </div>
            );
          },
        },
      ],
    };
  }

  componentDidMount() {
    this.props.dispatch(getMasterAtribut());
  }
  batal() {
    this.props.dispatch(reset("ModalAtribut"));
    this.props.dispatch(hideModal());
  }
  editDataAtribut(data) {
    this.props.dispatch(isEdit(true));
    this.props.dispatch(showModal());
    this.props.dispatch(shwoMasterAtribut(data));
  }
  showModalAtribut() {
    this.props.dispatch(showModal());
    this.props.dispatch(shwoMasterAtribut(false));
    this.props.dispatch(isEdit(false));
  }
  hapusDataAtribut(params, id) {
    Swal.fire({
      html:
        "Apakah Anda Yakin Ingin " +
        "Menghapus " +
        "<h1><b>Atribut " +
        params +
        "</b> ?</h1>",
      icon: "warning",
      position: "top-center",
      cancelButtonText: "Tidak",
      showCancelButton: true,
      confirmButtonText: "OK",
      showConfirmButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteData("masteratribut/delete/by-nama-atribut/" + id).then(() => {
          ToastNotification("success", "Data Berhasil Di Hapus").then(() =>
            this.props.dispatch(getMasterAtribut())
          );
        });
      }
    });
  }
  simpanAtribut(data) {
    this.props.dispatch(isLoading(true));

    let data_post = {
      //   id: uuidv4(),
      nama_atribut: data.atribut,
    };

    setTimeout(() => {
      this.props.isEdit
        ? putData(
            "masteratribut/edit/by-nama-atribut/" + data._id,
            data_post,
            ["nama_atribut"],
            true
          )
            .then(() => {
              this.props.dispatch(hideModal());
            })
            .then(() => {
              ToastNotification("success", "Data Berhasil Diedit");
            })
            .then(() => {
              this.props.dispatch(getMasterAtribut());
            })
            .then(() => {
              // this.setState({ isLoading: false });
              this.props.dispatch(isLoading(false));
            })
            .then(() => {
              this.props.dispatch(reset("ModalAtribut"));
            })
            .catch((error) => {
              ToastNotification("info", "Terjadi Kesalahan Saat Mengirim Data");
              this.props.dispatch(isLoading(false));
            })
        : postData("masteratribut/add", data_post)
            .then(() => {
              this.props.dispatch(hideModal());
            })
            .then(() => {
              ToastNotification("success", "Data Berhasil Disimpan");
            })
            .then(() => {
              this.props.dispatch(getMasterAtribut());
            })
            .then(() => {
              this.props.dispatch(reset("ModalAtribut"));
            })
            .then(() => {
              this.props.dispatch(isLoading(false));
            })
            .catch((error) =>
              ErrorHandling(
                error,
                "masteratribut/reactive/by-nama-atribut/",
                data_post,
                this.props.dispatch,
                getMasterAtribut(),
                hideModal(),
                "ModalAtribut"
              ).then(() => {
                this.props.dispatch(isLoading(false));
              })
            );
    }, 300);
  }
  render() {
    return (
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="#">Data Master</Link>
          </li>
          <li className="breadcrumb-item active">Master Atribut</li>
        </ol>
        <h1 className="page-header">Master Atribut </h1>
        <Panel>
          <PanelHeader>Master Atribut</PanelHeader>
          <br />
          {/* Blank Page */}
          <PanelBody>
            <Tabel
              tambahData={true}
              handleClick={() => this.showModalAtribut(this.props)}
              featur={true}
              keyField="_id"
              data={this.props.DataAtibut}
              columns={this.state.columns}
              empty={[]}
              textEmpty="Data Atribut Kosong"
            />
            <br />
            {/* End Tambah Blank Page  */}
            <ModalGlobal
              size="P"
              title={
                this.props.isEdit ? "Edit Data Atribut" : "Tambah Data Atribut"
              }
              content={
                <ModalAtribut
                  isEdit={this.state.isEdit}
                  isLoading={this.state.isLoading}
                  onSubmit={(data) => this.simpanAtribut(data)}
                  batal={() => this.batal()}
                />
              }
            />
          </PanelBody>
          <br />
        </Panel>
      </div>
    );
  }
}

MasterAtribut = reduxForm({
  form: "MasterAtribut",
  enableReinitialize: true,
})(MasterAtribut);
export default connect(maptostate)(MasterAtribut);
