import {
  React,
  connect,
  Link,
  reduxForm,
  Panel,
  PanelHeader,
  PanelBody,
  getItem,
  postDataNoTImeOut2,
  ToastNotification,
  reset,
  setItem,
} from "../../../components/helpers/library";
import HeadStockOpnamePesanan from "./HeadStockOpnamePesanan";
import BuktiSoPesanan from "./pdf/BuktiSoPesanan";

class StockOpnamePesanan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    // this.print = this.print.bind(this);
  }

  handleSubmit(data) {


    this.setState({
      isLoading: true,
    });
    let databarang = getItem("data_stock_opname_pesanan");

    let no_pesanan = [];
    databarang.forEach((list) => {
      let row = {
        no_pesanan: list.no_pesanan,
      };
      no_pesanan.push(row);
    });
    let hasil = {
      kode_tukang: data.kode_tukang,
      kode_group: data.kategori,
      stockOpnames: no_pesanan,
      berat_pakai: databarang.reduce(
        (a, b) => parseFloat(a) + parseFloat(b.berat_selesai),
        0
      ),
      ongkos: databarang.reduce((a, b) => parseFloat(a) + parseFloat(b.ongkos), 0),
      saldo_awal: parseFloat(data.total_awal_bahan),
      selisih: parseFloat(data.seleisih),
      setor: parseFloat(data.total_setor_bahan || 0),
      berat_sisa: parseFloat(data.berat_sisa || 0),
      susut_pakai: databarang.reduce(
        (a, b) => parseFloat(a) + parseFloat(b.berat_susut),
        0
      ),
    };

    postDataNoTImeOut2("stock-opname", hasil)
      .then((res) => {
          this.props.dispatch(reset("HeadStockOpnamePesanan"));
          setItem("data_stock_opname_pesanan",[]);
          ToastNotification("success", res.data.message || "Stock Opname Berhasil");
          BuktiSoPesanan(res.data)
          this.setState({
            isLoading: false,
          });
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
        ToastNotification(
          "info",
          err.response?.data.message || "Stock Opname Gagal, Silahkan Coba Lagi"
        );
      });
  }

  render() {
    return (
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="#">Stock Opname Pesanan</Link>
          </li>
        </ol>
        <h1 className="page-header">Stock Opname Pesanan </h1>
        <Panel>
          <PanelHeader>Stock Opname Pesanan</PanelHeader>
          <br />
          {/* Stock Opname Pesanan */}
          <PanelBody>
            <HeadStockOpnamePesanan
              isLoading={this.state.isLoading}
              onSubmit={(data) => this.handleSubmit(data)}
            />
          </PanelBody>
          <br />
        </Panel>
      </div>
    );
  }
}

StockOpnamePesanan = reduxForm({
  form: "StockOpnamePesanan",
  enableReinitialize: true,
})(StockOpnamePesanan);
export default connect()(StockOpnamePesanan);
