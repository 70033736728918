import {
  React,
  connect,
  reduxForm,
  ReanderField,
  Field,
  Tabel,
} from "../../../components/helpers/library";

let HeadPindahPesanan = ({ handleSubmit }) => {
    const columns = [
        {
          dataField: "deskripsi",
          text: "Deskripsi",
        },
        {
          dataField: "atribut",
          text: "Atribut",
        },
        {
          dataField: "berat",
          text: "Berat",
          headerClasses: "text-right",
        },
        {
          dataField: "kadar",
          text: "Kadar",
          headerClasses: "text-right",
        },
        {
          dataField: "qty",
          text: "Qty",
          headerClasses: "text-right",
        },
        {
          dataField: "harga",
          text: "Harga",
          headerClasses: "text-right",
        },
        {
          dataField: "harga_atr",
          text: "Harga Atribut",
          headerClasses: "text-right",
        },
        {
          dataField: "sub_total",
          text: "Sub Total",
          headerClasses: "text-right",
        },
      ];
  return (
    <form
      onSubmit={handleSubmit}
      onKeyPress={(e) => {
        e.key === "Enter" && e.preventDefault();
      }}
      autoComplete="off"
    >
      <div className="row">
        <div className="col-4">
          <Field
            name="no_pesanan"
            component={ReanderField}
            type="text"
            // onChange={(e) => getNopesanan(e.target.value)}
            label="No Pesanan"
            placeholder="Masukan No Pesanan"
          />
        </div>
        <div className="col-4">
          <Field
            name="tgl_pesanan"
            component={ReanderField}
            type="text"
            readOnly
            label="Tanggal Pesan"
            placeholder="Masukan Tanggal Pesan"
          />
        </div>
        <div className="col-4">
          <Field
            name="nama_customer"
            component={ReanderField}
            type="text"
            readOnly
            label="Nama Customer"
            placeholder="Masukan Nama Customer"
          />
        </div>
        <div className="col-4">
          <Field
            name="alamat"
            component={ReanderField}
            type="text"
            readOnly
            label="Alamat"
            placeholder="Masukan Alamat"
          />
        </div>
        <div className="col-4">
          <Field
            name="no_trlp"
            component={ReanderField}
            type="text"
            readOnly
            label="No Hp"
            placeholder="Masukan No Hp"
          />
        </div>
        <div className="col-2">
          <Field
            name="status_awal"
            component={ReanderField}
            type="text"
            readOnly
            label="Status Awal"
            placeholder="Masukan Status Awal"
          />
        </div>
        <div className="col-2">
          <Field
            name="status_selesai"
            component={ReanderField}
            type="text"
            readOnly
            label="Status Selesai"
            placeholder="Masukan Status Selesai"
          />
        </div>
        <div className="col-12">
          <Tabel
            keyField="kode_tukang"
            data={[]}
            columns={columns}
            empty={[]}
            textEmpty="Pesanan Kosong"
          />
        </div>
      </div>
    </form>
  );
};

HeadPindahPesanan = reduxForm({
  form: "HeadPindahPesanan",
  enableReinitialize: true,
})(HeadPindahPesanan);
export default connect()(HeadPindahPesanan);
