const ValidasiKirimSepuhan = (value) => {
    const errors = {};
    if (!value.kode_baki) {
        errors.kode_baki = "Kode Baki Harus Dipilih";
    }
    if (!value.kode_jenis) {
        errors.kode_jenis = "Kode Jenis Harus Dipilih";
    }
    if (!value.brt_sepuh) {
        errors.brt_sepuh = "Berat Sepuh Harus Diisi";
    }
    if (!value.qty_sepuh) {
        errors.qty_sepuh = "Qty Sepuh Harus Diisi";
    }
    if (!value.brt_rusak) {
        errors.brt_rusak = "Berat Rusak Harus Diisi";
    }
    if (!value.qty_rusak) {
        errors.qty_rusak = "Qty Rusak Harus Diisi";
    }
    return errors;
}

export default ValidasiKirimSepuhan;


