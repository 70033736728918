import {
  React,
  connect,
  Link,
  reduxForm,
  Panel,
  PanelHeader,
  formatDate,
  moment,
  localStorageencryp,
  ToastNotification,
  PanelBody,
  getDataNoTimeOut2,
} from "../../../components/helpers/library";
import HeadLaporanOwner from "./HeadLaporanOwner";

class LaporanOwner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      hidden: false,
      istypeRekap: true,
      tanggal: false,
      datalaporanowner: [],
      datamember: [],
      export: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(data) {
    this.setState({
      isLoading: true,
    });
    let data_post = {
      tanggal: formatDate(moment(new Date(data.tgl_awal)).format("YYYY-MM-DD")),
    };
    getDataNoTimeOut2(`owner/report?tanggal=${data_post.tanggal}`)
      .then((res) => {
        if (res.data.length === 0) {
          ToastNotification("info", "Data Yang Di Cari Tidak Ada !!!");
          this.setState({
            isLoading: false,
          });
        } else {
          ToastNotification("success", "Laporan Owner Tersedia");
          // console.log(res.data);
          this.setState({
            datalaporanowner: res.data,
            isLoading: false,
            export: true,
          });
        }
        localStorageencryp("tgl_laporan", data_post);
      })
      .catch((err) => {
        ToastNotification("info", "Data Yang Di Cari Tidak Ada !!!");
        this.setState({
          isLoading: false,
          export: false,
        });
        console.log(err);
        localStorageencryp("tgl_laporan", data_post);
      });
  }

  render() {
    return (
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="#">Laporan Owner</Link>
          </li>
          <li className="breadcrumb-item active">Laporan Owner</li>
        </ol>
        <h1 className="page-header">Laporan Owner </h1>
        <Panel>
          <PanelHeader>Laporan Owner</PanelHeader>
          <br />
          <PanelBody>
            <HeadLaporanOwner
              isLoading={this.state.isLoading}
              export={this.state.export}
              datalaporanowner={this.state.datalaporanowner}
              onSubmit={(data) => this.handleSubmit(data)}
            />
          </PanelBody>
          <br />
          {/* End Tambah Laporan Owner  */}
        </Panel>
      </div>
    );
  }
}

LaporanOwner = reduxForm({
  form: "LaporanOwner",
  enableReinitialize: true,
})(LaporanOwner);
export default connect()(LaporanOwner);
