import jsPDF from "jspdf";
import "jspdf-autotable";
import {
  formatGram,
  getToday,
  getUserdata,
  localStoragedecryp,
} from "../../../../components/helpers/function";
// Date Fns is used to format the dates we receive

// define a generatePDF function that accepts a tickets argument
const CetakLaporanOwner = (data = "", username = "", tanggal = "") => {
  // initialize jsPDF
  const doc = new jsPDF("l", "mm", [297, 210]);
  //   let data = JSON.parse(localStorage.getItem("tt_pengeluaran_barang")) || [];
  let tableRows = [];
  let footRows = [];
  let tableColumn = [];
  let finalY = 30;
  let total_jumlah_saldo = 0;
  let total_berat_saldo = 0;
  let total_jumlah_penjualan = 0;
  let total_berat_penjualan = 0;
  let total_harga_penjualan = 0;
  let total_jumlah_pembelian = 0;
  let total_berat_pembelian = 0;
  let total_harga_pembelian = 0;

  doc.setFontSize(15);
  doc.text("LAPORAN OWNER", 14, 15);
  doc.setFontSize(20);
  doc.text(getUserdata().nama_toko, 200, 15);
  doc.setFontSize(8);
  let jml_alamat = getUserdata().alamat_toko.length;
  if (jml_alamat > 20) {
    doc.text(getUserdata().alamat_toko.slice(0, 48), 200, 20);
  }
  if (jml_alamat > 50) {
    doc.text(getUserdata().alamat_toko.slice(48, 90), 200, 25);
  }
  doc.setFontSize(10);
  doc.setProperties({
    title: "LAPORAN OWNER",
  });
  doc.text(
    `Tanggal : ${
      localStoragedecryp("tgl_laporan").length === 0
        ? null
        : localStoragedecryp("tgl_laporan").tanggal
    }`,
    14,
    25
  );
  tableColumn = [
    [
      {
        content: `TOKO`,
        rowSpan: 2,
        styles: {
          halign: "center",
          valign: "center",
        },
      },
      {
        content: `SALDO`,
        colSpan: 2,
        styles: {
          halign: "center",
        },
      },
      {
        content: `PENJUALAN`,
        colSpan: 3,
        styles: {
          halign: "center",
          valign: "center",
        },
      },
      {
        content: `PEMBELIAN`,
        colSpan: 3,
        styles: {
          halign: "center",
          valign: "center",
        },
      },
    ],
    [
      {
        content: `JUMLAH`,
        styles: {
          halign: "right",
        },
      },
      {
        content: `BERAT`,
        styles: {
          halign: "right",
        },
      },
      {
        content: `JUMLAH`,
        styles: {
          halign: "right",
        },
      },
      {
        content: `BERAT`,
        styles: {
          halign: "right",
        },
      },
      {
        content: `HARGA TOTAL`,
        styles: {
          halign: "right",
        },
      },
      {
        content: `JUMLAH`,
        styles: {
          halign: "right",
        },
      },
      {
        content: `BERAT`,
        styles: {
          halign: "right",
        },
      },
      {
        content: `HARGA TOTAL`,
        styles: {
          halign: "right",
        },
      },
    ],
  ];

  // item.list_barang.forEach((barang, index) => {

  data.forEach((item) => {
    let rows = [
      item.kode_toko,
      {
        content: parseInt(item.jumlah_saldo),
        styles: {
          halign: "right",
        },
      },
      {
        content: formatGram(item.berat_saldo),
        styles: {
          halign: "right",
        },
      },
      {
        content: parseInt(item.jumlah_penjualan),
        styles: {
          halign: "right",
        },
      },
      {
        content: formatGram(item.berat_penjualan),
        styles: {
          halign: "right",
        },
      },
      {
        content: parseInt(item.harga_penjualan).toLocaleString("kr-KO"),
        styles: {
          halign: "right",
        },
      },
      {
        content: parseInt(item.jumlah_pembelian),
        styles: {
          halign: "right",
        },
      },
      {
        content: formatGram(item.berat_pembelian),
        styles: {
          halign: "right",
        },
      },
      {
        content: parseInt(item.harga_pembelian).toLocaleString("kr-KO"),
        styles: {
          halign: "right",
        },
      },
    ];
    tableRows.push(rows);
    total_berat_pembelian += item.berat_pembelian;
    total_berat_penjualan += item.berat_penjualan;
    total_berat_saldo += item.berat_saldo;
    total_harga_pembelian += item.harga_pembelian;
    total_harga_penjualan += item.harga_penjualan;
    total_jumlah_pembelian += item.jumlah_pembelian;
    total_jumlah_penjualan += item.jumlah_penjualan;
    total_jumlah_saldo += item.jumlah_saldo;
  });

  let footer = [
    {
      content: `TOTAL`,
      styles: {
        fillColor: "#E8E5E5",
        textColor: "#000",
      },
    },
    {
      content: `${total_jumlah_saldo}`,
      styles: {
        fillColor: "#E8E5E5",
        textColor: "#000",
        halign: "right",
      },
    },
    {
      content: `${formatGram(total_berat_saldo)}`,
      styles: {
        fillColor: "#E8E5E5",
        textColor: "#000",
        halign: "right",
      },
    },
    {
      content: `${total_jumlah_penjualan}`,
      styles: {
        fillColor: "#E8E5E5",
        textColor: "#000",
        halign: "right",
      },
    },
    {
      content: `${formatGram(total_berat_penjualan)}`,
      styles: {
        fillColor: "#E8E5E5",
        textColor: "#000",
        halign: "right",
      },
    },
    {
      content: `${total_harga_penjualan.toLocaleString("kr-KO")}`,
      styles: {
        fillColor: "#E8E5E5",
        textColor: "#000",
        halign: "right",
      },
    },
    {
      content: `${total_jumlah_pembelian}`,
      styles: {
        fillColor: "#E8E5E5",
        textColor: "#000",
        halign: "right",
      },
    },
    {
      content: `${formatGram(total_berat_pembelian)}`,
      styles: {
        fillColor: "#E8E5E5",
        textColor: "#000",
        halign: "right",
      },
    },
    {
      content: `${total_harga_pembelian.toLocaleString("kr-KO")}`,
      styles: {
        fillColor: "#E8E5E5",
        textColor: "#000",
        halign: "right",
      },
    },
  ];
  footRows.push(footer);
  let printed = [
    {
      content: `Printed By ${
        localStoragedecryp("userdata").user_id
      } / ${getToday()}`,
      colSpan: 3,
      styles: {
        // lineWidth: 0.1,
        fontStyle: "italic",
        textColor: "#000",
        // fillColor: "#E8E5E5"
      },
    },
  ];
  footRows.push(printed);
  doc.autoTable({
    head: tableColumn,
    body: tableRows,
    foot: footRows,
    startY: finalY,
    theme: "plain",
    rowPageBreak: "avoid",
    // pageBreak: "avoid",
    margin: { top: 10 },
    bodyStyles: {
      // lineWidth: 0.02,
      // lineColor: "#000",
      fontSize: 8,
    },
    headStyles: {
      fontSize: 8,
      // lineWidth: 0.02,
      // lineColor: "#000",
      fillColor: "#E8E5E5",
      textColor: "#000",
    },
    tableLineColor: [255, 255, 255],
    tableLineWidth: 1,
  });
  finalY = doc.autoTableEndPosY() + 3;
  tableRows = [];
  footRows = [];
  // const date = Date().split(" ");
  // we use a date string to generate our filename.
  // const dateStr = date[2] + date[3] + date[4];
  // ticket title. and margin-top + margin-left
  // doc.text(`User                           : ${username}`, 14, finalY + 10);
  // doc.text(`Cetak                        : ${getToday()}`, 14, finalY + 16);
  const pages = doc.internal.getNumberOfPages();
  const pageWidth = doc.internal.pageSize.width; //Optional
  const pageHeight = doc.internal.pageSize.height; //Optional
  doc.setFontSize(10); //Optional
  for (let j = 1; j < pages + 1; j++) {
    let horizontalPos = pageWidth / 2; //Can be fixed number
    let verticalPos = pageHeight - 10; //Can be fixed number
    doc.setPage(j);
    doc.text(`${j} of ${pages}`, horizontalPos, verticalPos, {
      align: "center",
    });
  }
  // doc.autoPrint();
  // doc.save(`LAPORAN KEUANGAN REKAP.pdf`);
  var string = doc.output("datauristring");
  // var embed = "<embed width='100%' height='100%' src='" + string + "'/>";
  var x = window.open();
  x.document.open();
  x.document.write(
    "<html><head><title>LAPORAN OWNER</title></head><body style='margin:0 !important'><embed width='100%' height='100%'  src=" +
      string +
      "></embed></body></html>"
  );
  // x.document.write(embed);
  // setInterval(() => {
  //   x.close();
  // }, 1000);
};

export default CetakLaporanOwner;
