import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { ReanderField, ReanderSelect } from "../../../components/helpers/field";
import { NumberOnly, Gramasi } from "../../../Validasi/normalize";
import HeadStockPembelian from "../../../Validasi/StockPembelian/HeadStockPembelian";

const maptoState = (state) => {
  if (state.KirimBarangDariBos.shoModalTambahBarang !== null) {
    return {
      initialValues: {
        kategori : state.KirimBarangDariBos.shoModalTambahBarang.kategori,
        jenis : state.KirimBarangDariBos.shoModalTambahBarang.jenis,
        asal_barang : state.KirimBarangDariBos.shoModalTambahBarang.asal_barang,
        qty : state.KirimBarangDariBos.shoModalTambahBarang.qty,
        berat : state.KirimBarangDariBos.shoModalTambahBarang.berat,
        index : state.KirimBarangDariBos.shoModalTambahBarang.index,
      },
    };
  }
};
class DetailBarang extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}
  render() {
    const kategori = [
      {
        name: "Kategori 1",
        value: "Kategori 1",
      },
      {
        name: "Kategori 2",
        value: "Kategori 2",
      },
    ];
    const jenis = [
      {
        name: "Jenis 1",
        value: "Jenis 1",
      },
      {
        name: "Jenis 2",
        value: "Jenis 2",
      },
    ];
    return (
      <form
        onSubmit={this.props.handleSubmit}
        onKeyPress={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
        autoComplete="off"
      >
        <div className="row">
          <div className="col-4">
            <Field
              label="kategori"
              name="kategori"
              options={kategori}
              placeholder={"Silahkan Pilih Kategori"}
              component={ReanderSelect}
            />
          </div>
          <div className="col-4">
            <Field
              label="Jenis"
              name="jenis"
              options={jenis}
              placeholder={"Silahkan Pilih Jenis"}
              component={ReanderSelect}
            />
          </div>
          <div className="col-4">
            <Field
              name="asal_barang"
              component={ReanderField}
              label="Asal Barang"
              type="text"
              placeholder="Masukan Asal Barang"
            />
          </div>
          <div className="col-4">
            <Field
              name="qty"
              component={ReanderField}
              label="Qty"
              normalize={NumberOnly}
              type="text"
              placeholder="Masukan Qty"
            />
          </div>
          <div className="col-4">
            <Field
              name="berat"
              component={ReanderField}
              label="Berat"
              normalize={Gramasi}
              type="text"
              placeholder="Masukan Berat"
            />
          </div>
          <div className="col-4 text-right">
            <label> &nbsp; </label>
            <br />
            <button
              className="btn btn-primary btn-block"
              disabled={this.props.isLoadingTambahBarang}
              type="submit"
            >
              {this.props.isLoadingTambahBarang ? (
                <>
                  <i className="fas fa-spinner fa-spin"></i> &nbsp; Sedang
                  Menyimpan
                </>
              ) : (
                "Simpan Data"
              )}
            </button>
          </div>
        </div>
      </form>
    );
  }
}

DetailBarang = reduxForm({
  form: "DetailBarang",
  enableReinitialize: true,
  validate : HeadStockPembelian
})(DetailBarang);
export default connect(maptoState)(DetailBarang);
