
import {
  React,
  Component,
  getDataNoTimeOut2,
  ToastNotification,
  Field,
  ReanderField,
  Gramasi,
  NumberOnly,
  ReanderSelect,
  connect,
  reduxForm,
  Loading,
  setItem,
  getData,
} from "../../../components/helpers/library";
import ValidasiKirimLabeling from "../../../Validasi/Sepuhan/ValidasiKirimLabeling";

class HeadKirimLabeling extends Component {
  constructor(props) {
    super(props);
    this.state = {
      databaki: [],
      datajenis: [],
      tujuan: [],
      kode_baki: "ALL",
      kode_jenis: "ALL",
      disable : false
     
    };
  }
  componentDidMount(){
    this.props.change("tujuan","NISA1")
    getData("baki/get/by-kode-gudang/KC").then((res) => {
      // console.log(res.data);
      let tujuan = [];
      res.data.forEach((element) => {
        let row = {
          value: element.kode_baki,
          name: element.kode_baki,
        };
        tujuan.push(row);
      });

      this.setState({
        tujuan: tujuan,
      });
    });
  }
  getDataJenis(e){
    this.setState({ loadingData: true });
    getDataNoTimeOut2(
      "terima-sepuh?no_terima_sepuh=" +
        this.state.nokirim_sepuh_sepuh.toUpperCase() +
        "&kode_baki=" +
        this.state.kode_baki +
        "&kode_dept=" + e
    )
      .then((res) => {
        if (res.data.length === 0) {
          ToastNotification("info", "Data Kosong !");
          this.setState({ loadingData: false });
          return false;
        }
        this.props.change("berat",res.data[0].berat.toFixed(3))
        this.props.change("qty",res.data[0].qty)
        this.setState({
          loadingData: false,
        });
      }).catch((err)=>{
        ToastNotification("info", err.response?.data.message || "Terjadi Kesalahaan Saat Mengirim Data");
        this.setState({
          loadingData: false,
        });
      })
  }
  getDataKirimSepuh() {
    clearTimeout(this.delayTimer);
    this.delayTimer = setTimeout(() => {
      this.setState({ loadingData: true });
      let datano = {
        no_terima  : this.state.nokirim_sepuh_sepuh,
        kode_baki  : this.state.kode_baki,
        kode_dept  : this.state.kode_jenis,
      }
      setItem('data_no_terima_sepuhan',datano);
      getDataNoTimeOut2(
        "terima-sepuh?no_terima_sepuh=" +
          this.state.nokirim_sepuh_sepuh.toUpperCase() +
          "&kode_baki=" +
          this.state.kode_baki +
          "&kode_dept=" +
          this.state.kode_jenis
      )
        .then((res) => {
          if (res.data.length === 0) {
            ToastNotification("info", "Data Kosong !");
            this.setState({ loadingData: false });
            return false;
          }
          // this.props.change("berat_kirim",res.data.berat)
          // this.props.change("qty_kirim",res.data.qty)
          let kode_baki = [];
          res.data.baki.forEach((element) => {
            let row = {
              value: element,
              name: element,
            };
            kode_baki.push(row);
          });

        
          this.props.change("kode_baki",res.data.baki[0])
          let kode_jenis = [];
          res.data.jenis.forEach((element) => {
            let row = {
              value: element,
              name: element,
            };
            kode_jenis.push(row);
          });
          this.props.change("jenis",res.data.jenis[0])

          this.setState({
            kode_baki : res.data.baki[0],
            kode_jenis : res.data.jenis[0],
          })
          this.setState({
            loadingData: false,
            databaki: kode_baki,
            datajenis: kode_jenis,
          });
          let datano2 = {
            no_terima  : this.state.nokirim_sepuh_sepuh,
            kode_baki  : res.data.baki[0],
            kode_dept  : res.data.jenis[0],
          }
          setItem('data_no_terima_sepuhan',datano2);
          this.setState({ loadingData: true });
          getDataNoTimeOut2(
            "terima-sepuh?no_terima_sepuh=" +
              this.state.nokirim_sepuh_sepuh.toUpperCase() +
              "&kode_baki=" +
              res.data.baki[0] +
              "&kode_dept=" +  res.data.jenis[0]
          )
            .then((res) => {
              if (res.data.length === 0) {
                ToastNotification("info", "Data Kosong !");
                this.setState({ loadingData: false });
                return false;
              }
              

              this.props.change("berat",res.data[0].berat.toFixed(3))
              this.props.change("qty",res.data[0].qty)
              this.setState({
                loadingData: false,
              });
            }).catch((err)=>{
              console.log(err)
              ToastNotification("info", err.response?.data.message || "Terjadi Kesalahaan Saat Mengirim Data");
              this.setState({
                loadingData: false,
              });
            })

        })
        .catch((err) => {
          console.log(err)
          this.setState({
            loadingData: false,
          });
          ToastNotification("info", err.response?.data.message || "Terjadi Kesalahaan Saat Mengirim Data");
        });
    }, 1000);
  }
  cekQty(e){
    // let jml_berat = document.getElementById("jml_berat").value;
    // let berat = document.getElementById("berat").value;
    let qty = document.getElementById("qty").value;
    if(e !== ""){
      if(parseInt(e) > parseInt(qty)){
        this.setState({
          disable : true
        })
        ToastNotification("info", "Jumlah Qty Melebihi Qty Awal")
        return false
      }else{
        this.setState({
          disable : false
        })
      }
    }
  }
  cekBerat(e){
    let berat = document.getElementById("berat").value;
    let qty = document.getElementById("qty").value;
    let jml_qty = document.getElementById("jml_qty").value;
    if(e !== ""){
      if(parseInt(jml_qty) > parseInt(qty)){
        this.setState({
          disable : true
        })
        ToastNotification("info", "Jumlah Qty Melebihi Qty Awal")
        return false
      }else{
        if(parseFloat(e) > parseFloat(berat)){
          this.setState({
            disable : true
          })
          ToastNotification("info", "Jumlah Berat Melebihi Berat Awal")
          return false
        }else{
          this.setState({
            disable : false
          })
        }
    }
  }

  }
  getNokirim(e){
    if(e.target.value.length >= 12){
      this.setState({
        nokirim_sepuh_sepuh: e.target.value,
      });
      this.getDataKirimSepuh();
    }
  }
  render() {
    return (
      <form
        onSubmit={this.props.handleSubmit}
        onKeyPress={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
        autoComplete="off"
      >
        <div className="row">
          <div className="col-6">
            <Field
              label="No Terima Sepuh"
              name="nokirim_sepuh_sepuh"
              placeholder="Silahkan Pilih No Terima Sepuh"
              component={ReanderField}
              onChange={(e) => {
                this.getNokirim(e);
              }}
            />
          </div>
          <div className="col-3">
            <Field
              label="Baki"
              name="kode_baki"
              onChange={(e) => {
                this.setState({ kode_baki: e });
                this.getDataKirimSepuh();
              }}
              placeholder="Masukan Kode Baki"
              options={this.state.databaki || []}
              component={ReanderSelect}
            />
          </div>
          <div className="col-3">
            <Field
              label="jenis"
              name="jenis"
              onChange={(e) => {
                this.getDataJenis(e);
              }}
              placeholder="Masukan jenis"
              options={this.state.datajenis || []}
              component={ReanderSelect}
            />
          </div>
          <div className="col-3">
            <Field
              label="Qty Awal"
              id="qty"
              name="qty"
              readOnly={true}
              normalize={NumberOnly}
              placeholder="Masukan Qty"
              component={ReanderField}
            />
          </div>
          <div className="col-3">
            <Field
              id="berat"
              label="Berat Awal"
              name="berat"
              readOnly={true}
              normalize={Gramasi}
              placeholder="Masukan berat"
              component={ReanderField}
            />
          </div>

          <div className="col-3">
            <Field
              label="Tujuan"
              name="tujuan"
              placeholder="Masukan Kode Tujuan"
              options={this.state.tujuan}
              component={ReanderSelect}
            />
          </div>
          <div className="col-3">
            <Field
              label="Cabang"
              name="cabang"
              placeholder="Masukan Cabang"
              component={ReanderField}
            />
          </div>
          <div className="col-3">
            <Field
              label="Jumlah Qty"
              id="jml_qty"
              name="jml_qty"
              normalize={NumberOnly}
              onChange={(e)=> this.cekQty(e.target.value)}
              placeholder="Masukan Jumlah Qty"
              component={ReanderField}
            />
          </div>
          <div className="col-2">
            <Field
              label="Jumlah Berat"
              id="jml_berat"
              name="jml_berat"
              normalize={Gramasi}
              onChange={(e)=> this.cekBerat(e.target.value)}
              placeholder="Masukan Jumlah Berat"
              component={ReanderField}
            />
          </div>
          <div className="col-2">
            <Field
              label="Berat Atr"
              name="berat_atribut"
              normalize={Gramasi}
              placeholder="Masukan Berat Atribut"
              component={ReanderField}
            />
          </div>
          <div className="col-2">
          <Field
              label="Type Barang"
              name="tipe_barang"
              options={
                [
                  {
                    value: "A",
                    name: "A",
                  },
                  {
                    value: "SP",
                    name: "SP",
                  }
                ]
              }
              placeholder={"Silahkan Pilih Type Barang"}
              component={ReanderSelect}
            />
          </div>
        
          <div className="col-3">
            <button disabled={this.state.disable} className="btn btn-primary btn-block mt-4">
              {" "}
              <i className="fa fa-plus"></i>{" "}
            </button>
          </div>
          
        </div>
        {this.state.loadingData ? (
          <Loading
            loading
            background="rgba(0, 0, 0, 0.35)"
            loaderColor="rgba(94, 147, 117, 1)"
          />
        ) : null}
      </form>
    );
  }
}

HeadKirimLabeling = reduxForm({
  form: "HeadKirimLabeling",
  enableReinitialize: true,
  validate : ValidasiKirimLabeling
})(HeadKirimLabeling);
// export default connect()(HeadKirimLabeling);
export default connect((state) => {
  return {};
})(HeadKirimLabeling);
