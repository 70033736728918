import LaporanPesananTukang from "./pdf/LaporanPesananTukang";
import {
  GetDataGroup,
  getDataTukang,
} from "../../../../actions/datamaster_action";
import {
  React,
  connect,
  reduxForm,
  Loading,
  Tabel,
  Field,
  InputDate,
  getTglSystem,
  ReanderSelect,
} from "../../../../components/helpers/library";
import LaporanPesananTukangExel from "./exel/LaporanPesananTukangExel";

class HeadLaporanPesananTukang extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      columns: [
        {
          dataField: "no_persekot",
          text: "No Persekot",
          footer: "",
          footerAttrs: {
            colSpan: "3",
          },
          headerStyle: (column, colIndex) => {
            return { width: "13%" };
          },
        },
        {
          dataField: "tgl_system",
          text: "Tanggal",
        },
        {
          dataField: "nama_customer",
          text: "Nama Customer",
          headerStyle: (column, colIndex) => {
            return { width: "15%" };
          },
        },
        {
          dataField: "tukang",
          text: "Tukang",
        },
        {
          dataField: "nama_barang",
          text: "Nama Barang",
        },
        {
          dataField: "tgl_selesai",
          text: "Tanggal Selesai",
        },
        {
          dataField: "berat_tukang",
          text: "Berat Pakai",
          formatter: (data) => {
            return <div className="text-right">{data.toFixed(3)}</div>;
          },
        },
        {
          dataField: "ongkos",
          text: "Ongkos Tukang",
          formatter: (data) => {
            return (
              <div className="text-right">{data.toLocaleString("kr-KO")}</div>
            );
          },
        },
        // {
        //   dataField: "susut_tukang",
        //   text: "Susut Tukang",
        //   formatter: (data) => {
        //     return <div className="text-right">{data.toFixed(3)}</div>;
        //   },
        // },
        // {
        //   dataField: "asal_bahan",
        //   text: "Asal Bahan",
        //   formatter: (data) => {
        //     return <div className="text-right">{data.toFixed(3)}</div>;
        //   },
        // },
        // {
        //   dataField: "saldo_bahan",
        //   text: "Saldo Bahan",
        //   formatter: (data) => {
        //     return <div className="text-right">{data.toFixed(3)}</div>;
        //   },
        // },
        {
          dataField: "status",
          text: "Status",
        },
      ],
    };
    // this.print = this.print.bind(this);
  }

  setStartDate(date) {
    this.setState({
      tgl_awal: new Date(date),
    });
  }
  setLastDate(date) {
    this.setState({
      tgl_akhir: new Date(date),
    });
  }
  componentDidMount() {
    this.props.dispatch(getDataTukang());
    this.props.dispatch(GetDataGroup());
    this.props.change("kategori", "ALL");
    this.props.change("kode_tukang", "ALL");
    this.props.change("type", "PROSES");
  }
  exportPdf() {
    LaporanPesananTukang(this.props.LaporanPesanan);
  }
  render() {
    let datatukang = [
      {
        value: "ALL",
        name: "SEMUA",
      },
    ];
    let datagroup = [
      {
        value: "ALL",
        name: "SEMUA",
      },
    ];
    this.props.DataGroup.forEach((list) => {
      let row = {
        name: list.kode_group,
        value: list.kode_group,
      };
      datagroup.push(row);
    });
    this.props.DataTukang.forEach((element) => {
      let row = {
        name: element.nama_tukang,
        value: element.kode_tukang,
      };
      datatukang.push(row);
    });
    return (
      <form onSubmit={this.props.handleSubmit} autoComplete="off">
        <div className="row">
          <div className="col-lg-4">
            <Field
              name="tgl_awal"
              component={InputDate}
              label="Tanggal Dari"
              type="text"
              selected={this.state.tgl_awal}
              onChange={(date) => this.setStartDate(date)}
              placeholder="Masukan Tanggal Dari"
            />
          </div>

          <div className="col-lg-4">
            <Field
              name="tgl_akhir"
              component={InputDate}
              type="text"
              selected={this.state.tgl_akhir}
              onChange={(date) => this.setLastDate(date)}
              label="Tanggal Akhir"
              placeholder="Masukan Tanggal Akhir"
            />
          </div>
          <div className="col-4">
            <Field
              id="type"
              name="type"
              component={ReanderSelect}
              type="text"
              options={[
                {
                  name: "PROSES",
                  value: "PROSES",
                },
                {
                  name: "SELESAI",
                  value: "SELESAI",
                },
              ]}
              label="Type"
              placeholder="Silahkan Pilih Type"
            />
          </div>
          <div className="col-4">
            <Field
              id="kode_tukang"
              name="kode_tukang"
              component={ReanderSelect}
              type="text"
              options={datatukang}
              label="Kode Tukang"
              placeholder="Silahkan Pilih Kode Tukang"
            />
          </div>
          <div className="col-4">
            <Field
              id="kategori"
              name="kategori"
              component={ReanderSelect}
              type="text"
              options={datagroup}
              label="Kategori"
              placeholder="Silahkan Pilih Kategori"
            />
          </div>
          <div className="col-lg-4">
            <div className="text-right">
              <label>&nbsp;</label>
              <button
                type="submit"
                value="PDF"
                name="type_btn"
                className="btn btn-primary btn-block"
              >
                {this.props.isLoading ? (
                  <>
                    <i className="fas fa-spinner fa-spin"></i> &nbsp; Sedang
                    Mencari
                  </>
                ) : (
                  "Cari Laporan"
                )}
              </button>
            </div>
          </div>
          <div className="col-lg-12">
            <Tabel
              keyField="no_pesanan"
              data={this.props.export ? this.props.LaporanPesanan : []}
              columns={this.state.columns}
              empty={true}
              textEmpty="Data Laporan Pesanan Tukang Kosong"
            />
          </div>
          <div className={this.props.export ? "col-lg-12" : "col-lg-12 d-none"}>
            {/* <label htmlFor="">&nbsp;</label> */}
            <div className="row">
              <div className="col-lg-6">
                <button
                  type="button"
                  // onClick={() => this.exportPdf()}
                  className="btn btn-warning btn-block"
                  onClick={() => this.exportPdf()}
                >
                  {" "}
                  Export PDF{" "}
                </button>
              </div>
              <div className="col-lg-6">
                <LaporanPesananTukangExel
                  dataexel={this.props.LaporanPesanan || []}
                />
              </div>
            </div>
          </div>
        </div>
        {this.props.isLoading ? (
          <Loading
            loading
            background="rgba(0, 0, 0, 0.35)"
            loaderColor="rgba(94, 147, 117, 1)"
          />
        ) : null}
      </form>
    );
  }
}

HeadLaporanPesananTukang = reduxForm({
  form: "HeadLaporanPesananTukang",
  enableReinitialize: true,
})(HeadLaporanPesananTukang);
export default connect((state) => {
  return {
    DataGroup: state.datamaster.GetDataGroup,
    DataTukang: state.datamaster.getDataTukang,
    initialValues: {
      tgl_awal: getTglSystem(),
      tgl_akhir: getTglSystem(),
    },
  };
})(HeadLaporanPesananTukang);
