import jsPDF from "jspdf";
import "jspdf-autotable";
import {
  getToday,
  getUserdata,
  localStoragedecryp,
} from "../../../../../components/helpers/function";
// Date Fns is used to format the dates we receive

// define a generatePDF function that accepts a tickets argument
const LaporamSortirSepuhGlobal = (data = "") => {
  // initialize jsPDF
  console.log(data);
  const doc = new jsPDF("l", "mm", [297, 210]);
  //   let data = JSON.parse(localStorage.getItem("tt_pengeluaran_barang")) || [];
  let tableRows = [];
  let tableColumn = [];

  //   let footRows = [];
  let finalY = 30;
  doc.text("LAPORAN SORTIR SEPUH GLOBAL", 14, 15);
  doc.setFontSize(20);
  doc.text(getUserdata().nama_toko, 200, 15);
  doc.setFontSize(8);
  let jml_alamat = getUserdata().alamat_toko.length;
  if (jml_alamat > 20) {
    doc.text(getUserdata().alamat_toko.slice(0, 48), 200, 20);
  }
  if (jml_alamat > 50) {
    doc.text(getUserdata().alamat_toko.slice(48, 90), 200, 25);
  }

  doc.setFontSize(10);
  doc.setProperties({
    title: "LAPORAN SORTIR SEPUH GLOBAL",
  });
  doc.text(
    `PERIODE : ${
      localStoragedecryp("tgl_laporan",[], true).length === 0
        ? null
        : localStoragedecryp("tgl_laporan",[], true).tgl_awal +
          " s/d " +
          localStoragedecryp("tgl_laporan",[], true).tgl_akhir
    }`,
    14,
    25
  );

  //   tableRows.push(tableColumn)
  tableColumn = [
    [
      {
        content: `NO SORTIR`,
      },

      {
        content: `KONDISI`,
      },
      {
        content: `KODE GROUP`,
      },
      {
        content: `KODE BAKI`,
      },

      {
        content: `TOTAL QTY`,
        styles: {
          halign: "right",
        },
      },
      {
        content: `TOTAL BERAT`,
        styles: {
          halign: "right",
        },
      },
    ],
  ];
  data.forEach((item, no) => {
    let row = [
      item.no_sortir,
      item.kondisi,
      item.kategori,
      item.kode_lokasi_toko,
      {
        content: item.qty_total,
        styles: {
          halign: "right",
        },
      },
      {
        content: item.berat_total.toFixed(3),
        styles: {
          halign: "right",
        },
      },
    ];
    tableRows.push(row);
  });
  let footer = [
    {
      content: `TOTAL SORTIR SEPUH ${data.length}`,
      colSpan: 4,
      styles: {
        halign: "center",
        fillColor: "#E8E5E5",
        textColor: "#000",
      },
    },
    {
      content: `${data.reduce((a, b) => a + parseInt(b.qty_total), 0)}`,
      styles: {
        halign: "right",
        fillColor: "#E8E5E5",
        textColor: "#000",
      },
    },
    {
      content: `${data
        .reduce((a, b) => a + parseFloat(b.berat_total), 0)
        .toFixed(3)}`,
      styles: {
        halign: "right",
        fillColor: "#E8E5E5",
        textColor: "#000",
      },
    },
  ];
  tableRows.push(footer);

  let printed = [
    {
      content: `Printed By ${
        localStoragedecryp("userdata").user_id
      } / ${getToday()}`,
      colSpan: 12,
      styles: {
        // lineWidth: 0.1,
        fontStyle: "italic",
        textColor: "#000",
        // fillColor: "#E8E5E5"
      },
    },
  ];
  tableRows.push(printed);

  doc.autoTable({
    head: tableColumn,
    body: tableRows,
    // foot: footRows,
    startY: finalY,
    theme: "plain",
    // rowPageBreak: "avoid",
    // pageBreak: "avoid",
    margin: { top: 10 },
    bodyStyles: {
      // lineWidth: 0.02, lineColor: "#000",
      fontSize: 8,
    },
    headStyles: {
      fontSize: 8,
      //   lineWidth: 0.02,
      //   lineColor: "#000",
      fillColor: "#E8E5E5",
      textColor: "#000",
    },
    tableLineColor: [255, 255, 255],
    tableLineWidth: 1,
  });
  tableRows = [];
  tableColumn = [];
  finalY = doc.autoTableEndPosY() + 20;

  const pages = doc.internal.getNumberOfPages();
  const pageWidth = doc.internal.pageSize.width; //Optional
  const pageHeight = doc.internal.pageSize.height; //Optional
  doc.setFontSize(10); //Optional
  for (let j = 1; j < pages + 1; j++) {
    let horizontalPos = pageWidth / 2; //Can be fixed number
    let verticalPos = pageHeight - 10; //Can be fixed number
    doc.setPage(j);
    doc.text(`${j} of ${pages}`, horizontalPos, verticalPos, {
      align: "center",
    });
  }
  // doc.autoPrint();
  // doc.save(`PenerimaanSUpplier.pdf`);
  var string = doc.output("datauristring");
  var x = window.open();
  x.document.open();
  x.document.write(
    "<html><head><title>LAPORAN SORTIR SEPUH GLOBAL</title></head><body style='margin:0 !important'><embed width='100%' height='100%'  src=" +
      string +
      "></embed></body></html>"
  );
  // setInterval(() => {
  //   x.close();
  // }, 1000);
};

export default LaporamSortirSepuhGlobal;
