import {
    React,
    Component,
    HiidenFiled,
    ReanderField,
    connect,
    Field,
    reduxForm,
  } from "../../../components/helpers/library";
import ValidateMasterBarang from "../../../Validasi/Barang/ValidateMasterBarang";

const maptoState = (state) => {
    if (state.datamaster.shwoMasterAtribut !== null) {
      return {
        isEdit : state.datamaster.isEdit,
        isLoading : state.datamaster.isLoading,
        initialValues: {
          _id: state.datamaster.shwoMasterAtribut._id,
          atribut_lama: state.datamaster.shwoMasterAtribut.atribut,
          atribut: state.datamaster.shwoMasterAtribut.atribut,
          index: state.datamaster.shwoMasterAtribut.index,
        },
      };
    }
  };
  class ModalAtribut extends Component {
    constructor(props) {
      super(props);
      this.state = {};
    }
  
    componentDidMount() {
      setTimeout(() => {
        document.getElementById("atribut").focus();
      }, 100);
    }
    render() {
      return (
        <form
          onSubmit={this.props.handleSubmit}
          onKeyPress={(e) => {
            e.key === "Enter" && e.preventDefault();
          }}
          autoComplete="off"
        >
          <div className="row">
            <Field name="_id" type="hidden" component={HiidenFiled} />
            <div className="col-12">
              <Field
                id="atribut"
                tabIndex="1"
                name="atribut"
                component={ReanderField}
                label="Atribut"
                type="text"
                placeholder="Masukan Atribut"
              />
            </div>
  
            <div className="col-12 text-right">
              <label> &nbsp; </label>
              <br />
              <button
                className="btn btn-warning"
                type="button"
                onClick={() => this.props.batal()}
              >
                Batal
              </button>
              &nbsp;
              <button
                tabIndex="2"
                className="btn btn-primary"
                disabled={this.props.isLoading}
                type="submit"
              >
                {this.props.isLoading ? (
                  <>
                    <i className="fas fa-spinner fa-spin"></i> &nbsp; Sedang
                    Menyimpan
                  </>
                ) : (
                  "Simpan Data"
                )}
              </button>
            </div>
          </div>
        </form>
      );
    }
  }
  
  ModalAtribut = reduxForm({
    form: "ModalAtribut",
    enableReinitialize: true,
    validate: ValidateMasterBarang,
  })(ModalAtribut);
  export default connect(maptoState)(ModalAtribut);
  