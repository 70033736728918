import {
  GetDataGroup,
  getDataJenis,
  getDataKadar,
} from "../../../actions/datamaster_action";
import {
  React,
  ModalGlobal,
  Link,
  Swal,
  uuidv4,
  reset,
  hideModal,
  moment,
  showModal,
  Panel,
  PanelHeader,
  connect,
  reduxForm,
  Tabel,
  getItem,
  setItem,
  formatDate,
  ToastNotification,
  postData,
} from "../../../components/helpers/library";
import HeadDetailKirim from "./HeadDetailKirim";
import DetailBarang from "./DetailBarang";
import HeadStockPembelian from "../../../Validasi/StockPembelian/HeadStockPembelian";
import { shoModalTambahBarang } from "../../../actions/KirimBarangDariBos_action";

class KirimBarangBos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadingTambahDetailKirim: false,
      isLoadingReset: false,
      isLoadingTambahBarang: false,
      isEdit: false,
      isLoadingSimpanDataKirim: false,
      form: "",
      columns: [
        {
          dataField: "kategori",
          text: "Kategori Barang",
          footer: "Total",
          footerAttrs: {
            colSpan: "2",
          },
        },
        {
          dataField: "jenis",
          text: "Jenis Barang",
        },
        {
          dataField: "asal_barang",
          text: "Detail Jenis",
          footer: "",
        },
        {
          dataField: "berat",
          text: "Berat",
          formatter: (cell) => {
            return <span>{cell.toFixed(3)}</span>;
          },
          footer: (columnData) =>
            columnData.reduce((acc, item) => acc + item, 0).toFixed(3),
        },
        {
          dataField: "qty",
          text: "Qty",
          footer: (columnData) =>
            columnData.reduce((acc, item) => acc + item, 0),
        },
        // {
        //   dataField: "detail_jenis",
        //   text: "Detail Jenis",
        //   footer: "",
        // },
       
        {
          dataField: "action",
          text: "Action",
          csvExport: false,
          headerClasses: "text-center",
          formatter: (rowcontent, row, index) => {
            let data = {
              id: row.id,
              kategori: row.kategori,
              jenis: row.jenis,
              detail_jenis: row.detail_jenis,
              asal_barang: row.asal_barang,
              berat: parseFloat(row.berat),
              qty: parseInt(row.qty),
              index: index,
            };
            return (
              <div className="row text-center">
                <div className="col-12">
                  <button
                    type="button"
                    onClick={() => this.editData(data)}
                    className="btn btn-warning mr-3"
                  >
                    <i className="fa fa-edit"></i>
                  </button>
                  <button
                    type="button"
                    onClick={() => this.hapusdata(index)}
                    className="btn btn-danger mr-3"
                  >
                    <i className="fa fa-trash"></i>
                  </button>
                </div>
              </div>
            );
          },
          footer: "",
        },
      ],
    };
    this.submitDetailKirim = this.submitDetailKirim.bind(this);
    this.showModalDetailKirim = this.showModalDetailKirim.bind(this);
    this.showModalTambahBarang = this.showModalTambahBarang.bind(this);
    this.tambahDataBarang = this.tambahDataBarang.bind(this);
  }
  editData(data) {
    this.setState({
      isEdit: true,
    });
    this.props.dispatch(shoModalTambahBarang(data));
    this.props.dispatch(showModal());
  }
  hapusdata(id) {
    Swal.fire({
      title: "Anda Yakin !!",
      text: "Ingin Menghapus Data Ini ?",
      icon: "warning",
      position: "top-center",
      cancelButtonText: "Tidak",
      showCancelButton: true,
      confirmButtonText: "OK",
      showConfirmButton: true,
    }).then((result) => {
      // console.log(id);
      if (result.isConfirmed) {
        let data = getItem("databarang_dari_bos");
        data.splice(id, 1);
        setItem("databarang_dari_bos", data);
        this.setState({
          hapus: "berhasil",
        });
      }
    });
  }

  tambahDataBarang(data) {
    this.setState({ isLoadingTambahBarang: true });
    setTimeout(() => {
      let row = {
        id: uuidv4(),
        kategori: data.kategori,
        jenis: data.jenis,
        detail_jenis: data.detail_jenis,
        asal_barang: data.asal_barang.toUpperCase(),
        berat: parseFloat(data.berat),
        qty: parseInt(data.qty),
      };
      let cek =
        getItem("databarang_dari_bos").length === 0
          ? []
          : getItem("databarang_dari_bos");
      let hasil_cek = cek.findIndex((hasil) => hasil.id === 0);

      if (hasil_cek === -1) {
        if (cek.length > data.index) {
          cek[data.index].kategori = data.kategori;
          cek[data.index].jenis = data.jenis;
          cek[data.index].asal_barang = data.asal_barang;
          cek[data.index].detail_jenis = data.detail_jenis;
          cek[data.index].berat = parseFloat(data.berat);
          cek[data.index].qty = parseInt(data.qty);
          setItem("databarang_dari_bos", cek);
          this.props.dispatch(hideModal());
        } else {
          if (cek.length === 0) {
            cek.push(row);
            setItem("databarang_dari_bos", cek);
          } else {
            ToastNotification("info", "Tidak Bisa Input Barang Lebih Dari 1");
          }
        }
      }

      this.setState({ isLoadingTambahBarang: false });
      this.props.dispatch(reset("DetailBarang"));
      this.props.dispatch(hideModal());
    }, 200);
  }
  submitDetailKirim(data) {
    // console.log(data);
    let row = {
      tanggal_kirim: formatDate(
        moment(new Date(data.tanggal_kirim)).format("YYYY-MM-DD")
      ),
      kadar: data.kadar,
      sales_pabrik: data.sales_pabrik,
      tujuan: data.tujuan,
    };
    this.setState({
      isLoadingTambahDetailKirim: true,
    });

    this.props.dispatch(reset("HeadDetailKirim"));
    setTimeout(() => {
      setItem("data_detail_kirim_barang_bos", row);
      this.setState({
        isLoadingTambahDetailKirim: false,
      });
      this.props.dispatch(hideModal());
    }, 200);
  }
  showModalDetailKirim() {
    this.props.dispatch(showModal());
    this.setState({
      form: "INPUT_DETAIL_KIRIM",
    });
  }
  showModalTambahBarang() {
    this.setState({
      isEdit: false,
    });
    this.props.dispatch(shoModalTambahBarang(false));
    this.props.dispatch(showModal());
    this.setState({
      form: "INPUT_DATA_BARANG",
    });
  }
  reset() {
    this.setState({
      isLoadingReset: true,
    });
    setTimeout(() => {
      setItem("data_detail_kirim_barang_bos", []);
      setItem("databarang_dari_bos", []);
      this.setState({
        isLoadingReset: false,
      });
      this.props.dispatch(reset("HeadDetailKirim"));
    }, 200);
  }
  simpanDataKirim() {
    this.setState({
      isLoadingSimpanDataKirim: true,
    });
    // let databarang = getItem("databarang_dari_bos");
    let data_barang = getItem("databarang_dari_bos");
    let detail_kirim = getItem("data_detail_kirim_barang_bos");

    if (data_barang.length === 0) {
      ToastNotification("info", "Data Barang Masih Kosong");
      this.setState({
        isLoadingSimpanDataKirim: false,
      });
      return false;
    }
    if (detail_kirim.length === 0) {
      ToastNotification("info", "Detail Kirim Masih Kosong");
      this.setState({
        isLoadingSimpanDataKirim: false,
      });
      return false;
    }
    data_barang.forEach((element) => {
      let row = {
        kode_group: element.kategori,
        kode_dept: element.jenis,
        asal_barang: element.asal_barang.toUpperCase(),
        detail_barang: element.asal_barang,
        qty: element.qty,
        berat: element.berat,
        kode_labeling: detail_kirim.tujuan,
        kadar: detail_kirim.kadar,
        sales_pabrik: detail_kirim.sales_pabrik,
      };

      postData("krmbrgbos/simpan", row, [], true)
        .then((res) => {
          this.reset();
          console.log(res.data);
          ToastNotification("success", "Data Berhasil Disimpan");
          let datasepuh = [];
          let nama = "";
          for (let IndexAwal = 0; IndexAwal < 2; IndexAwal++) {
            if (IndexAwal === 0) {
              nama = "INTERNAL";
            } else {
              nama = "LABEL";
            }
            let feedback = "";
            feedback += `========================================\n`;
            feedback += `          KIRIM BARANG DARI BOS         \n`;
            feedback += `                 ${nama}                \n`;
            feedback += `========================================
No Kirim Stock   : ${res.data.nota.no_kirim_bos}
Tanggal Kirim    : ${res.data.nota.tgl_kirim.split("-").reverse().join("-")}`;
            feedback += `\n========================================`;
            feedback += `
Kategori         : ${res.data.nota.kategori}
Asal             : ${res.data.nota.asal}
Baki             : ${res.data.nota.baki}
Jenis            : ${res.data.nota.jenis}
Keterangan       : ${res.data.nota.keterangan}
Qty Kirim        : ${res.data.nota.qty_kirim}
Brt Kirim        : ${res.data.nota.berat_kirim.toFixed(3)} Gr\n`;
            feedback += `========================================\n
Tot Qty Kirim   : ${res.data.nota.qty_kirim}
Tot Brt Kirim   : ${res.data.nota.berat_kirim.toFixed(3)} Gr\n`;
            feedback += `========================================\n`;
            feedback += `              TERIMA KASIH              \n`;
            feedback += `========================================`;
            feedback += `






`;
            datasepuh.push(feedback);
          }
          // console.log(datasepuh);
          document.getElementById("myInput").value = datasepuh.join("");
        })
        .then(() => {
          const element = document.createElement("a");
          const file = new Blob([document.getElementById("myInput").value], {
            type: "text/plain;charset=utf-8",
          });
          element.href = URL.createObjectURL(file);
          element.download = "autoprint_pembelian.txt";
          document.body.appendChild(element);
          element.click();

          this.setState({
            isLoadingSimpanDataKirim: false,
          });
        })
        .catch((error) => {
          console.log(error);
          ToastNotification("info", "Terjadi Kesalahan Saat Mengirim Data");
        });
    });

    // ToastNotification("success", "Data Berhasil Disimpan");
  }

  componentDidMount() {
    this.props.dispatch(getDataKadar());
    this.props.dispatch(GetDataGroup());
    this.props.dispatch(getDataJenis());
  }
  render() {
    return (
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="#">Stock Pembelian</Link>
          </li>
          <li className="breadcrumb-item active">Kirim Dari Barang Bos</li>
        </ol>
        <h1 className="page-header">Kirim Dari Barang Bos </h1>
        <Panel>
          <PanelHeader>Kirim Dari Barang Bos</PanelHeader>
          <br />
          <textarea
            style={{ display: "none" }}
            id="myInput"
            rows="100"
            cols="100"
          />
          {/* Kirim Dari Barang Bos */}
          <div className="container">
            <div className="row">
              <div className="col-4">
                Tanggal Kirim :{" "}
                {getItem("data_detail_kirim_barang_bos").tanggal_kirim} <br />
                Type Barang : {
                  getItem("data_detail_kirim_barang_bos").kadar
                }{" "}
                <br />
              </div>
              <div className="col-3">
                Sales Pabrik :{" "}
                {getItem("data_detail_kirim_barang_bos").sales_pabrik} <br />
                Tujuan : {getItem("data_detail_kirim_barang_bos").tujuan} <br />
              </div>
              <div className="col-5 text-right">
                <button
                  onClick={() => this.showModalDetailKirim()}
                  className="btn btn-primary"
                >
                  {getItem("data_detail_kirim_barang_bos").tanggal_kirim
                    ? "Edit Data Detail Kirim"
                    : "Tambah Data Detail Kirim"}
                </button>{" "}
                &nbsp;
                <button
                  onClick={() => this.showModalTambahBarang()}
                  className="btn btn-success"
                >
                  {" "}
                  Tambah Data Barang{" "}
                </button>
              </div>
            </div>
            &nbsp;
            <Tabel
              keyField="id"
              data={getItem("databarang_dari_bos") || []}
              columns={this.state.columns}
              empty={getItem("databarang_dari_bos") || []}
              textEmpty="Data Barang Kosong"
            />
            <div className="row">
              <div className="col-5 text-left">
                <button
                  className="btn btn-primary"
                  disabled={this.state.isLoadingSimpanDataKirim}
                  onClick={() => this.simpanDataKirim()}
                >
                  {this.state.isLoadingSimpanDataKirim ? (
                    <>
                      <i className="fas fa-spinner fa-spin"></i> &nbsp; Simpan
                      Data Kirim
                    </>
                  ) : (
                    "Simpan Data Kirim"
                  )}
                </button>{" "}
                &nbsp;
                <button
                  className="btn btn-warning"
                  // disabled={this.state.isLoadingReset}
                  onClick={() => this.reset()}
                >
                  {/* {this.state.isLoadingReset ? (
                    <>
                      <i className="fas fa-spinner fa-spin"></i> &nbsp; Reset
                    </>
                  ) : (
                    "Reset"
                  )} */}
                  Reset
                </button>
              </div>
            </div>
          </div>
          <br />
          {/* End Tambah Kirim Barang Bos  */}
          <ModalGlobal
            size="P"
            title={
              this.state.form === "INPUT_DETAIL_KIRIM"
                ? "Form Input Detail Kirim"
                : "Form Input Detail Barang"
            }
            content={
              this.state.form === "INPUT_DETAIL_KIRIM" ? (
                <HeadDetailKirim
                  isLoadingTambahDetailKirim={
                    this.state.isLoadingTambahDetailKirim
                  }
                  onSubmit={(data) => this.submitDetailKirim(data)}
                />
              ) : (
                <DetailBarang
                  isEdit={this.state.isEdit}
                  isLoadingTambahBarang={this.state.isLoadingTambahBarang}
                  onSubmit={(data) => this.tambahDataBarang(data)}
                />
              )
            }
          />
        </Panel>
      </div>
    );
  }
}

KirimBarangBos = reduxForm({
  form: "KirimBarangBos",
  enableReinitialize: true,
  validate: HeadStockPembelian,
})(KirimBarangBos);
export default connect()(KirimBarangBos);
