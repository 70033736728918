import {
    React,
    Component,
    HiidenFiled,
    ReanderField,
    connect,
    Field,
    reduxForm,
  } from "../../../components/helpers/library";   
import ValidateMasterBarang from "../../../Validasi/Barang/ValidateMasterBarang";
  
  const maptoState = (state) => {
    if (state.datamaster.showMasterPabrik !== null) {
      return {
        isEdit : state.datamaster.isEdit,
        isLoading : state.datamaster.isLoading,
        initialValues: {
          _id: state.datamaster.showMasterPabrik._id,
          pabrik: state.datamaster.showMasterPabrik.pabrik,
          index: state.datamaster.showMasterPabrik.index,
        },
      };
    }
  };
  class ModalPabrik extends Component {
    constructor(props) {
      super(props);
      this.state = {};
    }
  
    componentDidMount() {
      setTimeout(() => {
        document.getElementById("pabrik").focus();
      }, 100);
    }
    render() {
      return (
        <form
          onSubmit={this.props.handleSubmit}
          onKeyPress={(e) => {
            e.key === "Enter" && e.preventDefault();
          }}
          autoComplete="off"
        >
          <div className="row">
          <Field name="_id" type="hidden" component={HiidenFiled} />
            <div className="col-12">
              <Field
                tabIndex="1"
                id="pabrik"
                name="pabrik"
                component={ReanderField}
                label="Pabrik"
                type="text"
                placeholder="Masukan Pabrik"
              />
            </div>
  
            <div className="col-12 text-right">
              <label> &nbsp; </label>
              <br />
              <button
                className="btn btn-warning"
                type="button"
                onClick={() => this.props.batal()}
              >
                Batal
              </button>
              &nbsp;
              <button
                tabIndex="2"
                className="btn btn-primary"
                disabled={this.props.isLoading}
                type="submit"
              >
                {this.props.isLoading ? (
                  <>
                    <i className="fas fa-spinner fa-spin"></i> &nbsp; Sedang
                    Menyimpan
                  </>
                ) : (
                  "Simpan Data"
                )}
              </button>
            </div>
          </div>
        </form>
      );
    }
  }
  
  ModalPabrik = reduxForm({
    form: "ModalPabrik",
    enableReinitialize: true,
    validate: ValidateMasterBarang,
  })(ModalPabrik);
  export default connect(maptoState)(ModalPabrik);
  