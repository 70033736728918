import { getDataJenis } from "../../../../actions/datamaster_action";
import {
    React,
    connect,
    reduxForm,
    Tabel,
    getTglSystem, formatGram
} from "../../../../components/helpers/library";
import LaporanBarangDalamExcel from "./exel/LaporanKirimSepuhExel";
import LaporanBarangDalam from "../../LaporanBarang/LaporanBarangDalam";
  

  class HeadBarangDalam extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        isLoading: false,
        columns : [
            {
              dataField: "kode_gudang",
              text: "Deskripsi",
              footer : ''
            },
            {
              dataField: "kode_group",
              text: 'Kategori',
              footer : ''
            },

            {
              dataField: "qty",
              text: "Ptg",
              headerClasses: "text-right",
              formatter: (data) => {
                return <div className="text-right"> {data}</div>;
              },
              footer: (columnData) => (
                <div className="text-right">
                  {" "}
                  {columnData.reduce((acc, item) => acc + item, 0)}{" "}
                </div>
              ),
            },
            {
                dataField: "berat",
                text: "Berat",
                headerClasses: "text-right",
                formatter: (data) => {
                    return <div className="text-right"> {formatGram(data, 2)}</div>;
                },
                footer: (columnData) => (
                    <div className="text-right">
                        {" "}
                        {formatGram(columnData.reduce((acc, item) => acc + item, 0), 2)}{" "}
                    </div>
                ),
            },
          ]
      };
    }
    componentDidMount(){
        this.props.dispatch(getDataJenis())
    }
    render() {
      return (
        <form onSubmit={this.props.handleSubmit} autoComplete="off">
        <div className="row">
        <textarea
            style={{ display: "none" }}
            id="buktiSlipInternal"
            rows="100"
            cols="100"
          />
          <div className="col-lg-12 d-none">
            <div className="text-right">
              <label>&nbsp;</label>
              <button
                type="submit"
                value="PDF"
                name="type_btn"
                className="btn btn-primary btn-block"
              >
                {this.props.isLoading ? (
                  <>
                    <i className="fas fa-spinner fa-spin"></i> &nbsp; Sedang
                    Mencari
                  </>
                ) : (
                  "Cari Laporan"
                )}
              </button>
            </div>
          </div>
          <div className="col-12">
            <Tabel
              keyField="no_sepuh"
              data={this.props.export ? this.props.databarang : []}
              columns={this.state.columns}
              empty={true}
              textEmpty="Data Laporan Terima Barang Sepuh"
            />
          </div>
          <div className={this.props.export ? "col-lg-12" : "col-lg-12 d-none"}>
            {/* <label htmlFor="">&nbsp;</label> */}
            <div className="row">
              <div className="col-lg-6">
                <button
                type="button"
                  onClick={() => LaporanBarangDalam(this.props.databarang)}
                  className="btn btn-warning btn-block"
                >
                  {" "}
                  Export PDF{" "}
                </button>
              </div>
              <div className="col-lg-6">
                <LaporanBarangDalamExcel data={this.props.databarang} />
              </div>
            </div>
          </div>
        </div>
      </form>
      );
    }
  }
  
  HeadBarangDalam = reduxForm({
    form: "HeadBarangDalam",
    enableReinitialize: true,
  })(HeadBarangDalam);
  export default connect((state) => {
  return {
    datajenis : state.datamaster.getDataJenis,
    initialValues: {
      tgl_awal: getTglSystem(),
      tgl_akhir: getTglSystem(),
    },
  };
})(HeadBarangDalam);

  