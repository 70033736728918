import {
  React,
  connect,
  Link,
  reduxForm,
  Panel,
  PanelHeader,
  PanelBody,
  setItem,
} from "../../../components/helpers/library";
import HeadSortirSepuh from "./HeadSortirSepuh";

class SortirSepuh extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    // this.print = this.print.bind(this);
  }

  handleSubmit() {}

  componentDidMount() {
    setItem("detail_sortir_sepuh", []);
  }
  componentWillUnmount() {
    localStorage.removeItem("no_ss");
  }
  render() {
    return (
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="#">Sepuhan</Link>
          </li>
          <li className="breadcrumb-item active">Sortir Sepuh</li>
        </ol>
        <h1 className="page-header">Sortir Sepuh </h1>
        <Panel>
          <PanelHeader>Sortir Sepuh</PanelHeader>
          <br />
          {/* Sortir Sepuh */}
          <PanelBody>
            <HeadSortirSepuh />
          </PanelBody>

          <br />
        </Panel>
      </div>
    );
  }
}

SortirSepuh = reduxForm({
  form: "SortirSepuh",
  enableReinitialize: true,
})(SortirSepuh);
export default connect()(SortirSepuh);
