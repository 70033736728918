import React, { Component } from "react";

import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { getDataBaki } from "../../../../actions/datamaster_action";
import { InputDate, ReanderSelect } from "../../../../components/helpers/field";
import { getTglSystem,getDataNoTimeOut2,ToastNotification } from "../../../../components/helpers/library";
import Tabel from "../../../../components/helpers/tabel";
// import Tabel from "../../../../components/helpers/tabel";
import ValidasiTanggalLaporan from "../../../../Validasi/ValidasiTanggalLaporan";
import LaporanKirimLabelingExel from "./exel/LaporanKirimLabelingExel";
import LaporanKirimLabeling from "./pdf/LaporanKirimLabeling";


class HeadKirimLabeling extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      databaki : [],
      columns : [
        {
            dataField: "tgl_system",
            text: "Tanggal",
            footer : ''
          },
        {
          dataField: "no_kirim_stock",
          text: "No Kirim Stock",
          footer : ''
        },
        {
          dataField: "no_terima_sepuh",
          text: "No Terima Sepuh",
          footer : ''
        },
        {
          dataField: "kode_lokasi_toko",
          text: "Kode Baki",
          footer : ''
        },
        {
          dataField: "kode_dept",
          text: "Jenis",
          footer : ''
        },
        {
          dataField: "qty",
          text: "Qty",
          headerClasses: "text-right",
          formatter: (data) => {
            return <div className="text-right"> {data}</div>;
          },
          footer: (columnData) => (
            <div className="text-right">
              {" "}
              {columnData.reduce((acc, item) => acc + item, 0)}{" "}
            </div>
          ),
        },
        {
          dataField: "berat",
          text: "berat",
          headerClasses: "text-right",
          formatter: (data) => {
            return <div className="text-right"> {data.toFixed(2)}</div>;
          },
          footer: (columnData) => (
            <div className="text-right">
              {" "}
              {columnData.reduce((acc, item) => acc + item, 0).toFixed(2)}{" "}
            </div>
          ),
        },
        {
          dataField: "qty_kembali",
          text: "Qty Kembali",
          headerClasses: "text-right",
          formatter: (data) => {
            return <div className="text-right"> {data}</div>;
          },
          footer: (columnData) => (
            <div className="text-right">
              {" "}
              {columnData.reduce((acc, item) => acc + item, 0)}{" "}
            </div>
          ),
        },
  
  
        {
          dataField: "berat_kembali",
          text: "Berat Kembali",
          headerClasses: "text-right",
          formatter: (data) => {
            return <div className="text-right"> {data.toFixed(2)}</div>;
          },
          footer: (columnData) => (
            <div className="text-right">
              {" "}
              {columnData.reduce((acc, item) => acc + item, 0).toFixed(2)}{" "}
            </div>
          ),
        },
        {
          dataField: "action",
          text: "Action",
          csvExport: false,
          headerClasses: "text-center",
          formatter: (rowcontent, row) => {
            return (
              <div className="row text-center">
                <div className="col-12">
                  <button
                    type="button"
                    onClick={() => this.reprint(row)}
                    className="btn btn-primary mr-3"
                  >
                    {" "}
                    <i className="fa fa-print"></i>
                  </button>
                </div>
              </div>
            );
          },
        },
    
       
      ]
    };
    this.setStartDate = this.setStartDate.bind(this);
    this.setLastDate = this.setLastDate.bind(this);
  }
  reprint(row){
    getDataNoTimeOut2("kirim-labeling/reprint?no_kirim_stock="+row.no_kirim_stock).then((res)=>{
      this.print(res.data)
    }).catch((err)=>{
      console.log(err)
      ToastNotification("info","Terjadi Kesalahan Saat Mengirim Data, Silahkan Ulangi Lagi !!")
    })
  }
print(data){
let datasepuh = [];
let nama = "";
for (let IndexAwal = 0; IndexAwal < 3; IndexAwal++) {
if(IndexAwal===0){
  nama = "INTERNAL"
}else if(IndexAwal===1){
  nama = "LABEL"
}else{
  nama = "TUKANG"
}
let feedback = '';
feedback += `========================================\n`
feedback += `             Kirim LABELING             \n`
feedback += `                ${nama}                \n`
feedback += `========================================
No Kirim Stock   : ${data.no_kirim_stock}
Cabang           : ${data.cabang}
No Terimas Sepuh : ${data.no_terima_sepuh}
Tanggal Kirim    : ${data.tgl_system}
Tujuan           : ${data.baki_tujuan}
\n`
data.bukti_baki.forEach(element => {
feedback += `
Baki             : ${element.baki || "-"}
Jenis            : ${element.jenis || "-"}
Qty Kirim        : ${element.qty_kirim || "-"}
Brt Kirim        : ${element.berat_kirim || "-"}
Brt Atribut      : ${element.berat_atribut || "-"}
`  
});
feedback += `
Tot Qty Kirim   : ${data.bukti_baki.reduce((a,b)=>a+b.qty_kirim,0)}
Tot Brt Kirim   : ${data.bukti_baki.reduce((a,b)=>a+b.berat_kirim,0)}
`
feedback += `========================================\n`
feedback += `              TERIMA KASIH              \n`
feedback += `========================================`
feedback += `






VA\n`;
datasepuh.push(feedback);
}
// console.log(datasepuh);
document.getElementById("myInput").value = datasepuh.join('');
const element = document.createElement("a");
const file = new Blob([document.getElementById("myInput").value], {
type: "text/plain;charset=utf-8",
});
element.href = URL.createObjectURL(file);
element.download = "autoprint_pembelian.txt";
document.body.appendChild(element);
element.click();
}
  exportPdf() {
    LaporanKirimLabeling(this.props.databarang)
  }
  setStartDate(date) {
    this.setState({
      tgl_awal: new Date(date),
    });
  }
  setLastDate(date) {
    this.setState({
      tgl_akhir: new Date(date),
    });
  }

  componentDidMount(){
    this.props.dispatch(getDataBaki())
    this.props.change('tgl_awal',getTglSystem())
    this.props.change('tgl_akhir',getTglSystem())
    this.props.change('kode_baki',"ALL")
    
  }
  render() {
    let DataBaki = [
      {
        value: "ALL",
        name: "SEMUA",
      },
    ];
    this.props.databaki.forEach((list) => {
      let hasil = {
        value: list.kode_baki,
        name: list.kode_baki,
      };
      DataBaki.push(hasil);
    });


    
  
    return (
      <form onSubmit={this.props.handleSubmit} autoComplete="off">
        <div className="row">
          <div className="col-lg-3">
          <textarea
            style={{ display: "none" }}
            id="myInput"
            rows="100"
            cols="100"
          />
            <Field
              name="kode_baki"
              component={ReanderSelect}
              label="Kode Baki"
              type="text"
              options={DataBaki}
              placeholder="Masukan Kode Baki"
              // customInput={<CustomeInputDate />}
            />
          </div>
          <div className="col-lg-3">
            <Field
              name="tgl_awal"
              component={InputDate}
              label="Tanggal Dari"
              type="text"
              selected={this.state.tgl_awal}
              onChange={(date) => this.setStartDate(date)}
              placeholder="Masukan Tanggal Dari"
            />
          </div>

          <div className="col-lg-3">
            <Field
              name="tgl_akhir"
              component={InputDate}
              type="text"
              selected={this.state.tgl_akhir}
              onChange={(date) => this.setLastDate(date)}
              label="Tanggal Akhir"
              placeholder="Masukan Tanggal Akhir"
            />
          </div>

          <div className="col-lg-3">
            <div className="text-right">
              <label>&nbsp;</label>
              <button
                type="submit"
                value="PDF"
                name="type_btn"
                className="btn btn-primary btn-block"
              >
                {this.props.isLoading ? (
                  <>
                    <i className="fas fa-spinner fa-spin"></i> &nbsp; Sedang
                    Mencari
                  </>
                ) : (
                  "Cari Laporan"
                )}
              </button>
            </div>
          </div>
          <div className="col-12">
            <Tabel
              keyField="no_terima_sepuh"
              data={this.props.export ? this.props.dataterimabarang : []}
              columns={this.state.columns}
              empty={true}
              textEmpty="Data Laporan Terima Barang Sepuh"
            />
          </div>
          <div className={this.props.export ? "col-lg-12" : "col-lg-12 d-none"}>
            {/* <label htmlFor="">&nbsp;</label> */}
            <div className="row">
              <div className="col-lg-6">
                <button
                type="button"
                  onClick={() => this.exportPdf()}
                  className="btn btn-warning btn-block"
                >
                  {" "}
                  Export PDF{" "}
                </button>
              </div>
              <div className="col-lg-6">
                <LaporanKirimLabelingExel data={this.props.databarang} />
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

HeadKirimLabeling = reduxForm({
  form: "HeadKirimLabeling",
  enableReinitialize: true,
  validate: ValidasiTanggalLaporan,
})(HeadKirimLabeling);
// export default connect()(HeadKirimLabeling);
export default connect((state) => {
  return {
    databaki : state.datamaster.getDataBaki,
    initialValues: {
      tgl_awal: getTglSystem(),
      tgl_akhir: getTglSystem(),
    },
  };
})(HeadKirimLabeling);
