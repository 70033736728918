const ValidasiFilterTambahBarang = (value) => {
    const errors = {};
    if (!value.kode_kategori) {
        errors.kode_kategori = "Kategori Tidak Boleh Kosong";
    }
    if (!value.kode_jenis) {
        errors.kode_jenis = "Jenis Tidak Boleh Kosong";
    }
    if (!value.kode_lokasi_toko) {
        errors.kode_lokasi_toko = "Kode Lokasi Tidak Boleh Kosong";
    }
    if (!value.tipe_barang) {
        errors.tipe_barang = "Tipe Barang Tidak Boleh Kosong";
    }

 
    if (value.nama_barang === undefined || "") {
        errors.nama_barang = "Nama Barang Tidak Boleh Kosong";
    }
    if (value.berat === undefined || "") {
        errors.berat = "Berat Tidak Boleh Kosong";
    }
    if (value.berat_asli === undefined || "") {
        errors.berat_asli = "Berat Asli Tidak Boleh Kosong";
    }
    if (value.kadar === undefined || "") {
        errors.kadar = "Kadar Tidak Boleh Kosong";
    }
    if (value.kadar_cetak === undefined || "") {
        errors.kadar_cetak = "Kadar Cetak Tidak Boleh Kosong";
    }
    if (value.kadar_modal === undefined || "") {
        errors.kadar_modal = "Kadar Modal Tidak Boleh Kosong";
    }
    if (!value.tujuan) {
        errors.tujuan = "Tujuan Tidak Boleh Kosong";
    }
    if (value.berat_cad=== undefined || "") {
        errors.berat_cad = "Berat Cad Tidak Boleh Kosong";
    }
    if (value.berat_susut === undefined || "") {
        errors.berat_susut = "Berat Susut Tidak Boleh Kosong";
    }
    // if (!value.berat_bandrol) {
    //     errors.berat_bandrol = "Berat Bandrol Tidak Boleh Kosong";
    // }

    return errors;
}
export default ValidasiFilterTambahBarang;


