import {
  React,
  Field,
  reduxForm,
  connect,
  Component,
  ReanderField,
  ReanderSelect,
  getItem,
  getToday,
  getData,
} from "../../../components/helpers/library";
import HeadStockPembelian from "../../../Validasi/StockPembelian/HeadStockPembelian";

const maptostate = (state) => {
  let detail = getItem("data_detail_kirim_barang_bos");
  return {
    kadar: state.datamaster.getDataKadar,
    initialValues: {
      tanggal_kirim: detail.tanggal_kirim ? detail.tanggal_kirim : getToday(),
      kadar: detail.kadar || "",
      sales_pabrik: detail.sales_pabrik || "",
      tujuan: detail.tujuan || "",
    },
  };
};
class HeadDetailKirim extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tujuan: [],
    };
  }
  setStartDate(date) {
    this.setState({
      tanggal_kirim: new Date(date),
    });
  }

  componentDidMount() {
    getData("baki/get/by-kode-gudang/KC").then((res) => {
      this.setState({
        tujuan: res.data,
      });
    });
    this.props.change("tujuan","NISA1")
  }
  render() {
    return (
      <form
        onSubmit={this.props.handleSubmit}
        onKeyPress={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
        autoComplete="off"
      >
        <div className="row">
      
          <div className="col-6">
            <Field
              label="Type Barang"
              name="kadar"
              // options={this.props.kadar}
              options={
                [
                  {
                    value: "A",
                    name: "A",
                  },
                  {
                    value: "SP",
                    name: "SP",
                  }
                ]
              }
              placeholder={"Silahkan Pilih Type Barang"}
              component={ReanderSelect}
            />
           
          </div>
          <div className="col-6">
            <Field
              name="sales_pabrik"
              component={ReanderField}
              label="Sales Pabrik"
              type="text"
              placeholder="Masukan Sales Pabrik"
            />
          </div>
          <div className="col-12">
            <Field
              label="Tujuan"
              name="tujuan"
              options={this.state.tujuan
                .filter((list) => list.kode_baki !== undefined)
                .map((list) => {
                  let data = {
                    value: list.kode_baki,
                    name: list.kode_baki,
                  };
                  return data;
                })}
              placeholder={"Silahkan Pilih Tujuan"}
              component={ReanderSelect}
            />
          </div>
          <div className="col-12 text-right">
            <label> &nbsp; </label>
            <br />
            <button
              className="btn btn-primary btn-block"
              disabled={this.props.isLoadingTambahDetailKirim}
              type="submit"
            >
              {this.props.isLoadingTambahDetailKirim ? (
                <>
                  <i className="fas fa-spinner fa-spin"></i> &nbsp; Sedang
                  Menyimpan
                </>
              ) : getItem("data_detail_kirim_barang_bos").tanggal_kirim ? (
                "Edit Data"
              ) : (
                "Simpan Data"
              )}
            </button>
          </div>
        </div>
      </form>
    );
  }
}

HeadDetailKirim = reduxForm({
  form: "HeadDetailKirim",
  enableReinitialize: true,
  validate: HeadStockPembelian,
})(HeadDetailKirim);
export default connect(maptostate)(HeadDetailKirim);
