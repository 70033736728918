import {
  React,
  connect,
  Field,
  reduxForm,
  ReanderField,
} from "../../../components/helpers/library";
import { NumberOnly, Gramasi } from "./../../../Validasi/normalize";
import Tabel from './../../../components/helpers/tabel';

class HeadStockOpnameBos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      columns: [
        {
          dataField: "no_kirim_bos",
          text: "No Kirim Bos",
        }
      ]
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    // this.print = this.print.bind(this);
  }

  handleSubmit() {}

  render() {
    return (
      <form
        onSubmit={this.props.handleSubmit}
        onKeyPress={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
        autoComplete="off"
      >
        <div className="row">
          <div className="col-lg-3">
            <Field
              id="no_kirim_bos"
              name="no_kirim_bos"
              component={ReanderField}
              label="No Kirim Bos"
              type="text"
              placeholder="Masukan No Kirim Bos"
            />
          </div>
          <div className="col-lg-3">
            <Field
              id="qty_opname"
              name="qty_opname"
              component={ReanderField}
              label="Qty Opname"
              normalize={NumberOnly}
              type="text"
              placeholder="Masukan Qty Opname"
            />
          </div>

          <div className="col-lg-3">
            <Field
              id="berat_opname"
              name="berat_opname"
              component={ReanderField}
              label="Berat Opname"
              normalize={Gramasi}
              type="text"
              placeholder="Masukan Berat Opname"
            />
          </div>

          <div className="col-lg-3 mt-4">
            <button
              className="btn btn-primary btn-block"
              disabled={this.props.isLoading}
              type="submit"
            >
              {this.props.isLoading ? (
                <>
                  <i className="fas fa-spinner fa-spin"></i> &nbsp; Sedang
                  Mencari
                </>
              ) : (
                "Cari Data"
              )}
            </button>
          </div>
          <div className="col-lg-12 mt-4">
            <Tabel
              keyField="kode_barcode"
              data={[]}
              columns={this.state.columns}
              empty={true}
              textEmpty="Data Barang Kosong"
            />
          </div>
        </div>
      </form>
    );
  }
}

HeadStockOpnameBos = reduxForm({
  form: "HeadStockOpnameBos",
  enableReinitialize: true,
})(HeadStockOpnameBos);
export default connect()(HeadStockOpnameBos);
