import React, { Component } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import {
  getUserdata,
  localStoragedecryp,
} from "../../../../../components/helpers/function";
class LaporanBahanTukangExel extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="btn btn-success btn-block"
          table="table-to-xls"
          filename="LAPORAN BAHAN TUKANG"
          sheet="LAPORAN BAHAN TUKANG"
          buttonText="Export Exel"
        />
        <table id="table-to-xls" style={{ display: "none" }}>
          <thead>
            <tr>
              <th colSpan="12" style={{ textAlign: "center" }}>
                {" "}
                LAPORAN BAHAN TUKANG{" "}
              </th>
            </tr>
            <tr>
              <th colSpan="12"> Periode </th>
            </tr>
            <tr>
              <th colSpan="12">
                {" "}
                {localStoragedecryp("tgl_laporan", [], true).length === 0
                  ? null
                  : localStoragedecryp("tgl_laporan", [], true).tgl_awal +
                    " s/d " +
                    localStoragedecryp("tgl_laporan", [], true).tgl_akhir}{" "}
              </th>
            </tr>
            <tr>
              <th colSpan="12"> TOKO : {getUserdata().nama_toko} </th>
            </tr>

            <tr>
              <th colSpan="12"> ALAMAT : {getUserdata().alamat_toko} </th>
            </tr>
            <tr></tr>
            <tr>
              <td style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                KODE TUKANG
              </td>
              <td style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                KATEGORI
              </td>
              <td style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                KATEGORI
              </td>
              <td style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                KETERANGAN
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                SUSUT
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                PATRI
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                BAHAN
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                PAKAI
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                PATRI AMB
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                BAHAN AMB
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                SISA BAHAN
              </td>
            </tr>
          </thead>
          <tbody style={{ display: this.props.dataexel ? "none" : "" }}>
            {/* {console.log(this.props.dataexel.length)} */}
            {this.props.dataexel.map((row, index) => (
              <tr key={index}>
                <td>{row.kode_tukang}</td>
                <td>{row.kode_trx}</td>
                <td>{row.detail_kategori}</td>
                <td>{row.keterangan.toUpperCase()}</td>
                <td style={{ textAlign: "right" }}>
                  &nbsp;{row.susut.toLocaleString("kr-KO")}
                </td>
                <td style={{ textAlign: "right" }}>
                  &nbsp;{row.patri.toLocaleString("kr-KO")}
                </td>
                <td style={{ textAlign: "right" }}>
                  &nbsp;{row.bahan.toLocaleString("kr-KO")}
                </td>
                <td style={{ textAlign: "right" }}>
                  &nbsp;{row.pakai.toLocaleString("kr-KO")}
                </td>
                <td style={{ textAlign: "right" }}>
                  &nbsp;{row.patri_amb.toLocaleString("kr-KO")}
                </td>
                <td style={{ textAlign: "right" }}>
                  &nbsp;{row.bahan_amb.toLocaleString("kr-KO")}
                </td>
                <td style={{ textAlign: "right" }}>
                  &nbsp;{row.sisa_bhn.toFixed(3)}
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td
                colSpan="4"
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "center",
                }}
              >
                Grand Total :
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                {" "}
                &nbsp;
                {this.props.dataexel
                  .map((list) => list.susut)
                  .reduce((a, b) => a + b, 0)
                  .toLocaleString("ko-KO")}{" "}
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                {" "}
                &nbsp;
                {this.props.dataexel
                  .map((list) => list.patri)
                  .reduce((a, b) => a + b, 0)
                  .toLocaleString("ko-KO")}{" "}
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                {" "}
                &nbsp;
                {this.props.dataexel
                  .map((list) => list.bahan)
                  .reduce((a, b) => a + b, 0)
                  .toLocaleString("ko-KO")}{" "}
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                {" "}
                &nbsp;
                {this.props.dataexel
                  .map((list) => list.pakai)
                  .reduce((a, b) => a + b, 0)
                  .toLocaleString("ko-KO")}{" "}
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                {" "}
                &nbsp;
                {this.props.dataexel
                  .map((list) => list.patri_amb)
                  .reduce((a, b) => a + b, 0)
                  .toLocaleString("ko-KO")}{" "}
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                {" "}
                &nbsp;
                {this.props.dataexel
                  .map((list) => list.bahan_amb)
                  .reduce((a, b) => a + b, 0)
                  .toLocaleString("ko-KO")}{" "}
              </td>
              <td
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                {" "}
              </td>
            </tr>
          </tfoot>
        </table>
      </>
    );
  }
}
export default LaporanBahanTukangExel;
