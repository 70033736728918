import {
  React,
  Component,
  Camera,
  HiidenFiled,
  ReanderField,
  createNumberMask,
  Field,
  reduxForm,
  connect,
  showModal,
  ToastNotification,
  getData,
  Gramasi,
  imgfound,
  hideModal,
  formValueSelector,
  ReanderSelect,
} from "../../../../components/helpers/library";
import { getData2, postData2 } from "../../../../config/axios";
import ValidasiFilterTambahBarang from "../../../../Validasi/Barang/TambahBarang/ValidasiFilterTambahBarang";
import { setCamera } from "../Module";
import Loading from 'react-fullscreen-loading';
import { Kadar } from "../../../../Validasi/normalize";
import { dataURLtoFile, resizeFile } from "../../../../components/helpers/function";
import { getDataJenis, getMasterToko } from "../../../../actions/datamaster_action";
// import { getTimbangan } from "../../../../actions/utility_action";



const currencyMask = createNumberMask({
  prefix: "Rp. ",
  locale: "id-ID",
});

class FormTambahBarang extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: true,
      ModalWebCam: false,
      disabled: false,
      loadimage: imgfound,
      isLoadingBaki: false,
      isLoadingJenis: false,
      kategori_harga: "",
      tgl_awal: new Date(),
      tgl_akhir: new Date(),
      databaki: [],
      DataGroup: [],
      DataJenis: [],
    };
  }
  tgl_awalChange = (date) => {
    this.setState({
      tgl_awal: date,
    });
  };
  tgl_akhirChange = (date) => {
    this.setState({
      tgl_akhir: date,
    });
  };
  showModalWebcam() {
    this.props.dispatch(setCamera(false));
    this.props.dispatch(showModal());
    this.setState({ ModalWebCam: true });
  }

  async handleTakePhoto(url) {
    try {
      document.getElementById("kode_intern").focus();
      const file = dataURLtoFile(url);
      const res = await resizeFile(file);
      // console.log(res);
      this.props.dispatch(setCamera(res));
      this.props.change("foto", res);
    } catch (err) {
      // console.log(err);
    }

  }
  async componentWillUnmount() {
    localStorage.removeItem("estimasi_harga_tambah")


  }

  componentDidMount() {
    localStorage.removeItem("estimasi_harga_tambah")
    this.props.dispatch(getDataJenis());

    this.props.dispatch(setCamera(false));
    this.props.change("foto", "");

    getData("baki/tambah-barang").then((res) => {
      this.setState({
        databaki: res.data,
      });
    });
    getData2("para-system/key/kategori_harga").then((res) => {
      // console.log(res.data.value);
      if (res.data.value === "harga_100%") {
        this.setState({
          kategori_harga: true,
        });
      } else {
        this.setState({
          kategori_harga: false,
        });
      }

    });
    // let presentase = 0;
    // getData("group/get/by-kode-group?kode_group="+this.props.kode_group).then((res)=>{
    //   presentase = res.data.persentase || 0;
    //   this.props.change("kadar", res.data.persentase || 0);
    // })

    this.props.dispatch(getMasterToko())

    // let kode = this.props.DataBarang.kode_lokasi_toko.split("~");
    // console.log('Didmount');
    // console.log(this.props.DataBarang);
    // kode_kategori: state.databarang.showEditBarang.kode_kategori,
    // kode_jenis: state.databarang.showEditBarang.kode_jenis,
    // kode_lokasi_toko: kode[0],
    // kode_gudang: kode[1],
    this.props.change('kode_kategori', this.props.DataBarang.kode_kategori)
    // this.props.change('kode_jenis', this.props.DataBarang.kode_jenis )
    // this.props.change('kode_lokasi_toko', kode[0])
    // this.props.change('kode_gudang', kode[1])
    // this.props.change('berat', this.props.DataBarang.berat)
    // this.props.change('berat_asli', this.props.DataBarang.berat_asli)
    this.props.change('berat_susut', this.props.DataBarang.berat_susut)
    // this.props.change('tujuan', this.props.DataBarang.tujuan)
    this.props.change('susut_tukang', this.props.DataBarang.susut_tukang)
    this.props.change('berat_cad', this.props.DataBarang.berat_cad)
    // this.props.change('berat_customer', 0)
    this.props.change('kode_intern', this.props.DataBarang.kode_intern)
    this.props.change('kode_barcode', this.props.DataBarang.kode_barcode)
    this.props.change('nama_barang', this.props.DataBarang.nama_barang)
    this.props.change('ongkos', this.props.DataBarang.ongkos)
    // this.props.change('kadar', presentase)
    this.props.change('kadar_cetak', this.props.DataBarang.kadar_cetak)
    this.props.change('kadar_modal', this.props.DataBarang.kadar_modal)
    this.props.change('nama_atribut', this.props.DataBarang.nama_atribut)
    this.props.change('berat_atribut', this.props.DataBarang.berat_atribut)
    this.props.change('berat_bandrol', this.props.DataBarang.berat_bandrol)
    this.props.change('harga_atribut', this.props.DataBarang.harga_atribut)
    this.props.change('foto', this.props.DataBarang.foto)

    this.props.change('tag_id', this.props.DataBarang.tag_id || "-")


    setTimeout(() => {
      getData("parabandrol/get/all").then((res) => {
        // console.log(res.data);
        if (res.data.length !== 0) {
          this.props.change("berat_bandrol", res.data[0].berat_bandrol);
        } else {
          this.props.change("berat_bandrol", 0);
        }
      });
      document.getElementById("kode_intern").focus();
    }, 100);

  }

  getValue = async (event) => {
    try {
      document.getElementById("kode_intern").focus();
      const file = event.target.files[0];
      const res = await resizeFile(file);
      // console.log(res);
      this.props.dispatch(setCamera(res));
      this.props.change("foto", res);
    } catch (err) {
      // console.log(err);
    }
  };

  getJenis(params) {
    this.setState({ isLoadingJenis: true });
    getData("jenis/get/by-kode-kategori/" + params)
      .then((res) => {
        this.setState({
          DataJenis: res.data,
        });
      })
      .then((res) => {
        this.setState({
          isLoadingJenis: false,
        });
      })
      .catch((err) => {
        ToastNotification("info", err.data);
        console.log(err);
      });
  }
  getBaki(params) {
    this.setState({ isLoadingBaki: true });
    getData("baki/get/by-kode-baki/" + params)
      .then((res) => {
        this.setState({
          databaki: res.data === "" ? [] : [res.data],
        });
      })
      .then((res) => {
        this.setState({
          isLoadingBaki: false,
        });
      })
      .catch((err) => {
        ToastNotification("info", err.data);
        console.log(err);
      });
  }
  loadcamera() {
    return (
      <Camera
        isImageMirror={true}
        onTakePhoto={(dataUri) => {
          this.handleTakePhoto(dataUri);
        }}
      />
    );
  }
  cekTotal() {
    if (this.props.status === "PESANAN") {
      let row = {
        kode_group: this.props.kode_group,
        berat: parseFloat(document.getElementById("berat").value || 0),
        kadar: parseInt(document.getElementById("kadar").value || 0)
      }
      postData2('tambah-barang/calculate', row).then((res) => {
        // console.log(res.data.harga_jual + this.props.harga_atribut + this.props.ongkos);
        // console.log(this.props.harga_atribut);
        // let harga = getItem("estimasi_harga_tambah") || [];
        // console.log(harga.length);
        // if (harga.length === 0) {
        // setItem('estimasi_harga_tambah',res.data.harga_jual + this.props.harga_atribut + this.props.ongkos)
        // this.props.change("harga_estimasi", getItem('estimasi_harga_tambah'))
        // } 
        this.props.change("harga_total", res.data.harga_jual + this.props.harga_atribut + this.props.ongkos)
        this.props.change("harga_total_tmp", res.data.harga_jual)
      })
    }
    // console.log(e);
  }
  hitungCalculate() {
    if (this.props.status === "PESANAN") {
      this.props.change("harga_total", this.props.harga_total)
    }
  }

  celSusut(e) {
    let susut_tukang = document.getElementById('susut_tukang').value;

    if (parseFloat(e.target.value || 0) > parseFloat(susut_tukang)) {
      this.setState({
        disabled: true
      })
      ToastNotification("info", 'Berat Susut Tidak Boleh Lebih Dari Susut Tukang')
      return false
    } else {
      this.setState({
        disabled: false
      })
    }
  }
  kodeLokasiToko(e) {
    let kode = e.split("~");
    this.props.change('kode_gudang', kode[1])
    // console.log(kode);
  }
  render() {
    return (
      <>
        <form onSubmit={this.props.handleSubmit} autoComplete="off">
          <div className="row">
            <div className="col-lg-12 text-center">
              {this.state.ModalWebCam === true ? (
                this.props.setCamera === false ? (
                  <div className="container">{this.loadcamera()}</div>
                ) : (
                  <>
                    <img
                      src={
                        this.props.foto === undefined
                          ? this.props.setCamera === false
                            ? imgfound
                            : this.props.setCamera
                          : this.props.setCamera
                            ? this.props.setCamera
                            : this.props.foto
                      }
                      alt="img"
                      height="245px"
                      width="355px"
                    />
                  </>
                )
              ) : (
                <>
                  <img
                    src={
                      this.props.foto === undefined
                        ? this.props.setCamera === false
                          ? imgfound
                          : this.props.setCamera
                        : this.props.setCamera
                          ? this.props.setCamera
                          : this.props.foto
                    }
                    alt="img"
                    height="245px"
                    width="355px"
                  />
                </>
              )}
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-2"> </div>
            <div className="col-5">
              <div className="input-group">
                <div className="custom-file">
                  <input
                    tabIndex="1"
                    type="file"
                    name="photo"
                    autoComplete="off"
                    className="custom-file-input"
                    id="exampleInputFile"
                    onChange={(e) => this.getValue(e)}
                  />
                  <label
                    className="custom-file-label"
                    htmlFor="exampleInputFile"
                  >
                    Pilih gambar
                  </label>
                </div>
              </div>
            </div>
            <div className="col-3">
              <button
                tabIndex="2"
                className="btn btn-block btn-primary"
                type="button"
                onClick={() => this.showModalWebcam()}
              >
                {" "}
                WebCam{" "}
              </button>
            </div>
            <hr />
          </div>
          <hr />
          <div className="row">
            <div className="col-lg-4">
              <Field
                readOnly={true}
                id="kode_kategori"
                name="kode_kategori"
                component={ReanderField}
                label="Kategori"
                placeholder="Masukan Kategori"
                onFocus={(e) => e.target.select()}
                onClick={(e) => e.target.select()}
              />
            </div>
            <div className="col-lg-4">
              <Field
                id="kode_jenis"
                name="kode_jenis"
                component={ReanderSelect}
                options={this.props.DataJenis.filter(
                  (list) => list.kode_group === this.props.kode_group
                ).map((list) => {
                  let data = {
                    value: list.kode_dept,
                    name: list.kode_dept,
                  };
                  return data;
                })}
                label="Kode Jenis"
                placeholder="Silahkan Pilih Kode Jenis"
              />
              {/* <Field
                readOnly={true}
                name="kode_jenis"
                component={ReanderField}
                label="Jenis"
                placeholder="Masukan Jenis"
                onFocus={(e) => e.target.select()}
                onClick={(e) => e.target.select()}
              /> */}
            </div>
            <div className="col-lg-4">
              <Field
                id="kode_lokasi_toko"
                name="kode_lokasi_toko"
                component={ReanderSelect}
                onChange={(e) => this.kodeLokasiToko(e)}
                options={this.state.databaki
                  .filter((list) => list.kode_baki !== undefined)
                  .map((list) => {
                    let data = {
                      value: list.kode_baki + "~" + list.kode_gudang,
                      name: list.kode_baki,
                    };
                    return data;
                  })}
                label="Kode Baki"
                placeholder="Silahkan Pilih Kode Lokasi"
              />
              {/* <Field
                readOnly={true}
                name="kode_lokasi_toko"
                component={ReanderField}
                label="Kode Lokasi Toko"
                placeholder="Masukan Lokasi"
                onFocus={(e) => e.target.select()}
                onClick={(e) => e.target.select()}
              /> */}
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-6">
              <Field
                readOnly={true}
                id="ongkos"
                name="ongkos"
                type="hidden"
                component={HiidenFiled}
              />
              <Field
                readOnly={true}
                name="harga_total_tmp"
                type="hidden"
                component={HiidenFiled}
              />
              <Field
                readOnly={true}
                name="status_pesanan"
                type="hidden"
                component={HiidenFiled}
              />
              <Field
                readOnly={true}
                name="kode_gudang"
                type="hidden"
                component={HiidenFiled}
              />
              <Field
                readOnly={true}
                name="foto"
                type="hidden"
                component={HiidenFiled}
              />
              <Field
                readOnly={true}
                name="kode_barcode"
                type="hidden"
                component={HiidenFiled}
              />

              <Field
                id="kode_intern"
                tabIndex="3"
                onFocus={(e) => this.cekTotal()}
                name="kode_intern"
                component={ReanderField}
                label="Kode Intern"
                placeholder="Masukan Kode Intern"
                onClick={(e) => e.target.select()}
              />

            </div>
            <div className={this.props.status === "PESANAN" ? "col-6" : "col-6 d-none"} >
              <Field
                id="no_pesanan"
                tabIndex="3"
                name="no_pesanan"
                component={ReanderField}
                readOnly={true}
                label="No Pesanan"
                placeholder="Masukan No Pesanan"
                onFocus={(e) => e.target.select()}
                onClick={(e) => e.target.select()}
              />
            </div>
            <div className="col-12">
              <Field
                tabIndex="4"
                name="nama_barang"
                component={ReanderField}
                label="Nama Barang"
                placeholder="Masukan Nama Barang"
                onFocus={(e) => e.target.select()}
                onClick={(e) => e.target.select()}
              />
            </div>
            <div className="col-lg-6">
              {this.props.status === "PESANAN" ?
                <>
                  <Field
                    tabIndex="5"
                    type="text"
                    name="berat_awal"
                    component={ReanderField}
                    // readOnly={this.props.status === "PESANAN" ? true : false}
                    label="Berat Awal"
                    normalize={Gramasi}
                    readOnly={true}
                    placeholder="Masukan Berat Awal"
                    onFocus={(e) => e.target.select()}
                    onClick={(e) => e.target.select()}
                    onBlur={(e) => this.props.change("berat", e.target.value)}
                  />

                </>

                : <>
                  <Field
                    tabIndex="5"
                    type="text"
                    name="berat_asli"
                    component={ReanderField}
                    // readOnly={this.props.status === "PESANAN" ? true : false}
                    label="Berat Asli"
                    normalize={Gramasi}
                    placeholder="Masukan Berat Asli"
                    onFocus={(e) => e.target.select()}
                    onClick={(e) => e.target.select()}
                    onBlur={(e) => this.props.change("berat", e.target.value)}
                  />
                </>}

            </div>
            <div className="col-lg-6">
              <Field
                id="berat"
                tabIndex="6"
                type="text"
                name="berat"
                component={ReanderField}
                label={this.props.status === "PESANAN" ? "Berat Tukang" : "Berat"}
                normalize={Gramasi}
                placeholder={this.props.status === "PESANAN" ? "Masukan Berat Tukang" : "Masukan Berat"}
                onFocus={(e) => this.cekTotal()}
                onClick={(e) => e.target.select()}
                onBlur={() => this.cekTotal()}
              />
            </div>
            <div className="col-lg-6">
              <Field
                id="susut_tukang"
                tabIndex="6"
                type="text"
                name="susut_tukang"
                readOnly
                label="Susut Tukang"
                component={ReanderField}
                normalize={Gramasi}
                onClick={(e) => e.target.select()}
              />
            </div>
            <div className={this.props.status === "PESANAN" ? "col-lg-6" : "d-none"}>
              <Field
                id="berat_customer"
                tabIndex="6"
                type="text"
                name="berat_customer"
                label="Berat Customer"
                component={ReanderField}
                normalize={Gramasi}
              // onChange={(e)=>this.celSusut(e)}
              // onFocus={(e) => e.target.select()}
              // onClick={(e) => e.target.select()}
              />
            </div>
            <div className="col-lg-6">
              <Field
                id="berat_susut"
                tabIndex="6"
                type="text"
                name="berat_susut"
                label="Berat Susut"
                component={ReanderField}
                normalize={Gramasi}
                onChange={(e) => this.celSusut(e)}
                onFocus={(e) => e.target.select()}
                onClick={(e) => e.target.select()}
              />
            </div>
            <div className="col-lg-6">
              <Field
                id="berat_cad"
                tabIndex="6"
                type="text"
                label="Berat Cad"
                name="berat_cad"
                component={ReanderField}
                normalize={Gramasi}
                onClick={(e) => e.target.select()}
              />
            </div>
            <div
              className={
                this.state.kategori_harga ? "col-lg-6" : "col-lg-6"
              }
            >
              <Field
                id="kadar"
                tabIndex="7"
                type="text"
                name="kadar"
                component={ReanderField}
                label="Kadar"
                normalize={Kadar}
                placeholder="Masukan Kadar"
                onFocus={(e) => e.target.select()}
                onClick={(e) => e.target.select()}
                onChange={() => this.cekTotal()}
              />
            </div>
            <div className="col-lg-6">
              <Field
                tabIndex="8"
                type="text"
                name="kadar_cetak"
                component={ReanderField}
                label="Kadar Cetak"
                normalize={Kadar}
                placeholder="Masukan Kadar Cetak"
                onFocus={(e) => e.target.select()}
                onClick={(e) => e.target.select()}
                onChange={() => this.cekTotal()}
              />
            </div>
            <div className="col-lg-6">
              <Field
                tabIndex="9"
                type="text"
                name="kadar_modal"
                normalize={Kadar}
                component={ReanderField}
                label="Kadar Modal"
                placeholder="Masukan Kadar Modal"
                onFocus={(e) => e.target.select()}
                onClick={(e) => e.target.select()}
              />
            </div>
            <div className="col-lg-6">
              <Field
                tabIndex="10"
                type="text"
                name="tipe_barang"
                component={ReanderSelect}
                label="Tipe Barang"
                placeholder="Pilih Tipe Barang"
                options={
                  [
                    {
                      value: "SP",
                      name: "SP",
                    },
                    {
                      value: "A",
                      name: "A",
                    }
                  ]
                }
              />
            </div>
          </div>

          <hr />
          <div className="row">
            <div className="col-6">
              <Field
                id="tujuan"
                tabIndex="10"
                type="text"
                name="tujuan"
                component={ReanderSelect}
                options={
                  this.props.DataToko.map((list) => {
                    let row = {
                      value: list.kode_toko,
                      name: list.kode_toko,
                    }

                    return row
                  })
                }
                label="Tujuan"
                placeholder="Masukan Tujuan"
                onFocus={(e) => e.target.select()}
                onClick={(e) => e.target.select()}
              />
            </div>
            <div className="col-6">
              <Field
                id="nama_atribut"
                tabIndex="10"
                type="text"
                name="nama_atribut"
                component={ReanderField}
                label="Nama Atribut"
                placeholder="Masukan Nama Atribut"
                onFocus={(e) => e.target.select()}
                onClick={(e) => e.target.select()}
              />
            </div>
            <div className="col-lg-6">
              <Field
                tabIndex="11"
                type="text"
                name="berat_atribut"
                component={ReanderField}
                label="Berat Atribut"
                normalize={Gramasi}
                placeholder="Masukan Berat Atribut"
                onFocus={(e) => e.target.select()}
                onClick={(e) => e.target.select()}
              />
            </div>
            <div className="col-lg-6 d-none">
              <Field
                tabIndex="12"
                id="harga_atribut"
                name="harga_atribut"
                component={ReanderField}
                {...currencyMask}
                label="Harga Atribut"
                placeholder="Masukan Harga Atribut"
                onFocus={(e) => e.target.select()}
                onClick={(e) => e.target.select()}
                onChange={this.hitungCalculate()}
              />
            </div>
            <div className="col-lg-6">
              <Field
                tabIndex="13"
                type="text"
                name="berat_plastik"
                component={ReanderField}
                label="Berat Plastik"
                normalize={Gramasi}
                placeholder="Masukan Berat Plastik"
                onFocus={(e) => e.target.select()}
                onClick={(e) => e.target.select()}
              />
            </div>
            {this.props.status === "PESANAN" ? <>
              <div className="col-lg-6 d-none">
                <Field
                  tabIndex="13"
                  type="text"
                  name="harga_estimasi"
                  component={ReanderField}
                  label="Harga Estimasi"
                  // normalize={Gramasi}
                  {...currencyMask}
                  placeholder="Harga Estimasi"
                  readOnly={true}
                />

              </div>
              <div className="col-6">
              </div>
              <div className="col-6 d-none">
                <Field
                  tabIndex="13"
                  type="text"
                  name="harga_total"
                  component={ReanderField}
                  label="Harga Total"
                  // normalize={Gramasi}
                  {...currencyMask}
                  placeholder="Harga Total"
                // readOnly={true}
                />
              </div>
            </> : null
            }
          </div>
          <div className="row">
            <div className="col-lg-6 d-none">
              <Field
                tabIndex="13"
                name="tag_id"
                component={HiidenFiled}
                type="hidden"
                label="Tag Id"
                placeholder="Masukan Tag Id"
              />
            </div>
            <div className="col-lg-6 d-none">
              <Field
                tabIndex="14"
                type="text"
                name="berat_bandrol"
                component={ReanderField}
                label="Berat Bandrol"
                normalize={Gramasi}
                placeholder="Masukan Berat Bandrol"
                onFocus={(e) => e.target.select()}
                onClick={(e) => e.target.select()}
              />
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-6"></div>
            <div className="col-lg-6 text-right">
              {/* <div className="form-group">
                <label> &nbsp; </label> */}
              <button
                // tabIndex="14"
                onClick={() => this.props.dispatch(hideModal())}
                type="button"
                className="btn btn-secondary"
              >
                Batal
              </button>
              &nbsp;
              <button
                tabIndex="15"
                disabled={this.props.isLoading || this.state.disabled}
                type="submit"
                className="btn btn-success"
              >
                {this.props.isLoading ? (
                  <>
                    <i className="fas fa-spinner fa-spin"></i> &nbsp; Sedang
                    Menyimpan
                  </>
                ) : (
                  "Simpan"
                )}
              </button>
            </div>
          </div>
          {/* </div> */}
          {this.props.isLoading ? (
            <Loading
              loading
              background="rgba(0, 0, 0, 0.35)"
              loaderColor="rgba(94, 147, 117, 1)" />
          ) : null}
        </form>
      </>
    );
  }
}

FormTambahBarang = reduxForm({
  form: "FormTambahBarang",
  enableReinitialize: true,
  validate: ValidasiFilterTambahBarang,
})(FormTambahBarang);
// export default connect(maptostate)(FormTambahBarang);
const selector = formValueSelector("FormTambahBarang"); // <-- same as form name

export default connect((state) => {
  const { harga_atribut, harga_total_tmp, ongkos } = selector(state, "harga_atribut", "harga_total_tmp", "ongkos");

  // let kode = state.databarang.showEditBarang.kode_lokasi_toko.split("~");
  // this.props.
  // console.log('state');
  // console.log(state.databarang.showEditBarang.berat);

  let calculate_harga = parseInt(harga_total_tmp) + parseInt(harga_atribut || 0) + parseInt(ongkos || 0) || 0;
  // console.log(calculate_harga)
  return {
    DataJenis: state.datamaster.getDataJenis,
    DataToko: state.datamaster.getMasterToko,
    DataBarang: state.databarang.showEditBarang,
    status: state.databarang.showEditBarang.status,
    getDataTimbangan: state.utility.getTimbangan,
    kode_group: state.databarang.showEditBarang.kode_kategori,
    harga_total: calculate_harga,
    ongkos: state.databarang.showEditBarang.ongkos || 0,
    initialValues: {
      kode_kategori: state.databarang.showEditBarang.kode_kategori,
      // kode_jenis: state.databarang.showEditBarang.kode_jenis,
      // kode_lokasi_toko: kode[0],
      // kode_gudang: kode[1],
      kode_intern: state.databarang.showEditBarang.kode_intern,
      kode_barcode: state.databarang.showEditBarang.kode_barcode,
      nama_barang: state.databarang.showEditBarang.nama_barang,
      ongkos: state.databarang.showEditBarang.ongkos || 0,
      kadar: state.databarang.showEditBarang.kadar || 0,
      harga_estimasi: state.databarang.showEditBarang.estimasi,
      susut_tukang: state.databarang.showEditBarang.susut_tukang,
      // tujuan: state.databarang.showEditBarang.tujuan,
      berat_susut: state.databarang.showEditBarang.berat_susut,
      berat_cad: state.databarang.showEditBarang.berat_cad,
      kadar_cetak: state.databarang.showEditBarang.kadar_cetak,
      kadar_modal: state.databarang.showEditBarang.kadar_modal,
      nama_atribut: state.databarang.showEditBarang.nama_atribut,
      berat_atribut: state.databarang.showEditBarang.berat_atribut,
      harga_atribut: state.databarang.showEditBarang.harga_atribut,
      tag_id: state.databarang.showEditBarang.tag_id || "-",
      berat: state.databarang.showEditBarang.status === "PESANAN" ? state.databarang.showEditBarang.berat : 0,
      berat_awal: state.databarang.showEditBarang.status === "PESANAN" ? state.databarang.showEditBarang.berat : "-",
      berat_customer: state.databarang.showEditBarang.status === "PESANAN" ? state.databarang.showEditBarang.berat : 0,
      berat_bandrol: state.databarang.showEditBarang.berat_bandrol,
      berat_plastik: state.databarang.showEditBarang.berat_plastik,
      foto: state.databarang.showEditBarang.foto,
      status_pesanan: state.databarang.showEditBarang.status,
      no_pesanan: state.databarang.showEditBarang.no_pesanan === undefined ? "" : state.databarang.showEditBarang.no_pesanan.trim(),
    },
    harga_atribut: state.databarang.showEditBarang.harga_atribut,
    foto: state.databarang.showEditBarang.foto,
    setCamera: state.databarang.setCamera,
    hideModal: state.datamaster.modalDialog,
  };

})(FormTambahBarang);
