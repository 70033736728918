import { getDataJenis } from "../../../../actions/datamaster_action";
import {
    React,
    connect,
    reduxForm,
    Tabel,
    ReanderSelect,
    InputDate,
    Field,
    getTglSystem,
    getDataNoTimeOut2,
    ToastNotification
  } from "../../../../components/helpers/library";
import LaporanKirimSepuhExel from "./exel/LaporanKirimSepuhExel";
import LaporanKirimSepuhPdf from "./pdf/LaporanKirimSepuhPdf";
  

  class HeadKirimSepuh extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        isLoading: false,
        columns : [
            {
              dataField: "tgl_system",
              text: "Tanggal",
              footer : ''
            },
            {
              dataField: "no_sepuh",
              text: "No Sepuh",
              footer : ''
            },
            {
              dataField: "kode_baki",
              text: "Kode Baki",
              footer : ''
            },
            {
                dataField: "berat",
                text: "Berat",
                headerClasses: "text-right",
                formatter: (data) => {
                  return <div className="text-right"> {data.toFixed(2)}</div>;
                },
                footer: (columnData) => (
                  <div className="text-right">
                    {" "}
                    {columnData.reduce((acc, item) => acc + item, 0).toFixed(2)}{" "}
                  </div>
                ),
              },
            {
              dataField: "qty",
              text: "Qty",
              headerClasses: "text-right",
              formatter: (data) => {
                return <div className="text-right"> {data}</div>;
              },
              footer: (columnData) => (
                <div className="text-right">
                  {" "}
                  {columnData.reduce((acc, item) => acc + item, 0)}{" "}
                </div>
              ),
            },
      
            
            {
              dataField: "qty_rusak",
              text: "Qty Rusak",
              headerClasses: "text-right",
              formatter: (data) => {
                return <div className="text-right"> {data}</div>;
              },
              footer: (columnData) => (
                <div className="text-right">
                  {" "}
                  {columnData.reduce((acc, item) => acc + item, 0)}{" "}
                </div>
              ),
            },
            {
              dataField: "berat_rusak",
              text: "Berat Rusak",
              headerClasses: "text-right",
              formatter: (data) => {
                return <div className="text-right"> {data.toFixed(2)}</div>;
              },
              footer: (columnData) => (
                <div className="text-right">
                  {" "}
                  {columnData.reduce((acc, item) => acc + item, 0).toFixed(2)}{" "}
                </div>
              ),
            },
            {
              dataField: "qty_sepuh",
              text: "Qty Sepuh",
              headerClasses: "text-right",
              formatter: (data) => {
                return <div className="text-right"> {data}</div>;
              },
              footer: (columnData) => (
                <div className="text-right">
                  {" "}
                  {columnData.reduce((acc, item) => acc + item, 0)}{" "}
                </div>
              ),
            },
            {
              dataField: "berat_sepuh",
              text: "Berat Sepuh",
              headerClasses: "text-right",
              formatter: (data) => {
                return <div className="text-right"> {data}</div>;
              },
              footer: (columnData) => (
                <div className="text-right">
                  {" "}
                  {columnData.reduce((acc, item) => acc + item, 0).toFixed(3)}{" "}
                </div>
              ),
            },
            {
              dataField: "action",
              text: "Action",
              csvExport: false,
              headerClasses: "text-center",
              formatter: (rowcontent, row) => {
                return (
                  <div className="row text-center">
                    <div className="col-12">
                      <button
                        type="button"
                        onClick={() => this.reprint(row)}
                        className="btn btn-primary mr-3"
                      >
                        {" "}
                        <i className="fa fa-print"></i>
                      </button>
                    </div>
                  </div>
                );
              },
            },
          ]
      };
    }
    reprint(row){
      getDataNoTimeOut2("kirim-sepuh/reprint?no_sepuh="+row.no_sepuh).then((res)=>{
        this.print(res.data)
      }).catch((err)=>{
        console.log(err)
        ToastNotification("info","Terjadi Kesalahan Saat Mengirim Data, Silahkan Ulangi Lagi !!")
      })
    }
print(data){
let buktiSlipInternal = [];
for (let index = 0; index < 3; index++) {
let nama = "";
if(index===0){
nama= "Internal";
}else if(index===1){
nama= "Owner";
}else{
nama= "Tukang";
}
let feedback = '';
feedback += `========================================\n`
feedback += `          Kirim Sepuh ${nama}           \n`
feedback += `========================================
No Sepuh         : ${data.no_sepuh}
Tgl Sepuh        : ${data.tgl_sepuh}\n`
feedback += `========================================`
data.bukti_jenis.forEach((row, index) => {
feedback += `
No Sortir        : ${data.no_sepuh}`
feedback += `
Jenis            : ${row.jenis}`
feedback += `
Qty Beli         : ${row.qty_beli}`
feedback += `
Brt Beli         : ${row.berat_beli.toFixed(3)}`
feedback += `
Qty Sepuh        : ${row.qty_sepuh}`
feedback += `
Brt Sepuh        : ${row.berat_sepuh.toFixed(3)}`
feedback += `
Qty Rusak        : ${row.qty_rusak}`
feedback += `
Brt Rusak        : ${row.berat_rusak.toFixed(3)}\n`
})
feedback += `========================================`
feedback += `
Total Qty Sepuh  : ${data.qty_sepuh_total}
Total Brt Sepuh  : ${data.berat_sepuh_total.toFixed(3)}
Total Qty Rusak  : ${data.qty_rusak_total}
Total Brt Rusak  : ${data.berat_rusak_total.toFixed(3)}
Selisih Gr       : ${(parseFloat(data.berat_total) - parseFloat(data.berat_sepuh_total) - parseFloat(data.berat_rusak_total)).toFixed(3)}

`
feedback += `========================================\n`
feedback += `              TERIMA KASIH              \n`
feedback += `========================================`
feedback += `






VA\n`;
buktiSlipInternal.push(feedback);
}
document.getElementById("buktiSlipInternal").value = buktiSlipInternal.join('');
const elementbuktiSlipInternal = document.createElement("a");
const fileint = new Blob([document.getElementById("buktiSlipInternal").value], {
type: "text/plain;charset=utf-8",
});
elementbuktiSlipInternal.href = URL.createObjectURL(fileint);
elementbuktiSlipInternal.download = "autoprint_pembelian.txt";
document.body.appendChild(elementbuktiSlipInternal);
elementbuktiSlipInternal.click();
}
    componentDidMount(){
        this.props.dispatch(getDataJenis())
        this.props.change("kode_dept","ALL")
        this.props.change("tgl_akhir",getTglSystem())
        this.props.change("tgl_awal",getTglSystem())
    }
    setStartDate(date) {
        this.setState({
          tgl_awal: new Date(date),
        });
      }
      setLastDate(date) {
        this.setState({
          tgl_akhir: new Date(date),
        });
      }
    render() {
        let DataJenis = [
            {
              value: "ALL",
              name: "SEMUA",
            },
          ];
          this.props.datajenis.forEach((list) => {
            let hasil = {
              value: list.kode_dept,
              name: list.kode_dept,
            };
            DataJenis.push(hasil);
          });
      
      return (
        <form onSubmit={this.props.handleSubmit} autoComplete="off">
        <div className="row">
        <textarea
            style={{ display: "none" }}
            id="buktiSlipInternal"
            rows="100"
            cols="100"
          />
          <div className="col-lg-3">
            <Field
              name="kode_dept"
              component={ReanderSelect}
              label="Kode Dept"
              type="text"
              options={DataJenis}
              placeholder="Masukan Kode Dept"
              // customInput={<CustomeInputDate />}
            />
          </div>
          <div className="col-lg-3">
            <Field
              name="tgl_awal"
              component={InputDate}
              label="Tanggal Dari"
              type="text"
              selected={this.state.tgl_awal}
              onChange={(date) => this.setStartDate(date)}
              placeholder="Masukan Tanggal Dari"
            />
          </div>

          <div className="col-lg-3">
            <Field
              name="tgl_akhir"
              component={InputDate}
              type="text"
              selected={this.state.tgl_akhir}
              onChange={(date) => this.setLastDate(date)}
              label="Tanggal Akhir"
              placeholder="Masukan Tanggal Akhir"
            />
          </div>

          <div className="col-lg-3">
            <div className="text-right">
              <label>&nbsp;</label>
              <button
                type="submit"
                value="PDF"
                name="type_btn"
                className="btn btn-primary btn-block"
              >
                {this.props.isLoading ? (
                  <>
                    <i className="fas fa-spinner fa-spin"></i> &nbsp; Sedang
                    Mencari
                  </>
                ) : (
                  "Cari Laporan"
                )}
              </button>
            </div>
          </div>
          <div className="col-12">
            <Tabel
              keyField="no_sepuh"
              data={this.props.export ? this.props.databarang : []}
              columns={this.state.columns}
              empty={true}
              textEmpty="Data Laporan Terima Barang Sepuh"
            />
          </div>
          <div className={this.props.export ? "col-lg-12" : "col-lg-12 d-none"}>
            {/* <label htmlFor="">&nbsp;</label> */}
            <div className="row">
              <div className="col-lg-6">
                <button
                type="button"
                  onClick={() => LaporanKirimSepuhPdf(this.props.databarang)}
                  className="btn btn-warning btn-block"
                >
                  {" "}
                  Export PDF{" "}
                </button>
              </div>
              <div className="col-lg-6">
                <LaporanKirimSepuhExel data={this.props.databarang} />
              </div>
            </div>
          </div>
        </div>
      </form>
      );
    }
  }
  
  HeadKirimSepuh = reduxForm({
    form: "HeadKirimSepuh",
    enableReinitialize: true,
  })(HeadKirimSepuh);
  export default connect((state) => {
  return {
    datajenis : state.datamaster.getDataJenis,
    initialValues: {
      tgl_awal: getTglSystem(),
      tgl_akhir: getTglSystem(),
    },
  };
})(HeadKirimSepuh);

  