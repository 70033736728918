import jsPDF from "jspdf";
import "jspdf-autotable";
import {
  getToday,
  localStoragedecryp,
  formatGram,
  getUserdata,
  getItem,
} from "../../../components/helpers/function";

const CetakLaporanKirimBarang = (data = "", bukti = "") => {
  const doc = new jsPDF("l", "mm", [297, 210]);
  let tableRows = [];
  let finalY = 35;

  doc.setFontSize(15);
  doc.text("Bukti KIRIM BARANG", 14, 15);
  doc.setFontSize(10);
  doc.text("No Bukti      : " + bukti, 14, 20);
  doc.text("Tanggal       : " + getToday(), 14, 25);
  doc.text("Tujuan        : " + getItem("tujuan"), 14, 30);
  doc.setFontSize(20);
  doc.text(getUserdata().nama_toko, 200, 15);
  doc.setFontSize(8);
  let jml_alamat = getUserdata().alamat_toko.length;
  if (jml_alamat > 20) {
    doc.text(getUserdata().alamat_toko.slice(0, 48), 200, 20);
  }
  if (jml_alamat > 50) {
    doc.text(getUserdata().alamat_toko.slice(48, 90), 200, 25);
  }
  doc.setFontSize(10);
  doc.setProperties({
    title: "Bukti Kirim Barang",
  });
  //row 1

  let tableColumn = [
    [
      {
        content: `No`,
      },
      {
        content: `KODE`,
      },
      {
        content: `NAMA BARANG`,
      },
      {
        content: `GUDANG`,
      },
      {
        content: `BAKI`,
      },
      {
        content: `BERAT`,
        styles: {
          halign: "right",
        },
      },
    ],
  ];
  let i = 1;
  data.forEach((barang, no) => {
    let rows = [
      i,
      barang.kode_barcode,
      barang.nama_barang,
      barang.kode_gudang,
      barang.kode_toko,
      {
        content: formatGram(barang.berat, 3),
        styles: {
          halign: "right",
        },
      },
    ];
    ++i;
    tableRows.push(rows);
  });

  let total = [
    {
      content: `Total`,
      colSpan: 5,
      styles: {
        halign: "center",
        fillColor: "#E8E5E5",
        textColor: "#000",
      },
    },

    {
      content: data.reduce((a, b) => a + parseFloat(b.berat), 0).toFixed(3),
      styles: {
        halign: "right",
        fillColor: "#E8E5E5",
        textColor: "#000",
      },
    },

    // `${sub_qty}`,
  ];
  tableRows.push(total);
  let printed = [
    {
      colSpan: 9,
      content: `Printed By ${
        localStoragedecryp("userdata").user_id
      } / ${getToday()}`,
      styles: {
        // lineWidth: 0.1,
        fontStyle: "italic",
        textColor: "#000",
        // fillColor: "#E8E5E5"
      },
    },
  ];
  tableRows.push(printed);
  let space = [
    {
      content: `\n\n`,
      colSpan: 6,
      styles: {
        halign: "right",
      },
    },
  ];
  tableRows.push(space);
  let dibuat = [
    {
      content: ``,
      colSpan: 4,
      styles: {
        halign: "right",
      },
    },
    {
      content: `Di Buat Oleh`,
      styles: {
        halign: "center",
      },
    },

    {
      content: `Di Terima Oleh`,
      styles: {
        halign: "center",
      },
    },
  ];
  tableRows.push(dibuat);
  let ttd = [
    {
      content: ``,
      colSpan: 4,
      styles: {
        halign: "right",
      },
    },
    {
      content: `\n\n\n\n\n__________________\nPengirim`,
      styles: {
        halign: "center",
      },
    },

    {
      content: `\n\n\n\n\n__________________\nPenerima`,
      styles: {
        halign: "center",
      },
    },
  ];
  tableRows.push(ttd);

  doc.autoTable({
    head: tableColumn,
    body: tableRows,
    // foot: footRows,
    startY: finalY,
    theme: "plain",
    rowPageBreak: "avoid",
    // pageBreak: "avoid",
    margin: { top: 10 },
    footStyles: {
      // lineWidth: 0.02,
      // lineColor: "#000",
      fontSize: 8,
    },
    bodyStyles: {
      // lineWidth: 0.02,
      // lineColor: "#000",
      fontSize: 8,
    },
    headStyles: {
      fontSize: 8,
      // lineWidth: 0.02,
      // lineColor: "#000",

      fillColor: "#E8E5E5",
      textColor: "#000",
    },
  });
  finalY = doc.autoTableEndPosY() + 3;
  tableRows = [];

  const pages = doc.internal.getNumberOfPages();
  const pageWidth = doc.internal.pageSize.width; //Optional
  const pageHeight = doc.internal.pageSize.height; //Optional
  doc.setFontSize(10); //Optional
  for (let j = 1; j < pages + 1; j++) {
    let horizontalPos = pageWidth / 2; //Can be fixed number
    let verticalPos = pageHeight - 10; //Can be fixed number
    doc.setPage(j);
    doc.text(`${j} of ${pages}`, horizontalPos, verticalPos, {
      align: "center",
    });
  }
  doc.save(`Bukti Kirim Barang.pdf`);
};

export default CetakLaporanKirimBarang;
