import {
  React,
  Link,
  Panel,
  PanelHeader,
  connect,
  reduxForm,
  postData,
  ToastNotification,
  localStorageencryp,
  moment,
  PanelBody,
  formatDate,
} from "../../../../components/helpers/library";
import HeadForm from "./HeadForm";

class LaporanKirimBarangBos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      dataterimabarang: [],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(data) {
    this.setState({
      isLoading: true,
    });
    let data_post = {
      tgl_awal: formatDate(
        moment(new Date(data.tgl_awal)).format("YYYY-MM-DD")
      ),
      tgl_akhir: formatDate(
        moment(new Date(data.tgl_akhir)).format("YYYY-MM-DD")
      ),
      kode_labeling: data.kode_labeling,
    };
    postData("krmbrgbos/get/report", data_post)
      .then((res) => {
        if (res.data.length === 0) {
          ToastNotification("info", "Data Yang Anda Cari Tidak Ada");
          this.setState({
            export: false,
            isLoading: false,
          });
        } else {
          ToastNotification("success", "Laporan Terima Barang Tersedia");
          localStorageencryp("tgl_laporan", data_post, [], true);
          this.setState({
            export: true,
            isLoading: false,
            dataterimabarang: res.data,
          });
        }
      })
      .catch((err) => {
        this.setState({
          export: false,
          isLoading: false,
        });
        ToastNotification("info", "Data Yang Anda Cari Tidak Ada");
      });
  }

  render() {
    return (
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="#">Laporan Stock Pembelian</Link>
          </li>
          <li className="breadcrumb-item active">
            Laporan Kirim Barang Dari Bos
          </li>
        </ol>
        <h1 className="page-header">Laporan Kirim Barang Dari Bos </h1>
        <Panel>
          <PanelHeader>Laporan Kirim Barang Dari Bos</PanelHeader>
          <br />
          {/* Laporan Kirim Barang Dari Bos */}
          <PanelBody>
            <HeadForm
              export={this.state.export}
              isLoading={this.state.isLoading}
              dataterimabarang={this.state.dataterimabarang}
              onSubmit={(data) => this.handleSubmit(data)}
            />
          </PanelBody>
          <br />
          {/* End Tambah Laporan Kirim Barang Dari Bos  */}
        </Panel>
      </div>
    );
  }
}

LaporanKirimBarangBos = reduxForm({
  form: "LaporanKirimBarangBos",
  enableReinitialize: true,
})(LaporanKirimBarangBos);
export default connect()(LaporanKirimBarangBos);
