import { GET_DATA_TIMBANGAN, GET_PARA_SYSTEM } from "../actions/utility_action";

const initialState = {
  getTimbangan: [],
  getParaSystem: [],
};

const utility = (state = initialState, actions) => {
  switch (actions.type) {
    case GET_DATA_TIMBANGAN:
      return {
        ...state,
        getTimbangan: actions.payload.data,
      };
    case GET_PARA_SYSTEM:
      return {
        ...state,
        getParaSystem: actions.payload.data,
      };
    default:
      return state;
  }
};

export default utility;
