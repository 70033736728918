import jsPDF from "jspdf";
import "jspdf-autotable";
import { getUserdata } from "../../../../components/helpers/function";
// Date Fns is used to format the dates we receive

// define a generatePDF function that accepts a tickets argument
const BuktiSoPesanan = (data = "") => {
  // initialize jsPDF
  const doc = new jsPDF("l", "mm", [297, 210]);
  //   let data = JSON.parse(localStorage.getItem("tt_pengeluaran_barang")) || [];
  let tableRows = [];
  // let footRows = [];
  let tableColumn = [];
  let finalY = 30;

  doc.setFontSize(15);
  doc.text("BUKTI PESANAN SO", 14, 15);
  doc.setFontSize(20);
  doc.text(getUserdata().nama_toko, 200, 15);
  doc.setFontSize(8);
  let jml_alamat = getUserdata().alamat_toko.length;
  if (jml_alamat > 20) {
    doc.text(getUserdata().alamat_toko.slice(0, 48), 200, 20);
  }
  if (jml_alamat > 50) {
    doc.text(getUserdata().alamat_toko.slice(48, 90), 200, 25);
  }
  doc.setFontSize(10);
  doc.setProperties({
    title: "BUKTI PESANAN SO",
  });
  //row 1

  tableColumn = [
    [
      {
        content: `NO PRESKOT`,
      },
      {
        content: `KODE TUKANG`,
      },
      {
        content: `KATEGORI`,
      },

      {
        content: `BERAT PAKAI`,
        styles: {
          halign: "right",
        },
      },
      {
        content: `BERAT SUSUT`,
        styles: {
          halign: "right",
        },
      },
      {
        content: `ONGKOS`,
        styles: {
          halign: "right",
        },
      },
    ],
  ];

  data.dataPesanan.forEach((item) => {
    let rows = [
      item.no_persekot,
      item.tukang,
      item.kode_kategori,
      {
        content: (parseFloat(item.berat_pakai) - parseFloat(item.berat_susut)).toFixed(2),
        styles: {
          halign: "right",
        },
      },
      {
        content: parseFloat(item.berat_susut).toFixed(2),
        styles: {
          halign: "right",
        },
      },
      {
        content: parseInt(item.ongkos).toLocaleString("KR-ko"),
        styles: {
          halign: "right",
        },
      },
    ];
    tableRows.push(rows);
  });
  let pemisah = [
    {
      content: `--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------`,
      colSpan: 6,
      styles: {
        // lineWidth: 0.1,
        fontStyle: "italic",
        textColor: "#000",
        // fillColor: "#E8E5E5"
      },
    },
  ];
  tableRows.push(pemisah);
  let total_pakai = [
    {
      content: `Total Susut Pakai : `,
      styles: {
        fontSize: 12,
        halign: "right",
        fontStyle: "italic",
        textColor: "#000",
      },
    },
    {
      content: `${data.total_pakai}`,
      styles: {
        fontSize: 12,
        fontStyle: "italic",
        textColor: "#000",
      },
    },
    {
      content: `Total Awal Bahan : ${data.total_awal_bahan.toFixed(3)}`,
      colSpan: 3,

      styles: {
        fontSize: 12,
        halign: "left",
        fontStyle: "italic",
        textColor: "#000",
      },
    },

    {
      content: `Total Sisa Bahan      : ${data.total_sisa_bahan.toFixed(3)}`,
      styles: {
        fontSize: 12,
        halign: "left",
        fontStyle: "italic",
        textColor: "#000",
      },
    },
  ];
  tableRows.push(total_pakai);

  let total_susut = [
    {
      content: `Total Susut : `,
      styles: {
        fontSize: 12,
        halign: "right",
        fontStyle: "italic",
        textColor: "#000",
      },
    },
    {
      content: `${data.total_susut}`,
      colSpan: 4,
      styles: {
        fontSize: 12,
        fontStyle: "italic",
        textColor: "#000",
      },
    },
    {
      content: `Total Setor Bahan     : ${data.total_setor_bahan}\n______________________________________`,
      styles: {
        fontSize: 12,
        halign: "left",
        fontStyle: "italic",
        textColor: "#000",
      },
    },
  ];
  tableRows.push(total_susut);

  let total_ongkos = [
    {
      content: `Total Ongkos : `,
      styles: {
        fontSize: 12,
        halign: "right",
        fontStyle: "italic",
        textColor: "#000",
      },
    },
    {
      content: `${data.total_ongkos.toLocaleString("Kr-ko")}`,
      colSpan: 4,
      styles: {
        fontSize: 12,
        fontStyle: "italic",
        textColor: "#000",
      },
    },
    {
      content: `Selisih                       : ${(data.total_setor_bahan - data.total_sisa_bahan).toFixed(3)}`,
      styles: {
        fontSize: 12,
        halign: "left",
        fontStyle: "italic",
        textColor: "#000",
      },
    },
  ];
  tableRows.push(total_ongkos);

  doc.autoTable({
    head: tableColumn,
    body: tableRows,
    // foot: tbl_barang,
    startY: finalY,
    theme: "plain",
    rowPageBreak: "avoid",
    // pageBreak: "avoid",
    margin: { top: 10 },
    footStyles: {
      // lineWidth: 0.02,
      // lineColor: "#000",
      fontSize: 8,
    },
    bodyStyles: {
      // lineWidth: 0.02,
      // lineColor: "#000",
      fontSize: 8,
    },
    headStyles: {
      fontSize: 8,
      // lineWidth: 0.02,
      // lineColor: "#000",
      fillColor: "#E8E5E5",
      textColor: "#000",
    },
    tableLineColor: [255, 255, 255],
    tableLineWidth: 1,
  });
  finalY = doc.autoTableEndPosY() + 3;
  tableRows = [];

  const pages = doc.internal.getNumberOfPages();
  const pageWidth = doc.internal.pageSize.width; //Optional
  const pageHeight = doc.internal.pageSize.height; //Optional
  doc.setFontSize(10); //Optional
  for (let j = 1; j < pages + 1; j++) {
    let horizontalPos = pageWidth / 2; //Can be fixed number
    let verticalPos = pageHeight - 10; //Can be fixed number
    doc.setPage(j);
    doc.text(`${j} of ${pages}`, horizontalPos, verticalPos, {
      align: "center",
    });
  }
  // doc.autoPrint();
  doc.save(`BUKTI PESANAN SO.pdf`);
  //   var string = doc.output("datauristring");
  //   var embed = "<embed width='100%' height='100%' src='" + string + "'/>";
  //   var x = window.open();
  //   x.document.open();
  //   x.document.write(
  //     "<html><head><title>BUKTI PESANAN SO</title></head><body style='margin:0 !important'><embed width='100%' height='100%'  src=" +
  //       string +
  //       "></embed></body></html>"
  //   );
  //   x.document.write(embed);
  //   setInterval(() => {
  //     x.close();
  //   }, 1000);
};

export default BuktiSoPesanan;
