import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { InputDate, ReanderSelect } from "../../../../components/helpers/field";
import { getTglSystem } from "../../../../components/helpers/function";
import Tabel from "../../../../components/helpers/tabel";
import ValidasiTanggalLaporan from "../../../../Validasi/ValidasiTanggalLaporan";
import Loading from "react-fullscreen-loading";
import {
  GetDataGroup,
  getDataTukang,
} from "../../../../actions/datamaster_action";
// import LaporanPesananSo from "./pdf/LaporanPesananSo";
// import LaporanPesananSoExek from "./exel/LaporanPesananSoExek";
import LaporanPesananSoNew from "./pdf/LaporanPesananSoNew";
import LaporanPesananSoExelNew from "./exel/LaporanPesananSoExelNew";

class FilterLaporanSo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      columns: [
        {
          dataField: "no_so_pesanan",
          text: "No SO Pesanan",
          footer: "",
        },
      ],
      expandcolumns: [
        {
          dataField: "no_pesanan",
          text: "No Pesanan",
          footer: "",
        },
        {
          dataField: "kode_tukang",
          text: "Kode Tukang",
          footer: "",
        },
        {
          dataField: "deskripsi",
          text: "Deskripsi",
          footer: "",
        },
        {
          dataField: "nama_attribut",
          text: "Nama Atribut",
          footer: "",
          formatter: (data) => {
            return <div> {data.toUpperCase()}</div>;
          },
        },
        {
          dataField: "berat_atribut",
          text: "Berat Atr",
          footer: (columnData) => (
            <div className="text-right">
              {" "}
              {columnData.reduce((acc, item) => acc + item, 0).toFixed(3)}{" "}
            </div>
          ),
          formatter: (data) => {
            return <div className="text-right"> {data.toFixed(3)}</div>;
          },
        },
        {
          dataField: "berat_pakai",
          text: "Berat Pakai",
          footer: (columnData) => (
            <div className="text-right">
              {" "}
              {columnData.reduce((acc, item) => acc + item, 0).toFixed(3)}{" "}
            </div>
          ),
          formatter: (data) => {
            return <div className="text-right"> {data.toFixed(3)}</div>;
          },
        },
        {
          dataField: "susut_pakai",
          text: "Susut Pakai",
          footer: (columnData) => (
            <div className="text-right">
              {" "}
              {columnData
                .reduce((acc, item) => parseFloat(acc) + parseFloat(item), 0)
                .toFixed(3)}{" "}
            </div>
          ),
          formatter: (data) => {
            return (
              <div className="text-right"> {parseFloat(data).toFixed(3)}</div>
            );
          },
        },
        {
          dataField: "ongkos",
          text: "Ongkos",
          footer: (columnData) => (
            <div className="text-right">
              {" "}
              {columnData
                .reduce((acc, item) => acc + item, 0)
                .toLocaleString("KO-ko")}{" "}
            </div>
          ),
          formatter: (data) => {
            return (
              <div className="text-right"> {data.toLocaleString("KO-ko")}</div>
            );
          },
        },
        {
          dataField: "st_selesai",
          text: "St Selesai",
        },
        {
          dataField: "st_serah",
          text: "St Serah",
        },
      ],
    };
    this.setStartDate = this.setStartDate.bind(this);
    this.setLastDate = this.setLastDate.bind(this);
  }

  exportPdf() {
    LaporanPesananSoNew(this.props.LaporanStockOpname);
  }
  setStartDate(date) {
    this.setState({
      tgl_awal: new Date(date),
    });
  }
  setLastDate(date) {
    this.setState({
      tgl_akhir: new Date(date),
    });
  }

  componentDidMount() {
    this.props.dispatch(getDataTukang());
    this.props.dispatch(GetDataGroup());
  }
  render() {
    const expandRow = {
      renderer: (row) => {
        return (
          <Tabel
            keyField="no_pesanan"
            data={this.props.export ? row.pesanan : []}
            columns={this.state.expandcolumns}
            empty={true}
            textEmpty="Data Laporan Pesanan So"
            disable_search={true}
          />
        );
      },
      showExpandColumn: true,
      expandByColumnOnly: true,
    };
    return (
      <form
        onSubmit={this.props.handleSubmit}
        autoComplete="off"
        onKeyPress={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
      >
        <div className="row">
          <div className="col-lg-3">
            <Field
              name="tgl_awal"
              component={InputDate}
              label="Tanggal Dari"
              type="text"
              selected={this.state.tgl_awal}
              onChange={(date) => this.setStartDate(date)}
              placeholder="Masukan Tanggal Dari"
            />
          </div>

          <div className="col-lg-3">
            <Field
              name="tgl_akhir"
              component={InputDate}
              type="text"
              selected={this.state.tgl_akhir}
              onChange={(date) => this.setLastDate(date)}
              label="Tanggal Akhir"
              placeholder="Masukan Tanggal Akhir"
            />
          </div>
          <div className="col-lg-2">
            <Field
              name="tukang"
              component={ReanderSelect}
              type="text"
              label="Tukang"
              options={this.props.DataTukang.map((list) => {
                let row = {
                  name: list.kode_tukang,
                  value: list.kode_tukang,
                };
                return row;
              })}
              placeholder="Masukan Tukang"
            />
          </div>
          <div className="col-lg-2">
            <Field
              name="kategori"
              component={ReanderSelect}
              type="text"
              label="Kategori"
              options={this.props.DataKategori.map((list) => {
                let row = {
                  name: list.kode_group,
                  value: list.kode_group,
                };
                return row;
              })}
              placeholder="Masukan Kategori"
            />
          </div>

          <div className="col-lg-2">
            <div className="text-right">
              <label>&nbsp;</label>
              <button
                type="submit"
                value="PDF"
                name="type_btn"
                className="btn btn-primary btn-block"
              >
                {this.props.isLoading ? (
                  <>
                    <i className="fas fa-spinner fa-spin"></i> &nbsp; Sedang
                    Mencari
                  </>
                ) : (
                  "Cari Laporan"
                )}
              </button>
            </div>
          </div>
          <div className="col-lg-12">
            {/* {console.log(this.props.pesanan)} */}
            <Tabel
              keyField="no_so_pesanan"
              data={this.props.export ? this.props.pesanan : []}
              columns={this.state.columns}
              empty={true}
              textEmpty="Data Laporan Pesanan So"
              expandRow={expandRow}
            />
          </div>
          <div className={this.props.export ? "col-lg-12" : "col-lg-12 d-none"}>
            <div className="row">
              <div className="col-lg-6">
                <button
                  type="button"
                  onClick={() => this.exportPdf()}
                  className="btn btn-warning btn-block"
                >
                  {" "}
                  Export PDF{" "}
                </button>
              </div>
              <div className="col-lg-6">
                <LaporanPesananSoExelNew data={this.props.LaporanStockOpname} />
              </div>
            </div>
          </div>
        </div>
        {this.props.isLoading ? (
          <Loading
            loading
            background="rgba(0, 0, 0, 0.35)"
            loaderColor="rgba(94, 147, 117, 1)"
          />
        ) : null}
      </form>
    );
  }
}

FilterLaporanSo = reduxForm({
  form: "FilterLaporanSo",
  enableReinitialize: true,
  validate: ValidasiTanggalLaporan,
})(FilterLaporanSo);
// export default connect()(FilterLaporanSo);
export default connect((state) => {
  return {
    DataTukang: state.datamaster.getDataTukang,
    DataKategori: state.datamaster.GetDataGroup,
    initialValues: {
      tgl_awal: getTglSystem(),
      tgl_akhir: getTglSystem(),
    },
  };
})(FilterLaporanSo);
