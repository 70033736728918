//Mendaftarkan reducer / Menggabungkan
import { combineReducers } from "redux";
import datamaster from "./datamaster";
import { reducer as formReducer} from 'redux-form'
import manageusers from "./manageusers";
import databarang from "./databarang";
import penjualan from "./penjualan";
import services from "./services";
import hutang from "./hutang";
import datamember from "./datamember";
import pembelian from "./pembelian";
import utility from "./utility";
import KirimBarangDariBos from "./KirimBarangDariBos";
export default combineReducers({
    datamaster,
    manageusers,
    databarang,
    penjualan,
    services,
    hutang,
    datamember,
    pembelian,
    utility,
    KirimBarangDariBos,
    form: formReducer,
});