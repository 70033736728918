import {
  React,
  connect,
  Link,
  reduxForm,
  Panel,
  PanelHeader,
  PanelBody,
  localStorageencryp,
  moment,
  formatDate,
  getDataNoTimeOut2,
} from "../../../../components/helpers/library";
import {
  ToastNotification,
} from "../../../../components/helpers/notification";
import HeadLaporanBahanTukang from "./HeadLaporanBahanTukang";

class LaporanBahanTukang extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      databahantukang: [],
      typelaporan: "DETAIL",
      isLoading: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    // this.print = this.print.bind(this);
  }

  setTypeLaporan(type) {
    this.setState({
      typelaporan: type,
      databahantukang: [],
    });
  }

  handleSubmit(data) {
    console.log(data);
    let kode_tukang = "";
    this.setState({ isLoading: true });
    let data_post = {
      tgl_awal: formatDate(
        moment(new Date(data.tgl_awal)).format("YYYY-MM-DD")
      ),
      tgl_akhir: formatDate(
        moment(new Date(data.tgl_akhir)).format("YYYY-MM-DD")
      ),
      type_laporan: data.type_laporan,
    };
    // console.log(data_post);
    if (data.kode_tukang === undefined) {
      kode_tukang = "ALL";
    } else {
      kode_tukang = data.kode_tukang;
    }
    if (data.type_laporan === "DETAIL") {
      getDataNoTimeOut2(
        `bahan/reports?type=${data.type_laporan}&kode_group=${data.kategori}&kode_tukang=${kode_tukang}&tgl_from=${data_post.tgl_awal}&tgl_to=${data_post.tgl_akhir}`
      )
        .then((res) => {
          console.log(res.data);
          if (res.data.length === 0) {
            ToastNotification("info", "Data Laporan Kosong!");
            this.setState({
              export: false,
              isLoading: false,
              databahantukang: [],
            });
          } else {
            ToastNotification(
              "success",
              "Laporan Bahan Tukang Detail Tersedia"
            );
            localStorageencryp("tgl_laporan", data_post, [], true);

            let databahantukangdetail = [];
            res.data.forEach((element) => {
              let row = {};
              if (element.jenis === "PATRI") {
                if (element.trx_type === "AMBIL PATRI") {
                  row = {
                    _id: element._id,
                    kode_tukang: element.kode_tukang,
                    kode_trx: element.no_tambah_tukar,
                    detail_kategori: element.kode_group,
                    keterangan: element.keterangan,
                    ongkos: 0,
                    susut: element.berat_susut || 0,
                    patri: 0,
                    bahan: 0,
                    pakai: 0,
                    patri_amb: element.jml_bahan,
                    bahan_amb: 0,
                    sisa_bhn: element.saldo_akhir,
                  };
                } else {
                  row = {
                    _id: element._id,
                    kode_tukang: element.kode_tukang,
                    kode_trx: element.no_tambah_tukar,
                    detail_kategori: element.kode_group,
                    keterangan: element.keterangan,
                    ongkos: 0,
                    susut: element.berat_susut || 0,
                    patri: element.jml_bahan,
                    bahan: 0,
                    pakai: 0,
                    patri_amb: 0,
                    bahan_amb: 0,
                    sisa_bhn: element.saldo_akhir,
                  };
                }
              } else if (element.jenis === "BAHAN") {
                if (element.trx_type === "AMBIL BAHAN" || element.trx_type === "SO PESANAN") {
                  row = {
                    _id: element._id,
                    kode_tukang: element.kode_tukang,
                    kode_trx: element.no_tambah_tukar,
                    detail_kategori: element.kode_group,
                    keterangan: element.keterangan,
                    ongkos: 0,
                    susut: element.berat_susut || 0,
                    patri: 0,
                    bahan: 0,
                    pakai: 0,
                    patri_amb: 0,
                    bahan_amb: element.jml_bahan,
                    sisa_bhn: element.saldo_akhir,
                  };
                }else if(element.trx_type === "PESANAN SELESAI"){
                  row = {
                    _id: element._id,
                    kode_tukang: element.kode_tukang,
                    kode_trx: element.no_tambah_tukar,
                    detail_kategori: element.kode_group,
                    keterangan: element.keterangan,
                    ongkos: 0,
                    susut: element.berat_susut || 0,
                    patri: 0,
                    bahan: 0,
                    pakai: element.jml_bahan,
                    patri_amb: 0,
                    bahan_amb: 0,
                    sisa_bhn: element.saldo_akhir,
                  };
                } else {
                  row = {
                    _id: element._id,
                    kode_tukang: element.kode_tukang,
                    kode_trx: element.no_tambah_tukar,
                    detail_kategori: element.kode_group,
                    keterangan: element.keterangan,
                    ongkos: 0,
                    susut: element.berat_susut || 0,
                    patri: 0,
                    bahan: element.jml_bahan,
                    pakai: 0,
                    patri_amb: 0,
                    bahan_amb: 0,
                    sisa_bhn: element.saldo_akhir,
                  };
                }
              } else {
                row = {
                  _id: element._id,
                  kode_tukang: element.kode_tukang,
                  kode_trx: element.no_tambah_tukar,
                  detail_kategori: element.kode_group,
                  keterangan: element.keterangan,
                  ongkos: 0,
                  susut: element.berat_susut || 0,
                  patri: element.jml_bahan,
                  bahan: 0,
                  pakai: 0,
                  patri_amb: 0,
                  bahan_amb: 0,
                  sisa_bhn: element.saldo_akhir,
                };
              }
              databahantukangdetail.push(row);
            });
            this.setState({
              isLoading: false,
              export: true,
              databahantukang: databahantukangdetail,
            });
          }
        })
        .catch((err) => {
          this.setState({
            export: false,
            isLoading: false,
            databahantukang: [],
          });
          ToastNotification("info", "Data Yang Anda Cari Tidak Ada");
        });
    } else {
      getDataNoTimeOut2(
        `bahan/reports?type=${data.type_laporan}&kode_group=${data.kategori}&kode_tukang=${data.kode_tukang}&tgl_from=${data_post.tgl_awal}&tgl_to=${data_post.tgl_akhir}`
      )
        .then((res) => {
          console.log(res.data);
          if (res.data.length === 0) {
            ToastNotification("info", "Data Laporan Kosong!");
            this.setState({
              export: false,
              isLoading: false,
              databahantukang: [],
            });
          }else{
            ToastNotification(
              "success",
              "Laporan Bahan Tukang Rekap Tersedia"
            );
            localStorageencryp("tgl_laporan", data_post, [], true);

            let databahantukangrekap = [];
            res.data.forEach(item => {
              let row_rekap = {
                kode_tukang: item.kode_tukang,
                kode_kategori: item.kode_group,
                total_bahan: item.total_bahan,
                pakai_bahan: item.pakai_bahan,
                patri: item.ambil_patri,
                sisa_bhn: item.sisa_bahan,
              }
              databahantukangrekap.push(row_rekap);
            });
            this.setState({
              isLoading: false,
              export: true,
              databahantukang: databahantukangrekap,
            });
          }
          this.setState({ isLoading: false, export: true });
          
        })
        .catch((err) => {
          this.setState({
            export: false,
            isLoading: false,
            databahantukang: [],
          });
          ToastNotification("info", "Data Yang Anda Cari Tidak Ada");
        });
      // setTimeout(() => {
      //   this.setState({ isLoading: false, export: true });
      //   NotifSucces("Berhasil Melihat Data");
      // }, 5000);
    }
  }

  render() {
    return (
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="#">Laporan</Link>
          </li>
          <li className="breadcrumb-item active">Laporan Bahan Tukang</li>
        </ol>
        <h1 className="page-header">Laporan Bahan Tukang</h1>
        <Panel>
          <PanelHeader>Laporan Bahan Tukang</PanelHeader>
          <br />
          {/* Laporan Bahan Tukang */}
          <PanelBody>
            <HeadLaporanBahanTukang
              onSubmit={(data) => this.handleSubmit(data)}
              isLoading={this.state.isLoading}
              export={this.state.export}
              LaporanBahanTukang={this.state.databahantukang}
              typeLap={(type) => this.setTypeLaporan(type)}
              typelaporan={this.state.typelaporan}
            />
          </PanelBody>
        </Panel>
      </div>
    );
  }
}

LaporanBahanTukang = reduxForm({
  form: "LaporanBahanTukang",
  enableReinitialize: true,
})(LaporanBahanTukang);
export default connect()(LaporanBahanTukang);
