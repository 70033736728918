import jsPDF from "jspdf";
import "jspdf-autotable";
import {
  getToday,
  localStoragedecryp,
  getUserdata,
} from "../../../../../components/helpers/function";
// Date Fns is used to format the dates we receive

// define a generatePDF function that accepts a tickets argument
const LaporanKonversiBarangPdf = (data = "") => {
  // initialize jsPDF
  const doc = new jsPDF("l", "mm", [297, 210]);
  //   let data = (localStorage.getItem("tt_pengeluaran_barang")) || [];
  let tableRows = [];
  let finalY = 30;

  doc.setFontSize(15);
  doc.text("LAPORAN KONVERSI BARANG", 14, 15);
  doc.setFontSize(20);
  doc.text(getUserdata().nama_toko, 200, 15);
  doc.setFontSize(8);
  let jml_alamat = getUserdata().alamat_toko.length;
  if (jml_alamat > 20) {
    doc.text(getUserdata().alamat_toko.slice(0, 48), 200, 20);
  }
  if (jml_alamat > 50) {
    doc.text(getUserdata().alamat_toko.slice(48, 90), 200, 25);
  }
  doc.setFontSize(10);
  doc.setProperties({
    title: "LAPORAN KONVERSI BARANG",
  });
  //row 1
  doc.text(
    `PERIODE : ${
      localStoragedecryp("tgl_laporan")?.length === 0
        ? null
        : localStoragedecryp("tgl_laporan").start_date +
          " s/d " +
          localStoragedecryp("tgl_laporan").end_date
    }`,
    14,
    25
  );
  let tableColumn = [
    [
      { content: `KODE BARCODE` },
      { content: `NAMA BARANG` },
      { content: `KATEGORI ASAL` },
      { content: `KATEGORI TUJUAN` },
      { content: `JENIS ASAL` },
      { content: `JENIS TUJUAN` },
    ],
  ];
  data.forEach((row, no) => {
    let rows = [
      row.kode_barcode,
      row.nama_barang,
      row.kategori_asal,
      row.kategori_tujuan,
      row.jenis_asal,
      row.jenis_tujuan,
    ];

    tableRows.push(rows);
  });

  let total = [
    {
      content: `Total : ${data.length}`,
      colSpan: 6,
      styles: {
        halign: "left",
        fillColor: "#E8E5E5",
        textColor: "#000",
      },
    },

    // `${sub_qty}`,
  ];
  tableRows.push(total);

  let printed = [
    {
      colSpan: 6,
      content: `Printed By ${
        localStoragedecryp("userdata").user_id
      } / ${getToday()}`,
      styles: {
        // lineWidth: 0.1,
        fontStyle: "italic",
        textColor: "#000",
        // fillColor: "#E8E5E5"
      },
    },
  ];
  tableRows.push(printed);
  doc.autoTable({
    head: tableColumn,
    body: tableRows,
    // foot: footRows,
    startY: finalY,
    theme: "plain",
    rowPageBreak: "avoid",
    // pageBreak: "avoid",
    margin: { top: 10 },
    footStyles: {
      // lineWidth: 0.02,
      // lineColor: "#000",
      fontSize: 8,
    },
    bodyStyles: {
      // lineWidth: 0.02,
      // lineColor: "#000",
      fontSize: 8,
    },
    headStyles: {
      fontSize: 8,
      // lineWidth: 0.02,
      // lineColor: "#000",

      fillColor: "#E8E5E5",
      textColor: "#000",
    },
  });
  finalY = doc.autoTableEndPosY() + 3;
  tableRows = [];

  const pages = doc.internal.getNumberOfPages();
  const pageWidth = doc.internal.pageSize.width; //Optional
  const pageHeight = doc.internal.pageSize.height; //Optional
  doc.setFontSize(10); //Optional
  for (let j = 1; j < pages + 1; j++) {
    let horizontalPos = pageWidth / 2; //Can be fixed number
    let verticalPos = pageHeight - 10; //Can be fixed number
    doc.setPage(j);
    doc.text(`${j} of ${pages}`, horizontalPos, verticalPos, {
      align: "center",
    });
  }
  var string = doc.output("datauristring");
  var x = window.open();
  x.document.open();
  x.document.write(
    "<html><head><title>Laporan KONVERSI BARANG</title></head><body style='margin:0 !important'><embed width='100%' height='100%'  src=" +
      string +
      "></embed></body></html>"
  );
};

export default LaporanKonversiBarangPdf;
