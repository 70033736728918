import React, { Component } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import {
  formatGram,
  formatRp,
  getUserdata,
  localStoragedecryp,
} from "../../../../components/helpers/function";
class LaporanOwnerExcel extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    let total_jumlah_saldo = 0;
    let total_berat_saldo = 0;
    let total_jumlah_penjualan = 0;
    let total_berat_penjualan = 0;
    let total_harga_penjualan = 0;
    let total_jumlah_pembelian = 0;
    let total_berat_pembelian = 0;
    let total_harga_pembelian = 0;
    this.props.data.forEach((item, index) => {
      total_jumlah_saldo += item.jumlah_saldo;
      total_berat_saldo += item.berat_saldo;
      total_jumlah_penjualan += item.jumlah_penjualan;
      total_berat_penjualan += item.berat_penjualan;
      total_harga_penjualan += item.harga_penjualan;
      total_jumlah_pembelian += item.jumlah_pembelian;
      total_berat_pembelian += item.berat_pembelian;
      total_harga_pembelian += item.harga_pembelian;
    });
    return (
      <>
        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="btn btn-success btn-block"
          table="table-to-xls"
          filename="Laporan Keuangan Non Cash Rekap"
          sheet="Laporan Keuangan Non Cash Rekap"
          buttonText="Export Exel"
        />
        <table
          rowkey="kode_member"
          id="table-to-xls"
          style={{ display: "none" }}
        >
          <thead>
            <tr>
              <th colSpan="9" style={{ textAlign: "center" }}>
                {" "}
                LAPORAN OWNER
              </th>
            </tr>
            <tr>
              <th colSpan="9"> Tanggal </th>
            </tr>
            <tr>
              <th colSpan="9">
                {localStoragedecryp("tgl_laporan").length === 0
                  ? null
                  : localStoragedecryp("tgl_laporan").tanggal}
              </th>
            </tr>
            <tr>
              <th colSpan="9"> TOKO : {getUserdata().nama_toko} </th>
            </tr>

            <tr>
              <th colSpan="9"> ALAMAT : {getUserdata().alamat_toko} </th>
            </tr>
            <tr>
              <th colSpan="9"></th>
            </tr>
            <tr>
              <th
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "center",
                  verticalAlign: "center",
                }}
                rowSpan={2}
              >
                TOKO
              </th>
              <th
                colSpan={2}
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "center",
                }}
              >
                SALDO
              </th>
              <th
                colSpan={3}
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "center",
                }}
              >
                PENJUALAN
              </th>
              <th
                colSpan={3}
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "center",
                }}
              >
                PEMBELIAN
              </th>
            </tr>
            <tr>
              <th
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                JUMLAH
              </th>
              <th
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                BERAT
              </th>
              <th
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                JUMLAH
              </th>
              <th
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                BERAT
              </th>
              <th
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                HARGA TOTAL
              </th>
              <th
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                JUMLAH
              </th>
              <th
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                BERAT
              </th>
              <th
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                HARGA TOTAL
              </th>
            </tr>
          </thead>
          <tbody>
            {this.props.data.map((item, index) => (
              <tr key={index}>
                <td> {item.kode_toko}</td>
                <td style={{ textAlign: "right" }}> {item.jumlah_saldo}</td>
                <td style={{ textAlign: "right" }}>
                  {" "}
                  {formatGram(item.berat_saldo)}
                </td>
                <td style={{ textAlign: "right" }}> {item.jumlah_penjualan}</td>
                <td style={{ textAlign: "right" }}>
                  {" "}
                  {formatGram(item.berat_penjualan)}
                </td>
                <td style={{ textAlign: "right" }}>
                  {" "}
                  {item.harga_penjualan.toLocaleString("kr-KO")}
                </td>
                <td style={{ textAlign: "right" }}> {item.jumlah_pembelian}</td>
                <td style={{ textAlign: "right" }}>
                  {" "}
                  {formatGram(item.berat_pembelian)}
                </td>
                <td style={{ textAlign: "right" }}>
                  {" "}
                  {item.harga_pembelian.toLocaleString("kr-KO")}
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                TOTAL
              </th>
              <th
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                {total_jumlah_saldo}
              </th>
              <th
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                {formatGram(total_berat_saldo)}
              </th>
              <th
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                {total_jumlah_penjualan}
              </th>
              <th
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                {formatGram(total_berat_penjualan)}
              </th>
              <th
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                {formatRp(total_harga_penjualan)}
              </th>
              <th
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                {total_jumlah_pembelian}
              </th>
              <th
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                {formatGram(total_berat_pembelian)}
              </th>
              <th
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "right",
                }}
              >
                {formatRp(total_harga_pembelian)}
              </th>
            </tr>
          </tfoot>
        </table>
      </>
    );
  }
}
export default LaporanOwnerExcel;
