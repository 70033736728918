const encryptascii = (str) => {
  let key = process.env.REACT_APP_ENCKEY;

  let dataKey = {};
  for (let i = 0; i < key.length; i++) {
    dataKey[i] = key.substr(`${i}`, 1);
  }

  let strEnc = "";
  let nkey = 0;
  let jml = str.length;

  for (let i = 0; i < parseInt(jml); i++) {
    strEnc =
      strEnc + hexEncode(str[i].charCodeAt(0) + dataKey[nkey].charCodeAt(0));

    if (nkey === Object.keys(dataKey).length - 1) {
      nkey = 0;
    }
    nkey = nkey + 1;
  }
  return strEnc.toUpperCase();
};

const decryptascii = (str) => {
  if (str !== null) {
    let key = process.env.REACT_APP_ENCKEY;
    let dataKey = {};
    for (let i = 0; i < key.length; i++) {
      dataKey[i] = key.substr(`${i}`, 1);
    }

    let strDec = "";
    let nkey = 0;
    let jml = str.length;
    let i = 0;
    while (i < parseInt(jml)) {
      strDec =
        strDec + chr(hexdec(str.substr(i, 2)) - dataKey[nkey].charCodeAt(0));
      if (nkey === Object.keys(dataKey).length - 1) {
        nkey = 0;
      }
      nkey = nkey + 1;
      i = i + 2;
    }
    return strDec;
  }
};
const hexEncode = (str) => {
  var result = "";
  result = str.toString(16);
  return result;
};

const hexdec = (hex) => {
  var str = "";
  str = parseInt(hex, 16);
  return str;
};
const chr = (asci) => {
  var str = "";
  str = String.fromCharCode(asci);
  return str;
};

// const writeLocal = (nama, data) => {
//   doEncrypt(data, ["kode_baki", "nama_baki", "kode_barang"]);
//   console.log(unMaskingFunction(data.berat));
//   console.log(doDecrypt(data, ["kode_baki", "nama_baki", "kode_barang"]));
//   // return localStorage.setItem(nama, encryptascii(JSON.stringify(data)));
// };

export function doEncrypt(data, ignore = []) {
  if (typeof data === "object" && data) {
    Object.keys(data).map((x) => {
      const result = ignore.find((find) => find === x);
      if (!result) {
        if (Array.isArray(data[x])) {
          data[x].map((y, i) => {
            if (typeof y === "string") {
              data[x][i] = encryptascii(y);
            } else {
              doEncrypt(y, ignore);
            }
            return true;
          });
        } else {
          if (typeof data[x] === "string" && data[x]) {
            // console.log(data[x]);
            data[x] = encryptascii(data[x]);
          } else if (typeof data[x] === "number" && data[x]) {
            data[x] = maskingFunction(data[x]);
          }
        }
      }
      return true;
    });
  } else {
    if (typeof data === "string") {
      // console.log(data);
      data = encryptascii(data);
    }
    return data
  }
}

export function doDecrypt(data, ignore = []) {
  if (typeof data === "object" && data) {
    Object.keys(data).map((x) => {
      const result = ignore.find((find) => find === x);
      if (!result) {
        if (Array.isArray(data[x])) {
          data[x].map((y, i) => {
            if (typeof y === "string") {
              data[x][i] = decryptascii(y);
            } else {
              doDecrypt(y, ignore);
            }
            return true;
          });
        } else {
          if (typeof data[x] === "string" && data[x]) {
            data[x] = decryptascii(data[x]);
          } else if (typeof data[x] === "number" && data[x]) {
            data[x] = unMaskingFunction(data[x]);
          }
        }
      }
      return true;
    });
  } else {
    if (typeof data === "string") {
      // console.log(data);
      data = decryptascii(data);
    }
  }

  return data;
}

function maskingFunction(number) {
  return Number(number);
  // const numberString = String(number);
  // const list = numberString.split("");
  // return Number(
  //   list
  //     .map((data) => {
  //       if (data === ".") {
  //         return ".";
  //       } else {
  //         // console.log(data);
  //         return String(Number(data) + 22);
  //       }
  //     })
  //     .join("")
  // );
}

function unMaskingFunction(number) {
  return Number(number);
  // const numberString = String(number);
  // const list = numberString.split(".");
  // return Number(
  //   list
  //     .map((data) => {
  //       const segment = data.split("").reduce((s, c) => {
  //         let l = s.length - 1;
  //         s[l] && s[l].length < 2 ? (s[l] += c) : s.push(c);
  //         return s;
  //       }, []);
  //       return segment
  //         .map((x) => {
  //           // console.log(x);
  //           return x - 22;
  //         })
  //         .join("");
  //     })
  //     .join(".")
  // );
}
