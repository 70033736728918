import {
  React,
  connect,
  Link,
  reduxForm,
  Panel,
  PanelHeader,
  PanelBody,
  ToastNotification,
  Swal,
  getItem,
  deleteData2,
  convertDate,
  localStorageencryp,
} from "../../../../components/helpers/library";
import { getDataParams2 } from "../../../../config/axios";
import HeadLaporanSortirPembelian from "./HeadLaporanSortirPembelian";

class LaporanSortirPembelian extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      datasortir: [],
      type: "GLOBAL",
      export: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    // this.print = this.print.bind(this);
  }

  handleSubmit(data) {
    this.setState({
      isLoading: true,
    });
    // console.log(data.tgl_from)
    let data_post = {
      tgl_from: convertDate(data.tgl_awal || data.tgl_from),
      tgl_to: convertDate(data.tgl_akhir || data.tgl_to),
      type: data.type,
      kode_dept: data.kode_dept,
      kode_gudang: data.kode_gudang,
    };
    getDataParams2("sortir-pembelian/reports", data_post)
      .then((res) => {
        // console.log(res.data);
        if (res.data.length === 0) {
          this.setState({
            datasortir: [],
            isLoading: false,
            export: true,
            type: data.type,
          });
          ToastNotification("info", "Data Laporan Tidak Tersedia");
          return false;
        }
        this.setState({
          datasortir: [],
        });
        localStorageencryp("tgl_laporan", data_post);
        this.setState({
          datasortir: res.data,
          isLoading: false,
          export: true,
          type: data.type,
        });
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
          export: false,
          type: data_post.type,
        });
        ToastNotification("info", err.response?.data);
      });
  }
  changeType(e) {
    this.setState({ type: e, datasortir: [], export: false });
  }
  btlSortir(row) {
    Swal.fire({
      html:
        "Apakah Anda Yakin Ingin " +
        "Membatal " +
        "<h1><b>No Sortir Ini <br/>" +
        row.no_sortir +
        "</b> ?</h1>",
      icon: "warning",
      position: "top-center",
      cancelButtonText: "Tidak",
      showCancelButton: true,
      confirmButtonText: "OK",
      showConfirmButton: true,
    })
      .then((result) => {
        if (result.isConfirmed) {
          let data_laporan = getItem("tgl_laporan");
          this.setState({
            isLoading: true,
          });
          deleteData2("sortir-pembelian/cancel?no_sortir=" + row.no_sortir)
            .then(() => {
              this.handleSubmit(data_laporan);
              ToastNotification("success", "No Sortir Berhasil Di Batal");
              this.setState({
                isLoading: false,
              });
            })
            .catch((err) => {
              this.setState({
                isLoading: false,
              });
              ToastNotification(
                "info",
                err?.response?.data?.message ||
                  "Terjadi Kesalahan Saat Mengirim Data, Silahkan Ulangi Lagi !!"
              );
            });
        } else {
          this.setState({
            isLoading: false,
          });
        }
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
        ToastNotification(
          "info",
          err?.response?.data?.message ||
            "Terjadi Kesalahan Saat Mengirim Data, Silahkan Ulangi Lagi !!"
        );
      });
  }
  render() {
    return (
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="#">Laporan</Link>
          </li>
          <li className="breadcrumb-item active">Laporan Sortir Pembelian</li>
        </ol>
        <h1 className="page-header">Laporan Sortir Pembelian</h1>
        <Panel>
          <PanelHeader>Laporan Sortir Pembelian</PanelHeader>
          <br />
          {/* Blank Page */}
          <PanelBody>
            <HeadLaporanSortirPembelian
              type={this.state.type}
              export={this.state.export}
              data={this.state.datasortir}
              changeType={(e) => this.changeType(e)}
              isLoading={this.state.isLoading}
              onSubmit={(data) => this.handleSubmit(data)}
              btlSortir={(data) => this.btlSortir(data)}
            />
          </PanelBody>

          <br />
        </Panel>
      </div>
    );
  }
}

LaporanSortirPembelian = reduxForm({
  form: "LaporanSortirPembelian",
  enableReinitialize: true,
})(LaporanSortirPembelian);
export default connect()(LaporanSortirPembelian);
