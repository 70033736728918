const ValidasiSortirPembelian = (value) => {
    const errors = {};
   
    if (!value.kode_dept) {
        errors.kode_dept = "Kode Jenis Harus Dipilih";
    }
    if (!value.kode_group) {
        errors.kode_group = "Kategori Harus Dipilih";
    }
    
    if (!value.kode_toko) {
        errors.kode_toko = "Kode Toko Harus Dipilih";
    }
   
    if (!value.kondisi) {
        errors.kondisi = "Kondisi Harus Dipilih";
    }

    return errors;
}

export default ValidasiSortirPembelian;


