import {
  getMasterToko,
  showModalDataToko,
} from "../../../actions/datamaster_action";
import {
  React,
  connect,
  Link,
  reduxForm,
  Panel,
  PanelHeader,
  PanelBody,
  Tabel,
  ModalGlobal,
  hideModal,
  reset,
  showModal,
  isEdit,
  putDataNoTImeOut2,
  ToastNotification,
} from "../../../components/helpers/library";
import ModalDataToko from "./ModalDataToko";

const maptostate = (state) => {
  return {
    isEdit: state.datamaster.isEdit,
    DataToko: state.datamaster.getMasterToko,
  };
};
class MasterToko extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      columns: [
        {
          dataField: "kode_toko",
          text: "Kode Toko",
        },
        {
          dataField: "nama_toko",
          text: "Nama Toko",
        },
        {
          dataField: "action",
          text: "Action",
          csvExport: false,
          headerClasses: "text-center",
          formatter: (rowcontent, row, index) => {
            let data = {
              _id: row._id,
              kode_toko: row.kode_toko,
              nama_toko: row.nama_toko,
            };
            return (
              <div className="row text-center">
                <div className="col-12">
                  <button
                    type="button"
                    onClick={() => this.editDataToko(data)}
                    className="btn btn-warning mr-3"
                  >
                    <i className="fa fa-edit"></i>
                  </button>
                  {/* <button
                    type="button"
                    onClick={() => this.hapusDataToko(row.kode_toko, row._id)}
                    className="btn btn-danger mr-3"
                  >
                    <i className="fa fa-trash"></i>
                  </button> */}
                </div>
              </div>
            );
          },
        },
      ],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    // this.print = this.print.bind(this);
  }

  handleSubmit(data) {
    let data_post = {
      nama_toko: data.nama_toko,
    };
    this.setState({
      isLoading: true,
    });
    putDataNoTImeOut2("tokos/" + data._id, data_post, ["nama_toko"], false)
      .then((res) => {
        ToastNotification("success", "Data Berhasil Disimpan");
        isEdit(false);
        this.setState({
          isLoading: false,
        });
        this.props.dispatch(reset("ModalDataToko"));
        this.props.dispatch(hideModal());
        this.props.dispatch(getMasterToko());
      })
      .catch((err) => {
        ToastNotification("info", err.response?.data);
        this.setState({
          isLoading: false,
        });
      });
  }

  hapusDataToko(id) {}

  componentDidMount() {
    this.props.dispatch(getMasterToko());
  }
  editDataToko(data) {
    this.props.dispatch(isEdit(true));
    this.props.dispatch(showModal());
    this.props.dispatch(showModalDataToko(data));
  }
  showModalToko() {
    this.props.dispatch(isEdit(false));
    this.props.dispatch(showModal());
    this.props.dispatch(showModalDataToko(false));
  }
  batal() {
    this.props.dispatch(reset("ModalToko"));
    this.props.dispatch(hideModal());
  }
  render() {
    return (
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="#">Data Master</Link>
          </li>
          <li className="breadcrumb-item active">Master Toko</li>
        </ol>
        <h1 className="page-header">Master Toko </h1>
        <Panel>
          <PanelHeader>Master Toko</PanelHeader>
          <br />
          {/* Blank Page */}
          <PanelBody>
            <Tabel
              // tambahData={true}
              // handleClick={() => this.showModalToko()}
              featur={true}
              keyField="_id"
              data={this.props.DataToko}
              columns={this.state.columns}
              empty={[]}
              textEmpty="Data Toko Kosong"
            />
          </PanelBody>

          <ModalGlobal
            size="P"
            title={
              this.props.isEdit ? "Edit Data Pabrik" : "Tambah Data Pabrik"
            }
            content={
              <ModalDataToko
                isLoading={this.state.isLoading}
                onSubmit={(data) => this.handleSubmit(data)}
                batal={() => this.batal()}
              />
            }
          />

          <br />
        </Panel>
      </div>
    );
  }
}

MasterToko = reduxForm({
  form: "MasterToko",
  enableReinitialize: true,
})(MasterToko);
export default connect(maptostate)(MasterToko);
