import {
  React,
  connect,
  Link,
  reduxForm,
  Panel,
  PanelHeader,
  PanelBody,
  BgLoading,
  getDataNoTimeOut2,
  ToastNotification,convertDate,localStorageencryp
} from "../../../../components/helpers/library";
import HeadLaporanBarangRusak from "./HeadLaporanBarangRusak";

class LaporanValidasiBarangRusak extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        isLoading: false,
        databarang : []
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    // this.print = this.print.bind(this);
  }

  handleSubmit(data) {
    this.setState({
        isLoading: true,
      });
      let data_post = {
        tgl_awal: convertDate(data.tgl_awal),
        tgl_akhir: convertDate(data.tgl_akhir),
      };
      getDataNoTimeOut2(`barang-rusak/reports?&tgl_to=${data_post.tgl_akhir}&tgl_from=${data_post.tgl_awal}`)
        .then((res) => {
          if (res.data.length === 0) {
            ToastNotification("info", "Data Yang Anda Cari Tidak Ada");
            this.setState({
              export: false,
              isLoading: false,
              databarang : []
            });
          } else {
            ToastNotification("success", "Laporan Tersedia");
            localStorageencryp("tgl_laporan", data_post, [], true);
            this.setState({
              databarang: [],
            });
            this.setState({
              export: true,
              isLoading: false,
              databarang : res.data,
            });
          }
        })
        .catch((err) => {
          this.setState({
            export: false,
            isLoading: false,
            dataterimabarang : [],
            databarang : []
          });
          ToastNotification("info", "Data Yang Anda Cari Tidak Ada");
        });
  }

  render() {
    return (
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="#">Laporan</Link>
          </li>
          <li className="breadcrumb-item active">
            Laporan Validasi Barang Rusak
          </li>
        </ol>
        <h1 className="page-header">Laporan Validasi Barang Rusak </h1>
        <Panel>
          <PanelHeader>Laporan Validasi Barang Rusak</PanelHeader>
          <br />
          {/* Laporan Validasi Barang Rusak */}
          <PanelBody>
            <HeadLaporanBarangRusak
              export={this.state.export}
              isLoading={this.state.isLoading}
              databarang={this.state.databarang}
              onSubmit={(data) => this.handleSubmit(data)}
            />
          </PanelBody>

          <br />
          {this.state.isLoading && <BgLoading />}
        </Panel>
      </div>
    );
  }
}

LaporanValidasiBarangRusak = reduxForm({
  form: "LaporanValidasiBarangRusak",
  enableReinitialize: true,
})(LaporanValidasiBarangRusak);
export default connect()(LaporanValidasiBarangRusak);
