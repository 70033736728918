import jsPDF from "jspdf";
import "jspdf-autotable";
import {
  getToday,
  getUserdata,
  localStoragedecryp,
} from "../../../../../components/helpers/function";
// Date Fns is used to format the dates we receive

// define a generatePDF function that accepts a tickets argument
const LaporanPesananPdf = (
  data = "",
  username = "",
  tanggal_dari = "",
  tanggal_sampai = ""
) => {
  // initialize jsPDF
  const doc = new jsPDF("l", "mm", [297, 210]);
  //   let data = JSON.parse(localStorage.getItem("tt_pengeluaran_barang")) || [];
  let tableRows = [];
  // let footRows = [];
  let tableColumn = [];
  let finalY = 30;

  doc.setFontSize(15);
  doc.text("LAPORAN PESANAN TUKANG", 14, 15);
  doc.setFontSize(20);
  doc.text(getUserdata().nama_toko, 200, 15);
  doc.setFontSize(8);
  let jml_alamat = getUserdata().alamat_toko.length;
  if (jml_alamat > 20) {
    doc.text(getUserdata().alamat_toko.slice(0, 48), 200, 20);
  }
  if (jml_alamat > 50) {
    doc.text(getUserdata().alamat_toko.slice(48, 90), 200, 25);
  }
  doc.setFontSize(10);
  doc.setProperties({
    title: "LAPORAN PESANAN TUKANG",
  });
  //row 1
  doc.text(
    `PERIODE : ${
      localStoragedecryp("tgl_laporan_pesanan_tukang", [], true).length === 0
        ? "-"
        : localStoragedecryp("tgl_laporan_pesanan_tukang", [], true).tgl_awal +
          " s/d " +
          localStoragedecryp("tgl_laporan_pesanan_tukang", [], true).tgl_akhir
    }`,
    14,
    25
  );
  tableColumn = [
    [
      {
        content: `NO PERSEKOT`,
      },
      {
        content: `TANGGAL`,
      },
      {
        content: `NAMA CUSTOMER`,
      },
      {
        content: `NAMA BARANG`,
      },
      {
        content: `TUKANG`,
      },
      {
        content: `ATRIBUT`,
      },
      {
        content: `BERAT ATR`,
        styles: {
          halign: "right",
        },
      },
      {
        content: `BERAT CAD`,
        styles: {
          halign: "right",
        },
      },
      {
        content: `TANGGAL SELESAI`,
      },
      {
        content: `BERAT AWAL`,
        styles: {
          halign: "right",
        },
      },
      {
        content: `BERAT PAKAI`,
        styles: {
          halign: "right",
        },
      },
      {
        content: `ONGKOS CUSTOMER`,
        styles: {
          halign: "right",
        },
      },
      {
        content: `ONGKOS TUKANG`,
        styles: {
          halign: "right",
        },
      },
      // {
      //   content: `SUSUT TUKANG`,
      //   styles: {
      //     halign: "right",
      //   },
      // },
      // {
      //   content: `ASAL BAHAN`,
      //   styles: {
      //     halign: "right",
      //   },
      // },
      // {
      //   content: `SALDO BAHAN`,
      //   styles: {
      //     halign: "right",
      //   },
      // },
      {
        content: `STATUS`,
      },
    ],
  ];

  let total_berat_atr = 0;
  let total_berat_cad = 0;
  let total_berat_tukang = 0;
  let total_ongkos_customer = 0;
  let total_ongkos_tukang = 0;
  // let total_susut_tukang = 0;
  data.forEach((item, index) => {
    total_berat_atr += item.berat_atr;
    total_berat_cad += item.berat_cad;
    total_berat_tukang += item.berat_tukang;
    total_ongkos_customer += item.ongkos_customer;
    total_ongkos_tukang += item.ongkos;
    // total_susut_tukang += item.susut_tukang;
    let rows = [
      item.no_persekot,
      item.tgl_system,
      item.nama_customer,
      item.nama_barang,
      item.tukang,
      item.nama_atribut,
      {
        content: item.berat_atr.toFixed(3),
        styles: {
          halign: "right",
        },
      },
      {
        content: item.berat_cad.toFixed(3),
        styles: {
          halign: "right",
        },
      },
      item.tgl_selesai ? item.tgl_selesai : "-",
      {
        content: item.berat_customer.toFixed(3),
        styles: {
          halign: "right",
        },
      },
      {
        content: item.berat_tukang ? item.berat_tukang.toFixed(3) : 0,
        styles: {
          halign: "right",
        },
      },
      {
        content: item.ongkos_customer.toLocaleString("kr-KO"),
        styles: {
          halign: "right",
        },
      },
      {
        content: item.ongkos.toLocaleString("kr-KO"),
        styles: {
          halign: "right",
        },
      },
      // {
      //   content: item.susut_tukang.toFixed(3),
      //   styles: {
      //     halign: "right",
      //   },
      // },
      // {
      //   content: item.asal_bahan ? item.asal_bahan.toFixed(3) : 0,
      //   styles: {
      //     halign: "right",
      //   },
      // },
      // {
      //   content: item.saldo_bahan ? item.saldo_bahan.toFixed(3) : 0,
      //   styles: {
      //     halign: "right",
      //   },
      // },
      {
        content: item.status,
        styles: {
          halign: "right",
        },
      },
    ];
    tableRows.push(rows);
  });

  let footer = [
    {
      content: `Grand Total`,
      colSpan: 6,
      styles: {
        halign: "center",
        fillColor: "#E8E5E5",
        textColor: "#000",
      },
    },
    {
      content: total_berat_atr.toFixed(3),
      styles: {
        halign: "right",
        fillColor: "#E8E5E5",
        textColor: "#000",
      },
    },
    {
      content: parseFloat(total_berat_cad).toFixed(3),
      styles: {
        halign: "right",
        fillColor: "#E8E5E5",
        textColor: "#000",
      },
    },
    {
      content: "",
      colSpan: 2,
      styles: {
        halign: "right",
        fillColor: "#E8E5E5",
        textColor: "#000",
      },
    },
    {
      content: total_berat_tukang
        ? parseFloat(total_berat_tukang).toFixed(3)
        : "",
      styles: {
        halign: "right",
        fillColor: "#E8E5E5",
        textColor: "#000",
      },
    },
    {
      content: parseInt(total_ongkos_customer).toLocaleString("kr-KO"),
      styles: {
        halign: "right",
        fillColor: "#E8E5E5",
        textColor: "#000",
      },
    },
    {
      content: parseInt(total_ongkos_tukang).toLocaleString("kr-KO"),
      styles: {
        halign: "right",
        fillColor: "#E8E5E5",
        textColor: "#000",
      },
    },
    // {
    //   content: parseFloat(total_susut_tukang).toFixed(3),
    //   styles: {
    //     halign: "right",
    //     fillColor: "#E8E5E5",
    //     textColor: "#000",
    //   },
    // },
    {
      content: "",
      // colSpan: 3,
      styles: {
        halign: "right",
        fillColor: "#E8E5E5",
        textColor: "#000",
      },
    },
  ];
  tableRows.push(footer);

  let printed = [
    {
      content: `Printed By ${
        localStoragedecryp("userdata").user_id
      } / ${getToday()}`,
      colSpan: 7,
      styles: {
        // lineWidth: 0.1,
        fontStyle: "italic",
        textColor: "#000",
        // fillColor: "#E8E5E5"
      },
    },
  ];
  tableRows.push(printed);

  doc.autoTable({
    head: tableColumn,
    body: tableRows,
    // foot: tbl_barang,
    startY: finalY,
    theme: "plain",
    rowPageBreak: "avoid",
    // pageBreak: "avoid",
    margin: { top: 10 },
    footStyles: {
      // lineWidth: 0.02,
      // lineColor: "#000",
      fontSize: 8,
    },
    bodyStyles: {
      // lineWidth: 0.02,
      // lineColor: "#000",
      fontSize: 8,
    },
    headStyles: {
      fontSize: 8,
      // lineWidth: 0.02,
      // lineColor: "#000",
      fillColor: "#E8E5E5",
      textColor: "#000",
    },
    tableLineColor: [255, 255, 255],
    tableLineWidth: 1,
  });
  finalY = doc.autoTableEndPosY() + 3;
  tableRows = [];
  // footRows = [];
  // tbl_barang = [];
  // const date = Date().split(" ");
  // we use a date string to generate our filename.
  // const dateStr = date[2] + date[3] + date[4];
  // ticket title. and margin-top + margin-left
  // doc.text(
  //   `User                          : ${
  //     localStoragedecryp("userdata").user_id
  //   }`,
  //   14,
  //   finalY + 10
  // );
  // doc.text(`Cetak                        : ${getToday()}`, 14, finalY + 16);
  const pages = doc.internal.getNumberOfPages();
  const pageWidth = doc.internal.pageSize.width; //Optional
  const pageHeight = doc.internal.pageSize.height; //Optional
  doc.setFontSize(10); //Optional
  for (let j = 1; j < pages + 1; j++) {
    let horizontalPos = pageWidth / 2; //Can be fixed number
    let verticalPos = pageHeight - 10; //Can be fixed number
    doc.setPage(j);
    doc.text(`${j} of ${pages}`, horizontalPos, verticalPos, {
      align: "center",
    });
  }
  // doc.autoPrint();
  //   doc.save(`LAPORAN PESANAN.pdf`);
  var string = doc.output("datauristring");
  // var embed = "<embed width='100%' height='100%' src='" + string + "'/>";
  var x = window.open();
  x.document.open();
  x.document.write(
    "<html><head><title>LAPORAN PESANAN TUKANG</title></head><body style='margin:0 !important'><embed width='100%' height='100%'  src=" +
      string +
      "></embed></body></html>"
  );
  // x.document.write(embed);
  // setInterval(() => {
  //   x.close();
  // }, 1000);
};

export default LaporanPesananPdf;
