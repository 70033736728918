import React, { Component } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import {
  localStoragedecryp,
  getUserdata,
} from "../../../../../components/helpers/function";
class LaporanKonversiBarangExel extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="btn btn-success btn-block"
          table="table-to-xls"
          filename="Laporan Konversi Barang"
          sheet="Laporan Konversi Barang"
          buttonText="Export Exel"
        />
        <table
          rowkey="kode_member"
          id="table-to-xls"
          style={{ display: "none" }}
        >
          <thead>
            <tr>
              <th colSpan="7"> Laporan Konversi Barang </th>
            </tr>
            <tr>
              <th colSpan="7"> Periode </th>
            </tr>
            <tr>
              <th colSpan="7">
                {" "}
                {localStoragedecryp("tgl_laporan")?.length === 0
                  ? null
                  : localStoragedecryp("tgl_laporan").start_date +
                    " s/d " +
                    localStoragedecryp("tgl_laporan").end_date}{" "}
              </th>
            </tr>
            <tr>
              <th colSpan="7"> TOKO : {getUserdata().nama_toko} </th>
            </tr>

            <tr>
              <th colSpan="7"> ALAMAT : {getUserdata().alamat_toko} </th>
            </tr>
            <tr>
              <th colSpan="7"></th>
            </tr>
            <tr>
              <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                KODE BARCODE
              </th>
              <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                NAMA BARANG
              </th>
              <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                KATEGORI ASAL
              </th>
              <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                KATEGORI TUJUAN
              </th>
              <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                JENIS ASAL
              </th>
              <th style={{ backgroundColor: "#E8E5E5", color: "#000" }}>
                JENIS TUJUAN
              </th>
            </tr>
          </thead>
          <tbody>
            {this.props.data.map((item, index) => (
              <tr key={index}>
                <td>{item.kode_barcode}</td>
                <td> {item.nama_barang}</td>
                <td> {item.kategori_asal}</td>
                <td> {item.kategori_tujuan}</td>
                <td> {item.jenis_asal}</td>
                <td> {item.jenis_tujuan}</td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td
                colSpan="6"
                style={{
                  backgroundColor: "#E8E5E5",
                  color: "#000",
                  textAlign: "left",
                }}
              >
                Total : {this.props.data.length}
              </td>
            </tr>
          </tfoot>
        </table>
      </>
    );
  }
}
export default LaporanKonversiBarangExel;
