import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { reduxForm, reset } from "redux-form";
import {
  disableButtonValidasi,
  saveValidasiJual,
} from "../../../actions/hutang_action.jsx";
import {
  cetakLaporanValidasi,
  formatGram,
} from "../../../components/helpers/function.jsx";
import { ToastNotification } from "../../../components/helpers/notification.jsx";
import {
  Panel,
  PanelBody,
  PanelHeader,
} from "../../../components/panel/panel.jsx";
import { postDataNoTImeOut2 } from "../../../config/axios.jsx";
import HeadValidasiinputan from "./HeadValidasiInputan.jsx";
// import CetakFakturJualBeli from "./pdf/CetakFakturJualBeli.jsx";

class FormValidasiInputan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isLoadingBtl: false,
      columnsJual: [
        {
          dataField: "no_kirim_bos",
          text: "No Kirim Bos",
        },
        {
          dataField: "tgl_system",
          text: "Tanggal",
        },
        {
          dataField: "kode_group",
          text: "Kode Group",
        },
        {
          dataField: "qty_awal",
          text: "Qty Awal",
          formatter: (data, row) => row.qty,
        },
        {
          dataField: "qty_input",
          text: "Qty input",
          formatter: (data, row) => row.qty_input,
        },
        {
          dataField: "qty_sisa",
          text: "Qty Sisa",
          formatter: (data, row) => row.qty - row.qty_input,
        },
        {
          dataField: "berat",
          text: "Berat Awal",
          formatter: (data, row) => formatGram(row.berat, 3),
        },
        {
          dataField: "berat_input",
          text: "Berat input",
          formatter: (data, row) => formatGram(row.berat_input, 3),
        },
        {
          dataField: "berat_sisa",
          text: "Berat Sisa",
          formatter: (cell, row) => formatGram(row.berat - row.berat_input, 3),
        },
      ],
      columnsLabeling: [
        {
          dataField: "no_kirim_stock",
          text: "No Kirim Stock",
        },
        {
          dataField: "kode_lokasi_toko",
          text: "Kode Lokasi",
        },
        {
          dataField: "kode_dept",
          text: "Kode Jenis",
        },
        {
          dataField: "tgl_system",
          text: "Tanggal",
        },
        // {
        //   dataField: "kode_group",
        //   text: "Kode Group",
        // },
        {
          dataField: "qty_awal",
          text: "Qty Awal",
          formatter: (data, row) => row.qty,
        },
        {
          dataField: "qty_input",
          text: "Qty input",
          formatter: (data, row) => row.qty_input,
        },
        {
          dataField: "qty_sisa",
          text: "Qty Sisa",
          formatter: (data, row) => row.qty - row.qty_input,
        },
        {
          dataField: "berat",
          text: "Berat Awal",
          formatter: (data, row) => formatGram(row.berat, 3),
        },
        {
          dataField: "berat_input",
          text: "Berat input",
          formatter: (data, row) => formatGram(row.berat_input, 3),
        },
        {
          dataField: "berat_sisa",
          text: "Berat Sisa",
          formatter: (cell, row) => formatGram(row.berat - row.berat_input, 3),
        },
      ],
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.btnClear = this.btnClear.bind(this);
  }

  btnClear() {
    this.setState({
      isLoadingBtl: true,
    });
    setTimeout(() => {
      localStorage.removeItem("validasi_inputan");
      localStorage.removeItem("validasi_beli");
      this.props.dispatch(saveValidasiJual([]));
      this.props.dispatch(reset("HeadValidasiJualBeliHutang"));
      this.props.dispatch(disableButtonValidasi(false));
      this.setState({
        isLoadingBtl: false,
      });
    }, 1000);
  }
  componentWillUnmount() {
    this.props.dispatch(saveValidasiJual([]));
  }
  async handleSubmit(data) {
    this.setState({
      isLoading: true,
    });
    let validasi_inputan = JSON.parse(
      localStorage.getItem("data_validasi_inputan")
    );
    if (data.jenis_transaksi === "KB") {
      postDataNoTImeOut2("validasi-inputan/kirim-barang-bos", {
        data_kirim: validasi_inputan,
      })
        .then((res) => {
          let hasil = [
            {
              data_kirim: validasi_inputan,
            },
          ];

          document.getElementById("myInput").value =
            cetakLaporanValidasi(hasil);
          setTimeout(() => {
            this.TextFile();
          }, 100);
          localStorage.removeItem("data_validasi_inputan");
          this.props.dispatch(reset("HeadValidasiInput"));
          this.props.dispatch(saveValidasiJual([]));
          ToastNotification("success", "Validasi Input Berhasil");
          this.props.dispatch(disableButtonValidasi(false));
          this.setState({
            isLoading: false,
          });
        })
        .catch((err) => {
          this.setState({
            isLoading: false,
          });
          ToastNotification(
            "info",
            err?.response?.data?.message ||
              "Terjadi Kesalahaan Saat Mengirim Data"
          );
        });
    } else {
      postDataNoTImeOut2("validasi-inputan/kirim-labeling", {
        data_kirim: validasi_inputan,
      })
        .then((res) => {
          let hasil = [
            {
              data_kirim: validasi_inputan,
            },
          ];

          document.getElementById("myInput").value =
            cetakLaporanValidasi(hasil);
          setTimeout(() => {
            this.TextFile();
          }, 100);
          localStorage.removeItem("data_validasi_inputan");
          this.props.dispatch(reset("HeadValidasiInput"));
          this.props.dispatch(saveValidasiJual([]));
          ToastNotification("success", "Validasi Input Berhasil");
          this.props.dispatch(disableButtonValidasi(false));
          this.setState({
            isLoading: false,
          });
        })
        .catch((err) => {
          this.setState({
            isLoading: false,
          });
          console.log(err.response.data);
          ToastNotification(
            "info",
            err?.response?.data?.message ||
              "Terjadi Kesalahaan Saat Mengirim Data"
          );
        });
    }
  }
  TextFile() {
    const element = document.createElement("a");
    const file = new Blob([document.getElementById("myInput").value], {
      type: "text/plain;charset=utf-8",
    });
    element.href = URL.createObjectURL(file);
    element.download = "autoprint_pembelian.txt";
    document.body.appendChild(element);
    element.click();
  }
  render() {
    return (
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="#">Kasir</Link>
          </li>
          <li className="breadcrumb-item active">Validasi Inputan</li>
        </ol>
        <h1 className="page-header">Validasi Inputan </h1>
        <Panel>
          <PanelHeader>Validasi Inputan</PanelHeader>
          <textarea
            style={{ display: "none" }}
            id="myInput"
            rows="100"
            cols="100"
          />

          <br />
          <PanelBody>
            <HeadValidasiinputan
              onSubmit={(data) => this.handleSubmit(data)}
              btnClear={() => this.btnClear()}
              isLoadingBtl={this.state.isLoadingBtl}
              isLoading={this.state.isLoading}
              columnsLabeling={this.state.columnsLabeling}
              columnsJual={this.state.columnsJual}
            />
          </PanelBody>
        </Panel>
      </div>
    );
  }
}

FormValidasiInputan = reduxForm({
  form: "FormValidasiInputan",
  enableReinitialize: true,
})(FormValidasiInputan);
export default connect()(FormValidasiInputan);
