import {
  React,
  connect,
  Link,
  reduxForm,
  Panel,
  PanelHeader,
  PanelBody,
  getDataNoTimeOut2,
  convertDate,
  ToastNotification,
  localStorageencryp,
} from "../../../../components/helpers/library";
import HeadKirimSepuh from "./HeadKirimSepuh";

class LaporanKirimSepuh extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      export: false,
      databarang: [],
    };
  }

  handleSubmit(data){
    this.setState({
      isLoading: true,
    });
    let data_post = {
      tgl_awal: convertDate(data.tgl_awal),
      tgl_akhir: convertDate(data.tgl_akhir),
      kode_dept : data.kode_dept
    };
    getDataNoTimeOut2("kirim-sepuh/reports?kode_dept="+data_post.kode_dept +'&tgl_to='+data_post.tgl_akhir+'&tgl_from='+data_post.tgl_awal).then((res)=>{
      if (res.data.length === 0) {
        ToastNotification("info", "Data Yang Anda Cari Tidak Ada");
        this.setState({
          export: false,
          isLoading: false,
          databarang : []
        });
      } else {
        ToastNotification("success", "Laporan Kirim Sepuh Tersedia");
        localStorageencryp("tgl_laporan", data_post, [], true);
        this.setState({
          databarang: [],
        });
        this.setState({
          export: true,
          isLoading: false,
          databarang : res.data,
        });
      }
    })
    .catch((err) => {
      this.setState({
        export: false,
        isLoading: false,
        dataterimabarang : [],
        databarang : []
      });
      ToastNotification("info", "Data Yang Anda Cari Tidak Ada");
    });

  }
  render() {
    return (
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="#">Laporan</Link>
          </li>
          <li className="breadcrumb-item active">Laporan Kirim Sepuh</li>
        </ol>
        <h1 className="page-header">Laporan Kirim Sepuh </h1>
        <Panel>
          <PanelHeader>Laporan Kirim Sepuh</PanelHeader>
          <PanelBody>
            <HeadKirimSepuh
            export={this.state.export}
            isLoading={this.state.isLoading} databarang={this.state.databarang} onSubmit={(data)=>this.handleSubmit(data)} />
          </PanelBody>
        </Panel>
      </div>
    );
  }
}

LaporanKirimSepuh = reduxForm({
  form: "LaporanKirimSepuh",
  enableReinitialize: true,
})(LaporanKirimSepuh);
export default connect(null)(LaporanKirimSepuh);
