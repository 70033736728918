import {
    React,
    connect,
    Link,
    reduxForm,
    Panel,
    PanelHeader,
    PanelBody,
} from "../../../components/helpers/library";
import HeadStockOpnameBos from './HeadStockOpnameBos';

  class StockKirimBarangDariBos extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        isLoading: false,
      };
      this.handleSubmit = this.handleSubmit.bind(this);
      // this.print = this.print.bind(this);
    }
  
    handleSubmit(data) {
      console.log(data)
    }
  
    render() {
      return (
        <div>
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item">
              <Link to="#">Stock Opname</Link>
            </li>
            <li className="breadcrumb-item active">Stock Opname Kirim Barang Dari Bos</li>
          </ol>
          <h1 className="page-header">Stock Opname Kirim Barang Dari Bos </h1>
          <Panel>
            <PanelHeader>Stock Opname Kirim Barang Dari Bos</PanelHeader>
            <br />
            {/* Stock Opname Kirim Barang Dari Bos */}
            <PanelBody>
                <HeadStockOpnameBos onSubmit={(data)=>this.handleSubmit(data)} />
            </PanelBody>
  
            <br />
          </Panel>
        </div>
      );
    }
  }
  
  StockKirimBarangDariBos = reduxForm({
    form: "StockKirimBarangDariBos",
    enableReinitialize: true,
  })(StockKirimBarangDariBos);
  export default connect()(StockKirimBarangDariBos);
  