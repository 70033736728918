import { getDataBaki, getDataGudang } from "../../../actions/datamaster_action";
import {
  React,
  connect,
  reduxForm,
  Field,
  ReanderSelect,
  getTglSystem,
} from "../../../components/helpers/library";
// import ValidasiGudangBaki from "../../../Validasi/Pembelian/ValidasiGudangBaki";

class HeadSortir extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      settings: "",
    };
    this.setStartDate = this.setStartDate.bind(this);

    // this.print = this.print.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(getDataGudang());
    this.props.dispatch(getDataBaki());
  }
  setStartDate(date) {
    this.setState({
      tanggal: new Date(date),
    });
  }
  render() {
    return (
      <form
        onSubmit={this.props.handleSubmit}
        onKeyPress={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
        autoComplete="off"
      >
        <div className="row">
          <div className="col-4">
            <Field
              name="kode_gudang"
              label="Gudang"
              placeholder="Pilih Gudang"
              onChange={(e) => this.setState({ kode_gudang: e })}
              options={this.props.DataGudang.filter(
                (list) => list.kode_gudang === "SS"
              ).map((list) => {
                let data = {
                  value: list.kode_gudang,
                  name: list.kode_gudang,
                };
                return data;
              })}
              component={ReanderSelect}
            />
          </div>
          <div className="col-4">
            <Field
              name="kode_baki"
              label="Baki"
              placeholder="Pilih Baki"
              options={this.props.DataBaki.filter(
                (list) => list.kode_gudang === this.state.kode_gudang
              ).map((list) => {
                let data = {
                  value: list.kode_baki,
                  name: list.kode_baki,
                };
                return data;
              })}
              component={ReanderSelect}
            />
          </div>
          {/* <div className="col-3">
            <Field
              name="no_faktur"
              label="&nbsp;"
              placeholder="No Sortir"
              readOnly={true}
              component={ReanderField}
            />
          </div> */}

          <div className="col-4">
            <label>&nbsp;</label>
            <button type="submit" className="btn btn-primary btn-block">
              {this.props.isLoadingSortir ? (
                <>
                  <i className="fas fa-spinner fa-spin"></i> &nbsp; Sedang
                  Menuimpan
                </>
              ) : (
                "Simpan"
              )}
            </button>
          </div>
        </div>
       
      </form>
    );
  }
}

HeadSortir = reduxForm({
  form: "HeadSortir",
  enableReinitialize: true,
  // validate : ValidasiGudangBaki
})(HeadSortir);
// export default connect()(HeadSortir);
export default connect((state) => {
  return {
    DataGudang: state.datamaster.getDataGudang,
    DataBaki: state.datamaster.getDataBaki,
    initialValues: {
      tanggal: getTglSystem(),
    },
  };
})(HeadSortir);
