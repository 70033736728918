import { setTmpBarang } from "../../../actions/databarang_action";
import { getDataTukang } from "../../../actions/datamaster_action";
import {
  React,
  connect,
  reduxForm,
  Field,
  ReanderField,
  createNumberMask,
  Loading,
  getDataNoTimeOut2,
  Tabel,
  ReanderSelect,
  ToastNotification,
} from "../../../components/helpers/library";
import { GramasiNol } from "../../../Validasi/normalize";
import ValidasiPesanan from "../../../Validasi/Pesanan/ValidasiPesanan";
const maptostate = (state) => {
  return {
    DataTukang: state.datamaster.getDataTukang,
    DataBarang: state.databarang.setTmpBarang,
  };
};
const columns = [
  {
    dataField: "deskripsi",
    text: "Deskripsi",
  },
  {
    dataField: "attribut",
    text: "Atribut",
    formatter: (data) => {
      return data && data.toUpperCase()
    }
  },
  {
    dataField: "perkiraan_berat",
    text: "Berat",
    headerClasses: "text-right",
    formatter: (coulmdata) => {
      return <div className="text-right"> {coulmdata.toFixed(3)} </div>;
    },
  },
  {
    dataField: "perkiraan_kadar",
    text: "Kadar",
    headerClasses: "text-right",
    formatter: (coulmdata) => {
      return <div className="text-right"> {coulmdata} </div>;
    },
  },

  {
    dataField: "harga_gram",
    text: "Harga",
    headerClasses: "text-right",
    formatter: (coulmdata) => {
      return (
        <div className="text-right"> {coulmdata.toLocaleString("kr-KO")} </div>
      );
    },
  },
  {
    dataField: "harga_atribut",
    text: "Harga Atribut",
    headerClasses: "text-right",
    formatter: (coulmdata) => {
      return (
        <div className="text-right"> {coulmdata.toLocaleString("kr-KO")} </div>
      );
    },
  },
  {
    dataField: "sub_total",
    text: "Sub Total",
    headerClasses: "text-right",
    formatter: (coulmdata) => {
      return (
        <div className="text-right"> {coulmdata.toLocaleString("kr-KO")} </div>
      );
    },
  },
];
const currencyMask = createNumberMask({
  prefix: "Rp. ",
  locale: "kr-KO",
});

class HeadPembagianPesanan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      databarang: [],
    };
  }

  componentDidMount() {
    this.props.dispatch(getDataTukang());
    this.props.change('berat_susut', 0)
    setTimeout(() => {
      document.getElementById("no_pesanan").focus();
    }, 100);
  }
  getNopesanan = (e) => {
    if (this.delayTimer) {
      clearTimeout(this.delayTimer);
    }

    this.delayTimer = setTimeout(() => {
      if (e === "") {
        return false;
      }
      this.setState({
        isLoading: true,
      });
      let nopesanan = e.toUpperCase();
      getDataNoTimeOut2("pesanan/available?no_pesanan=" + nopesanan)
        .then((res) => {
          if (res.data.pesanan.length === 0) {
            ToastNotification("info", "No Pesanan Tidak Ada");
            this.setState({
              isLoading: false,
            });
            return false;
          } else {
            this.props.change("kode_member", res.data.pesanan.kode_customer);
            this.props.change("nama_pemesan", res.data.pesanan.nama_customer);
            this.props.change("alamat", res.data.pesanan.alamat_customer);
            this.props.change("telp", res.data.pesanan.no_hp);
            // this.setState({
            //   databarang: [],
            // });

            this.props.dispatch(setTmpBarang([]))
            // console.log(res.data.pesanan.detail_barang);

            this.props.dispatch(setTmpBarang([res.data.pesanan.detail_barang]))

            this.setState({
              isLoading: false,
            });
          }
        })
        .catch((err) => {
          this.setState({
            isLoading: false,
          });
        });
    }, 1000);
  };
  render() {
    return (
      <form
        onSubmit={this.props.handleSubmit}
        onKeyPress={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
        autoComplete="off"
      >
        <div className="row">
          <div className="col-6">
            <div className="row">
              <div className="col-6">
                <Field
                  id="no_pesanan"
                  name="no_pesanan"
                  component={ReanderField}
                  type="text"
                  onChange={(e) => this.getNopesanan(e.target.value)}
                  label="No Pesanan"
                  placeholder="Masukan No Pesanan"
                />
              </div>

              <div className="col-6">
                <Field
                  name="kode_tukang"
                  component={ReanderSelect}
                  options={this.props.DataTukang.map((list) => {
                    let row = {
                      value: list.kode_tukang,
                      name: list.kode_tukang,
                    };
                    return row;
                  })}
                  type="text"
                  label="Tukang"
                  placeholder="Masukan Tukang"
                />
              </div>
              <div className="col-6">
                <Field
                  name="ongkos_tukang"
                  component={ReanderField}
                  type="text"
                  {...currencyMask}
                  label="Ongkos Tukang"
                  placeholder="Masukan Ongkos Tukang"
                />
              </div>
              <div className="col-6">
                <Field
                  name="berat_susut"
                  component={ReanderField}
                  type="text"
                  normalize={GramasiNol}
                  onFocus={(e) => e.target.select()}
                  label="Berat Susut"
                  placeholder="Masukan Berat Susut"
                />
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="row">
              <div className="col-6">
                <Field
                  name="kode_member"
                  component={ReanderField}
                  type="text"
                  readOnly={true}
                  label="Kode Member"
                  placeholder="Masukan Kode Member"
                />
              </div>
              <div className="col-6">
                <Field
                  name="nama_pemesan"
                  component={ReanderField}
                  type="text"
                  readOnly={true}
                  label="Nama Pemesan"
                  placeholder="Masukan Nama Pemesan"
                />
              </div>
              <div className="col-6">
                <Field
                  name="alamat"
                  component={ReanderField}
                  type="text"
                  readOnly={true}
                  label="Alamat"
                  placeholder="Masukan Alamat"
                />
              </div>

              <div className="col-6">
                <Field
                  name="telp"
                  component={ReanderField}
                  type="text"
                  readOnly={true}
                  label="Telepon"
                  placeholder="Masukan Telepon"
                />
              </div>
            </div>
          </div>
          <div className="col-12">
            <Tabel
              keyField="_id"
              data={this.props.DataBarang}
              columns={columns}
              empty={[]}
              textEmpty="Pesanan Kosong"
            />
          </div>
        </div>
        {this.state.isLoading || this.props.isLoading ? (
          <Loading
            loading
            background="rgba(0, 0, 0, 0.35)"
            loaderColor="rgba(94, 147, 117, 1)"
          />
        ) : null}
        <div className="row">
          <div className="col-12 text-right ">
            <button type="button" className="btn btn-warning mr-1">
              {this.props.isLoading ? (
                <>
                  <i className="fas fa-spinner fa-spin"></i> &nbsp; Batal
                </>
              ) : (
                "Batal"
              )}
            </button>
            <button type="submit" className="btn btn-primary">
              {this.props.isLoading ? (
                <>
                  <i className="fas fa-spinner fa-spin"></i> &nbsp; Sedang
                  Menyimpan
                </>
              ) : (
                "Simpan Data"
              )}
            </button>
          </div>
        </div>
      </form>
    );
  }
}

HeadPembagianPesanan = reduxForm({
  form: "HeadPembagianPesanan",
  validate: ValidasiPesanan,
  enableReinitialize: true,
})(HeadPembagianPesanan);
export default connect(maptostate)(HeadPembagianPesanan);
