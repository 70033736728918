import { convertDate, formatGram } from "../../../components/helpers/function";
import {
  React,
  connect,
  Link,
  reduxForm,
  Panel,
  PanelHeader,
  PanelBody,
  // Tabel,
  getDataNoTimeOut2,
  ToastNotification,
  setItem,
  getItem,
  postDataNoTImeOut2,
  Loading,
} from "../../../components/helpers/library";
import HeadGudangBaki from "./HeadGudangBaki";
import HeadSortir from "./HeadSortir";
import BuktiSortirPembelian from "./pdf/BuktiSortirPembelian";

class SortirPembelian extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isLoadingSortir: false,
      databarang: [],
      columns: [
        {
          dataField: "no_faktur_beli",
          text: "No Pembelian",
          footer: (cell, row, num, index) => {
            return (
              <div>Total Qty : {parseInt(this.state.databarang.length)  - parseInt(getItem("datasortir_barang").length || 0)} </div>
            );
          },
          footerAttrs: {
            colSpan: "6",
          },
        },
        {
          dataField: "tgl_system",
          text: "Tanggal",
          headerStyle: (colum, colIndex) => {
            return { width: '80px' };
          }
        },

        {
          dataField: "nama_barang",
          text: "Nama Barang",
          headerStyle: (colum, colIndex) => {
            return { width: '400px' };
          }
        },
        {
          dataField: "kondisi",
          text: "Kondisi",
          headerStyle: (colum, colIndex) => {
            return { width: '70px' };
          }
        },

        {
          dataField: "kode_dept",
          text: "Jenis",
          headerStyle: (colum, colIndex) => {
            return { width: '70px' };
          }
        },
        {
          dataField: "kode_group",
          text: "Kategori",
          headerStyle: (colum, colIndex) => {
            return { width: '70px' };
          }
        },
        {
          dataField: "berat",
          text: "Berat",
          headerClasses: "text-right",
          headerStyle: (colum, colIndex) => {
            return { width: '70px' };
          },
          formatter: (cell) => {
            return <div className="text-right"> {formatGram(cell, 2)} </div>;
          },
          footer: (columnData) => {
            return <div className="text-right"> { (parseFloat(columnData.reduce((acc, item) => acc + item, 0)) - Number(getItem("datasortir_barang").map((list) => list.berat || 0).reduce((a, b) => a + b, 0))).toFixed(3)} </div>
          }
        },
        {
          dataField: "harga",
          text: "Harga",
          headerClasses: "text-right",
          formatter: (cell) => {
            return <div className="text-right"> {cell.toLocaleString("kr-KO")} </div>;
          },
          footer: (columnData) => {
            return <div className="text-right"> { parseInt(columnData.reduce((acc, item) => acc + item, 0)).toLocaleString("kr-KO")} </div>
          }
        },
       
      ],
      columns2: [
        {
          dataField: "no_faktur_beli",
          text: "No Pembelian",
          footer: (cell, row, num, index) => {
            return (
              <div>Total Qty : {getItem("datasortir_barang").length} </div>
            );
          },
          footerAttrs: {
            colSpan: "6",
          },
        },
        {
          dataField: "tgl_system",
          text: "Tanggal",
          headerStyle: (colum, colIndex) => {
            return { width: '80px' };
          }
        },

        {
          dataField: "nama_barang",
          text: "Nama Barang",
          headerStyle: (colum, colIndex) => {
            return { width: '400px' };
          }
        },
        {
          dataField: "kondisi",
          text: "Kondisi",
          headerStyle: (colum, colIndex) => {
            return { width: '70px' };
          }
        },

        {
          dataField: "kode_dept",
          text: "Jenis",
          headerStyle: (colum, colIndex) => {
            return { width: '70px' };
          }
        },
        {
          dataField: "kode_group",
          text: "Kategori",
          headerStyle: (colum, colIndex) => {
            return { width: '70px' };
          }
        },
        {
          dataField: "berat",
          text: "Berat",
          headerClasses: "text-right",
          headerStyle: (colum, colIndex) => {
            return { width: '70px' };
          },
          formatter: (cell) => {
            return <div className="text-right"> {formatGram(cell, 2)} </div>;
          },
          footer: (columnData) => {
            return <div className="text-right"> { (parseFloat(columnData.reduce((acc, item) => acc + item, 0))).toFixed(3)} </div>
          }
        },
        {
          dataField: "harga",
          text: "Harga",
          headerClasses: "text-right",
          formatter: (cell) => {
            return <div className="text-right"> {cell.toLocaleString("kr-KO")} </div>;
          },
          footer: (columnData) => {
            return <div className="text-right"> { parseInt(columnData.reduce((acc, item) => acc + item, 0)).toLocaleString("kr-KO")} </div>
          }
        },
       
      ],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.simpanSortir = this.simpanSortir.bind(this);
    // this.print = this.print.bind(this);
  }
  componentWillUnmount(){
    setItem("filter_sortir", []);
  }
  componentDidMount(){
    setItem("datasortir_barang", []);
  }
  simpanSortir(data) {
    this.setState({
      isLoadingSortir: true,
    });
    // let filter_sortir = getItem("filter_sortir");
    if (data.kode_gudang === undefined) {
      ToastNotification("info", "Kode Gudang Harus Dipilih");
      this.setState({
        isLoadingSortir: false,
      });
      return false;
    }
    if (data.kode_baki === undefined) {
      ToastNotification("info", "Kode Baki Harus Dipilih");
      this.setState({
        isLoadingSortir: false,
      });
      return false;
    }
    let barang = [];

    getItem("datasortir_barang").forEach((element) => {
      let row = {
        no_faktur_beli: element.no_faktur_beli,
        kode_dept: element.kode_dept,
        nama_barang: element.nama_barang,
        kode_group: element.kode_group,
        berat: element.berat,
        harga: element.harga,
        kondisi: element.kondisi,
        kode_lokasi_toko: data.kode_toko,
      };
      barang.push(row);
    });
    let hasil = {
      kode_toko: getItem("filter_sortir").kode_toko,
      kode_gudang: data.kode_gudang,
      kode_baki: data.kode_baki,
      detail_barang: barang,
      qty_bos: getItem("datasortir_barang").length,
      qty_asal: this.state.databarang.length,
      berat_asal: Number(this.state.databarang.map((list) => list.berat).reduce((a, b) => a + b, 0).toFixed(3)),
      berat_bos: Number(getItem("datasortir_barang").map((list) => list.berat).reduce((a, b) => a + b, 0).toFixed(3)),
    };
postDataNoTImeOut2("sortir-pembelian", hasil)
.then((res) => {
ToastNotification("success", res.data.message);

BuktiSortirPembelian(res.data);
let datasepuh = [];
for (let index = 0; index < 2; index++) {
let nama = "";
if(index===0){
  nama= "INTERNAL";
}else{
  nama= "LABEL";
}
let feedback = '';
feedback += `========================================\n`
feedback += `     SORTIR PEMBELIAN ${data.kode_gudang} ${nama}\n`
feedback += `========================================
No Sortir        : ${res.data.buktiStruk.no_sortir}
Kode Toko        : ${res.data.buktiStruk.kode_toko}
Tgl Sortir       : ${res.data.buktiStruk.tgl_sortir}
kondisi          : ${res.data.buktiStruk.kondisi}
Kategori         : ${res.data.buktiStruk.kode_group}
Qty Asal         : ${this.state.databarang.length}
Berat Asal       : ${Number(this.state.databarang.map((list) => list.berat).reduce((a, b) => a + b, 0).toFixed(3))}
Total Qty        : ${res.data.buktiStruk.qty_sepuh}
Total Berat      : ${res.data.buktiStruk.berat_sepuh.toFixed(3)}
Kode Baki        : ${res.data.buktiStruk.kode_baki}\n`
res.data.buktiStruk.jenis.forEach((row, index) => {
  feedback += `Jenis            : ${row[Object.keys(row)[0]]}  ${Object.keys(row)[0]}\n`
})
// feedback += `
// Qty Bos          : ${res.data.buktiStruk.qty_bos}
// Berat Bos        : ${res.data.buktiStruk.berat_bos.toFixed(3)}
// Qty Sepuh        : ${res.data.buktiStruk.qty_sepuh}
// Berat Sepuh      : ${res.data.buktiStruk.berat_sepuh.toFixed(3)}
// `
feedback += `========================================\n`
feedback += `              TERIMA KASIH              \n`
feedback += `========================================`
feedback += `






VA\n`;
datasepuh.push(feedback);
}
document.getElementById("myInput").value = datasepuh.join('');
}).then(() => {
  const element = document.createElement("a");
  const file = new Blob([document.getElementById("myInput").value], {
    type: "text/plain;charset=utf-8",
  });
  element.href = URL.createObjectURL(file);
  element.download = "autoprint_pembelian.txt";
  document.body.appendChild(element);
  element.click();
  let datacek = getItem("filter_sortir");
  getDataNoTimeOut2(
    "pembelian/ready/sortir?kondisi=" +
      datacek.kondisi +
      "&kode_dept=" +
      datacek.kode_dept +
      "&kode_group=" +
      datacek.kode_group +
      "&kode_toko=" +
      datacek.kode_toko +
      "&tgl_system=" +
      convertDate(datacek.tanggal),
  )
    .then((res) => {
      if (res.data.length === 0) {
        ToastNotification("info", "Data Yang Anda Cari Tidak Ada !!!");
        this.setState({
          databarang: res.data,
          isLoading: false,
        });
        return false;
      }
      this.setState({
        databarang: res.data,
        isLoading: false,
      });

    })
    .catch((err) => {
      this.setState({
        databarang: [],
        isLoading: false,
      });
      ToastNotification("info", err.response?.data.message);
    });
}).then(() => {
  setItem("datasortir_barang", []);
  this.setState({
    terhapus : true,
    isLoadingSortir: false,
    databarang : []
  })
})
.catch((err) => {
  console.log(err);
  ToastNotification("info", err.response?.data.message);
  this.setState({
    isLoadingSortir: false,
  });
});
}
  handleSubmit(data) {
    //   console.log(data);
    setItem("filter_sortir", data);
    this.setState({
      isLoading: true,
    });

    getDataNoTimeOut2(
      "pembelian/ready/sortir?kondisi=" +
        data.kondisi +
        "&kode_dept=" +
        data.kode_dept +
        "&kode_group=" +
        data.kode_group +
        "&kode_toko=" +
        data.kode_toko +
        "&tgl_system=" +
        convertDate(data.tanggal),
    )
      .then((res) => {
        if (res.data.length === 0) {
          ToastNotification("info", "Data Yang Anda Cari Tidak Ada !!!");
          this.setState({
            databarang: res.data,
            isLoading: false,
          });
          return false;
        }
        this.setState({
          databarang: res.data,
          isLoading: false,
        });

      })
      .catch((err) => {
        this.setState({
          databarang: [],
          isLoading: false,
        });
        ToastNotification("info", err.response?.data.message);
      });
  }

  render() {
    return (
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="#">Sortir Pembleian</Link>
          </li>
        </ol>
        <textarea
            style={{ display: "none" }}
            id="myInput"
            rows="100"
            cols="100"
          />
          
        <h1 className="page-header">Sortir Pembelian </h1>
        <Panel>
          <PanelHeader>Sortir Pembelian</PanelHeader>
          <br />
          {/* Sortir Pembelian */}
          <PanelBody>
            <HeadSortir
              onSubmit={(data) => this.handleSubmit(data)}
              isLoading={this.state.isLoading}
              data={this.state.databarang}
              columns={this.state.columns}
              columns2={this.state.columns2}
            />
            <HeadGudangBaki isLoadingSortir={this.state.isLoadingSortir} onSubmit={(data) => this.simpanSortir(data)} />
          </PanelBody>
          <br />
        </Panel>
        {this.state.isLoadingSortir ? (
          <Loading
            loading
            background="rgba(0, 0, 0, 0.35)"
            loaderColor="rgba(94, 147, 117, 1)"
          />
        ) : null}
      </div>
    );
  }
}

SortirPembelian = reduxForm({
  form: "SortirPembelian",
  enableReinitialize: true,
})(SortirPembelian);
export default connect()(SortirPembelian);
