import ValidasiTanggalLaporan from "../../../../Validasi/ValidasiTanggalLaporan";
import { getMasterToko } from "../../../../actions/datamaster_action";
import LaporanKirimBarangPdf from "./pdf/LaporanKirimBarangPdf";
import LaporanKirimBarangExel from "./exel/LaporanKirimBarangExel";
import {
  ToastNotification,
  getDataNoTimeOut2,
  InputDate,
  ReanderSelect,
  getTglSystem,
  formatGram,
  BgLoading,
  Field,
  reduxForm,
  connect,
  React,
  Component,
  convertDate,
  Tabel,
} from "../../../../components/helpers/library";
import { getDataParams2 } from "../../../../config/axios";

class HeadKirimBarang extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      no_kirim: [],
      tgl_awal: new Date(),
      tgl_akhir: new Date(),
    };
    this.setStartDate = this.setStartDate.bind(this);
    this.setLastDate = this.setLastDate.bind(this);
  }

  exportPdf() {
    LaporanKirimBarangPdf(
      this.props.LaporanDataBarang,
      this.state.tgl_awal,
      this.state.tgl_akhir
    );
  }
  setStartDate(date) {
    this.setState({ tgl_awal: new Date(date) });

    setTimeout(() => {
      this.getNoKirim("tgl");
    }, 300);
    this.setState({
      no_kirim: [],
    });
  }
  setLastDate(date) {
    this.setState({ tgl_akhir: new Date(date) });

    setTimeout(() => {
      this.getNoKirim("tgl");
    }, 300);
    this.setState({
        no_kirim: [],
    });
      
  }

  componentDidMount() {
    this.props.dispatch(getMasterToko());
    this.props.change("tgl_awal", getTglSystem());
    this.props.change("tgl_akhir", getTglSystem());
  }
  reprint(row) {
    getDataNoTimeOut2("krmbrgbos/reprint?no_kirim_bos=" + row.no_kirim)
      .then((res) => {
        this.print(res.data);
      })
      .catch((err) => {
        ToastNotification(
          "info",
          "Terjadi Kesalahan Saat Mengirim Data, Silahkan Ulangi Lagi !!"
        );
      });
  }

  setKodeToko(data) {
    this.setState({ kode_toko: data });
    setTimeout(() => {
      this.getNoKirim("toko");
    }, 300);
  }

  getNoKirim(params) {
    console.log('masuk',params)
    let hasil = {
      kode_cabang: this.state.kode_toko,
      tgl_to: convertDate(this.state.tgl_akhir),
      tgl_from: convertDate(this.state.tgl_awal),
    };
    getDataParams2("kirim-barang/no_kirim", hasil)
      .then((res) => {
        if (params === "toko") {
          if (res.data.no_kirim.length === 0) {
            ToastNotification("info", "No Kirim Tidak Tersedia");
          }else{
            ToastNotification("success", "No Kirim Tersedia");
          }
        }else{
          if (res.data.no_kirim.length === 0) {
            ToastNotification("info", "No Kirim Tidak Tersedia");
          }else{
            ToastNotification("success", "No Kirim Tersedia");
          }
        }

        this.setState({
          no_kirim: [],
        });
        this.setState({
          no_kirim: res.data.no_kirim,
        });
      })
      .catch((err) => {
        this.setState({
          no_kirim: [],
        });
        this.props.change("no_kirim", "");
        // ToastNotification('info','')
      });
  }
  render() {
    let toko = [];
    this.props.dataToko.forEach((list) => {
      let data = {
        value: list.kode_toko,
        name: list.kode_toko,
      };
      toko.push(data);
    });
    let no_kirim = [];
    this.state.no_kirim.forEach((list) => {
      let data = {
        value: list,
        name: list,
      };
      no_kirim.push(data);
    });
    const columns = [
      {
        dataField: "no_kirim",
        text: "No Kirim",
        footerAttrs: {
          colSpan: "6",
        },
        footer: `Totla Barang Terkirim : ${this.props.LaporanDataBarang.length}`,
      },
      {
        dataField: "kode_barcode",
        text: "Barcode",
      },
      {
        dataField: "kode_group",
        text: "Kode Group",
      },

      {
        dataField: "kode_dept",
        text: "Kode Jenis",
      },
      {
        dataField: "nama_barang",
        text: "Nama Barang",
      },
      {
        dataField: "tipe_barang",
        text: "Tipe Barang",
      },

      {
        dataField: "berat",
        text: "Berat",
        headerClasses: "text-right",
        formatter: (cell) => {
          return <div className="text-right"> {formatGram(cell, 2)} </div>;
        },
        footer: (columnData) => {
          return (
            <div className="text-right">
              {" "}
              {formatGram(
                columnData.reduce((acc, item) => acc + item, 0),
                2
              )}{" "}
            </div>
          );
        },
      },
      // {
      //   dataField: "action",
      //   text: "Action",
      //   csvExport: false,
      //   headerClasses: "text-center",
      // formatter: (rowcontent, row) => {
      //   return (
      //     <div className="row text-center">
      //       <div className="col-12">
      //         <button
      //           type="button"
      //           onClick={() => this.reprint(row)}
      //           className="btn btn-primary mr-3"
      //         >
      //           {" "}
      //           <i className="fa fa-print"></i>
      //         </button>
      //       </div>
      //     </div>
      //   );
      // },
      // },
    ];

    return (
      <form onSubmit={this.props.handleSubmit} autoComplete="off">
        <div className="row">
          <div className="col-lg-3">
            <Field
              name="tgl_awal"
              component={InputDate}
              label="Tanggal Dari"
              type="text"
              selected={this.state.tgl_awal}
              onChange={(date) => this.setStartDate(date)}
              placeholder="Masukan Tanggal Dari"
            />
          </div>

          <div className="col-lg-3">
            <Field
              name="tgl_akhir"
              component={InputDate}
              type="text"
              selected={this.state.tgl_akhir}
              onChange={(date) => this.setLastDate(date)}
              label="Tanggal Akhir"
              placeholder="Masukan Tanggal Akhir"
            />
          </div>
          <div className="col-lg-3">
            <Field
              name="kode_toko"
              component={ReanderSelect}
              options={toko}
              type="text"
              label="Kode Toko"
              onChange={(data) => this.setKodeToko(data)}
              placeholder="Pilih Kode Toko"
            />
          </div>
          <div className="col-lg-3">
            <Field
              name="no_kirim"
              component={ReanderSelect}
              type="text"
              label="Nomor Kirim"
              placeholder="Masukan Nomor Kirim"
              options={no_kirim}
            />
          </div>
          <div className="col-lg-12 row mt-2 justify-content-end mb-3">
            <div className="col-lg-3">
              <button
                type="submit"
                value="PDF"
                name="type_btn"
                className="btn btn-primary btn-block"
              >
                {this.props.isLoading ? (
                  <>
                    <i className="fas fa-spinner fa-spin"></i> &nbsp; Sedang
                    Mencari
                  </>
                ) : (
                  "Cari Laporan"
                )}
              </button>
            </div>
          </div>
          <div className="col-12">
            <Tabel
              keyField="kode_barcode"
              data={this.props.LaporanDataBarang || []}
              columns={columns}
              empty={true}
              textEmpty="Data Laporan Kirim Barang Kosong"
            />
          </div>
          <div className={this.props.export ? "col-lg-12" : "col-lg-12 d-none"}>
            {/* <label htmlFor="">&nbsp;</label> */}
            <div className="row">
              <div className="col-lg-6">
                <button
                  type="button"
                  onClick={() => this.exportPdf()}
                  className="btn btn-warning btn-block"
                >
                  {" "}
                  Export PDF{" "}
                </button>
              </div>
              <div className="col-lg-6">
                <LaporanKirimBarangExel data={this.props.LaporanDataBarang} />
              </div>
            </div>
          </div>
        </div>
        {this.props.isLoading && <BgLoading />}
      </form>
    );
  }
}

HeadKirimBarang = reduxForm({
  form: "HeadKirimBarang",
  enableReinitialize: true,
  validate: ValidasiTanggalLaporan,
})(HeadKirimBarang);
export default connect((state) => {
  return {
    dataToko: state.datamaster.getMasterToko,
    initialValues: {
      tgl_awal: getTglSystem(),
      tgl_akhir: getTglSystem(),
    },
  };
})(HeadKirimBarang);
