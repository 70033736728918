import {
  React,
  connect,
  Link,
  reduxForm,
  Panel,
  PanelHeader,
  PanelBody,
} from "../../../components/helpers/library";
import HeadPindahPesanan from "./HeadPindahPesanan";

let PindahPesanan = () => {
  const handleSubmit = (data) => {
    console.log(data);
  };
  return (
    <div>
      <ol className="breadcrumb float-xl-right">
        <li className="breadcrumb-item">
          <Link to="#">Pesanan</Link>
        </li>
        <li className="breadcrumb-item active">Pindah Pesanan</li>
      </ol>
      <h1 className="page-header">Pindah Pesanan </h1>
      <Panel>
        <PanelHeader>Pindah Pesanan</PanelHeader>
        <br />
        {/* Pindah Pesanan */}
        <PanelBody>
          <HeadPindahPesanan onSubmit={(data) => handleSubmit(data)} />
        </PanelBody>

        <br />
      </Panel>
    </div>
  );
};

PindahPesanan = reduxForm({
  form: "PindahPesanan",
  enableReinitialize: true,
})(PindahPesanan);
export default connect()(PindahPesanan);
