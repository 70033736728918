import jsPDF from "jspdf";
import "jspdf-autotable";
import {
  getToday,
  getUserdata,
  localStoragedecryp,
} from "../../../../../components/helpers/function";
// Date Fns is used to format the dates we receive

// define a generatePDF function that accepts a tickets argument
const LaporanBahanTukangRekap = (data = "") => {
  // initialize jsPDF
  console.log(data);
  const doc = new jsPDF("l", "mm", [297, 210]);
  //   let data = JSON.parse(localStorage.getItem("tt_pengeluaran_barang")) || [];
  let tableRows = [];
  let tableColumn = [];

  //   let footRows = [];
  let finalY = 30;
  doc.text("LAPORAN BAHAN TUKANG REKAP", 14, 15);
  doc.setFontSize(20);
  doc.text(getUserdata().nama_toko, 200, 15);
  doc.setFontSize(8);
  let jml_alamat = getUserdata().alamat_toko.length;
  if (jml_alamat > 20) {
    doc.text(getUserdata().alamat_toko.slice(0, 48), 200, 20);
  }
  if (jml_alamat > 50) {
    doc.text(getUserdata().alamat_toko.slice(48, 90), 200, 25);
  }

  doc.setFontSize(10);
  doc.setProperties({
    title: "Bahan Tukang Rekap",
  });
  doc.text(
    `PERIODE : ${
      localStoragedecryp("tgl_laporan", [], true).length === 0
        ? null
        : localStoragedecryp("tgl_laporan", [], true).tgl_awal +
          " s/d " +
          localStoragedecryp("tgl_laporan", [], true).tgl_akhir
    }`,
    14,
    25
  );

  //   tableRows.push(tableColumn)
  tableColumn = [
    [
      {
        content: `TUKANG`,
      },
      {
        content: `KATEGORI`,
      },
      {
        content: `TOTAL BAHAN`,
        styles: {
          halign: "right",
        },
      },
      {
        content: `PAKAI BAHAN`,
        styles: {
          halign: "right",
        },
      },
      {
        content: `PATRI`,
        styles: {
          halign: "right",
        },
      },
      {
        content: `SISA BAHAN`,
        styles: {
          halign: "right",
        },
      },
    ],
  ];
  // data.forEach((rows, no) => {
  //   let head = [
  //     {
  //       content: `NO FAKTUR : ${rows._id}`,
  //       colSpan: 12,
  //       styles : {
  //           lineWidth: 0.02,
  //           lineColor: "#000",
  //       }
  //     },
  //   ];

  //   tableRows.push(head);
  let patri = 0;
  data.forEach((item, no) => {
    if (item.patri !== 0) {
      patri = item.patri.toString();
      patri = patri.slice(0, patri.indexOf(".") + 4);
    } else {
      patri = 0;
    }
    let row = [
      item.kode_tukang,
      item.kode_kategori,
      {
        content: item.total_bahan.toFixed(3),

        styles: {
          halign: "right",
        },
      },

      {
        content: item.pakai_bahan.toFixed(3),
        styles: {
          halign: "right",
        },
      },
      {
        content: item.patri.toFixed(3),
        styles: {
          halign: "right",
        },
      },
      {
        content: item.sisa_bhn.toFixed(3),
        styles: {
          halign: "right",
        },
      },
    ];
    tableRows.push(row);
  });
  let grand_total_bahan = 0;
  let total_pakai_bahan = 0;
  let total_patri = 0;
  let total_sisa_bahan = 0;
  data.forEach((element) => {
    grand_total_bahan += element.total_bahan;
    total_pakai_bahan += element.pakai_bahan;
    total_patri += element.patri;
    total_sisa_bahan += element.sisa_bhn;
  });

  let footer = [
    {
      content: `GRAND TOTAL : `,
      colSpan: 2,
      styles: {
        halign: "left",
        fillColor: "#E8E5E5",
        textColor: "#000",
      },
    },
    {
      content: grand_total_bahan.toFixed(3),
      styles: {
        halign: "right",
        fillColor: "#E8E5E5",
        textColor: "#000",
      },
    },
    {
      content: total_pakai_bahan.toFixed(3),
      styles: {
        halign: "right",
        fillColor: "#E8E5E5",
        textColor: "#000",
      },
    },
    {
      content: total_patri.toFixed(3),
      styles: {
        halign: "right",
        fillColor: "#E8E5E5",
        textColor: "#000",
      },
    },
    {
      content: total_sisa_bahan.toFixed(3),
      styles: {
        halign: "right",
        fillColor: "#E8E5E5",
        textColor: "#000",
      },
    },
  ];
  tableRows.push(footer);

  let printed = [
    {
      content: `Printed By ${
        localStoragedecryp("userdata").user_id
      } / ${getToday()}`,
      colSpan: 12,
      styles: {
        // lineWidth: 0.1,
        fontStyle: "italic",
        textColor: "#000",
        // fillColor: "#E8E5E5"
      },
    },
  ];
  tableRows.push(printed);

  doc.autoTable({
    head: tableColumn,
    body: tableRows,
    // foot: footRows,
    startY: finalY,
    theme: "plain",
    // rowPageBreak: "avoid",
    // pageBreak: "avoid",
    margin: { top: 10 },
    bodyStyles: {
      // lineWidth: 0.02, lineColor: "#000",
      fontSize: 8,
    },
    headStyles: {
      fontSize: 8,
      //   lineWidth: 0.02,
      //   lineColor: "#000",
      fillColor: "#E8E5E5",
      textColor: "#000",
    },
    tableLineColor: [255, 255, 255],
    tableLineWidth: 1,
  });
  tableRows = [];
  tableColumn = [];
  finalY = doc.autoTableEndPosY() + 20;

  const pages = doc.internal.getNumberOfPages();
  const pageWidth = doc.internal.pageSize.width; //Optional
  const pageHeight = doc.internal.pageSize.height; //Optional
  doc.setFontSize(10); //Optional
  for (let j = 1; j < pages + 1; j++) {
    let horizontalPos = pageWidth / 2; //Can be fixed number
    let verticalPos = pageHeight - 10; //Can be fixed number
    doc.setPage(j);
    doc.text(`${j} of ${pages}`, horizontalPos, verticalPos, {
      align: "center",
    });
  }
  // doc.autoPrint();
  // doc.save(`PenerimaanSUpplier.pdf`);
  var string = doc.output("datauristring");
  var x = window.open();
  x.document.open();
  x.document.write(
    "<html><head><title>Bahan Tukang Rekap</title></head><body style='margin:0 !important'><embed width='100%' height='100%'  src=" +
      string +
      "></embed></body></html>"
  );
  // setInterval(() => {
  //   x.close();
  // }, 1000);
};

export default LaporanBahanTukangRekap;
